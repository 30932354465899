import React, {useRef} from "react";
import {useTranslator} from "eo-locale";
import CustomButton from "../../atoms/CustomButton";
import {IUsersDetail} from "../ModalUserReport";
import dayjs from "dayjs";
import BigNumber from "bignumber.js";

interface IUsersPngReportButton {
  data: IUsersDetail | undefined,
  period: string | undefined,
  totalCount: number | undefined,
  device: string
}

const UsersPngReportButton: React.FC<IUsersPngReportButton> = (
  {
    data,
    period,
    totalCount,
    device
  }
) => {
  const refPng = useRef<HTMLCanvasElement>(null);
  const translator = useTranslator();

  function getFormatDataPng() {
    if(!data) return;

    // 300 и 70 это высота и ширина в dpi
    const elCavas = refPng.current,
      ctx = elCavas?.getContext('2d'),
      link = document.createElement('a'),
      itemCommon = data?.getUsersDetail.count,
      itemChart = data?.getUsersDetail.chart

    if(!ctx){
      return;
    }

    let xBlock = 300,
      yBlock = 85,
      xText = 315,
      yText = 117,
      difY = 50,
      difX = 200,
      widthBlock = 200,
      heightBlock = 50
    ctx.font = "30px Verdana";
    ctx.fillStyle = "black";
    ctx.fillText(`${device === 'Desktop' ? 'Desktop/Web': device} report`, 340, 47);
    ctx.font = `${18}px Verdana`;

    for (let i = 0; i < itemChart.length; i++) {
      const date = dayjs(itemChart[i].toDate)
      let normalDate = date.format('DD.MM.YYYY hh:mm'),
        copyXText = xText,
        copyXBlock = xBlock;

      ctx.beginPath();
      ctx.fillText(normalDate, copyXText+10, yText);
      ctx.strokeRect(copyXBlock, yBlock, widthBlock, heightBlock);
      copyXText += difX
      copyXBlock += difX
      ctx.strokeRect(copyXBlock, yBlock, widthBlock, heightBlock);
      copyXText += 80
      ctx.fillText(new BigNumber(itemChart[i].count).toFormat(), copyXText, yText)
      copyXText -= 40
      yText += difY
      yBlock += difY
    }
    ctx.font = "18px Verdana";
    xText = 10
    difY = 35
    difX = 400

    let copyXText = xText
    ctx.fillText(translator.translate('usersDetailItemAll'), copyXText, yText)
    yText += difY
    ctx.fillText(totalCount ? new BigNumber(totalCount).toFormat() : "-", copyXText, yText)
    yText += difY
    ctx.fillText(translator.translate('usersDetailItemRegistr'), copyXText, yText)
    yText += difY
    ctx.fillText(new BigNumber(itemCommon).toFormat(), copyXText, yText)
    copyXText += difX
    yText -= difY
    ctx.fillText(translator.translate('usersDetailItemInc'), copyXText, yText)
    yText += difY
    ctx.fillText(
      `${totalCount && new BigNumber(itemCommon / (totalCount - itemCommon) * 100).toPrecision(3)}%`,
      copyXText, yText
    )

    ctx.closePath();
    link.download = `${device === 'Desktop' ? 'Desktop/Web' : device}_report.png`;
    elCavas?.toDataURL("image/png")
    link.href = elCavas ? elCavas.toDataURL() : "";
    link.click();
    ctx.clearRect(0, 0, elCavas ? elCavas.width : 0, elCavas ? elCavas?.height : 0);
  }

  return(
    <>
      <CustomButton
        onClick={getFormatDataPng}
        height='37px'
      >
        {translator.translate('modalReportBtn3')}
      </CustomButton>

      <canvas
        style={{ display: 'none' }}
        ref={refPng}
        id="canvas"
        width="920"
        height={period === 'Week' ? '900' : period === 'Month' ? '3200' : '1500'}
      >
        {translator.translate('modalReportBtn3')}
      </canvas>
    </>
  )
}

export default UsersPngReportButton;

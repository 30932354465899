import {gql} from "apollo-boost";

export const TRANSACT_ALL = gql`
  query transactionsCommonInfo($symbol: CryptoSymbol!, $fromDate: Timestamp!, $toDate: Timestamp!){
    transactionsCommonInfo(filter:{symbol: [$symbol], fromDate: $fromDate, toDate: $toDate}) {
      symbol
      input {
        amount
        count
        commission
      }
      output {
        amount
        count
        commission
      }
      internal {
        amount
        count
        commission
      }
    }
  }
`

export const TRANSACT_DATE_PERIOD = gql`
query getChartDataPeriod(
  $symbol: CryptoSymbol!
  $toDate: Timestamp
  $fromDate: Timestamp
  $period: Period!
) {
  getChartData(
    period: $period
    symbol: $symbol
    fromDate: $fromDate
    toDate: $toDate
  ) {
    fromDate
    toDate
    input {
      amount
      count
      commission
    }
    output {
      amount
      count
      commission
    }
    internal {
      amount
      count
      commission
    }
  }
}
`;

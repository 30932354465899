import {gql} from "apollo-boost";

export const GET_MESSAGES = gql`
  query getSupportMessage($limit: Int!, $skip: Int!, $chatId: Int) {
    getSupportMessage(query:{
      limit: $limit,
      skip: $skip,
      chatId: $chatId,
      orderByIdDesc: true
    }) {
      pagination {
        next
      }
      messages {
        _id,
        toId,
        fromId
        chatId
        readed,
        deleted,
        message
        date,
        media {
          fileId,
          filename,
          thumbId,
          mimeType
        }
      }
    }
}
`;

export const MARK_MESSAGES_READ = gql`
  mutation readSupportMessage($chatId: Int!, $msgId: Int!){
    readSupportMessage(input: {chatId: $chatId, msgId: $msgId}) {
      success
    }
  }
`

import styled, { css } from "styled-components";
import { ILink } from "./Link";

const downloadLink = () => css`
  height: 37px;
  padding: 10px 5px;
  background-color: white;
  border: none;
  border-radius: 3px;
  text-align: center;
`;

export const StyledLink = styled.a<ILink>`
  display: block;
  width: 100%;
  font-size: 16px;
  font-family: "Roboto Medium",sans-serif;
  color: black;
  cursor: pointer;
  text-decoration: none;
  ${({ styledType }) => {
    switch (styledType) {
      case "DOWNLOAD": return downloadLink();
    }
  }}
  ${({ styled }) => styled}
`;

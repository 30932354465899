import React from "react";
import { StyledLabel } from "./style";

export interface ILabelProps {
  icon?: string;
  isError?: boolean;
}
export const CLabel: React.FC<ILabelProps> = ({ children, ...otherProps }) => {
  return <StyledLabel {...otherProps}>{children}</StyledLabel>;
};

import { useTranslator } from "eo-locale";
import { useState, useEffect } from "react";
import { useMutation } from "react-apollo";
import { WITH_DRAWAL } from "../../../gql/useMutation/WITH_DRAWAL";
export const useModalWithdrawalRequest = () => {
  const [requestSuccess, setRequestSuccess] = useState(false);
  const [requestError, setRequestError] = useState<string | null>(null);
  const translator = useTranslator();

  const [withdrawRequest, { loading }] = useMutation(WITH_DRAWAL, {
    onCompleted: () => setRequestSuccess(true),
    onError: (err) => {
      if (err?.graphQLErrors[0]?.message) {
        setRequestError(err?.graphQLErrors[0]?.message)
      } else {
        setRequestError(translator.translate("modalWithdrawalErrorIncorrectInputData"))
      }    
    }
  });

  useEffect(() => {
    if (requestSuccess) {
      setRequestSuccess(false);
    }

    if (requestError) {
      setRequestError(null);
    }
  }, [requestError, requestSuccess]);

  return {
    withdrawRequest,
    loading,
    requestSuccess,
    requestError,
  };
};

import styled from "styled-components";
import { ITitleProps } from ".";

const StyledTitle = styled.h1<ITitleProps>`
  margin: 0;
  font: normal normal 36px/1.2 Roboto Bold, sans-serif;
  ${({ styles }) => styles}
`;

export { StyledTitle };

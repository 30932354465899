import { useLazyQuery, useQuery } from "react-apollo";
import { useHistory } from "react-router-dom";
import { COMISS_INFO } from "../../../gql/useQuery/COMISS_INFO";
import { HISTORY_COMISS } from "../../../gql/useQuery/HISTORY_COMISS";
import { locationState } from "./type";

export const useComissRequest = (symbol: string) => {
  const history = useHistory<locationState>();
  const { data: infoData, loading: loadingInfo } = useQuery(COMISS_INFO);
  const [getHistoryData, { data: historyData, loading: historyLoading }] = useLazyQuery(HISTORY_COMISS, {
  
    onCompleted: () => {
      history.push('/comis/history', {historyData: historyData.getCommissionsHistory, symbol})
    },
    onError: () => {},
    fetchPolicy: "network-only"
  })


  return {
    infoData: infoData?.getCommissionsBalance,
    getHistoryData,
    loading: loadingInfo || historyLoading,
  }
}
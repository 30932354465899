import React, {useState} from "react";
import Modal from "../../atoms/Modal";
import {Background, Title, Selects, Controls} from './styled';
import {useTranslator} from "eo-locale";
import Select, {SelectItemType} from "../../atoms/Select";
import CustomButton from "../../atoms/CustomButton";
import {useMutation} from "@apollo/react-hooks";
import {UserDocumentType, UserQueryType} from "../../organism/UsersInfo";
import UserInfo from "../../../store/UserInfo";
import {USERS_ALL_SEARCH} from "../../organism/UsersInfo/queries";
import Loader from "react-loader-spinner";
import {VERIFIC_STATUS} from "./queries";

type statusType = "EMPTY" |
  "VERIFIED" |
  "PENDING" |
  "REJECTED";

type reasonsType =  "FILE_EMPTY" |
  "FILE_CORRUPTED" |
  "DOCUMENT_DUPLICATE" |
  "DOCUMENT_UNREADABLE" |
  "SELFIE_DOESNT_MATCH_PHOTO";

interface IModalChangeDocumentStatus {
  enabled?: boolean,
  item: UserDocumentType,
  onChange: React.Dispatch<UserDocumentType>,
  onClose: React.Dispatch<boolean>,
}

const ModalChangeDocumentStatus: React.FC<IModalChangeDocumentStatus> = ({
  enabled = false,
  onChange,
  onClose,
  item
}) => {
  const [status, setStatus] = useState<statusType>(item.status);
  
  const translator = useTranslator();

  const statusSelectItems: Array<SelectItemType<statusType>> = [
    {
      key: 'VERIFIED',
      value: translator.translate('usersVerificationStatus_verified')
    },
    {
      key: 'REJECTED',
      value: translator.translate('usersVerificationStatus_rejected')
    }
  ]

  const statusSelectReason: Array<SelectItemType<reasonsType>> = [
    {
      key: 'FILE_EMPTY',
      value: translator.translate('usersVerificationReason_file_empty')
    },
    {
      key: 'FILE_CORRUPTED',
      value: translator.translate('usersVerificationReason_file_corrupted')
    },
    {
      key: 'DOCUMENT_DUPLICATE',
      value: translator.translate('usersVerificationReason_document_duplicate')
    },
    {
      key: 'DOCUMENT_UNREADABLE',
      value: translator.translate('usersVerificationReason_document_unreadable')
    },
    {
      key: 'SELFIE_DOESNT_MATCH_PHOTO',
      value: translator.translate('usersVerificationReason_selfie_doesnt_match_photo')
    }
  ];
  const [reasons, setReasons] = useState<reasonsType[]>(
    item.reasons || [statusSelectReason[0].key]
  );
  const [setKycDocumentStatus, {loading: loadingStatus}] = useMutation(VERIFIC_STATUS, {
    update(proxy){
      const prevData: UserQueryType | null = proxy.readQuery({query: USERS_ALL_SEARCH, variables: {
        email: UserInfo.email,
        period: UserInfo.transactPeriod,
        skip: 12 * UserInfo.transactActivePage,
        from: UserInfo.fromDate?.getTime(),
        to: UserInfo.fromDate?.getTime()
      }});
      let newKycDocs = prevData?.getUserBy.kycDocuments;
      let el = newKycDocs?.find(el => el.document.file.localId === item?.document.file.localId);
      let index = el && newKycDocs?.indexOf(el);

      if(newKycDocs && index !== undefined){
        if(status){
          newKycDocs[index].status = status;
        }
        if(status === 'REJECTED' && reasons){
          newKycDocs[index].reasons = reasons;
        } else {
          newKycDocs[index].reasons = [];
        }

        proxy.writeQuery({
          query: USERS_ALL_SEARCH,
          data: {
            getUserBy: {
              ...prevData?.getUserBy,
              kycDocuments: newKycDocs
            }
          }
        })
      }
    }
  });

  const onCloseClick = () => {
    onClose(false);
  }

  const onStatusSelect = (item: SelectItemType<statusType>) => {
    if(item.key !== 'REJECTED'){
      setReasons([]);
    }
    setStatus(item.key);
  }

  const onReasonSelect = (item: SelectItemType<reasonsType>) => {
    setReasons([item.key]);
  }

  const onSendClick = () => {
    setKycDocumentStatus({
      variables: {
        userId: UserInfo.id,
        documentId: item?.document.file.localId,
        status: status,
        reasons: reasons ? reasons : []
      }
    }).then(() => {
      onChange({...item, status: status, reasons: reasons});
      onCloseClick();
    })
  }
  console.log("reasons", reasons); 
  console.log("status", status); 
  console.log("item.reasons", item.reasons);
  return(
    <>
      {
        loadingStatus ? (
          <div className="loader"><Loader type="Oval" color="#fff" height={100} width={100}/></div>
        ) : (
          <Modal
            enabled={enabled}
            onBackgroundClick={onCloseClick}
          >
            <Background>
              <Title>
                {translator.translate('ModalChangeStatusUserDocument', {value: item?.document.file.name})}
              </Title>

              <Selects>
                <Select
                  title={translator.translate('ModalSelectStatusTitle')}
                  items={statusSelectItems}
                  selectedItem={status}
                  onSelect={onStatusSelect}
                  margin="0 0 15px 0"
                />

                {
                  status === 'REJECTED' ? (
                    <Select
                      title={translator.translate('ModalSelectReasonTitle')}
                      items={statusSelectReason}
                      selectedItem={reasons[0]}
                      onSelect={onReasonSelect}
                    />
                  ) : null
                }
              </Selects>

              <Controls>
                <CustomButton
                  onClick={onSendClick}
                  margin="0 20px 0 0"
                  height='53px'
                  disabled={!status || (status === 'REJECTED' && !reasons)}
                >
                  {translator.translate('ModalChangeStatusVerifySave')}
                </CustomButton>

                <CustomButton
                  onClick={onCloseClick}
                  height='53px'
                >
                  {translator.translate('ModalChangeStatusVerifyCancel')}
                </CustomButton>
              </Controls>
            </Background>
          </Modal>
        )
      }
    </>
  )
}

export default ModalChangeDocumentStatus;

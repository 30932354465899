import styled from "styled-components";
import {IChatMessage} from "./index";

export const ChatMessage = styled.li<IChatMessage>`
  position: relative;
  border-radius: 10px;
  padding: 15px 20px;
  background-color: #ffffff;
  overflow-wrap: break-word;
  max-width: 70%;
  min-width: 140px;
  margin: ${({margin}) => margin};
`

export const Content = styled.div`
  position: relative;
  color: #000000;
  margin-bottom: 7px;
  min-width: 100px;
`

export const Image = styled.img`
  width: 100%;
  cursor: pointer;
`

export const StyledVideoContainer = styled.div`
  position: relative;
  cursor: pointer;
`

export const StyledVideoIcon = styled.img`
  width: 40px;
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
`

export const Deleted = styled.div`
  position: absolute;
  left: 10px;
  bottom: 5px;
  color: red;
  font-size: 10px;
`

export const Date = styled.div`
  position: absolute;
  bottom: 5px;
  right: 25px;
  font-size: 10px;
  color:  gray;
`

export const IconContainer = styled.div<{color: string}>`
  position: absolute;
  bottom: 13px;
  right: 10px;
  width: 11px;
  height: 8px;
  & use{
    color: ${({color}) => color};
  }
`

import { useTranslator } from "eo-locale";
import React from "react";
import { Wrapper } from "../../atoms/Wrapper";
import { useLoginRequest } from "./useLoginRequest";
import { useFormControl } from "./../../../hooks/useFormControl";
import { CLoader } from "../../atoms/CLoader";
import Loader from "react-loader-spinner";
import { CTitle } from "../../atoms/CTitle";
import { CForm } from "../../atoms/CForm";
import { CSubTitle } from "../../atoms/CSubTitle";
import { CInput } from "../../atoms/CInput";
import { CAlert } from "../../atoms/CAlert";
import CButton from "../../atoms/CButton";
import { useHistory } from "react-router-dom";
import { StyledLoginPage } from "./style";
import { useLoginError } from "./useLoginError";
import { LoginFormData } from "./type";

export const LoginPage: React.FC = (props) => {
  const translator = useTranslator();
  const history = useHistory();
  const { loginQuery, requestError, loading } = useLoginRequest();
  const {
    control,
    errors,
    onSubmit,
    reset,
    clearErrors,
  } = useFormControl<LoginFormData>(
    {
      defaultValues: { username: "", password: "" },
      reValidateMode: "onSubmit",
    },
    onSubmitHandler
  );
  const { errorMessage, setErrorMessage, IsErrorName } = useLoginError(
    errors,
    requestError,
    reset
  );
  const inputs = [
    {
      name: "username",
      type: "string",
      placeholder: `${translator.translate("loginInputLogin")}`,
      styledType: "DARK" as const,
      styles: { "margin-bottom": "15px" },
      rules: {
        required: translator.translate("loginRequiredError"),
      },
    },
    {
      name: "password",
      type: "password",
      placeholder: `${translator.translate("loginInputPass")}`,
      styledType: "DARK" as const,
      styles: { "margin-bottom": "15px" },
      rules: {
        required: translator.translate("loginRequiredError"),
      },
    },
  ];

  function onSubmitHandler(formData: LoginFormData) {
    const { username, password } = formData;
    loginQuery({
      variables: {
        username,
        password,
      },
    });
  }

  const onForgotHandler = () => {
    reset();
    history.push("/forgot-pass", {
      currentStep: "RECOVERY_PASS"
    });
  };

  const onChangeHandler = () => {
    clearErrors();
    setErrorMessage(null);
  };

  return (
    <StyledLoginPage>
      <Wrapper
        styles={{
          "justify-content": "center",
          "align-items": "center",
          "min-height": "80vh",
        }}
      >
        <CLoader trigger={loading}>
          <Loader visible={true} color={"white"} type="Oval" width={100} height={100} />
        </CLoader>
        <Wrapper styles={{ "flex-flow": "column nowrap", "width": "340px" }}>
          <CTitle styles={{ margin: "0 0 15px 0" }}>
            {translator.translate("title")}
          </CTitle>
          <CForm onSubmit={onSubmit}>
            <CSubTitle styles={{ margin: "0 0 10px 0" }}>
              {translator.translate("loginSubtitle")}
            </CSubTitle>
            {inputs.map((inputData, index) => (
              <CInput
                {...inputData}
                key={index}
                control={control}
                isError={IsErrorName(inputData.name)}
                sendInputData={onChangeHandler}
              />
            ))}
            <Wrapper styles={{ display: "flex", "margin-bottom": "15px" }}>
              {errorMessage && (
                <CAlert styledType="ERROR">{errorMessage.message}</CAlert>
              )}
              <CButton
                type="button"
                styles={{
                  "margin-left": "auto",
                  "margin-bottom": "5px",
                  "font-family": "Roboto Light",
                  "font-size": "14px",
                  "&:hover": {
                    "color": "#bbbbbb",
                  },
                }}
                onClick={onForgotHandler}
              >
                {translator.translate("loginForgotText")}
              </CButton>
            </Wrapper>
            <CButton type="submit" styledType="LIGHT">
              {translator.translate("loginButtonText")}
            </CButton>
          </CForm>
        </Wrapper>
      </Wrapper>
    </StyledLoginPage>
  );
};

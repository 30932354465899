import dayjs, { Dayjs } from "dayjs";
import React from "react";
import { Period } from "../components/organism/TransactItem/type";

type ReturnUseCalendarControl = [
  (date: Date) => void,
  (date: Date) => void,
  { fromDate: Dayjs; toDate: Dayjs, maxToDate: Date, minToDate: Date, maxFromDate: Date }
];

export const useCalendarControl = (
  period: Period | "Change",
  defaultFromDate?: Dayjs,
  defaultToDate?: Dayjs
): ReturnUseCalendarControl => {
  const [fromDate, setFromDate] = React.useState<Dayjs>(dayjs().startOf('week'));
  const [toDate, setToDate] = React.useState<Dayjs>(dayjs());
  const maxToDate = fromDate.isSame(dayjs(), 'month') ? dayjs().toDate() : fromDate.endOf('month').toDate();
  const minToDate = fromDate.toDate();
  const maxFromDate = dayjs().toDate();

  const onFromDateChange = React.useCallback((date: Date) => {
    const MAX_TO_DATE = dayjs(date).isSame(dayjs(), 'month') ? dayjs() : dayjs(date).endOf('month')
    const setCorrectPeriod = () => {
      setFromDate(dayjs(date));
      setToDate(MAX_TO_DATE);
    };
    // запрещает выбирать один и тот же день в диапазоне
    if (dayjs(date).isSame(toDate, 'day') || dayjs(date).isSame(MAX_TO_DATE, "day")) return;

    if (dayjs(date).isAfter(toDate, "day")) {
      setCorrectPeriod();
    } else if (dayjs(date).isBefore(toDate, "month")) {
      setCorrectPeriod();
    } else {
      setFromDate(dayjs(date));
    }
  }, [toDate]);

  const onToDateChange = React.useCallback((date: Date) => {
    // при выборе кастомного периода календарь всегда возвращает время 00:00:00
    // поэтому запрос на серве расценивает 17 число как 16 и, соответственно, не включает 17
    // следовательно надо добавить какое-то кол-во часов, чтобы дата в запросе обрабатывалась корректно
    // выбрал 7 часов
    if (dayjs(date).isSame(fromDate, 'day')) return;
    setToDate(dayjs(date).add(7, "hour"));
  },[fromDate]);

  // т.к. выбор Week Month Year не влияет на fromDate и toDate, то их установка идет через useEffect
  React.useEffect(() => {
    switch (period) {
      case "Week": {
        // начало недели у dayjs с воскресенься, а график идет с понедельника, 
        // поэтому сдвигаем на 1 день вперед
        setFromDate(dayjs().startOf('week').add(1, "day"));
        setToDate(dayjs());
        break;
      }
      case "Month": {
        setFromDate(dayjs().startOf("month"));
        setToDate(dayjs());
        break;
      }
      case "Year": {
        setFromDate(dayjs().startOf("year"));
        setToDate(dayjs());
        break;
      }
      default: {
        setFromDate(dayjs().startOf("month"));
        setToDate(dayjs());
      }
    }
  }, [period]);

    // Установка даты на основе данных переданных через аргументы хука
    React.useEffect(() => {
      if (defaultToDate) setToDate(defaultToDate);
      if (defaultFromDate) setFromDate(defaultFromDate);
    }, [defaultFromDate, defaultToDate])

  return [
    onToDateChange,
    onFromDateChange,
    { fromDate, toDate, maxToDate, minToDate, maxFromDate }
  ];
};

import React, {useEffect, useRef, useState} from "react";
import arrow from "../../../assets/img/arrow.svg"
import DateSelect from "../../atoms/DateSelect";
import { useLazyQuery } from "@apollo/react-hooks";
import ExcelTableDownload from "../../atoms/ExcelTableDownload";
import {ExcelColsType} from "../../atoms/ExcelTableDownload";
import PDFTableDownload from "../../atoms/PDFTableDownload";
import {PDFHeadType} from "../../atoms/PDFTableDownload";
import PNGTableDownload from "../../atoms/PNGTableDownload";
import {PNGHeadItemType} from "../../atoms/PNGTableDownload";
import {TABLE_DATA} from "./queries";
import {Title, Arrow, Button, Modal, ModalBackground, Period1, Period2} from './styled';
import {useTranslator} from "eo-locale";
import {useRequestError} from "../../../hooks/useRequestError/useRequestError";

type TableItemType = {
  txId: string,
  index: number,
  type: string,
  date: string,
  time: string,
  name: string,
  cred: string,
  deb: string,
  commission: string,

  btcBurse: string,
  ethBurse: string,
  eosBurse: string,
  usdtBurse: string,
  trxtBurse: string,

  btcBot: string,
  ethBot: string,
  eosBot: string,
  usdtBot: string,
  eurtBot: string,
  cnhtBot: string,
  xautBot: string,
  trxBot: string,
  usdttrxBot: string,
  usdBot: string,
  eurBot: string,
  cnyBot: string,

  btcRate: string,
  ethRate: string,
  eosRate: string,
  trxRate: string,
  sumFiat: string
}

enum OperationTypes {
  BOT_TRADE = "Обмен бота с пользователем",
  BOT_DEPOSIT = "Зачисление средств на бота",
  BOT_WITHDRAW = "Вывод средств с бота",
  BURSE_TRADE = "Обмен на бирже",
  BURSE_DEPOSIT = "Зачисление средств на биржу",
  BURSE_WITHDRAW = "Вывод средств с биржи"
}

const excelCols: Array<ExcelColsType> = [
  {
    label: "#",
    value: "index"
  },
  {
    label: "Date",
    value: "date"
  },
  {
    label: "Operation type",
    value: "name"
  },
  {
    label: "Debit",
    value: "deb"
  },
  {
    label: "Credit",
    value: "cred"
  },
  {
    label: "Comission",
    value: "commission"
  },
  {
    label: 'TxId',
    value: 'txId'
  },
  {
    label: "Balance burse BTC",
    value: "btcBurse"
  },
  {
    label: "Balance burse ETH",
    value: "ethBurse"
  },
  {
    label: "Balance burse EOS",
    value: "eosBurse"
  },
  {
    label: "Balance burse USDT",
    value: "usdtBurse"
  },
  {
    label: "Balance burse TRX",
    value: "trxtBurse"
  },
  {
    label: "Balance bot BTC",
    value: "btcBot"
  },
  {
    label: "Balance bot ETH",
    value: "ethBot"
  },
  {
    label: "Balance bot EOS",
    value: "eosBot"
  },
  {
    label: "Balance bot USDT",
    value: "usdtBot"
  },
  {
    label: "Balance bot EURT",
    value: "eurtBot"
  },
  {
    label: "Balance bot CNHT",
    value: "cnhtBot"
  },
  {
    label: "Balance bot XAUT",
    value: "xautBot"
  },
  {
    label: "Balance bot TRX",
    value: "trxBot"
  },
  {
    label: "Balance bot USDTTRX",
    value: "usdttrxBot"
  },
  {
    label: "Balance bot USD",
    value: "usdBot"
  },
  {
    label: "Balance bot EUR",
    value: "eurBot"
  },
  {
    label: "Balance bot CNY",
    value: "cnyBot"
  },
  {
    label: 'Rates BTC',
    value: 'btcRate'
  },
  {
    label: 'Rates ETH',
    value: 'ethRate'
  },
  {
    label: 'Rates EOS',
    value: 'eosRate'
  },
  {
    label: "Rates TRX",
    value: 'trxRate'
  },
  {
    label: 'Balance sum in $',
    value: 'sumFiat'
  },
]

const PDFHead: Array<PDFHeadType> = [
  {
    width: 20,
    label: '#',
    value: 'index'
  },
  {
    width: 50,
    label: 'Date',
    value: 'date'
  },
  {
    width: 70,
    label: 'Operation type',
    value: "name"
  },
  {
    width: 60,
    label: 'Debit',
    value: "deb"
  },
  {
    width: 60,
    label: 'Credit',
    value: "cred"
  },
  {
    width: 50,
    label: 'Commission',
    value: "commission"
  },
  {
    width: 200,
    label: 'TxId',
    value: "txId"
  },
  {
    width: 46,
    label: 'Balance burse BTC',
    value: 'btcBurse'
  },
  {
    width: 46,
    label: 'Balance burse ETH',
    value: 'ethBurse'
  },
  {
    width: 46,
    label: 'Balance burse EOS',
    value: 'eosBurse'
  },
  {
    width: 46,
    label: 'Balance burse USDT',
    value: 'usdtBurse'
  },
  {
    width: 46,
    label: 'Balance burse TRX',
    value: 'trxtBurse'
  },
  {
    width: 46,
    label: 'Balance bot BTC',
    value: 'btcBot'
  },
  {
    width: 46,
    label: 'Balance bot ETH',
    value: 'ethBot'
  },
  {
    width: 46,
    label: 'Balance bot EOS',
    value: 'eosBot'
  },
  {
    width: 46,
    label: 'Balance bot USDT',
    value: 'usdtBot'
  },
  {
    width: 46,
    label: 'Balance bot EURT',
    value: 'eurtBot'
  },
  {
    width: 46,
    label: 'Balance bot CNHT',
    value: 'cnhtBot'
  },
  {
    width: 46,
    label: 'Balance bot XAUT',
    value: 'xautBot'
  },
  {
    width: 46,
    label: 'Balance bot TRX',
    value: 'trxBot'
  },
  {
    width: 46,
    label: 'Balance bot USDTTRX',
    value: 'usdttrxBot'
  },
  {
    width: 46,
    label: 'Balance bot USD',
    value: 'usdBot'
  },
  {
    width: 46,
    label: 'Balance bot EUR',
    value: 'eurBot'
  },
  {
    width: 46,
    label: 'Balance bot CNY',
    value: 'cnyBot'
  },
  {
    width: 46,
    label: 'Rates BTC',
    value: 'btcRate'
  },
  {
    width: 46,
    label: 'Rates ETH',
    value: 'ethRate'
  },
  {
    width: 46,
    label: 'Rates EOS',
    value: 'eosRate'
  },
  {
    width: 46,
    label: 'Rates TRX',
    value: 'trxRate'
  },
  {
    width: 50,
    label: 'Balance sum in $',
    value: 'sumFiat'
  },
]

const PNGHead: Array<PNGHeadItemType> = [
  {
    label: '#',
    width: 50,
    value: 'index'
  },
  {
    label: "Date",
    width: 120,
    value: "date"
  },
  {
    label: "Operation type",
    width: 200,
    value: "name"
  },
  {
    label: "Debit",
    width: 180,
    value: "deb"
  },
  {
    label: "Credit",
    width: 180,
    value: "cred"
  },
  {
    label: "Comission",
    width: 180,
    value: "commission"
  },
  {
    label: "TxId",
    width: 850,
    value: "txId",
  },
  {
    label: 'Balance burse BTC',
    width: 200,
    value: "btcBurse",
  },
  {
    label: 'Balance burse ETH',
    width: 200,
    value: "ethBot",
  },
  {
    label: 'Balance burse EOS',
    width: 200,
    value: "eosBurse",
  },
  {
    label: 'Balance burse USDT',
    width: 200,
    value: "usdtBurse",
  },
  {
    label: 'Balance burse TRX',
    width: 200,
    value: "trxtBurse",
  },
  {
    label: 'Balance bot BTC',
    width: 200,
    value: "btcBot",
  },
  {
    label: 'Balance bot ETH',
    width: 200,
    value: "ethBot",
  },
  {
    label: 'Balance bot EOS',
    width: 200,
    value: "eosBot",
  },
  {
    label: 'Balance bot USDT',
    width: 200,
    value: "usdtBot",
  },
  {
    label: 'Balance bot EURT',
    width: 200,
    value: "eurtBot",
  },
  {
    label: 'Balance bot CNHT',
    width: 200,
    value: "cnhtBot",
  },
  {
    label: 'Balance bot XAUT',
    width: 200,
    value: "xautBot",
  },
  {
    label: 'Balance bot TRX',
    width: 200,
    value: "trxBot",
  },
  {
    label: 'Balance bot USDTTRX',
    width: 220,
    value: "usdttrxBot",
  },
  {
    label: 'Balance bot USD',
    width: 200,
    value: "usdBot",
  },
  {
    label: 'Balance bot EUR',
    width: 200,
    value: "eurBot",
  },
  {
    label: 'Balance bot CNY',
    width: 200,
    value: "cnyBot",
  },
  {
    label: 'Rates BTC',
    width: 150,
    value: "btcRate",
  },
  {
    label: 'Rates ETH',
    width: 150,
    value: "ethRate",
  },
  {
    label: 'Rates EOS',
    width: 150,
    value: "eosRate",
  },
  {
    label: 'Rates TRX',
    width: 150,
    value: "trxRate",
  },
  {
    label: 'Balance sum in $',
    width: 200,
    value: "sumFiat",
  },
]

type BalanceType = {
  symbol: string;
  value: string;
}

type TraderReportType = {
  type: string
  txId: string
  date: number
  debSymbol: string
  debAmount: string
  credSymbol: string
  credAmount: string
  comissionAmount: string
  comissionSymbol: string
  balances: {
    bot: Array<BalanceType>
    burse: Array<BalanceType>
    sum: string
  }
  rates: Array<BalanceType>
}

interface IModalExchangeReport {
  isOpen: boolean,
  onModalClose: () => void
}

const ModalExchangeReport: React.FC<IModalExchangeReport> = (
  {
    isOpen,
    onModalClose
  }
) => {
  const [dateBegin, setDateBegin] = useState<Date>();
  const [dateEnd, setDateEnd] = useState<Date>();
  const [tableData, setTableData] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [dateValid, setDateValid] = useState(false);
  const translator = useTranslator();
  const {showError} = useRequestError();

  const ExcelButton: React.Ref<HTMLButtonElement> = useRef(null);
  const PNGButton: React.Ref<HTMLButtonElement> = useRef(null);
  const PDFButton: React.Ref<HTMLButtonElement> = useRef(null);

  const [fetchTableData, { data, loading, error }] = useLazyQuery(TABLE_DATA, {
    onCompleted: (data) => {
      setIsLoaded(true);
    },
    onError: (er) => {
      showError(er.message);
    }
  });

  useEffect(() => {
    if(!dateBegin || !dateEnd){
      setDateValid(false);
      return;
    }
    if(dateBegin.getTime() > dateEnd.getTime()){
      setDateValid(false);
      return;
    }
    setIsLoaded(false);
    setDateValid(true);
  }, [dateBegin, dateEnd])

  useEffect(() => {
    if(dateValid && !loading && !error){
      fetchTableData({
        variables: {
          dateMin: dateBegin && dateBegin.getTime(),
          dateMax: dateEnd && dateEnd.getTime()
        }
      })
    }
  }, [error, dateValid, loading, dateBegin, dateEnd])

  useEffect(() => {
    if(isLoaded && data){
      let tdata: Array<TraderReportType> = data.getBotTraderReports.list;
      const newTableData: Array<TableItemType> = [];
      tdata.forEach((el, index) => {
        const balanceBot = el.balances && el.balances.bot
        const balanceBurse = el.balances && el.balances.burse

        const findToken = (items: Array<BalanceType>, symbol: string) => {
          const el = balanceBot && balanceBot.find(i => i.symbol === 'ETH')
          return el && el.value ? el.value : "";
        }

        newTableData.push({
          index: index + 1,
          type: el.type,
          txId: el.txId,
          date: new Date(el.date).toLocaleDateString('ru'),
          time: new Date(el.date).toLocaleTimeString('ru'),
          // @ts-ignore
          name: el.type,
          cred: el.credAmount && el.credSymbol ? `${el.credAmount} ${el.credSymbol}` : "",
          deb: el.debAmount && el.debSymbol ? `${el.debAmount} ${el.debSymbol}` : "",
          commission: el.comissionAmount && el.comissionSymbol ? `${el.comissionAmount} ${el.comissionSymbol}` : "",

          btcBurse: findToken(balanceBurse, 'BTC'),
          ethBurse: findToken(balanceBurse, 'ETH'),
          eosBurse: findToken(balanceBurse, 'EOS'),
          usdtBurse: findToken(balanceBurse, 'USDT'),
          trxtBurse: findToken(balanceBurse, 'TRX'),

          btcBot: findToken(balanceBot, 'BTC'),
          ethBot: findToken(balanceBot, 'ETH'),
          eosBot: findToken(balanceBot, 'EOS'),
          usdtBot: findToken(balanceBot, 'USDT'),
          eurtBot: findToken(balanceBot, 'EURT'),
          cnhtBot: findToken(balanceBot, 'CNHT'),
          xautBot: findToken(balanceBot, 'XAUT'),
          trxBot: findToken(balanceBot, 'TRX'),
          usdttrxBot: findToken(balanceBot, 'USDTTRX'),
          usdBot: findToken(balanceBot, 'USD'),
          eurBot: findToken(balanceBot, 'EUR'),
          cnyBot: findToken(balanceBot, 'CNY'),

          btcRate: findToken(el.rates, 'BTC'),
          ethRate: findToken(el.rates, 'ETH'),
          eosRate: findToken(el.rates, 'EOS'),
          trxRate: findToken(el.rates, 'TRX'),
          sumFiat: el.balances.sum
        })
      })
      setTableData(newTableData);
    }
  }, [isLoaded, data])

  const onBeginChange = (item: Date) => {
    setDateBegin(item);
  }

  const onEndChange = (item: Date) => {
    setDateEnd(item);
  }

  const onExcelDownload = () => {
    ExcelButton && ExcelButton.current && ExcelButton.current.click();
  }

  const onPDFDownload = () => {
    PDFButton && PDFButton.current && PDFButton.current.click()
  }

  const onPNGDownload = () => {
    PNGButton && PNGButton.current && PNGButton.current.click();
  }

  const onModalCloseClick = () => {
    setDateBegin(undefined);
    setDateEnd(undefined);
    setDateValid(false);
    setIsLoaded(false);
    setTableData(undefined);
    onModalClose()
  }

  if(!isOpen) {
    return null;
  }
  return(
    <ModalBackground onClick={onModalCloseClick}>
      <Modal
        width={351}
        height={385}
        onClick={(ev => ev.stopPropagation())}
      >
        <Title>
          {translator.translate('modalExchangeTitle')}
        </Title>

        <Period1>
          <DateSelect
            onValueChange={onBeginChange}
            title="Период отчетности"
            theme="white"
            value={dateBegin}
            placeholder="Дата начала отчёта"
          />
        </Period1>

        <Period2>
          <DateSelect
            onValueChange={onEndChange}
            title="Период отчетности"
            theme="white"
            value={dateEnd}
            placeholder="Дата окончания отчёта"
          />
        </Period2>

        <Button
          onClick={onExcelDownload}
          disabled={!isLoaded || !dateValid}
        >
          <Arrow src={arrow}/>
          {translator.translate('reportButton1')}
        </Button>

        <Button
          disabled={!isLoaded || !dateValid}
          onClick={onPDFDownload}
        >
          <Arrow src={arrow}/>
          {translator.translate('reportButton2')}
        </Button>


        <Button
          disabled={!isLoaded || !dateValid}
          onClick={onPNGDownload}
        >
          <Arrow src={arrow}/>
          {translator.translate('reportButton3')}
        </Button>

        <ExcelTableDownload
          cols={excelCols}
          data={tableData}
          ref={ExcelButton}
        />

        {
          tableData && <PDFTableDownload
              header={PDFHead}
              data={tableData}
              ref={PDFButton}
          />
        }

        <PNGTableDownload
          header={PNGHead}
          data={tableData}
          ref={PNGButton}
        />
      </Modal>
    </ModalBackground>
  )
}

export default ModalExchangeReport;

import React, {useState} from "react";
import {useSubscription} from "@apollo/react-hooks";
import {SUBSCRIBTION_CHATS_UPDATE} from "./queries";
import {SupportChatType} from "../../molecules/CSupportUsersList/useLoadChats";

export const useUpdateChatsSub = () => {
  const [updatedChat, setUpdatedChat] = useState<SupportChatType>();

  const { loading } = useSubscription(SUBSCRIBTION_CHATS_UPDATE, {
    onSubscriptionData: (options) => {
      let data = options.subscriptionData.data.updateChat;
      setUpdatedChat(data);
    }
  });

  return {updatedChat, loading};
}

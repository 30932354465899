import styled from "styled-components";
import {palette} from "styled-theme";
import {IInfoRow, IStatus} from "./index";

export const Title = styled.div`
  display: flex;
  align-items: center;
  font-size: 24px;
  margin: 0 0 24px;
`

export const InfoRow = styled.div<IInfoRow>`
  color: ${palette({primary: 0}, 1)};
  font-size: 18px;
  margin-bottom: 12px;

  & span {
    font-weight: 600;
  }
`
export const ButtonWrap = styled.div`
  display: flex;
  margin-top: 24px;
  margin-bottom: 32px;
`
export const Balance = styled.div<IInfoRow>`
  color: ${palette({primary: 0}, 1)};
  padding: 32px 0 20px 0;
  margin-bottom: 32px;
  font-size: 18px;
  position: relative;

  &::before, ::after {
    position: absolute;
    content: '';
    left: 0;
    width: 100%;
    height: 1px;
    background-color: ${palette({primary: 2}, 1)};
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }

  & span {
    display: block;
    margin-bottom: 12px;

    &.title {
      font-weight: 600;
    }
  }
`

export const TransactTitle = styled.div`
  font-size: 24px;
  margin: 0 0 24px;
  font-family: "Roboto Bold", sans-serif;
`

export const TableBackground = styled.div`
  width: 100%;
  min-height: 377px;
  padding-bottom: 66px;
  background-color: rgb(37, 40, 43);
  font-size: 14px;
  border-spacing: 0;
`

export const Status = styled.span<IStatus>`
  color: ${({enabled}) => enabled ? "rgb(52, 199, 89)" : "rgb(255, 59, 48)"}
`

export const RangeSelection = styled.div`
  display: flex;
  margin-bottom: 20px;
`

import React from "react";
import {
  UsersStatistic,
  StatisticsTitle,
  StatisticsBlock,
  StatisticsRow
} from './styled';
import {useTranslator} from "eo-locale";
import BigNumber from "bignumber.js";

interface IUsersReportStatistics {
  allUsers: number | undefined,
  regUsers: number | undefined
}

const UsersReportStatistics: React.FC<IUsersReportStatistics> = (
{
  allUsers,
  regUsers
}) => {
  const translator = useTranslator();

  return(
    <UsersStatistic>
      <StatisticsBlock margin="0 0 20px 0">
        <StatisticsTitle fontWeight="bold">
          {translator.translate('usersDetailItemAll')}
        </StatisticsTitle>

        {allUsers ? allUsers : "-"}
      </StatisticsBlock>

      <StatisticsRow>
        <StatisticsBlock margin="0 20px 0 0 ">
          <StatisticsTitle fontWeight="normal">
            {translator.translate('usersDetailItemRegistr')}
          </StatisticsTitle>

          {regUsers ? regUsers : "-"}
        </StatisticsBlock>

        <StatisticsBlock margin="0">
          <StatisticsTitle fontWeight="normal">
            {translator.translate('usersDetailItemInc')}
          </StatisticsTitle>

          {
            regUsers && allUsers ?
              new BigNumber(regUsers/allUsers * 100).precision(1).toFormat()
              : "-"
          }
        </StatisticsBlock>
      </StatisticsRow>
    </UsersStatistic>
  )
}

export default UsersReportStatistics;

import React from "react";
import { FC } from "react";
import icons from "../../../assets/img/sprite.svg";

export type IconId = "icChev" | "icClose" | "icReport" | "icCalend" | "icGal";

interface ISvg {
  iconId: IconId;
  className?: string;
  color?: string;
  height?: string;
  width?: string;
}
export const Svg: FC<ISvg> = ({ iconId, color, className, height, width }) => {
  return (
    <svg className={className} style={{width, height}}>
      <use xlinkHref={`${icons}#${iconId}`} style={{ color }} name="debtors" />
    </svg>
  );
};

import gql from 'graphql-tag'

export const USERS_ALL_SEARCH = gql`
    query getUserBy($email: String!, $period: Period, $skip: Int, $from: Timestamp, $to: Timestamp){
        getUserBy(query:{email: $email}transactions:{first: 12, skip: $skip, period: $period, fromDate: $from, toDate: $to}){
            _id
            email
            eosName
            firstName
            lastName
            lastSeen
            phone
            balance{
                symbol
                amount
            }
            country{
                name
                fullName
            }
            registeredAt
            restrictions{
                internal
                output
            }
            transactions{
                totalCount
                count
                transactions{
                    trx_id
                    from
                    to
                    quantity
                    memo
                    createdAt
                    block_num
                }
            }
            kycDocuments {
                type
                document {
                    file {
                        name
                        localId
                    }
                }
                thumb {
                    file {
                        name
                        localId
                    }
                }
                status
                createdAt
                updatedAt
                reasons
            }
            withdrawAmount {
                level
                canVerify
            }
        }
    }
`

export const USERS_BlOCK = gql`
    mutation setUserRestrictions($email: String!, $internal: Boolean, $output: Boolean){
        setUserRestrictions(query:{email: $email},restrictions:{internal: $internal, output: $output},locale: "RUS"){
            email
            eosName
            restrictions{
                internal
                output
            }
        }
    }
`

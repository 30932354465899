import styled from "styled-components";

export const Radio = styled.div<{ margin: string }>`
    margin: ${props => props.margin};
`

export const Label = styled.label`
  font-family: "Roboto Medium", sans-serif;
  font-size: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 0;
`

export const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
  padding: 0;
  margin: 0;
  border: 0;
`

export const Checkbox = styled.div<{selected: boolean}>`
  width: 14px;
  height: 14px;
  border: ${({selected}) => selected ? '4px solid rgb(255, 255, 255)' : '2px solid rgb(106, 114, 117)'};
  line-height: 8px;
  text-align: center;
  margin-right: 10px;
  background-color: transparent;
  border-radius: 50%;
  transition: all 0.3s ease-in;
`

import styled from "styled-components";

export const StyledHistoryPage = styled.main`
  width: 100%;
  padding: 0 30px;
  overflow: auto;

  & .history-chev {
    width: 15px;
    height: 15px;
  }
`;

export const HistoryBtnStyle = {
  "font": "normal normal 16px/1.2 'Roboto bold', sans-serif",
  "border": "1px solid #ffffff",
  "padding": "10px",
  "border-radius": "4px",
  "min-width": "185px",
  "&:hover": {
    "background": "rgba(255, 255, 255, 0.3)",
  },
};

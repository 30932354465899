import React from 'react'
import UsersMain from "../../organism/UsersMain";
import {Container} from './styled';
import {Route, Switch, useRouteMatch} from "react-router-dom";
import UsersAll from "../../organism/UsersAll";
import UsersVerification from "../../organism/UsersVerification";
import UsersInfo from "../../organism/UsersInfo";
import UsersReport from "../../organism/UsersReport";

export interface IWrap {
  palette:string
}

const UsersPage: React.FC = () => {
  const [userAllView, setUserAllView] = React.useState(false)
  let { path } = useRouteMatch();

  if (userAllView){
    setUserAllView(false);
  }

  return (
    <Container>
      <Switch>
        <Route path={path} exact>
          <UsersMain />
        </Route>
        <Route path={`${path}/all`} exact>
          <UsersAll />
        </Route>
        <Route path={`${path}/verif`} exact>
          <UsersVerification />
        </Route>
        <Route path={`${path}/:email`} exact>
          <UsersInfo />
        </Route>
        <Route path={`${path}/report/:device`}>
          <UsersReport />
        </Route>
      </Switch>
    </Container>
  )
}

export default UsersPage;

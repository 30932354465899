import React from 'react';
import { Route } from 'react-router-dom';
import AuthInfo from '../../../store/AuthInfo';

interface IPrivateRoute {
  path: string,
  exact?: boolean,
}

export const PrivateRoute: React.FC<IPrivateRoute> = ({children, path, ...otherProps}) => {
  const role = AuthInfo.role as 'ADMIN' | 'MODERATOR';
  const roleList = {
    ADMIN: [
      "/transact",
      "/comis",
      "/setting",
      "/exchange",
      "/users",
      "/auctions",
      "/support",
      "/comis/history"
    ],
    MODERATOR: ["/users", "/auctions", "/support"],
  }
  return (
    <Route {...otherProps} path={path}>
      {roleList[role].includes(path) && children}
    </Route>
  );
};
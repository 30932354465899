import {gql} from "apollo-boost";

export const GET_CHATS = gql`
  query getSupportChats($limit: Int!, $skip: Int!) {
    getSupportChats(query: {
      limit: $limit,
      skip: $skip
    }) {
      pagination {
       total,
       next
      }
      chats {
        _id,
        unreadedCount,
        usersData {
          userId,
          username
        }
        topMessage
        topMessageDate
      }
    }
  }
`;

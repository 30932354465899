import React from 'react'
import { font } from 'styled-theme'
import styled, { css } from 'styled-components'

export const Title = styled.h1<{fSize:string,margin:string,flex:boolean}>`
  font-size: ${props => props.fSize};
  margin: 0;
  margin: ${props => props.margin};
  font-weight: normal;
  font-family: ${font('bold')};
  & .icChev{
    fill: #fff;
  }
  ${props => props.flex === true
  && css`
    display: flex;
    align-items: center;
  `
}  
`
export interface Props {
  fSize?: string;
  margin?: string;
  flex?: boolean;
  children: any;
}
export default ({
  children, fSize = '36px', margin = '0 0 18px 0', flex = false,
}:Props) => {
  return (
    <Title fSize={fSize} margin={margin} flex={flex}>
      {children}
    </Title>
  )
}

import { gql } from "apollo-boost";

export const UPDATE_PASS = gql`
  mutation updateUserPassword(
    $username: String!
    $password: String!
    $oldPassword: String!
  ) {
    updateUserPassword(
      userCredentialsDto: {
        username: $username
        password: $password
        oldPassword: $oldPassword
      }
    )
  }
`;

import { makeAutoObservable } from "mobx";
import jwtDecode from "jwt-decode";

export type DecodeTokenType = {
  exp: number;
  iat: number;
  role: string;
  status: string;
  username: string;
};

class AuthInfo {
  role: string = "UNAUTHORIZED";
  username: string = "";
  exp: number | null = null;

  constructor(public token: string | null) {
    makeAutoObservable(this);
    this.setInfoByToken(token);
  }

  setInfoByToken(token: string | null) {
    if (token) {
      const decoded = jwtDecode<DecodeTokenType>(token);

      if (decoded.exp * 1000 - Date.now() > 0) {
        this.role = decoded.role;
        this.token = token;
        this.username = decoded.username;
        this.exp = decoded.exp;
      } else {
        localStorage.removeItem('token')
      }
    }
  }

  clearStore() {
    this.token = "";
    this.username = "";
    this.role = "UNAUTHORIZED";
    this.exp = null;
  }
}

export default new AuthInfo(localStorage.getItem('token'));

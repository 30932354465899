import styled from "styled-components";

export const Title = styled.div`
  display: flex;
  align-items: center;
  font-size: 24px;
  margin: 0 0 24px;
  font-weight: normal;
  font-family: "Roboto Bold", sans-serif;
`

export const Filters = styled.div`
  display: flex;
  margin-bottom: 25px;
`

export const TableBackground = styled.div`
  padding-bottom: 66px;
  background-color: rgb(37, 40, 43);
  font-size: 14px;
  margin-bottom: 32px;
  min-height: 250px;
`

export const Controls = styled.div`
  display: flex;
`

export const DateRange = styled.div`
  display: flex;
  margin-bottom: 25px;
`

import React from "react";
import {Label, Checkbox} from "./styled";

export interface LabelType {
  margin?: string
}

export interface CheckboxType {
  isChecked: boolean
}

export type CheckboxItemType = {
  key: string,
  title: string
}

interface ICheckBoxItem extends LabelType, CheckboxType{
  item: CheckboxItemType,
  onClick?: (item: CheckboxItemType) => void,
}

const CheckBoxItem: React.FC<ICheckBoxItem> = (
  {
    item,
    isChecked,
    onClick = () => {},
    margin= "0"
  }
) => {
  return(
    <Label
      onClick={() => onClick(item)}
      margin={margin}
    >
      <Checkbox
        isChecked={isChecked}
      />

      {item.title}
    </Label>
  )
}

export default CheckBoxItem;

import styled from "styled-components";

export const File = styled.button`
  display: flex;
  width: 230px;
  height: 50px;
  align-items: center;
  background-color: white;
  padding: 8px;
  border: none;
  border-radius: 3px;
  cursor: pointer;

  &:hover{
    background: #F8EFE5;
  }
`

export const Icon = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 10px;
`

export const Text = styled.span`
  color: black;
  margin-right: 2px;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
`

export const Arrow = styled.img`
  width: 25px;
  height: 25px;
`

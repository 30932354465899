import React from "react";
import Loader from "react-loader-spinner";
import { LoaderContainer } from "./styled";

export interface ILoaderContainer {
    isGlobal?: boolean
}

interface ICustomLoader extends ILoaderContainer{
    loading: boolean,
    loaderHeight?: number
    loaderWidth?: number
}

const CustomLoader: React.FC<ICustomLoader> = (
    {
        loading,
        isGlobal = true,
        loaderHeight = 100,
        loaderWidth = 100
    }
) => {
    return(
        <>
            {loading && (
                <LoaderContainer
                  isGlobal={isGlobal}
                >
                    <Loader
                        type="Oval"
                        color="#fff"
                        height={loaderHeight}
                        width={loaderWidth}
                    />
                </LoaderContainer>
            )}
        </>
    )
}

export default CustomLoader;

import styled, { css } from "styled-components";
import { ILabelProps } from ".";

export const StyledLabel = styled.label<ILabelProps>`
  position: relative;
  display: block;
  transition: all 0.3s ease-out;
  height: 35px;
  margin-right: 10px;
  margin-bottom: 10px;
  &::after {
    content: ${({ icon }) => icon && `"${icon}"`};
    cursor: text;
    color: #6a7275;
    position: absolute;
    top: 7px;
    right: 10px;
  }
  ${({ isError }) =>
    isError &&
    css`
      border-bottom: 1px solid #ab3c3c;
      color: #ab3c3c;
      &::after {
        color: #ab3c3c;
      }
    `};
`;

import React, {useState} from 'react'
import Title from '../../atoms/Title'
import SubTitle from '../../atoms/SubTitle'
import CheckButton from '../../atoms/CheckButton'
import Button from '../../atoms/Button'
import {WrapMain, Wrap, Status} from './styled'
import {useTranslator} from "eo-locale";
import {useMutation} from "@apollo/react-hooks";
import {useParams} from "react-router-dom";
import UserInfo from "../../../store/UserInfo";
import ModalChangeStatusVerify from "../ModalChangeStatusVerify";
import {USERS_ALL_SEARCH} from "../../organism/UsersInfo/queries";
import {UserQueryType} from "../../organism/UsersInfo";
import {VERIFIC_PROHIB} from "./queries";

export interface IWrapMain {
  margin: string
}

export interface IWrap {
  margin: string,
  nowrap?: boolean
}

interface IVerificationUser {
  canVerify: boolean | undefined;
  reason?: string;
  margin?: string;
}

const VerificationUser = (
{
  canVerify,
  margin = "0",
  reason,
}: IVerificationUser) => {
  const translator = useTranslator();
  const params = useParams<{email: string}>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [canUserReverify, {loading: loadingProhib}] = useMutation(VERIFIC_PROHIB, {
    update: (proxy) => {
      const prevData: UserQueryType | null = proxy.readQuery({
        query: USERS_ALL_SEARCH,
        variables: {
          email: params.email,
          period: UserInfo.transactPeriod,
          skip: 12 * UserInfo.transactActivePage,
          from: UserInfo.fromDate?.getTime(),
          to: UserInfo.toDate?.getTime()
        }
      });

      proxy.writeQuery({
        query: USERS_ALL_SEARCH,
        data: {
          getUserBy:{
            ...prevData?.getUserBy,
            withdrawAmount: {
              ...prevData?.getUserBy.withdrawAmount,
              canVerify: canVerify
            }
          }
        }
      })
    },
  });

  const onVerifStatusChange = () => {
    canUserReverify({
      variables: {
        email: params.email,
        access: canVerify
      }
    })
  }

  const onModalClose = () => {
    setIsModalOpen(false);
  }

  const onModalOpen = () => {
    setIsModalOpen(true)
  }

  return (
    <WrapMain margin={margin}>
      <Wrap nowrap margin='0 0 20px 0'>
        <Title fSize="24px" margin="0">
          {translator.translate('usersVerificationTitle')}
        </Title>

        <Status>
          {translator.translate('usersVerificationLevel')}
          <span>{UserInfo.verificatonLevel}</span>
        </Status>
      </Wrap>

      {reason &&
        <SubTitle fSize="14px" margin="0 0 25px auto">
          {translator.translate('usersVerificationReason', {value: reason}) }
        </SubTitle>
      }

      <Button height='30px' type="button" onClick={onModalOpen} margin="20px 0">
        {translator.translate('usersVerificationChangeLevel')}
      </Button>

      <CheckButton
        title={translator.translate('usersVerificationProhib')}
        name="prohibverif__check"
        changeRadio={onVerifStatusChange}
        checked={canVerify}
      />

      <ModalChangeStatusVerify
        onClose={onModalClose}
        enabled={isModalOpen}
        level={UserInfo.verificatonLevel}
      />
    </WrapMain>
  )
}

export default (VerificationUser)

import styled from "styled-components";
import { IFormProps } from ".";

const StyledForm = styled.form<IFormProps>`
  display: flex;
  flex-flow: column nowrap;
  ${({ styles }) => styles}
`;

export { StyledForm };

import { useTranslator } from "eo-locale";
import React, { Fragment, useState } from "react";
import { useMutation } from "react-apollo";
import Loader from "react-loader-spinner";
import { UPDATE_PASS } from "../../../gql/useMutation/UPDATE_PASS";
import AuthInfo from "../../../store/AuthInfo";
import { CAlert } from "../../atoms/CAlert";
import CButton from "../../atoms/CButton";
import { CForm } from "../../atoms/CForm";
import  {CInput}  from "../../atoms/CInput";
import { CLoader } from "../../atoms/CLoader";
import { CSubTitle } from "../../atoms/CSubTitle";
import { Svg } from "../../atoms/Svg";
import { useFormControl } from "./../../../hooks/useFormControl";
import { Wrapper } from "./../../atoms/Wrapper/index";
import { isPasswordsMatch, isSomeInputFilled } from "./helpers";
import { StyledModalUpdatePass } from "./style";

type Inputs = {
  oldPass: string;
  newPass: string;
  newPassConfirm: string;
};

export interface IModalUpdatePassProps {
  styles?: { [key: string]: string };
  onHideModalHandler?: () => void;
  onSuccessHandler?: () => void;
}

export const ModalUpdatePass: React.FC<IModalUpdatePassProps> = ({
  styles,
  onHideModalHandler,
  onSuccessHandler,
}) => {
  const translator = useTranslator();
  const inputs = [
    {
      name: "oldPass" as const,
      type: "password",
      placeholder: translator.translate("modalPassInputOld"),
      styledType: "DARK" as const,
      styles: {
        "margin-bottom": "15px",
      },
      rules: {
        minLength: {
          message: translator.translate("errorMessageMin6Symbols"),
          value: 6,
        },
        required: translator.translate("errorMessageRequiredField"),
      },
    },
    {
      name: "newPass" as const,
      type: "password",
      placeholder: translator.translate("modalPassInputNew"),
      styledType: "DARK" as const,
      styles: {
        "margin-bottom": "15px",
      },
      rules: {
        minLength: {
          message: translator.translate("errorMessageMin6Symbols"),
          value: 6,
        },
        required: translator.translate("errorMessageRequiredField"),
      },
    },
    {
      name: "newPassConfirm" as const,
      type: "password",
      placeholder: translator.translate("modalPassInputReturn"),
      styledType: "DARK" as const,
      styles: {
        "margin-bottom": "30px",
      },
      rules: {
        minLength: {
          message: translator.translate("errorMessageMin6Symbols"),
          value: 6,
        },
        required: translator.translate("errorMessageRequiredField"),
      },
    },
  ];
  const formControlConfig = {
    defaultValues: {
      oldPass: "",
      newPass: "",
      newPassConfirm: "",
    },
  };
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isBtnDisabled, setBtnDisabled] = useState(true);
  const {
    onSubmit,
    clearErrors,
    errors,
    control,
    getValues,
    reset,
    setError,
  } = useFormControl<Inputs>(formControlConfig, inputHandler);
  const [updatePass, { loading }] = useMutation(UPDATE_PASS, {
    onCompleted: () => {
      reset();
      onSuccessHandler?.();
    },
    onError: () => {
      setBtnDisabled(true);
      reset();
      setErrorMessage(translator.translate("errorMessageNotCorrectOldPass"));
    },
  });

  const onInputHandler = (name: keyof Inputs) => {
    const inputsValList = Object.values(getValues());
    setBtnDisabled(!isSomeInputFilled(inputsValList));
    clearErrors(name);
    setErrorMessage(null);
  };

  function inputHandler(formData: Inputs) {
    const { newPass, newPassConfirm, oldPass } = formData;
    const isValid = isPasswordsMatch(newPass, newPassConfirm);
    if (isValid) {
      if (isPasswordsMatch(newPass, newPassConfirm)) {
        updatePass({
          variables: {
            username: AuthInfo.username,
            password: newPass,
            oldPassword: oldPass,
          },
        });
      }
    } else {
      setError("newPassConfirm", {
        message: translator.translate("errorMessagePassNotMatch"),
      });
    }
  }

  return (
    <StyledModalUpdatePass styles={styles}>
      {loading && (
        <CLoader trigger={loading} styles={{ "background": "transparent" }}>
          <Loader type="Oval" color="#ffffff" height={80} width={80} />
        </CLoader>
      )}
      <Wrapper
        styles={{
          "display": "flex",
          "justify-content": "flex-end",
          "padding": "12px 8px 0 0",
        }}
      >
        <CButton
          type="button"
          styles={{
            "width": "35px",
            "height": "22px",
          }}
          onClick={onHideModalHandler}
        >
          <Svg iconId="icClose" className="close" color="#844040" />
        </CButton>
      </Wrapper>
      <Wrapper
        styles={{
          "padding": "13px 30px 43px 30px",
          "display": "block"
        }}
      >
        <CSubTitle
          styles={{
            "font": `normal normal 24px/1.2 "Roboto regular"`,
            "margin": "0 0 32px 0",
          }}
        >
          {translator.translate("modalPassTitle")}
        </CSubTitle>
        <CForm onSubmit={onSubmit}>
          {inputs.map((inputData, index) => (
            <Fragment key={index}>
              {errors[inputData.name] && (
                <CAlert styledType="ERROR">
                  {errors[inputData.name]?.message}
                </CAlert>
              )}
              <CInput<Inputs>
                {...inputData}
                control={control}
                isError={
                  Boolean(errors[inputData.name]) || Boolean(errorMessage)
                }
                defaultValue=""
                sendInputData={onInputHandler}
              />
            </Fragment>
          ))}
          {errorMessage && (
            <CAlert styles={{ "margin-bottom": "10px" }} styledType="ERROR">
              {errorMessage}
            </CAlert>
          )}
          <CButton isDisabled={isBtnDisabled} styledType="LIGHT" type="submit">
            {translator.translate("modalPassBtn")}
          </CButton>
        </CForm>
      </Wrapper>
    </StyledModalUpdatePass>
  );
};

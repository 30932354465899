import styled from "styled-components";
import { Styles } from "./type";

const StyledSettingPage = styled.main<{
  styles?: Styles;
}>`
  position: relative;
  margin-left: 30px;
  width: 100%;
  ${({ styles }) => styles}
`;

export { StyledSettingPage };

import React, {useEffect, useState} from "react";
import styled, {css} from "styled-components";
import icons from "../../../assets/img/sprite.svg";

export type SelectItemType<T> = {
  key: T,
  value: string
}

interface ISelect<T> {
  width?: string,
  height?: string,
  items: Array<SelectItemType<T>>,
  selectedItem: T | undefined,
  onSelect: (item: SelectItemType<T>) => void;
  placeholder?: string,
  title?: string,
  margin?: string,
}

const Select = <T,>(
  {
    placeholder = '',
    margin= '0',
    width = '100%',
    height = '52px',
    items,
    selectedItem,
    onSelect,
    title
  }: ISelect<T>
) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedMenuItem, setSelectedItem] = useState<SelectItemType<T> | null>();
  const [menuItems, setMenuItems] = useState<Array<SelectItemType<T>>>([]);

  useEffect(() => {
    let newSelectedItem = null;
    newSelectedItem = items.find(el => el.key === selectedItem)
    setSelectedItem(newSelectedItem);
  }, [selectedItem, items])

  useEffect(() => {
    let newMenuItems = items.filter(el => el.key !== selectedItem);
    setMenuItems(newMenuItems)
  }, [selectedItem, items])

  const onSelectClick = (e: React.MouseEvent) => {
    setIsOpen(!isOpen);
  }

  const onItemSelect = (item: SelectItemType<T>) => {
    onSelect(item);
    setIsOpen(false);
  }

  return(
    <Container
      width={width}
      height={height}
      margin={margin}
    >
      <StyledSelect
        isOpen={isOpen}
        onClick={onSelectClick}
      >
        <Content
          isTitle={!!title}
        >
          {
            title ? (
              <Title>{title}</Title>
            ): null
          }

          <Selected>
            {selectedMenuItem ? selectedMenuItem.value : placeholder}
          </Selected>
        </Content>

        <Arrow>
          <svg className="inline-svg-icon">
            <use className="icas" xlinkHref={`${icons}#icChev`} name="debtors" />
          </svg>
        </Arrow>
      </StyledSelect>

      <Menu length={menuItems.length} isOpen={isOpen}>
        {
          menuItems.map((item, index) => (
            <MenuItem
              key={index}
              visible={isOpen}
              onClick={() => onItemSelect(item)}
            >
              {item.value}
            </MenuItem>
          ))
        }
      </Menu>
    </Container>
  )
}

const Container = styled.div<{ margin: string, width: string, height: string }>`
  width: 100%;
  max-width: ${({width}) => width};
  height: ${({height}) => height};
  position: relative;
  margin: ${props => props.margin};
`

const StyledSelect = styled.div<{ isOpen: boolean }>`
  transition: all 0.2s ease-out;
  width: 100%;
  height: 52px;
  padding: 7px 30px 7px 16px;
  position: relative;
  background-color: transparent;
  border: 1px solid white;
  cursor: pointer;
  
  ${({isOpen}) => isOpen ? css`
    border-radius: 5px 5px 0 0;
  ` : css`
    border-radius: 5px;
  `}
`

const Arrow = styled.div`
  position: absolute;
  top: calc(50% - 6px);
  right: 14px;
  width: 8px;
  height: 14px;
  transform: rotate(-90deg);
`

const Content = styled.div<{ isTitle: boolean }>`
  width: 100%;
  height: ${props => !props.isTitle ? '100%' : 'auto'};
`

const Title = styled.div`
  font-size: 12px;
  color: rgb(106, 114, 117);
  margin-bottom: 3px;
`

const Selected = styled.div`
  font-size: 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Menu = styled.ul<{ isOpen: boolean, length: number}>`
  transition: all 0.2s ease-out;
  position: absolute;
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: white;
  top: 52px;
  list-style: none;
  left: 0;
  z-index: 80;
  border-radius: 0 0 4px 4px;
  overflow: hidden;
  
  ${props => props.isOpen ? css`
    min-height: ${`${props.length * 38}px`};
    opacity: 1;
  ` : css`
    opacity: 0;
    min-height: 0;
  `}
`

const MenuItem = styled.li<{visible: boolean}>`
  transition: all 0.2s ease-out;
  color: black;
  cursor: pointer;
  
  &:hover{
    background: rgb(248, 239, 229);
  }
  
  ${props => (
    props.visible ? css`
      opacity: 1;
      min-height: 38px;
      padding: 10px;
    ` : css`
      opacity: 0;
      min-height: 0;
      height: 0;
      padding: 0 10px 0 10px;
    `
  )}
`

export default Select;

import styled from "styled-components";

const StyledLoader = styled.div<{
  styles?: { [key: string]: string };
  timer: number;
  isDisplay: boolean;
  isVisible: boolean;
}>`
  display: ${({ isDisplay }) => (isDisplay ? "flex" : "none")};
  opacity: ${({ isVisible }) => (isVisible ? "1" : "0")};
  transition: ${({ timer }) => `all ${timer}ms ease-out`};
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background: #acd3d426;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(4px);
  ${({ styles }) => styles}
`;

export { StyledLoader };

import styled from "styled-components";
import {IWrap, IWrapMain} from "./index";

export const WrapMain = styled.div<IWrapMain>`
  display: block;
  margin: ${props => props.margin};
`
export const Wrap = styled.div<IWrap>`
  display: flex;
  margin: ${props => props.margin};
  margin-bottom: 20px;
  flex-direction: column;
  ${props => props.nowrap && 'flex-wrap: nowrap'}
`

export const Status = styled.div`
  font-weight: 500;
  margin-top: 10px;
  display: flex;
  align-items: center;

  & span {
    display: block;
    margin-left: 10px;
    font-size: 14px;
  }
`

import React, { FC } from "react";
import { StyledCAlert } from "./style";

export interface IAlertProps {
  styles?: { [key: string]: string };
  styledType?: "ERROR" | "SUCCESS";
}

export const CAlert: FC<IAlertProps> = ({ children, ...otherProps }) => {
  return (
    <StyledCAlert role="alert" {...otherProps}>
      {children}
    </StyledCAlert>
  );
};

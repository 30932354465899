import React, { useEffect, useState } from 'react';
import CustomLoader from '../../atoms/CustomLoader';
import {TableBackground, Arrow} from './styled';
import Title from '../../atoms/Title'
import { useTranslator } from 'eo-locale';
import Table, { TableHeadType } from '../../atoms/Table';
import {TRANSACT_ALL} from './queries';
import { useQuery } from 'react-apollo';
import CustomButton from '../../atoms/CustomButton';
import icons from '../../../assets/img/sprite.svg'
import {useHistory} from "react-router-dom";
import BigNumber from "bignumber.js";
import {useRequestError} from "../../../hooks/useRequestError/useRequestError";

type TableDataType = {
    id: string,
    currency: string,
    inputAmount: string,
    outputAmount: string,
    commissionAmount: string,
    inputCommission: string,
    outputCommission: string,
    transferCommission: string,
}

type InfoType = {
    amount: string,
    count: number,
    commission: string
}

type DataAllType = {
    transactionsCommonInfo: Array<{
        input: InfoType,
        internal: InfoType,
        output: InfoType,
        symbol: string
    }>
}

const TransactAll: React.FC = () => {
    const [tableData, setTableData] = useState<Array<TableDataType>>([]);
    const [selectedTableElement, setSelectedTableElement] = useState<TableDataType>();
    const history = useHistory();
    const translator = useTranslator();
    const {showError} = useRequestError();

    const {data: dataAll, loading} = useQuery<DataAllType>(TRANSACT_ALL, {
        onError: (error) => {
            showError(error.message);
        }
    });

    useEffect(() => {
        if(dataAll){
            let newTableData: Array<TableDataType> = [];

            dataAll.transactionsCommonInfo.forEach((item, index) => {
                newTableData.push({
                    id: index.toString(),
                    currency: item.symbol,
                    inputAmount: `${new BigNumber(item.input.amount).toFormat()} / ${new BigNumber(item.input.count).toFormat()}`,
                    outputAmount: `${new BigNumber(item.output.amount).toFormat()} / ${new BigNumber(item.output.count).toFormat()}`,
                    commissionAmount: `${new BigNumber(item.internal.amount).toFormat()} / ${new BigNumber(item.internal.count).toFormat()}`,
                    inputCommission: new BigNumber(item.input.commission).toFormat().toString(),
                    outputCommission: new BigNumber(item.output.commission).toFormat().toString(),
                    transferCommission: new BigNumber(item.internal.commission).toFormat().toString()
                })
            })
            setTableData(newTableData);
        }
    }, [dataAll]);

    const transactTableHead: Array<TableHeadType<TableDataType>> = [
        {
            dataKey: "currency",
            text: translator.translate('TransactTableHead1'),
            width: "160px"
        },
        {
            dataKey: "inputAmount",
            text: translator.translate('TransactTableHead2'),
            width: "160px"
        },
        {
            dataKey: "outputAmount",
            text: translator.translate('TransactTableHead3'),
            width: "160px"
        },
        {
            dataKey: "commissionAmount",
            text: translator.translate('TransactTableHead4'),
            width: "160px"
        },
        {
            dataKey: "inputCommission",
            text: translator.translate('TransactTableHead5'),
            width: "160px"
        },
        {
            dataKey: "outputCommission",
            text: translator.translate('TransactTableHead6'),
            width: "160px"
        },
        {
            dataKey: "transferCommission",
            text: translator.translate('TransactTableHead7'),
            width: "160px"
        },
    ];

    const onTableRowClick = (item: TableDataType) => {
        setSelectedTableElement(item);
    }

    const onReportClick = () => {
        if(selectedTableElement){
            history.push(`/transact/${selectedTableElement.currency}`);
        }
    }

    return(
        <>
            <CustomLoader
              loading={loading}
            />

            <Title fSize="24px" flex>
                {translator.translate('mainTabTransact', { value: ''})}
            </Title>

            <TableBackground>
                <Table
                    data={tableData}
                    headData={transactTableHead}
                    selectedId={selectedTableElement?.id}
                    onRowClick={onTableRowClick}
                    clickable
                />
            </TableBackground>

            <CustomButton
                onClick={onReportClick}
                height="53px"
                width="344px"
                disabled={!selectedTableElement}
            >
                <Arrow>
                    <svg className="inline-svg-icon">
                        <use className="icas" xlinkHref={`${icons}#icReport`} name="debtors"/>
                    </svg>
                </Arrow>

                {translator.translate('btnReport')}
            </CustomButton>
        </>
    )
}

export default TransactAll;

import BigNumber from "bignumber.js";
import React from "react";
import TransactInfo from "../../atoms/TransactInfo";
import { TransCommonInfo } from "../../organism/TransactItem/type";

type TransData = {
  allTitle: string;
  items: {
      title: string;
  }[];
}[]

export interface Props {
  data: TransData;
  value: string;
  transactItemData?: TransCommonInfo['transactionsCommonInfo'];
}
const TransactInfoList = ({ data, value, transactItemData }: Props) => {
  if (!transactItemData || !transactItemData.length) return null;

  const dataItem = [
    [
      transactItemData[0].input.count,
      transactItemData[0].output.count,
      transactItemData[0].internal.count,
    ],

    [
      transactItemData[0].input.amount,
      transactItemData[0].output.amount,
      transactItemData[0].internal.amount,
    ],
    [
      transactItemData[0].input.commission,
      transactItemData[0].output.commission,
      transactItemData[0].internal.commission,
    ],
  ];


  return (
    <>
      {data.map((item, index) => (
        <TransactInfo
          key={`TransactInfo${index}`}
          data={item}
          last={index + 1 === data.length}
          value={value}
          dataItem={dataItem[index]}
        />
      ))}
    </>
  );
};

export default TransactInfoList;

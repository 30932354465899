import React, {useEffect} from "react";
import {
  BotStatusContainer,
  ChatHeader,
  ChatUserName,
  BotStatus,
  BotStatusText
} from "./styled";
import {useTranslator} from "eo-locale";
import supportChatsStore, {ChatType} from "../../../store/SupportChats";
import {useParams} from "react-router-dom";
import { IGetBotStatus } from "./useBotStatus";
import Loader from "react-loader-spinner";
import {useBotStatusMutation} from "./useBotStatusMutation";
import {observer} from "mobx-react-lite";
import {useLazyQuery} from "@apollo/react-hooks";
import {GET_BOT_STATUS} from "./queries";

const SupportChatHeader: React.FC = observer(() => {
  let params: {id: string} = useParams();
  let chat: ChatType | undefined = supportChatsStore.getChatById(Number(params.id));
  const translator = useTranslator();
  const [updateBotStatus, {called, loading, data}] = useLazyQuery<IGetBotStatus>(GET_BOT_STATUS, {
      variables: {
          chatId: chat?.id
      }
  });

  useEffect(() => {
      if (chat && chat.id) {
          updateBotStatus();
      }
  }, [chat])

  useEffect(() => {
      if(data && !loading && chat?.id){
          supportChatsStore.setBotStatus(chat?.id, data.getSupportBotStatusByChatId);
      }
  }, [data, loading, chat])

  const setBotStatus = useBotStatusMutation();

  const onChangeBotStatusClick = () => {
    setBotStatus(chat?.id, !chat?.botStatus);
  }

  return(
    <ChatHeader>
      <ChatUserName>
        {chat?.userName}
      </ChatUserName>

      <BotStatusContainer
        onClick={onChangeBotStatusClick}
      >
        <BotStatusText>
          {translator.translate('supportChatBotStatus')}
        </BotStatusText>

        {loading ? (
          <Loader type="Oval" width={10} height={10} color="#fff" />
        ) : (
          <BotStatus statusColor={chat?.botStatus ? "green" : "red"} />
        )}
      </BotStatusContainer>
    </ChatHeader>
  )
})

export default SupportChatHeader;

import {useMutation} from "@apollo/react-hooks";
import {MARK_MESSAGES_READ} from "./queries";

export const useReadMessages = () => {
  const [markRead, {loading}] = useMutation(MARK_MESSAGES_READ);

  const markReaded = (chatId?: number, topMessageId?: number) => {
    return markRead({
      variables: {
        chatId: chatId,
        msgId: topMessageId
      },
    })
  }

  return {markReaded, loading};
}

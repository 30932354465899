import React, {useEffect, useState} from "react";
import dayjs from "dayjs";
import {MessageType} from "../../../store/SupportChats";
import icons from "../../../assets/img/sprite.svg";
import {
  ChatMessage,
  Date,
  Content,
  IconContainer,
  Image,
  Deleted, StyledVideoContainer, StyledVideoIcon
} from "./style";
import playIcon from '../../../assets/img/playButton.svg'
import {useTranslator} from "eo-locale";
import {MediaType} from "../CSupportMessagesList";

export interface IChatMessage {
  margin?: string;
}

interface ICSupportChatMessage extends IChatMessage{
  item: MessageType;
  onMediaDisplay?: (data: any) => void;
}

const CSupportChatMessage = React.forwardRef<HTMLLIElement, ICSupportChatMessage>((
  {
    item,
    margin = '0',
    onMediaDisplay = () => {}
  }
, ref) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [thumbUrl, setThumbUrl] = useState<string>();
  const translator = useTranslator();

  const getDate = (): string => {
    let now = dayjs();
    let messageDate = dayjs.unix(item.date);

    if(now.year() !== messageDate.year()){
      return messageDate.format("HH:mm DD.MM.YYYY")
    } else if(now.day() !== messageDate.day() || now.date() !== messageDate.date()) {
      return messageDate.format("HH:mm DD.MM")
    } else {
      return messageDate.format("HH:mm")
    }
  }

  const onMediaClick = (media: MediaType) => () => {
    onMediaDisplay(media)
  }

  useEffect(() => {
    if(item.media && (item.media.thumbId || item.media.fileId)){
      const token = localStorage.getItem('token');
      const link = process.env.REACT_APP_API_APLOAD;

      setIsLoading(true);
      fetch(`${link}${item.media.thumbId || item.media.fileId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
      })
        .then(data => {
          return data.blob()
        })
        .then(file => {
          const urlCreator = window.URL || window.webkitURL;

          return urlCreator.createObjectURL(file)
        }).then(res => {
        if(res) {
          setThumbUrl(res);
        }
        setIsLoading(false);
      })
      .catch(er => {
        setIsLoading(false);
      })
    }
  }, [item.media]);

  const getMedia = () => {
    const mime = item.media.mimeType
    const type = mime.slice(0, mime.indexOf('/'));

    if(isLoading){
      return (
        <Content>
          {translator.translate('supportBotLoading')}
        </Content>
      );
    }

    switch (type){
      case 'video': {
        return (
          <StyledVideoContainer onClick={onMediaClick(item.media)}>
            <Image
              src={thumbUrl}
            />
            <StyledVideoIcon src={playIcon} />
          </StyledVideoContainer>
        )
      }
      case 'image': {
        return(
          <Image
            src={thumbUrl}
            onClick={() => onMediaDisplay(item.media)}
          />
        )
      }
      case 'audio': {
        return (
          <Content>
            {translator.translate('supportBotWrongFileType')}
          </Content>
        )
      }
    }
  }

  return(
    <ChatMessage
      ref={ref}
      margin={margin}
    >
      {
        item.media ? (
          <>
            {getMedia()}
          </>
        ): (
          <Content>
            {item.message}
          </Content>
        )
      }

      {
        item.deleted ? (
          <Deleted>
            {translator.translate('messagesDeletedMessage')}
          </Deleted>
        ) : null
      }

      <Date>
        {getDate()}
      </Date>

      <IconContainer
        color={item.readed ? "#007aff" : "gray"}
      >
        <svg className="inline-svg-icon">
          <use className="icas" xlinkHref={`${icons}#icRead`}/>
        </svg>
      </IconContainer>
    </ChatMessage>
  )
})

export default CSupportChatMessage;

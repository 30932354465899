import React from 'react'
import styled, { css } from 'styled-components'
import { palette } from 'styled-theme'
import tick from '../../../assets/img/tick.svg'

const Check = styled.div<{margin:string,palette:string, type:string}>`
  margin: ${props => props.margin};
  input[type='checkbox'] {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .check {
    width: 14px;
    height: 14px;
    border: 2px solid ${palette({ primary: 7 }, 1)};
    line-height: 8px;
    text-align: center;
    margin-right: 10px;
    background-color: transparent;
    border-radius: 50%;
    transition: all 0.3s ease;
    &__checked {
        display: none;
    }
  }
  ${props => props.type !== 'normal' && css`
    & .check{
      margin-left: auto;
      border: none;
      position: relative;
      &:before, :after{
        transition: all 0.3s ease-out;
        position: absolute;
        content: '';        
        width: 0;
        height: 0;
        background-color: ${palette({ primary: 0 }, 1)};
      }
      &:before{
        left: 4px;
        bottom: 5px;
      }
      &:after{
        right: 0;
        top: 6px;
      }
    }
  `}
  input[type='checkbox']:checked + .check {
    border: none;
    background: url(${tick}) no-repeat center center ${palette({ primary: 0 }, 1)};
    background-size: 55%;    
    ${props => props.type !== 'normal' && css`
      background: none;
      &:before{                
        width: 5px;
        height: 2px;
        transform: rotate(40deg);
        background-color: ${palette({ primary: 0 }, 1)};
      }
      &:after{        
        width: 8px;
        height: 2px;
        transform: rotate(-55deg);
        background-color: ${palette({ primary: 0 }, 1)};
      }
      transition: all 0.3s ease-out;

    `}    
  }
  label {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 0;
  }

  span {
    font-size: ${props => props.type === 'normal' ? '16px' : '18px'};
  }
`

export default ({
  name = '',
  title = '',
  changeRadio = (e:any) => {},  
  value = '',
  color = '#0044CC',
  checked = false,
  margin = '0',
  type = 'normal',
  tag = '',
}) => (
  <Check palette="primary" color={color} margin={margin} type={type}>
    <label>
      {type === 'normal' ?
        <>
          <input
            type="checkbox"
            value={value}
            name={name}
            checked={checked}
            onChange={changeRadio}
          />
          <div className="check" />
          <span>{title}</span>
        </>
      :
        <>
          <span>{title}</span>
          <input
            type="checkbox"
            value={value}
            name={name}
            checked={checked}
            onChange={changeRadio}
            data-tag={tag}
          />
          <div className="check"/>
        </>
      }
    </label>
  </Check>
)

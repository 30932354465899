import styled from "styled-components";

export const ModalBackground = styled.div`
  width: 453px;
  height: 679px;
  background: rgb(37, 40, 43);
  border-radius: 3px;
  position: relative;
  padding: 48px 64px 58px 48px;
`

export const CloseButton = styled.button`
  display: block;
  border: none;
  background-color: transparent;
  padding: 0;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
`

export const DatePeriod = styled.div`
  display: flex;
  margin-bottom: 20px;
`

export const Title = styled.h2`
  font-size: 24px;
  margin: 0 0 24px;
  font-weight: normal;
`

export const BlockTitle = styled.h3`
  font-size: 18px;
  margin: 0 0 16px;
  font-weight: normal;
`

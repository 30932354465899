import styled, {css} from "styled-components";
import tick from '../../../assets/img/tick.svg'
import {CheckboxType, LabelType} from "./index";

export const Label = styled.label<LabelType>`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: ${({margin}) => margin};
  font-size: 16px;
`

export const Checkbox = styled.div<CheckboxType>`
  width: 14px;
  min-width: 14px;
  height: 14px;
  line-height: 8px;
  text-align: center;
  margin-right: 10px;
  border-radius: 50%;
  transition: all 0.3s ease;
  
  ${({isChecked}) => isChecked ? css`
    border: none;
    background: url(${tick}) no-repeat center center rgb(255, 255, 255);
    background-size: 55%;
  ` : css `
    border: 2px solid rgb(106, 114, 117);
    background-color: transparent;
  `}
`

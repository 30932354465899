import { gql } from "apollo-boost";

export const HISTORY_COMISS = gql`
  query getCommissionsHistory($symbol: CryptoSymbol!) {
    getCommissionsHistory(symbol: $symbol){
      type
      transaction{
        createdAt
        quantity,
        to,
        from,
        memo
      }
    }
  }
`
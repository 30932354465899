import React, {useState, useEffect} from 'react'
import {useTranslator} from 'eo-locale'
import {useMutation} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import Loader from 'react-loader-spinner'
import SubTitle from '../../atoms/SubTitle'
import Button from '../../atoms/Button'
import icons from '../../../assets/img/sprite.svg'
import Modal from "../../atoms/Modal";
import {CloseButton, BtnWrap, ModalBackground} from './styled';
import Select, {SelectItemType} from "../../atoms/Select";
import {observer} from "mobx-react-lite";
import UserInfo from "../../../store/UserInfo";
import {USERS_ALL_SEARCH} from "../../organism/UsersInfo/queries";
import {UserQueryType} from "../../organism/UsersInfo";

export interface Props {
    enabled: boolean;
    level?: number;
    onClose: () => void;
}

const ModalChangeStatusVerify = observer(({
    enabled,
    onClose,
    level
}: Props) => {
    const [activeStatus, setActiveStatus] = useState<string>();
    const [displayedStatuses, setDisplayedStatuses] = useState<Array<SelectItemType<string>>>([]);
    const translator = useTranslator();

    const [setKycLevel, {loading: loadingStatus}] = useMutation(VERIFIC_STATUS, {
        update(proxy) {
            const prevData: UserQueryType | null = proxy.readQuery({
                query: USERS_ALL_SEARCH,
                variables: {
                    email: UserInfo.email,
                    period: UserInfo.transactPeriod,
                    skip: 12 * UserInfo.transactActivePage,
                }
            });

            proxy.writeQuery({
                query: USERS_ALL_SEARCH,
                data: {
                    getUserBy:{
                        ...prevData?.getUserBy,
                        withdrawAmount: {
                            ...prevData?.getUserBy.withdrawAmount,
                            level: Number(activeStatus)
                        }
                    }
                }
            })
        },
    });

    useEffect(() => {
        const statuses: Array<SelectItemType<string>> = [
            {
                key: "0",
                value: "0"
            },
            {
                key: "1",
                value: "1"
            },
            {
                key: "2",
                value: "2"
            },
        ]

        setDisplayedStatuses(statuses.filter(el => el.key !== level?.toString()));
    }, [level])

    const onSend = () => {
        setKycLevel({
            variables: {
                userId: UserInfo.id,
                level: Number(activeStatus)
            }
        }).then(() => {
            UserInfo.setVerificationLevel(Number(activeStatus));
            onModalClose();
        })
    }

    const onModalClose = () => {
        setActiveStatus(undefined);
        onClose();
    }

    const onSelect = (item: SelectItemType<string>) => {
        setActiveStatus(item.key)
    }

    return (
        <Modal
          enabled={enabled}
          onBackgroundClick={onModalClose}
        >
            <ModalBackground>
                {
                    loadingStatus ? (
                        <div className="loader"><Loader type="Oval" color="#fff" height={100} width={100}/></div>
                    ) : null
                }

                <CloseButton onClick={onModalClose}>
                    <svg className="inline-svg-icon">
                        <use className="icas" xlinkHref={`${icons}#icClose`} name="debtors"/>
                    </svg>
                </CloseButton>

                <SubTitle margin="0 0 32px 0">
                    {translator.translate('ModalChangeStatusVerifTitle', { username: "" })}
                </SubTitle>

                <Select
                  title={translator.translate('ModalChangeStatusVerifSelectTitle')}
                  items={displayedStatuses}
                  selectedItem={activeStatus}
                  onSelect={onSelect}
                />

                <BtnWrap>
                    <Button margin="0" width="48%" onClick={onSend}>
                        {translator.translate('ModalChangeStatusVerifySave')}
                    </Button>

                    <Button margin="0" width="48%" type="button" onClick={onModalClose}>
                        {translator.translate('ModalChangeStatusVerifyCancel')}
                    </Button>
                </BtnWrap>
            </ModalBackground>
        </Modal>
    )
})

const VERIFIC_STATUS = gql`
mutation setKycLevel($userId: Int!, $level: Int!){
  setKycLevel(query:{userId: $userId, level: $level})
}
`
export default (ModalChangeStatusVerify)

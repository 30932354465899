import styled from "styled-components";

export const ModalBackground = styled.div`
  width: 580px;  
  background: rgb(37, 40, 43);
  border-radius: 3px;
  position: relative;
  padding: 48px 64px 58px 48px;
`
export const CloseButton = styled.button`
  display: block;
  border: none;
  background-color: transparent;
  padding: 0;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
`

export const BtnWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
`

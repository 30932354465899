import React from 'react'
import {Wrap} from "./styled";
import RadioItem, {RadioItemType} from '../../atoms/RadioButton'

export interface IRadioList {
  items: Array<RadioItemType>;
  margin: string;
  selected: string;
  itemsMargin?: string;
  direction?: "row" | "column";
  onSelect?: (item: RadioItemType) => void;
}

const RadioList: React.FC<IRadioList> = (
  {
    items,
    margin= '0',
    itemsMargin= '0',
    selected,
    direction= 'column',
    onSelect = () => {}
  }
) => {
  const onRadioSelect = (item: RadioItemType) => {
    onSelect(item);
  }

  return (
    <Wrap
      direction={direction}
      margin={margin}
    >
      {
        items.map(item => (
          <RadioItem
            key={item.key}
            item={item}
            selected={item.key === selected}
            margin={itemsMargin}
            onClick={onRadioSelect}
          />
        ))
      }
    </Wrap>
  )
}

export default RadioList

import React from 'react'
import {StyledSubTitle} from "./styled";

export interface Props {
  children: any;
  fSize?: string;
  margin?: string;
  bold?: boolean;
}

export default ({ children, fSize='24px', margin='0 0 18px 0', bold=false }:Props) => {
  return (
    <StyledSubTitle fSize={fSize} margin={margin} bold={bold}>
      {children}
    </StyledSubTitle>
  )
}

import React from 'react';
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloProvider } from '@apollo/react-hooks'
import 'normalize.css/normalize.css'
import App from './components/App'
import * as serviceWorker from './serviceWorker';
import { getMainDefinition } from 'apollo-utilities';
import { WebSocketLink } from "apollo-link-ws";
import { split } from 'apollo-link';

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token')
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})
const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL,
})


const wsLink = () => {
    const token = localStorage.getItem('token')
    return new WebSocketLink({
        uri: process.env.REACT_APP_WS_API_URL || '',
        options: {
            reconnect: true,
            timeout: 30000,
            connectionParams: {
                authorization: `Bearer ${token}`
            }
        }
    });
};

const link = split(
    ({ query }) => {
        //@ts-ignore
        const { kind, operation } = getMainDefinition(query);
        return kind === "OperationDefinition" && operation === "subscription";
    },
    wsLink(),
    authLink.concat(httpLink)
);

const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache(),
})

ReactDOM.render (
  <ApolloProvider client={client}>
    <BrowserRouter>
      <App /> 
    </BrowserRouter> 
  </ApolloProvider>,
  document.getElementById('root')
)

serviceWorker.unregister();

import styled from "styled-components";

export const ModalBackground = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(10px);
`

export const Modal = styled.div<{ width: number, height: number }>`
  position: relative;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  background-color: white;
  top: calc(50% - ${props => props.height/2}px);
  left: calc(50% - ${props => props.width/2}px);
  padding: 37px 34px 23px 34px;
  border-radius: 4px;
`

export const Period1 = styled.div`
  margin-bottom: 15px;
`

export const Period2 = styled.div`
  margin-bottom: 20px;
`

export const Title = styled.div`
  color: black;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 20px;
`

export const Button = styled.button`
  width: 151px;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  border: 1px solid black;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  &:disabled{
    color: gray;
    border: 1px solid gray;
    cursor: default;

    & img{
      opacity: 0.5;
    }
  }

`

export const Arrow = styled.img`
  width: 12px;
  height: 17px;
  margin-right: 11px;
`

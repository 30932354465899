import React, {useState} from "react";
import Modal from "../../atoms/Modal";
import { Background, StyledButton, Title, SubTitle, Block, RangeBlock } from "./styled";
import Loader from "react-loader-spinner";
import icons from "../../../assets/img/sprite.svg";
import {useTranslator} from "eo-locale";
import Select, {SelectItemType} from "../../atoms/Select";
import CheckButton from "../../atoms/CheckButton";
import DateSelect from "../../atoms/DateSelect";
import {USERS_DETAIL} from "../../organism/UsersReport/queries";
import {useQuery} from "@apollo/react-hooks";
import { Dayjs } from "dayjs";
import UsersPngReportButton from "../UsersPngReportButton";
import UsersPdfReportButton from "../UsersPdfReportButton";
import UsersExcelReportButton from "../UsersExcelReportButton";
import { useCalendarControl } from "../../../hooks/useCalendarControl";
import { Period } from "../../organism/TransactItem/type";

export interface IUsersDetail {
  getUsersDetail: {
    count: number
    chart: Array<
      {
        toDate: number
        count: number
      }
    >
    countries: Array<
      {
        fullName: string
        name: string
        count: number
      }
    >
  }
}

interface IModalUserReport {
  enabled: boolean;
  onClose: () => void;
  device: string;
  periods: Array<SelectItemType<string>>;
  selectedPeriod: Period | 'Change';
  countries: Array<SelectItemType<string>>;
  selectedCountry?: string;
  selectedFromDate?: Dayjs;
  selectedToDate?: Dayjs;
  totalCount?: number
}

const ModalUserReport: React.FC<IModalUserReport> = (
  {
    enabled= false,
    periods,
    selectedPeriod,
    selectedCountry,
    selectedFromDate,
    selectedToDate,
    countries,
    onClose,
    totalCount,
    device
  }
) => {
  const [period, setPeriod] = useState(selectedPeriod);
  const [country, setCountry] = useState(selectedCountry);
  const [
    onToDateChange,
    onFromDateChange,
    { fromDate, toDate, maxToDate, minToDate, maxFromDate },
  ] = useCalendarControl(period, selectedFromDate, selectedToDate)
  const translator = useTranslator();
  const {data: dataAll, loading: loadingAll} = useQuery<IUsersDetail>(USERS_DETAIL, {
    variables: {
      device: device,
      period: period,
      from: fromDate?.valueOf(),
      to: period === 'Change' ? toDate.subtract(1, "day").valueOf() : toDate?.valueOf(),
      country: country,
    },
    fetchPolicy: "no-cache",
  })
  const onPeriodSelect = (item: SelectItemType<string>) => setPeriod(item.key as Period);
  const onCountrySelect = (item: SelectItemType<string>) => setCountry(item.key);
  // Синхронизация периода модалки с периодом страницы
  React.useEffect(() => {
    if (selectedPeriod) setPeriod(prev => prev !== selectedPeriod ? selectedPeriod : prev);
  }, [selectedPeriod])

  return(
    <Modal
      enabled={enabled}
      onBackgroundClick={() => onClose()}
    >
      <Background>
        {loadingAll && <div className="loader"><Loader type="Oval" color="#fff" height={100} width={100} /></div>}
        <StyledButton onClick={() => onClose()}>
          <svg className="inline-svg-icon">
            <use className="icas" xlinkHref={`${icons}#icClose`} />
          </svg>
        </StyledButton>

        <Title>
          {translator.translate('modalReportTitle')}
        </Title>

        <Block>
          <SubTitle>
            {translator.translate('modalReportPeriod')}
          </SubTitle>

          <Select
            title={translator.translate('periodTitle')}
            items={periods}
            selectedItem={period}
            onSelect={onPeriodSelect}
            margin="0 0 16px"
          />

          {
            (period as Period | "Change") === "Change" || period  === "change"? (
              <RangeBlock>
                <DateSelect
                  title={translator.translate('periodTitleDate1')}
                  width='163px'
                  onValueChange={onFromDateChange}
                  value={new Date(fromDate.valueOf())}
                  maxDate={maxFromDate}
                  theme="black"
                  margin="0 15px 0 0"
                />

                <DateSelect
                  title={translator.translate('periodTitleDate2')}
                  width='163px'
                  onValueChange={onToDateChange}
                  value={new Date(toDate.valueOf())}
                  theme="black"
                  maxDate={maxToDate}
                  minDate={minToDate}
                />
              </RangeBlock>
            ) : null
          }

          <Select
            title={translator.translate('countryDescr')}
            items={countries}
            selectedItem={country}
            onSelect={onCountrySelect}
          />
        </Block>

        <Block>
          <SubTitle>
            {translator.translate('usersReportDescr')}
          </SubTitle>

          <CheckButton
            title={device === 'Desktop' ? 'Desktop/Web' : device}
            checked
            margin="0 0 32px 0"
          />
        </Block>

        <UsersExcelReportButton
          data={dataAll}
          device={device}
          totalCount={totalCount}
        />

        <UsersPdfReportButton
          data={dataAll}
          totalCount={totalCount}
          device={device}
        />

        <UsersPngReportButton
          data={dataAll}
          period={period}
          totalCount={totalCount}
          device={device}
        />
      </Background>
    </Modal>
  )
}

export default ModalUserReport;

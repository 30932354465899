import styled from "styled-components";

export const Container = styled.div`
  display: flex
`

export const Filter = styled.div`
  margin-right: 12px;
`

export const ResetButton = styled.button`
  margin-right: 31px;
  font-size: 14px;
  line-height: 16px;
  background: none;
  border: none;
  cursor: pointer;
  &:disabled{
    color: gray;
    cursor: default;
  }
`

export const TypeSelectionContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`

export const TypeButton = styled.button`
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  background: none;
  border: none;
  cursor: pointer;
`

import styled, {css} from "styled-components";
import {BodyItemType, HeadItemType, RowType, TableContainerType} from "./index";

export const TableContainer = styled.div<TableContainerType>`
  width: 100%;
  height: 100%;
  min-height: ${({minHeight}) => minHeight ? minHeight : 'auto'};
  position: relative;
  overflow-wrap: break-word;
  white-space: break-spaces;
  overflow: auto;
`

export const StyledTable = styled.table`
  width: 100%;
  font-size: 14px;
  border-spacing: 0;
`

export const LoaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  backdrop-filter: blur(2px);
  justify-content: center;
  align-items: center;
  z-index: 100;
  & svg{
    width: 70px;
    height: 70px;
  }
`

export const HeadRow = styled.tr`
  height: 45px;
`

export const Row = styled.tr<RowType>`
  height: 38px;
  cursor: ${({clickable}) => clickable ? 'pointer': 'default'};
  transition: all 0.3s ease-out;
  
  & td:first-of-type{
    //Нужно для адекватной работы transition
    transition: all 0.3s ease-out 0s;
    border-left: 0 solid rgb(255, 255, 255);
  }
  
  ${({selected}) => selected ? css`
    background-color: rgb(106, 114, 117);
    display: table-row;
    
    & td:first-of-type{
      border-width: 4px;
    }
  `: null}
  
  ${({clickable}) => clickable ? css`
    &:hover {
      background-color: #464749;
    }
  ` : null}
`

export const HeadItem = styled.th<HeadItemType>`
  font-family: Roboto Medium,sans-serif;
  padding: 10px 4px 5px 4px;
  text-align: ${({textAlign}) => textAlign || 'left'};
  width: ${({width}) => width ? width : 'auto'};
  &:first-of-type{
    padding-left: 24px;
  }
  &:last-of-type{
    padding-right: 24px;
  }
`

export const BodyItem = styled.td<BodyItemType>`
  padding: 0 4px 0 4px;
  text-align: ${({textAlign}) => textAlign || 'left'};
  &:first-of-type{
    padding-left: 24px;
  }
  &:last-of-type{
    padding-right: 24px;
  }
`

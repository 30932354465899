import {useEffect, useState} from "react";
import {useQuery} from "@apollo/react-hooks";
import {GET_MESSAGES} from "./queries";

export type SupportMessageType = {
  _id: number,
  chatId: number
  date: number
  fromId: number
  message: string
  readed: boolean,
  deleted: boolean,
  media: {
    fileId: number,
    filename: string,
    thumbId: number,
    mimeType: string
  }
  toId: number
}

export type MessagesByIdType = {
  getSupportMessage: {
    messages: Array<SupportMessageType>,
    pagination: {
      next: boolean
    }
  }
}

export const useLoadMessages = (chatId?: number, page?: number, limit?: number) => {
  const [data, setData] = useState();

  const { data: result, loading, refetch } = useQuery<MessagesByIdType>(GET_MESSAGES, {
    variables: {
      limit: limit,
      skip: page && limit ? page * limit : 0 ,
      chatId: chatId
    },
  })

  useEffect(() => {
    if(result && !loading){
      setData(result);
    }
  }, [result, loading]);

  return {data, loading, refetch}
}

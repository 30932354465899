import { useState } from "react";
import { FieldValues, UnpackNestedValue, useForm, UseFormOptions } from "react-hook-form";
export const useFormControl = <FData extends FieldValues>(
  config?: UseFormOptions<FData>,
  callback?: (formData: UnpackNestedValue<FData>) => void
) => {
  const [formData, setFormData] = useState<UnpackNestedValue<FData> | null>(
    null
  );
  const {
    handleSubmit,
    clearErrors,
    errors,
    control,
    getValues,
    setError,
    reset,
    setValue,
    formState,
  } = useForm<FData>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    shouldFocusError: true,
    criteriaMode: "all",
    ...config,
  });

  return {
    getValues,
    setError,
    reset,
    control,
    clearErrors,
    errors,
    formData,
    setValue,
    formState,
    onSubmit: handleSubmit((formData) => {
      setFormData(formData);
      callback?.(formData)
    }),
  };
};

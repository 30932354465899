import React, {useEffect, useState} from "react";
import Modal from "../../atoms/Modal";
import icons from "../../../assets/img/sprite.svg";
import SubTitle from "../../atoms/SubTitle";
import {useTranslator} from "eo-locale";
import CustomButton from "../../atoms/CustomButton";
import Select, {SelectItemType} from "../../atoms/Select";
import CustomInput from "../../atoms/CustomInput";
import {useMutation} from "@apollo/react-hooks";
import {GET_LOT_BY_ID, SET_LOT_STATUS} from "./queries";
import {Body, CloseButton, Controls} from './styled';

interface IChangeLotStatusModal {
  enabled: boolean,
  item: any,
  onClose: () => void;
  onStatusUpdate: () => void;
}

type getLotByIdType = {
  getLotById: {
    additionalDocuments: Array<number>,
    amount: string
    certificate: string
    createdBy: {
      _id: number,
      firstName: string,
      lastName: string,
      email: string
    }
    currency: string
    description: string
    insurance: Array<number>
    insuranceNo: string
    name: string
    percentToSell: number
    photos: Array<number>
    privacy: string
    reasons: Array<string>
    status: string
    _id: number
  }
}

const ModalChangeLotStatus: React.FC<IChangeLotStatusModal> = (
  {
    item,
    enabled,
    onStatusUpdate,
    onClose
  }
) => {
  const translator = useTranslator();
  const [status, setStatus] = useState<string>();
  const [reason, setReason] = useState<string>();
  const [customReason, setCustomReason] = useState<string>('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  const statusItems: Array<SelectItemType<string>> = [
    {
      key: "PUBLISHED",
      value: translator.translate('statusVerify1')
    },
    {
      key: "NOT_PUBLISHED",
      value: translator.translate('statusVerify2')
    },
  ]

  const reasonItems: Array<SelectItemType<string>> = [
    {
      key: "0",
      value: translator.translate('AuctionsRefusal0'),
    },
    {
      key: "1",
      value: translator.translate('AuctionsRefusal1'),
    },
    {
      key: "2",
      value: translator.translate('AuctionsRefusal2'),
    },
    {
      key: "3",
      value: translator.translate('AuctionsRefusal3'),
    },
    {
      key: "4",
      value: translator.translate('AuctionsRefusal4'),
    },
    {
      key: "5",
      value: translator.translate('AuctionsRefusal5'),
    },
    {
      key: "6",
      value: translator.translate('AuctionsRefusal6'),
    },
    {
      key: "7",
      value: translator.translate('AuctionsRefusal7'),
    },
  ]

  useEffect(() => {
    if(status === "PUBLISHED"){
      setIsSubmitDisabled(false);
    } else if(reason && reason !== "5"){
      setIsSubmitDisabled(false);
    } else if(customReason){
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  }, [status, reason, customReason]);

  const [setLotStatus, { loading: loadingStatus }] = useMutation(SET_LOT_STATUS, {
    update: (cache) => {
      const lotInCache = cache.readQuery<getLotByIdType>({query: GET_LOT_BY_ID, variables: {id: item?._id}});

      let newReasons;
      if(status !== "PUBLISHED"){
        newReasons = reason !== "5" ? [reasonItems.find(el => el.key === reason)?.value] : [customReason]
      }
      cache.writeQuery({
        query: GET_LOT_BY_ID,
        data: {
          getLotById: {
            ...(lotInCache?.getLotById),
            status: status,
            reasons: newReasons
          }
        }
      })
    },
    variables: {
      id: item?._id,
      status: status,
      reasons: status !== "PUBLISHED" && reason !== "5" ? [reasonItems.find(el => el.key === reason)?.value] : customReason
    }
  })

  const changeStatus = (item: SelectItemType<string>) => {
    setStatus(item.key);
  }

  const changeReason = (item: SelectItemType<string>) => {
    setReason(item.key);
  }

  const changeCustomReason = (value: string) => {
    const correctValue = value.trim();

    if (correctValue !== '' || value === '') {
      setCustomReason(value);
    }
  }

  const onSend = () => {
    setLotStatus().then(data => {
      onStatusUpdate();
      onModalClose();
    }).catch(er => {
      //Вставить тосты
    });
  }

  const onModalClose = () => {
    setStatus(undefined);
    setReason(undefined);
    setCustomReason('');
    onClose();
  }

  return(
    <Modal
      enabled={enabled}
      onBackgroundClick={onModalClose}
    >
      <Body>
        <CloseButton onClick={onModalClose}>
          <svg className="inline-svg-icon">
            <use className="icas" xlinkHref={`${icons}#icClose`} name="debtors" />
          </svg>
        </CloseButton>

        <SubTitle margin="0 0 32px 0">
          {translator.translate('AuctionModalTitle', { value: item?.name })}
        </SubTitle>

        <Select
          items={statusItems}
          selectedItem={status}
          title="Статус"
          placeholder={translator.translate('statusVerifyDefault')}
          onSelect={changeStatus}
          margin={status === "NOT_PUBLISHED" ? "0 0 10px 0" : "0 0 32px 0"}
        />

        {
          status === "NOT_PUBLISHED" ? (
            <Select
              items={reasonItems}
              selectedItem={reason}
              title="Причина отклонения"
              onSelect={changeReason}
              margin={reason === "5" ? "0 0 10px 0" : "0 0 32px 0"}
            />
          ) : null
        }
        {
          reason === "5" ? (
            <CustomInput
              value={customReason}
              title={translator.translate('AuctionInputReason')}
              onChange={changeCustomReason}
              margin="0 0 32px 0"
            />
          ) : null
        }

        <Controls>
          <CustomButton
            disabled={isSubmitDisabled}
            width='225px'
            height='52px'
            onClick={onSend}
          >
            {translator.translate('ModalChangeStatusVerifySave')}
          </CustomButton>

          <CustomButton
            width='225px'
            height='52px'
            onClick={onModalClose}
          >
            {translator.translate('ModalChangeStatusVerifyCancel')}
          </CustomButton>
        </Controls>
      </Body>
    </Modal>
  )
}

export default ModalChangeLotStatus;

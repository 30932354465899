import React, {forwardRef, useCallback, useEffect, useRef} from "react";

export type PNGHeadItemType = {
  label: string,
  value: string | Array<PNGHeadItemType>,
  width: number
}

interface IPNGTableDownload {
  header: Array<PNGHeadItemType>,
  data: Array<any>
}

const PNGTableDownload = forwardRef<HTMLButtonElement, IPNGTableDownload>((props, ref) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)

  const paintHeadCells = useCallback((
    ctx: CanvasRenderingContext2D,
    items: Array<PNGHeadItemType>,
    startX: number,
    startY: number,
    startHeight: number
  ) => {
    let x = startX;
    items.map((item, index) => {
      if(Array.isArray(item.value)){
        ctx.beginPath();
        ctx.strokeRect(x, startY, item.width, startHeight / 2);
        ctx.fillText(item.label, 5 + x, startY + startHeight / 2 - 5, item.width);
        ctx.closePath();
        paintHeadCells(ctx, item.value, x, startY + startHeight / 2, startHeight / 2);
        return;
      }
      ctx.beginPath();
      ctx.strokeRect(x, startY, item.width, startHeight);
      ctx.fillText(item.label, 5 + x, startY + startHeight - 5, item.width);
      x += item.width;
      ctx.closePath();
    })
  }, []);

  const paintRow = useCallback((
    ctx: CanvasRenderingContext2D,
    header: Array<PNGHeadItemType>,
    item: any,
    startX: number,
    startY: number,
    startHeight: number
  ) => {
    let x = startX;
    header.forEach((el) => {
      if(Array.isArray(el.value)){
        paintRow(ctx, el.value, item, x, startY, startHeight);
        return;
      }

      ctx.beginPath();
      ctx.strokeRect(x, startY, el.width, startHeight);
      ctx.fillText(item && item[el.value] ? item[el.value] : "", 5 + x, startY + startHeight - 5, el.width);
      ctx.closePath();
      x += el.width;
    })
  }, [])

  const paintBody = useCallback((
    ctx: CanvasRenderingContext2D,
    header: Array<PNGHeadItemType>,
    data: Array<any>,
    startX: number,
    startY: number,
    startHeight: number
  ) => {
    let y = startY;
    data.forEach(item => {
      paintRow(ctx, header, item, startX, y, startHeight);
      y += startHeight;
    })
  }, [paintRow]);

  useEffect(() => {
    const canvas = canvasRef && canvasRef.current;
    let ctx = canvas && canvas.getContext('2d')

    if(!canvas || !ctx || !props.data) return;
    //Предполетная подготовка
    canvas.height = 80 + props.data.length * 60;
    canvas.width = 6000;
    ctx.font = "22px Verdana";
    ctx.fillStyle = "black";
    ctx.lineWidth = 2;
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    paintHeadCells(ctx, props.header, 10, 10, 60);

    ctx.font = "18px Verdana";

    paintBody(ctx, props.header, props.data, 10, 70, 60);
  }, [props.header, props.data, paintHeadCells, paintBody]);

  const onClick = () => {
    const canvas = canvasRef.current;
    let ctx = canvas && canvas.getContext('2d'),
        link = document.createElement('a');
    if(!canvas || !ctx){return;}

    link.download = `report.png`;
    canvas.toDataURL("image/png")
    link.href = canvas.toDataURL();
    link.click();
  }

  return(
    <button
      style={{ display: 'none' }}
      ref={ref}
      onClick={onClick}
    >
      <canvas
        ref={canvasRef}
      />
    </button>
  )
})

export default PNGTableDownload;

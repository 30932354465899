import React, {useState, useEffect, useCallback} from 'react'
import { useTranslator } from 'eo-locale'
import {useQuery} from '@apollo/react-hooks'
import Pagination from '../../atoms/Pagination'
import CustomInput from "../../atoms/CustomInput";
import Table from "../../atoms/Table";
import {TableHeadType} from "../../atoms/Table";
import Title from "../../atoms/Title";
import {useHistory, useRouteMatch} from "react-router-dom";
import Select, {SelectItemType} from "../../atoms/Select";
import CustomButton from "../../atoms/CustomButton";
import {GET_LOTS} from "./queries";
import {SearchInput, TableBackground, Flex} from './styled';

type TableDataItem = {
  status: string,
  id: string,
  createdBy: string,
  name: string,
  amount: string,
  creationDate: string
}

type LotType = {
  amount: string
  createdAt: number
  createdBy: {
    _id: 4887
  }
  name: string
  status: "IN_MODERATION" | "PUBLISHED" | "NOT_PUBLISHED"
  _id: number
}

type allDataType = {
  getLots: {
    lots: Array<LotType>
    pagination: {
      total: number,
      previous: boolean,
    }
  }
}

const AuctionsAll: React.FC = () => {
  const translator = useTranslator();
  const [searchInputValue, setSearchInputValue] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [tableData, setTableData] = useState<Array<TableDataItem>>([])
  const [status, setStatus] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const history = useHistory();
  const {path} = useRouteMatch();

  const { data: dataAll, loading, error } = useQuery<allDataType>(GET_LOTS, {
    variables: {
      skip: 12 * currentPage,
      first: 12,
      status: status,
      name: searchInput
    },
  });

  const formatData = useCallback((data: Array<LotType>): Array<TableDataItem> => {
    let result: Array<TableDataItem> = [];
    const statusLocalize = {
      IN_MODERATION: translator.translate('statusVerify0'),
      PUBLISHED: translator.translate('statusVerify1'),
      NOT_PUBLISHED: translator.translate('statusVerify2')
    }

    data.forEach(item => {
      result.push({
        status: statusLocalize[item.status] || '',
        id: item._id.toString() || '',
        createdBy: item.createdBy._id.toString() || '',
        name: item.name || '',
        amount: item.amount || '',
        creationDate: new Date(item.createdAt).toLocaleString(['ru-RU', 'en-US']) || ''
      })
    });
    
    return result;
  }, [translator])

  useEffect(() => {
    const timeout = setTimeout(() => {
      setSearchInput(searchInputValue);
      setCurrentPage(0);
    }, 500);
    return () => clearTimeout(timeout);
  }, [searchInputValue])

  useEffect(() => {
    if(dataAll){
      let newTableData = formatData(dataAll.getLots.lots);
      setTableData(newTableData);
    }
  }, [formatData, dataAll]);

  const headData: Array<TableHeadType<TableDataItem>> = [
    {
      text: translator.translate('auctionsTableHead1'),
      dataKey: 'id',
      width: '80px'
    },
    {
      text: translator.translate('auctionsTableHead2'),
      dataKey: 'createdBy',
      width: '80px'
    },
    {
      text: translator.translate('auctionsTableHead3'),
      dataKey: 'name'
    },
    {
      text: translator.translate('auctionsTableHead4'),
      dataKey: 'amount',
      width: '150px'
    },
    {
      text: translator.translate('auctionsTableHead0'),
      dataKey: 'status',
      width: '150px'
    },
    {
      text: translator.translate('auctionsTableHead5'),
      dataKey: 'creationDate',
      width: '180px'
    },
  ]

  const statusItems: Array<SelectItemType<string>> = [
    {
      key: "IN_MODERATION",
      value: translator.translate('statusVerify0')
    },
    {
      key: "PUBLISHED",
      value: translator.translate('statusVerify1')
    },
    {
      key: "NOT_PUBLISHED",
      value: translator.translate('statusVerify2')
    },
  ]

  const changePage = (page: number) => {
    setCurrentPage(page);
  }

  const onSearchChange = (value: string) => {
    setSearchInputValue(value);
  }

  const changeStatus = (item: SelectItemType<string>) => {
    setStatus(item.key);
    setCurrentPage(0);
  }

  const searchReset = () =>{
    if(searchInput !== '' || status){
      setSearchInputValue('');
      setSearchInput('');
      setStatus(undefined);
      setCurrentPage(0);
    }
  }

  const onTableSelect = (item: any, index: number) => {
    if(item && item.id){
      history.push(`${path}/${item.id}`)
    }
  }

  return (
    <>
      <Title fSize="24px" flex margin="0 0 24px 0">
        {translator.translate('auctionsTitle', { value: '' })}
      </Title>

      <Flex>
        <SearchInput>
          <CustomInput
            value={searchInputValue}
            title="Поиск"
            placeholder="Введите id лота или название"
            onChange={onSearchChange}
          />
        </SearchInput>

        <Select
          items={statusItems}
          selectedItem={status}
          onSelect={changeStatus}
          title="Статус"
          margin="0 10px 0 0 "
          placeholder={translator.translate('statusVerifyDefault')}
        />

        <CustomButton
          onClick={() => searchReset()}
          margin="0"
          height='52px'
          width='100px'
        >
          {translator.translate('AuctionSearchReset')}
        </CustomButton>
      </Flex>

      <TableBackground>
        <Table
          data={tableData}
          headData={headData}
          isLoading={loading}
          minHeight='567px'
          clickable
          onRowClick={onTableSelect}
        />
      </TableBackground>

      { (!error)&&
      <Pagination
          total={dataAll ? dataAll?.getLots.pagination.total / 12 : 0}
          paginActive={currentPage}
          changePaginActive={changePage}
      />
      }
    </>
  )
}

export default AuctionsAll;

import React, {useEffect, useState} from "react";
import {Header, UsersInfoStyled, UsersInfoItem, TableBackground, Controls} from './styled';
import Table from "../../atoms/Table";
import {TableHeadType} from "../../atoms/Table";
import {useTranslator} from "eo-locale";
import { useQuery } from '@apollo/react-hooks'
import { graphql } from 'react-apollo'
import { flowRight as compose } from 'lodash';
import CustomButton from "../../atoms/CustomButton";
import {useHistory, useRouteMatch} from "react-router-dom";
import {GET_COUNT} from "./queries";
import CustomLoader from "../../atoms/CustomLoader";

export type DeviceCountType = {
  total: number,
  countries: Array<{
    name: string,
    count: number,
    fullName: string
  }>
}

export type UsersCountType = {
  getUsersCount: {
    total: number,
    active: number,
    devices: {
      Desktop: DeviceCountType
      Android: DeviceCountType
      iOS: DeviceCountType
    }
  }
}

type TableItemType = {
  id: string,
  platform: string,
  amount: number
}

const UsersMain: React.FC = () =>
{
  const [tableData, setTableData] = useState<Array<TableItemType>>([]);
  const [selectedItem, setSelectedItem] = useState<TableItemType>();
  const translator = useTranslator();
  const history = useHistory();
  const {path} = useRouteMatch();

  const tableHead: Array<TableHeadType<TableItemType>> = [
    {
      text: translator.translate('usersTableHead1'),
      dataKey: "platform",
      width: '500px',
    },
    {
      text: translator.translate('usersTableHead2'),
      dataKey: "amount",
      width: '500px',
      align: "right"
    },
  ]

  const {data: usersCount, loading} = useQuery<UsersCountType>(GET_COUNT);

  useEffect(() => {
    const data = usersCount?.getUsersCount?.devices
    if(data){
      setTableData([
        {
          id: "Desktop",
          platform: translator.translate('usersTableBodyWeb'),
          amount: data.Desktop?.total
        },
        {
          id: "Android",
          platform: translator.translate('usersTableBodyAndroid'),
          amount: data.Android?.total
        },
        {
          id: "iOS",
          platform: translator.translate('usersTableBodyIOS'),
          amount: data.iOS?.total
        }
      ])
    }
  }, [translator, usersCount]);

  const onTableClick = (item: TableItemType) => {
    if(item.id === selectedItem?.id){
      setSelectedItem(undefined);
      return;
    }
    setSelectedItem(item);
  }

  const onReportClick = () => {
    history.push(`${path}/report/${selectedItem?.id}`)
  }

  return(
    <>
      <Header>
        {translator.translate('mainTabProfile')}
      </Header>

      <UsersInfoStyled>
        <UsersInfoItem>
          {translator.translate('usersAll', { value: usersCount?.getUsersCount.total || '-' })}
        </UsersInfoItem>

        <UsersInfoItem>
          {translator.translate('usersActive', { value: usersCount?.getUsersCount.active || '-' })}
        </UsersInfoItem>
      </UsersInfoStyled>

      <TableBackground>
        <Table
          data={tableData}
          headData={tableHead}
          clickable
          onRowClick={onTableClick}
          selectedId={selectedItem?.id}
        />

        <CustomLoader
          loading={loading}
          isGlobal={false}
          loaderWidth={50}
        />
      </TableBackground>

      <Controls>
        <CustomButton
          height='53px'
          width='344px'
          margin="0 33px 0 0"
          onClick={onReportClick}
          disabled={loading || !selectedItem}
        >
          {translator.translate('btnReport')}
        </CustomButton>

        <CustomButton
          height='53px'
          width='344px'
          margin="0 33px 0 0"
          onClick={() => {history.push(`${path}/all`)}}
          disabled={loading}
        >
          {translator.translate('usersAllBtn')}
        </CustomButton>

        <CustomButton
          height='53px'
          width='344px'
          onClick={() => {history.push(`${path}/verif`)}}
          disabled={loading}
        >
          {translator.translate('usersVerificationBtn')}
        </CustomButton>
      </Controls>
    </>
  );
}

export default compose(graphql(GET_COUNT))(UsersMain);

import React, { Fragment, useCallback, useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { useFormControl } from "../../../hooks/useFormControl";
import { CAlert } from "../../atoms/CAlert";
import CButton from "../../atoms/CButton";
import { CForm } from "../../atoms/CForm";
import { CInput } from "../../atoms/CInput";
import { CLoader } from "../../atoms/CLoader";
import { CSubTitle } from "../../atoms/CSubTitle";
import { CTitle } from "../../atoms/CTitle";
import Modal from "../../atoms/Modal";
import { Svg } from "../../atoms/Svg";
import { Wrapper } from "../../atoms/Wrapper";
import { isSomeInputFilled } from "../../pages/CSettingPage/helpers";
import { StyledModalWithdrawal } from "./style";
import { Inputs } from "./type";
import { useModalWithdrawalError } from "./useModalWithdrawalError";
import { useModalWithdrawalRequest } from "./useModalWithdrawalRequest";
import { useTranslator } from "eo-locale";

interface IModalWithdrawal {
  isShow: boolean;
  onHideModal: () => void;
  amount: string;
  symbol: string;
}

export const CustomModalWithdrawal: React.FC<IModalWithdrawal> = ({
  isShow,
  onHideModal,
  symbol,
  amount,
}) => {
  const [isBtnDisabled, setBtnDisabled] = useState(true);
  const translator = useTranslator();

  const inputs = [
    {
      name: "walletAddress" as const,
      type: "string",
      placeholder: translator.translate("modalWithdrawalPlace1"),
      styledType: "DARK" as const,
      styles: {
        "margin-bottom": "15px",
      },
      rules: {
        required: translator.translate("errorMessageRequiredField"),
      },
    },
    {
      name: "withdrawalAmount" as const,
      type: "string",
      placeholder: translator.translate("modalWithdrawalPlace2"),
      styledType: "DARK" as const,
      styles: {
        "margin-bottom": "15px",
      },
      rules: {
        required: translator.translate("errorMessageRequiredField"),
      },
    },
  ];
  const {
    withdrawRequest,
    loading,
    requestError,
    requestSuccess,
  } = useModalWithdrawalRequest();
  const {
    onSubmit,
    clearErrors,
    errors,
    control,
    getValues,
    reset,
  } = useFormControl<Inputs>(
    {
      defaultValues: {
        walletAddress: "",
        withdrawalAmount: "",
      },
      reValidateMode: "onSubmit",
    },
    onSubmitHandler
  );

  const {
    errorMessage,
    setErrorMessage,
    isErrorName,
  } = useModalWithdrawalError(errors, requestError);

  const getCorrectAmount = (val: string) => {
    const regexp = /(,)/i;
    return parseFloat(val.replace(regexp, "."));
  };

  function onSubmitHandler(formData: Inputs) {
    const { walletAddress, withdrawalAmount } = formData;
    if (!(parseFloat(withdrawalAmount) > parseFloat(amount))) {
      getCorrectAmount(withdrawalAmount);
      withdrawRequest({
        variables: {
          symbol,
          sum: getCorrectAmount(withdrawalAmount),
          address: walletAddress,
        },
      });
    } else {
      setErrorMessage({
        name: ["withdrawalAmount"],
        message: translator.translate("modalWithdrawalErrorInsufficientFunds"),
      });
    }
  }

  const onCloseModal = useCallback(() => {
    reset();
    clearErrors();
    setBtnDisabled(true);
    setErrorMessage(null);
    onHideModal();
  }, [clearErrors, onHideModal, reset, setErrorMessage]);

  const onInputHandler = (name: string, value: string) => {
    const isFilled = isSomeInputFilled(getValues());
    clearErrors();
    setErrorMessage(null);
    if (isFilled) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  };

  useEffect(() => {
    if (requestSuccess) {
      onCloseModal();
    }
  }, [onCloseModal, requestSuccess]);
  return (
    <Modal enabled={isShow} onBackgroundClick={onCloseModal}>
      <StyledModalWithdrawal>
        {loading && (
          <CLoader trigger={loading} styles={{ background: "transparent" }}>
            <Loader type="Oval" color="#ffffff" height={80} width={80} />
          </CLoader>
        )}
        <Wrapper
          styles={{
            display: "flex",
            "justify-content": "flex-end",
          }}
        >
          <CButton
            type="button"
            styles={{
              width: "35px",
              height: "22px",
            }}
            onClick={onCloseModal}
          >
            <Svg iconId="icClose" className="close" color="#844040" />
          </CButton>
        </Wrapper>
        <Wrapper
          styles={{
            padding: "13px 30px 43px 30px",
            display: "block",
          }}
        >
          <CTitle>{translator.translate("modalWithdrawalTitle")}</CTitle>
          <CSubTitle
            styles={{
              font: `normal normal 18px/1.2 "Roboto regular"`,
              margin: "0 0 20px 0",
            }}
          >
            {translator.translate("modalWithdrawalSubTitle")}
          </CSubTitle>
          <CForm onSubmit={onSubmit}>
            {inputs.map((inputData, index) => (
              <Fragment key={`withdraw_form_inp_${index}`}>
                {isErrorName(inputData.name) && (
                  <CAlert styledType="ERROR">{errorMessage?.message}</CAlert>
                )}
                <CInput
                  {...inputData}
                  control={control}
                  isError={isErrorName(inputData.name)}
                  sendInputData={onInputHandler}
                />
              </Fragment>
            ))}
            {requestError && (
              <CAlert styles={{ "margin-bottom": "10px" }} styledType="ERROR">
                {translator.translate("modalWithdrawalErrorRequest")}
              </CAlert>
            )}
            <CButton
              isDisabled={isBtnDisabled}
              styledType="LIGHT"
              type="submit"
              styles={{
                background: `${isBtnDisabled ? "#9e9e9e" : "#ffffff"}`,
              }}
            >
              {translator.translate("modalWithdrawalOut")}
            </CButton>
          </CForm>
        </Wrapper>
      </StyledModalWithdrawal>
    </Modal>
  );
};

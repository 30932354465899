import React from "react";
import {Title, ListItem, List} from './styled';

export type ListItemPrototypeType = {
  title: string,
  dataKey: string
}

interface IInfoList {
  prototype: Array<ListItemPrototypeType>
  data: any
}

const InfoList: React.FC<IInfoList> = (props) => {
  return(
    <List>
      {
        props.prototype.map(item => (
          <ListItem key={item.dataKey}>
            <Title>{item.title}</Title>

            <span>{props.data && props.data[item.dataKey] ? props.data[item.dataKey] : ''}</span>
          </ListItem>
        ))
      }
    </List>
  )
}

export default InfoList;

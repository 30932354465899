import React, {useEffect, useState} from "react";
import AuthInfo from "../../../store/AuthInfo";
import {observer} from "mobx-react-lite";

interface IRoleRenderer {
  roles: Array<string>
}

const RoleRenderer: React.FC<IRoleRenderer> = observer((
  {
    roles,
    children
  }
) => {
  const [isValid, setIsValid] = useState();

  useEffect(() => {
    setIsValid(roles.includes(AuthInfo.role));
  }, [roles])

  if(isValid){
    return (
      <>
        {children}
      </>
    );
  }
  return null;
})

export default RoleRenderer;

import React, { Fragment } from "react";
import Loader from "react-loader-spinner";
import { CAlert } from "../../atoms/CAlert";
import { CLoader } from "../../atoms/CLoader";
import { Svg } from "../../atoms/Svg";
import {
  StyledSettingTable,
  SettingHeadGroup,
  SettingRow,
  SettingHead,
  SettingData,
} from "./style";
import { useTranslator } from "eo-locale";

interface ISettingTableProps {
  headData: string[];
  bodyData: React.ReactElement[][];
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
}

export const CSettingTable: React.FC<ISettingTableProps> = ({
  headData,
  bodyData,
  isSuccess,
  isError,
  isLoading,
}) => {
  const translator = useTranslator();
  const headContentJSX = (
    <SettingRow>
      {headData.map((item, index) => (
        <SettingHead key={index}>{item}</SettingHead>
      ))}
    </SettingRow>
  );

  const bodyContentJSX = bodyData?.map((row, indexRow) => (
    <SettingRow key={indexRow}>
      {row?.map((item: React.ReactElement, index) => (
        <SettingData data-label={headData[index]} key={index}>
          {item}
        </SettingData>
      ))}
    </SettingRow>
  ));

  return (
    <Fragment>
      <CLoader
        trigger={isLoading}
        timer={500}
        styles={{ background: "transparent" }}
      >
        {isSuccess && (
          <Svg iconId="icGal" className="success" color="#448844" />
        )}

        {isError && (
          <CAlert
            styledType="ERROR"
            styles={{ "font-size": "24px", "font-weight": "600" }}
          >
            {translator.translate("settingErrorRequest")}
          </CAlert>
        )}

        {isLoading && (
          <Loader type="Oval" color="#ffffff" height={80} width={80} />
        )}
      </CLoader>

      <StyledSettingTable>
        <SettingHeadGroup>{headContentJSX}</SettingHeadGroup>
        <tbody>{bodyContentJSX}</tbody>
      </StyledSettingTable>
    </Fragment>
  );
};

import React, { useState, useEffect } from "react";
import { useTranslator } from "eo-locale";
import { useQuery } from "@apollo/react-hooks";
import { GET_COUNT } from "../UsersMain/queries";
import { UsersCountType } from "../UsersMain";
import { Filters, TableBackground, Controls, DateRange } from "./styled";
import { observer } from "mobx-react-lite";
import { useHistory, useParams } from "react-router-dom";
import Select, { SelectItemType } from "../../atoms/Select";
import Table, { TableHeadType } from "../../atoms/Table";
import CustomButton from "../../atoms/CustomButton";
import UsersReportStatistics from "../../molecules/UsersReportStatistics";
import ModalUserReport from "../../molecules/ModalUserReport";
import DateSelect from "../../atoms/DateSelect";
import { USERS_DETAIL } from "./queries";
import Plot from "../../atoms/Plot";
import { useCalendarControl } from "./../../../hooks/useCalendarControl";
import { Period } from "../TransactItem/type";
import CButton from "../../atoms/CButton";
import { CSubTitle } from "../../atoms/CSubTitle";
import { Svg } from "../../atoms/Svg";
import { Wrapper } from "../../atoms/Wrapper";

type UserDetailType = {
  getUsersDetail: {
    count: number;
    chart: Array<{
      fromDate: number;
      toDate: number;
      count: number;
    }>;
    countries: Array<{
      fullName: string;
      name: string;
      count: number;
    }>;
  };
};

type TableDataItem = {
  id: string;
  country: string;
  allUsers: string;
  regUsers: string;
};

const UsersReport: React.FC = observer(() => {
  const [period, setPeriod] = useState<Period | "Change">("Week");
  const [isReportModalOpen, setIsReportModalOpen] = useState<boolean>(false);
  const [country, setCountry] = useState<string>("All");
  const [tableData, setTableData] = useState<Array<TableDataItem>>([]);
  const translator = useTranslator();
  const [
    onToDateChange,
    onFromDateChange,
    { fromDate, toDate, maxToDate, minToDate, maxFromDate },
  ] = useCalendarControl(period);
  const [countryItems, setCountryItems] = useState<
    Array<SelectItemType<string>>
  >([
    {
      key: "All",
      value: translator.translate("countryAll"),
    },
  ]);
  const params = useParams<{ device: "Desktop" | "Android" | "iOS" }>();
  const history = useHistory();

  const periodItems: Array<SelectItemType<string>> = [
    {
      key: "Week",
      value: translator.translate("periodWeek"),
    },
    {
      key: "Month",
      value: translator.translate("periodMonth"),
    },
    {
      key: "Year",
      value: translator.translate("periodYear"),
    },
    {
      key: "Change",
      value: translator.translate("periodChange"),
    },
  ];

  const UsersTableHead: Array<TableHeadType<TableDataItem>> = [
    {
      dataKey: "country",
      text: translator.translate("usersDetailCountryTableHead1"),
      width: "241px",
    },
    {
      dataKey: "allUsers",
      text: translator.translate("usersDetailCountryTableHead2"),
      width: "807px",
      align: "right",
    },
    {
      dataKey: "regUsers",
      text: translator.translate("usersDetailCountryTableHead3"),
      width: "195px",
      align: "right",
    },
  ];

  const { data: dataAll, loading } = useQuery<UserDetailType>(USERS_DETAIL, {
    variables: {
      device: params.device,
      period: period,
      from: period === "Change" ? fromDate?.valueOf() : null,
      to: period === "Change" ? toDate?.valueOf() : null,
      country: country,
    },
    fetchPolicy: "no-cache",
  });

  const { data: usersCount, loading: countLoading } = useQuery<UsersCountType>(GET_COUNT);

  useEffect(() => {
    let newCountries: Array<SelectItemType<string>> = [
      {
        key: "All",
        value: translator.translate("countryAll"),
      },
    ];

    usersCount?.getUsersCount.devices[params.device].countries.forEach(
      (item) => {
        newCountries.push({
          key: item.name,
          value: item.fullName,
        });
      }
    );
    setCountryItems(newCountries);
  }, [translator, params.device, usersCount]);

  useEffect(() => {
    if (dataAll && usersCount) {
      let newTableData: Array<TableDataItem> = [];
      const allCountries =
        usersCount.getUsersCount.devices[params.device].countries;

      allCountries.forEach((item, index) => {
        const allUsers = dataAll.getUsersDetail.countries.find(
          (el) => el.name === item.name
        )?.count;

        newTableData.push({
          id: index.toString(),
          country: item.fullName,
          allUsers: item.count ? item.count.toString() : "-",
          regUsers: allUsers ? `+${allUsers}` : "-",
        });
      });

      setTableData(newTableData);
    }
  }, [params.device, usersCount, dataAll]);

  const onPeriodSelect = (item: SelectItemType<string>) => setPeriod(item.key as Period);
  const onCountrySelect = (item: SelectItemType<string>) => setCountry(item.key);
  return (
    <>
      <Wrapper
        styles={{
          "margin-bottom": "20px",
          "flex-flow": "row wrap",
        }}
      >
        <CButton
          type="button"
          styledType="BACK"
          onClick={() => history.push("/users")}
        >
          <Svg iconId="icChev" color="white" width={"15px"} height={"15px"} />
        </CButton>
        <CSubTitle
          styles={{
            "font-size": "24px",
            margin: "0",
            "font-family": "Roboto bold, sans-serif",
          }}
        >
          {translator.translate("usersTitle", { value: params.device })}
        </CSubTitle>
      </Wrapper>

      <Filters>
        <Select
          title={translator.translate("periodTitle")}
          width="320px"
          margin="0 20px 0 0"
          items={periodItems}
          selectedItem={period}
          onSelect={onPeriodSelect}
        />

        <Select
          title={translator.translate("countryDescr")}
          width="251px"
          items={countryItems}
          selectedItem={country}
          onSelect={onCountrySelect}
        />
      </Filters>

      {period === "Change" ? (
        <DateRange>
          <DateSelect
            title={translator.translate("periodTitleDate1")}
            onValueChange={onFromDateChange}
            value={new Date(fromDate.valueOf())}
            theme="black"
            margin="0 20px 0 0"
            maxDate={maxFromDate}
          />

          <DateSelect
            title={translator.translate("periodTitleDate2")}
            onValueChange={onToDateChange}
            value={new Date(toDate.valueOf())}
            theme="black"
            maxDate={maxToDate}
            minDate={minToDate}
          />
        </DateRange>
      ) : null}

      <Plot
        indicator={"registration"}
        period={period as Period}
        fromDate={fromDate.valueOf()}
        toDate={toDate.valueOf()}
        chartData={{ data: dataAll?.getUsersDetail.chart, type: "USERS" }}
        loading={loading}
      />

      <UsersReportStatistics
        allUsers={usersCount?.getUsersCount.devices[params.device].total}
        regUsers={dataAll?.getUsersDetail.count}
      />

      <TableBackground>
        <Table data={tableData} headData={UsersTableHead} />
      </TableBackground>

      <Controls>
        <CustomButton
          width="344px"
          height="52px"
          margin="0 33px 0 0"
          onClick={() => setIsReportModalOpen(true)}
        >
          {translator.translate("btnReportActive")}
        </CustomButton>

        <CustomButton
          width="344px"
          height="52px"
          margin="0 33px 0 0"
          onClick={() => history.push("/users/all")}
        >
          {translator.translate("usersAllBtn")}
        </CustomButton>

        <CustomButton
          width="344px"
          height="52px"
          onClick={() => history.push("/users/verif")}
        >
          {translator.translate("usersVerificationBtn")}
        </CustomButton>
      </Controls>
   
      <ModalUserReport
        totalCount={usersCount?.getUsersCount.devices[params.device].total}
        countries={countryItems}
        selectedCountry={country}
        periods={periodItems}
        selectedPeriod={period}
        enabled={isReportModalOpen}
        onClose={() => setIsReportModalOpen(false)}
        device={params.device}
        selectedFromDate={fromDate}
        selectedToDate={toDate}
      />
 
    </>
  );
});

export default UsersReport;

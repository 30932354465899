import React, {useEffect, useRef, useState} from "react";
import CustomButton from "../../atoms/CustomButton";
import ReactExport from "react-export-excel";
import {useTranslator} from "eo-locale";
import dayjs from "dayjs";
import BigNumber from "bignumber.js";
import {IChartData, ITransactData} from "../ModalTransactionsReport";
import { getCorrectSum } from './../../../utils/getCorrectSum';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

interface ITransExcelReportButton {
  chartData?: IChartData;
  transactionsData?: ITransactData;
  coin: string;
  parts?: Array<string>;
}

type ReportData = { col1: string; col2: string; col3: string; col4: string; }[]

const TransExcelReportButton: React.FC<ITransExcelReportButton> = (
  {
    chartData,
    transactionsData,
    coin,
    parts = []
  }
) => {
  const [reportData, setReportData] = useState<ReportData>();
  const buttonRef = useRef<HTMLButtonElement>(null)
  const translator = useTranslator();

  const getFormatDataExcel = () => {
    let formatData = [];

    if(
      !chartData
      || !chartData.getChartData
      || !transactionsData
      || !transactionsData.transactionsCommonInfo
    ) return;

    chartData.getChartData.map((item, index: number) => {
      const date = dayjs(item.fromDate);
      // было 'DD.MM.YYYY - hh:mm' убрал hh:mm т.к. время стоит заглушкой 7:00
      // добавим когда часы на беке будут корректно обрабаываться
      const normalDate = date.format('DD.MM.YYYY');
      let col1 = Number(item.input.count) + Number(item.output.count) + Number(item.internal.count),
        col2 = Number(item.input.amount) + Number(item.output.amount) + Number(item.internal.amount),
        col3 = getCorrectSum(Number(item.input.commission) + Number(item.output.commission) + Number(item.internal.commission)).toString();

      formatData[index] = {
        col1: normalDate,
        col2: parts.includes('count') ? getCorrectSum(col1).toString() : '',
        col3: parts.includes('transact') ? getCorrectSum(col2).toString() : '',
        col4: parts.includes('commis') ? getCorrectSum(col3).toString() : '',
      }
      return true;
    });

    const item = transactionsData.transactionsCommonInfo[0];
    formatData[formatData.length] = {
      col1: '',
      col2: '',
      col3: '',
      col4: '',
    }
    // Сумарное кол-во транзакций
    if (parts.includes('count')) {
      formatData[formatData.length] = {
        col1: translator.translate('reportCountSum'),
        col2: '',
        col3: '',
        col4: '',
      }
      formatData[formatData.length] = {
        col1: new BigNumber(
          Number(item.input.count) + Number(item.output.count) + Number(item.internal.count)
        ).toFormat(),
        col2: '',
        col3: '',
        col4: '',
      }
      formatData[formatData.length] = {
        col1: translator.translate('reportCountEntry'),
        col2: translator.translate('reportCountOut'),
        col3: translator.translate('reportCountInside'),
        col4: '',
      }
      formatData[formatData.length] = {
        col1: new BigNumber(item.input.count).toFormat(),
        col2: new BigNumber(item.output.count).toFormat(),
        col3: new BigNumber(item.internal.count).toFormat(),
        col4: '',
      }
      formatData[formatData.length] = {
        col1: '',
        col2: '',
        col3: '',
        col4: '',
      }
    }
    // Суммарный объем средств

    if (parts.includes('transact')) {
      formatData[formatData.length] = {
        col1: translator.translate('reportTransactSum'),
        col2: '',
        col3: '',
        col4: '',
      }
      formatData[formatData.length] = {
        col1: getCorrectSum(
          Number(item.input.amount) + Number(item.output.amount) + Number(item.internal.amount)
        ).toString(),
        col2: '',
        col3: '',
        col4: '',
      }
      formatData[formatData.length] = {
        col1: translator.translate('reportTransactEntry'),
        col2: translator.translate('reportTransactOut'),
        col3: translator.translate('reportTransactInside'),
        col4: '',
      }
      formatData[formatData.length] = {
        col1: getCorrectSum(item.input.amount).toString(),
        col2: getCorrectSum(item.output.amount).toString(),
        col3: getCorrectSum(item.internal.amount).toString(),
        col4: '',
      }
      formatData[formatData.length] = {
        col1: '',
        col2: '',
        col3: '',
        col4: '',
      }
    }
    // Суммарный объем комиссии
    if (parts.includes('commis')) {
      formatData[formatData.length] = {
        col1: translator.translate('reportComissSum'),
        col2: '',
        col3: '',
        col4: '',
      }
      formatData[formatData.length] = {
        col1: getCorrectSum(Number
          (item.input.commission) + Number(item.output.commission) + Number(item.internal.commission)
        ).toString(),
        col2: '',
        col3: '',
        col4: '',
      }
      formatData[formatData.length] = {
        col1: translator.translate('reportComissEntry'),
        col2: translator.translate('reportComissOut'),
        col3: translator.translate('reportComissInside'),
        col4: '',
      }
      formatData[formatData.length] = {
        col1: getCorrectSum(item.input.commission).toString(),
        col2: getCorrectSum(item.output.commission).toString(),
        col3: getCorrectSum(item.internal.commission).toString(),
        col4: '',
      }
    }
    setReportData(formatData);
  }

  useEffect(() => {
    if(reportData){
      buttonRef.current?.click();
    }
  }, [reportData])

  const onDownloadClick = () => {
    getFormatDataExcel();
  }

  return(
    <>
      <CustomButton
        height="37px"
        margin="0px 0px 15px"
        onClick={onDownloadClick}
      >
        {translator.translate('modalReportBtn1')}
      </CustomButton>

      <ExcelFile
        filename={`${coin}_report`}
        element={
          <button
            style={{display: 'none'}}
            ref={buttonRef}
          >
            {translator.translate('reportDownloadFiller1')}
          </button>
        }
      >
        <ExcelSheet data={reportData} name="Currency report">
          <ExcelColumn label={translator.translate('reportTransactLabel')} value="col1"/>
          <ExcelColumn label={ parts.includes('count') ? 'Количество транзакций' : '' } value="col2"/>
          <ExcelColumn label={ parts.includes('transact') ? 'Объем средств' : '' } value="col3"/>
          <ExcelColumn label={ parts.includes('commis') ? 'Комиссия' : '' } value="col4"/>
        </ExcelSheet>
      </ExcelFile>
    </>
  )
}

export default TransExcelReportButton;

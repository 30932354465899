import {gql} from "apollo-boost";

export const SET_LOT_STATUS = gql`
  mutation setLotStatus($id: Float!,$status: LotStatus!, $reasons: [String!] = null){
    setLotStatus(details:{status: $status,reasons:$reasons}, id:$id)
  }
`

export const GET_LOT_BY_ID = gql`
  query getLotById($id: Float!){
      getLotById(id:$id){
          _id
          createdBy{
              _id
              firstName
              lastName
              email
          }
          name
          description
          amount
          currency
          insuranceNo
          reasons
          insurance
          privacy
          status
          photos
          certificate
          additionalDocuments
          percentToSell
      }
}`

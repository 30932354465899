import React, {useEffect, useState} from "react";
import Modal from "../../atoms/Modal";
import icons from '../../../assets/img/sprite.svg'
import {Background, CloseButton, Img, ImgContainer, DownloadContainer, Video} from "./styled";
import Loader from "react-loader-spinner";

interface IModalOpenDocument {
  name: string | undefined,
  id: string | undefined,
  onClose: React.Dispatch<boolean>,
  enabled: boolean,
  mimeType?: string,
}

const ModalOpenDocument: React.FC<IModalOpenDocument> = ({
  id,
  name,
  onClose,
  enabled= false,
  mimeType = "image/jpeg"
}) => {
  const [loadedImage, setLoadedImage] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  const onCloseClick = () => {
    onClose(false);
  }

  useEffect( () => {
    setLoadedImage('')
    if(id){
      let load = loadImage(id);
      if(load) {
        load
          .then(url => {
            setIsLoading(false);
            if(url){
              setLoadedImage(url);
            }
          });
      }
    }
  }, [id]);

  const loadImage = (id: string): Promise<string | void> | undefined => {
    const token = localStorage.getItem('token');
    const link = process.env.REACT_APP_API_APLOAD;

    if(link){
      setIsLoading(true);
      return fetch(link + id, {
        headers: {
          Authorization: `Bearer ${token}`
        },
      })
        .then(data => {
          return data.blob()
        })
        .then(file => {
          const urlCreator = window.URL || window.webkitURL;

          return urlCreator.createObjectURL(file)
        })
        .catch(er => {
          setIsLoading(false);
        })
    }
  }

  const getContent = () => {
    const type = mimeType.slice(0, mimeType.indexOf('/'));

    switch (type){
      case 'video': {
        return(<Video controls src={loadedImage} />)
      }
      case 'image': {
        return(<Img src={loadedImage} />)
      }
    }
    return null
  }

  return(
    <Modal
      enabled={enabled}
      onBackgroundClick={onCloseClick}
    >
      <Background>
        <CloseButton onClick={onCloseClick}>
          <svg className="inline-svg-icon">
            <use className="icas" xlinkHref={`${icons}#icClose`} name="debtors"/>
          </svg>
        </CloseButton>

        {isLoading ? (
          <Loader
            height={75}
            width={75}
            type="Oval"
            color="white"
          />
        ) : (
          <>
            <ImgContainer>
              {getContent()}
            </ImgContainer>

            <DownloadContainer>
              <a download={name} href={loadedImage}>Скачать</a>
            </DownloadContainer>
          </>
        )}
      </Background>
    </Modal>
  )
}

export default ModalOpenDocument;

import {useEffect, useState} from "react";
import {useQuery} from "@apollo/react-hooks";
import {GET_CHATS} from "./queries";

export type AllChatsType = {
  getSupportChats: {
    chats: Array<SupportChatType>,
    pagination: {
      total: number,
      next: boolean
    }
  }
}

export type SupportChatType = {
  topMessage: number
  topMessageDate: number,
  usersData: Array<{
    userId: number,
    username: string
  }>,
  botAnswers: boolean,
  unreadedCount: number
  _id: number
}

export const useLoadChats = (
  page: number,
  limit: number
) => {
  const [data, setData] = useState<AllChatsType>();

  const { data: chats, loading, refetch } = useQuery<AllChatsType>(GET_CHATS, {
    variables: {
      limit: limit,
      skip: page * limit
    },
    fetchPolicy: "no-cache"
  });

  useEffect(() => {
    if(chats){
      setData(chats);
    }
  }, [chats])

  return {data, loading, refetch}
}

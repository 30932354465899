import styled, {css} from "styled-components";
import {palette} from "styled-theme";

export const PaginationWrap = styled.div`
  width: 100%;
  padding-top: 32px;
  display: flex;
  justify-content: center;
`
export const StyledPagination = styled.div<{
  palette: string,
  active: boolean,
  cursor?: "default" | "pointer"
}>`
  cursor: ${({ cursor }) => cursor === "default" ? 'default' : 'pointer'};
  width: 24px;
  height: 24px;
  margin: 0 5px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${palette({ primary: 0 }, 1)};
  ${props => props.active && css`
  border:1px solid ${palette({ primary: 0 }, 1)};
  `}
`

import {useMutation} from "@apollo/react-hooks";
import {SEND_MUTATION} from "./queries";

export const useSendMessage = () => {
  const [sendMutation] = useMutation(SEND_MUTATION);

  const sendMessage = (chatId?: number, message?: string) => {
    return sendMutation({
      variables: {
        chatId: chatId,
        message: message
      }
    })
  }

  return sendMessage;
}

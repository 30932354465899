import { gql } from "apollo-boost";

export const SETTING_INFO = gql`
  {
    getTransactionCommonDetails {
      name
      commission {
        input
        internal
        output
      }
      limits {
        input
        internal
        output
      }
    }
  }
`;

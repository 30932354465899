import styled, {css} from "styled-components";
import {IUserItem} from "./index";

export const UserItem = styled.div<IUserItem>`
  display: grid;
  cursor: pointer;
  font-size: 14px;
  padding: 12px 8px;
  grid-column-gap: 6px;
  align-items: center;
  grid-template-columns: minmax(80px, 1fr) 90px;
  grid-template-areas: "title date";

  &:hover {
    background-color: rgb(75, 75, 75);
  }

  ${({selected}) => (
          selected ? css`
      background-color: #fff;
      color: #000;
      cursor: default;
      
      &:hover{
        background-color: #fff;
      }
    ` : null
  )}
`

export const Title = styled.div`
  grid-area: title;
  height: 20px;
  display: flex;
  align-items: center;
  font-weight: 700;
  color: inherit;
  text-overflow: ellipsis;
  overflow: hidden;
`

export const UnreadMessages = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: red;
  font-size: 12px;
  font-weight: 700;
`

export const LastDate = styled.div`
  grid-area: date;
  text-align: end;
  color: inherit;
`

import styled from "styled-components";
import {ISelect, ISelected} from "./index";

export const Wrap = styled.div`
  position: relative;
`

export const Select = styled.div<ISelect>`
  width: ${({width}) => width};
  margin: ${({margin}) => margin};
  min-height: 48px;
  display: flex;
  border: 1px solid ${({border}) => border};
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  padding: 7px 15px;
`

export const Menu = styled.div`
  position: absolute;
  width: 251px;
  top: 100%;
  left: 0;
  background-color: white;
  z-index: 1;
  .react-calendar, 
  .react-calendar *, 
  .react-calendar *:before, 
  .react-calendar *:after{
    color: black !important;
  }
`

export const Selected = styled.div<ISelected>`
  color: ${({color}) => color};
  font-size: 14px;
  line-height: 17px;
`

export const Title = styled.div<ISelected>`
  color: ${({color}) => color};
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 2px;
`

import AuthInfo from "../../store/AuthInfo";
import { NavList } from "./type";

export const getFilteredNavList = (dirtyNavList: NavList[]) => {
  const role = AuthInfo.role as "ADMIN" | "MODERATOR";
  const allowedRoleList = {
    ADMIN: [
      "/transact",
      "/comis",
      "/setting",
      "/exchange",
      "/users",
      "/auctions",
      "/support",
    ],
    MODERATOR: ["/users", "/auctions", "/support"],
  };

  return dirtyNavList.filter((item) =>
    allowedRoleList[role].includes(item.to)
  );
};

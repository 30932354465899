import styled from "styled-components";
import {palette} from "styled-theme";
import {IList} from "./index";

export const List = styled.ul<IList>`
  position: absolute;
  top: 18px;
  left: 5px;
  width: 342px;
  background-color: ${palette({ primary: 11 }, 1)};
  list-style: none;
  padding-left: 0;
  z-index: 100;
`

export const ListItem = styled.li`
  font-size: 18px;
  line-height: 21px;
  padding: 5px 0 5px 0;
  display: flex;
  align-items: center;
`

export const CheckboxContainer = styled.div`
  margin-left: 10px;
  margin-right: 13px;
`

import React from "react";
import BalanceTable from "../../molecules/BalanceTable";
import {useQuery} from "@apollo/react-hooks";
import Loader from "react-loader-spinner";
import {BALANCE, BALANCE_PROFIT} from "./queries";
import {Title, Table} from './styled'
import {useTranslator} from "eo-locale";

const Index: React.FC = () => {
  const { data: balance, loading } = useQuery(BALANCE);
  const { data: botProfit, loading: profitLoading } = useQuery(BALANCE_PROFIT);
  const translator = useTranslator();

  const cells = [
    {
      text: "ETH",
      symbol: "ETH"
    },
    {
      text: "BTC",
      symbol: "BTC"
    },
    {
      text: "EOS",
      symbol: "EOS"
    },
    {
      text: "USDT",
      symbol: "USDT"
    }
  ]

  return(
    <>
      {(loading) ? <div className="loader">< Loader type="Oval" color="#fff" height={100} width={100} /></div > : (
        <>
          <Title>
            {translator.translate('currentBalanceTitle')}
          </Title>

          <Table>
            <BalanceTable
              data={balance && balance.getBotTraderBalances}
              profits={botProfit && botProfit.getBotTraderProfit}
              cells={cells}
            />
          </Table>
        </>)
      }
    </>
  )
}

export default Index;

import { useState, useEffect } from 'react';
import { useTranslator } from 'eo-locale';import { ErrorMessage, Errors } from './type';
;

export const useModalWithdrawalError = <Err>(errors: Errors<Err>, requestError: string | null) => {
  const [errorMessage, setErrorMessage] = useState<ErrorMessage>(null);
  const translator = useTranslator();

  useEffect(() => {
    if (!errors) return;
    const errorNames = Object.keys(errors);
    const isErrorFromForm = errorNames.length !== 0;
    if (isErrorFromForm) {
      setErrorMessage({
        name: errorNames,
        message: translator.translate('modalWithdrawalErrorRequired')
      })
    }

    if (requestError) {
      setErrorMessage({
        name:
          requestError.indexOf("BNB") > 1 ? "withdrawalAmount" : "requestError",
        message: requestError,
      });
    }
  }, [errors, requestError, translator])

  const isErrorName = (name: string) => {
    if (errorMessage?.name) {
      return errorMessage?.name === name;
    } else {
      return errorMessage?.name === "requestError";
    }
  };

  return {isErrorName, errorMessage, setErrorMessage};

}
import React, { forwardRef, useCallback, useEffect, useState } from "react";
import {
  Document,
  Page,
  PDFDownloadLink,
  Text,
  View,
  Font,
} from "@react-pdf/renderer";
import { styles } from "./styled";
import font from "../../../assets/font/Roboto-Medium/Roboto-Medium.woff";

export type PDFHeadType = {
  label: string;
  value: string | Array<PDFHeadType>;
  width?: number;
};

interface IPDFTableDownload {
  header: Array<PDFHeadType>;
  data: any;
}

//стили для pdf
Font.register({
  family: "Roboto",
  src: font,
});

const Index = forwardRef<HTMLButtonElement, IPDFTableDownload>((props, ref) => {
  const [pdfData, setPdfData] = useState<JSX.Element>();

  const buildHead = useCallback((cols: Array<PDFHeadType>, level = 0) => {
    return cols.map((item, index) => {
      if (!Array.isArray(item.value)) {
        return (
          <View
            key={index}
            style={{
              width: item.width,
              padding: 1,
              borderTop: "1 solid #000",
              borderBottom: "1 solid #000",
              borderLeft: index === 0 && level === 0 ? "1 solid #000" : "none",
              borderRight: "1 solid #000",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text>{item.label}</Text>
          </View>
        );
      }

      return (
        <View key={index}>
          <View
            style={{
              width: item.width,
              padding: 1,
              border: "1 solid #000",
              borderLeft: "none",
              borderBottom: "none",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text>{item.label}</Text>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            {buildHead(item.value, ++level)}
          </View>
        </View>
      );
    });
  }, []);

  // @ts-ignore
  const buildRow = useCallback(
    (cols: Array<PDFHeadType>, item: any, level = 0) => {
      return cols.map((col, index) => {
        if (!Array.isArray(col.value)) {
          return (
            <View
              key={index}
              style={{
                width: col.width,
                padding: 2,
                borderRight: "1 solid #000",
                borderBottom: "1 solid #000",
                borderLeft:
                  index === 0 && level === 0 ? "1 solid #000" : "none",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <Text>{item[col.value] ? item[col.value] : ""}</Text>
            </View>
          );
        }

        return <>{buildRow(col.value, item, ++level)}</>;
      });
    },
    []
  );

  const buildBody = useCallback(
    (cols: Array<PDFHeadType>, data: Array<any>) => {
      if(!data) return ;

      const body = data.map((item, index) => {
        return (
          <View
            style={{
              display: "flex",
              flexDirection: "row",
            }}
            key={index}
          >
            {buildRow(cols, item)}
          </View>
        );
      })

      return body;
    },
    [buildRow]
  );

  useEffect(() => {
    let newPdfData = (
      <PDFDownloadLink
        style={{
          display: "none",
        }}
        document={
          <Document>
            <Page style={styles.page} size="A2" orientation="landscape">
              <View style={styles.head}>{buildHead(props.header)}</View>

              <View
                style={{
                  fontSize: 5,
                }}
              >
                {buildBody(props.header, props.data)}
              </View>
            </Page>
          </Document>
        }
      />
    );
    setPdfData(newPdfData);
  }, [props.header, props.data, buildHead, buildBody]);

  const onClick = (
    ev: React.MouseEvent<HTMLButtonElement> & {
      target: HTMLButtonElement & { children: HTMLElement[] };
    }
  ) => {
    ev.target.children[0] && ev.target.children[0].click();
  };

  return (
    <button style={{ display: "none" }} ref={ref} onClick={onClick}>
      {pdfData ? pdfData : null}
    </button>
  );
});

export default Index;

import React, { useEffect, useState } from "react";
import { CTitle } from "../../atoms/CTitle";
import { StyledSettingPage } from "./style";
import { useFormControl } from "../../../hooks/useFormControl";
import { CForm } from "../../atoms/CForm";
import CButton from "../../atoms/CButton";
import { CSettingTable } from "./../../organism/CSettingTable/index";
import { CLabel } from "../../atoms/CLabel";
import { CInput } from "../../atoms/CInput";
import { CAlert } from "../../atoms/CAlert";
import { useTranslator } from "eo-locale";
import {
  checkError,
  getCleanInputList,
  isSomeInputFilled,
  validateInputs,
} from "./helpers";
import { OnSubmitData, TargetField } from "./type";
import { useSettingRequest } from "./useSettingRequest";

const template = {
  name: "",
  commission: {
    input: "0",
    internal: "",
    output: "",
  },
  limits: {
    input: "0",
    internal: "",
    output: "",
  },
};

const inputRegExpList = {
  startFromZero: /^0{1}$/i,
  startFromZeroWithPoint: /^0{1}\.{1}[0-9]*[1-9]+$/i,
  startFromInteger: /^[1-9]+[0-9]*$/i,
  startFromIntegerWithPoint: /^[1-9]+[0-9]*\.{1}[0-9]*[1-9]+$/i,
  empyField: /^$/i,
};

export const CSettingPage: React.FC = (props) => {
  const [isBtnDisabled, setBtnDisabled] = useState(true);
  const translator = useTranslator();
  const headData = [
    translator.translate("settingTableHead1"),
    translator.translate("settingTableHead2"),
    translator.translate("settingTableHead3"),
    translator.translate("settingTableHead4"),
    translator.translate("settingTableHead5"),
  ];
  // Обработка ответов запросов к серверу
  const {
    loading,
    cryptoSymbols,
    isSuccess,
    isError,
    transactionDetails,
    setSettings,
  } = useSettingRequest();

  // Управление формой
  const {
    onSubmit,
    setValue,
    clearErrors,
    control,
    errors,
    reset,
    getValues,
  } = useFormControl(
    {
      mode: "onSubmit",
      reValidateMode: "onSubmit",
    },
    onSubmitHandler
  );

  // Очистка инпутов и блок кнопки после запроса к серверу
  useEffect(() => {
    if (isSuccess || isError) {
      reset(getCleanInputList(getValues()));
      setBtnDisabled(true);
    }
  }, [getValues, isError, isSuccess, reset]);

  // Преобразует данные к виду, валидному для запроса
  function onSubmitHandler(formData: OnSubmitData) {
    const newTransactionsDetails = cryptoSymbols?.map((symbol, index) => {
      // transactItem из данных, пришедших с сервера
      const transactItemFromServer = transactionDetails.find(
        (item) => item.name === symbol
      )!;

      const newTransactItem = Object.entries(formData).reduce(
        (tansactItem, field) => {
          const [name, value] = field;
          const regexp = new RegExp(`^([a-z]+)-?([a-z]+)-?(?=${index})`, "i");
          const targetField = name.match(regexp);
          if (targetField) {
            const [, parentName, childName] = targetField! as TargetField;
            const prevParentData = transactItemFromServer[parentName] as {
              input: string;
              output: string;
              internal: string;
            };

            return {
              ...tansactItem,
              name: symbol,
              [parentName]: {
                ...(tansactItem[parentName] as object),
                [childName]: value || prevParentData[childName],
              },
            };
          } else {
            return tansactItem;
          }
        },
        template
      );
      return newTransactItem;
    });

    setSettings({ variables: { list: newTransactionsDetails } });
  }

  // Запрещает добавлять в поля невалидные символы
  const inputHandler = (name: string, value: string) => {
    const regExpList = {
      startFromZero: /^0{1}$/i,
      startFromZeroWithPoint: /^0{1}\.{1}(0*|0*[1-9]*)$/i,
      startFromInteger: /^[1-9]+[0-9]*\.?(0*|0*[1-9]*)$/i,
    };

    const isValid = validateInputs(regExpList, value);
    if (isValid) {
      setBtnDisabled(false);
      clearErrors(name);
    } else {
      setValue(name, value?.slice(0, value.length - 1));
      const isFilled = isSomeInputFilled(getValues());
      setBtnDisabled(!isFilled);
    }
  };

  // Создает ряд таблицы
  const getTableRowComponents = (
    tableRowData: object,
    namePrefix: string | null,
    index: number
  ) => {
    const tableRowComponents: React.ReactElement[] = [];
    const targetFields = ["name", "internal", "output"];
    const inputsWithIcons = ["commission-internal", "commission-output"];

    Object.entries(tableRowData).forEach((data) => {
      const [name, value] = data;
      if (typeof value === "object") {
        tableRowComponents.push(...getTableRowComponents(value, name, index));
      }

      if (targetFields.includes(name)) {
        if (!namePrefix) {
          tableRowComponents.push(<span>{value}</span>);
        } else {
          tableRowComponents.push(
            <CLabel
              icon={
                inputsWithIcons.includes(`${namePrefix}-${name}`) ? "%" : ""
              }
            >
              <CInput
                control={control}
                sendInputData={inputHandler}
                name={`${namePrefix}-${name}-${index}`}
                type="string"
                styledType="GRAY"
                placeholder={value}
                defaultValue=""
                rules={{
                  validate: {
                    isValid: (value) => {
                      return validateInputs(inputRegExpList, value);
                    },
                  },
                  required: false,
                }}
              />
            </CLabel>
          );
        }
      }
    });
    return tableRowComponents;
  };

  return (
    <StyledSettingPage styles={{ "min-height": "722" }}>
      <CTitle styles={{ "margin-bottom": "15px" }}>
        {translator.translate("mainTabSetting")}
      </CTitle>
      <CForm
        onSubmit={onSubmit}
        styles={{
          "align-items": "flex-start",
          "width": "100%",
          "height": transactionDetails ? "auto" : "585px",
          "background": transactionDetails ? "" : "#25282b",
        }}
      >
        <CSettingTable
          isSuccess={isSuccess}
          isError={isError}
          isLoading={loading}
          headData={headData}
          bodyData={transactionDetails?.map((row, index) =>
            getTableRowComponents(row, null, index)
          )}
        />
        {checkError(errors) && (
          <CAlert
            styledType="ERROR"
            styles={{
              "margin-top": "10px",
              "font-size": "16px",
            }}
          >
            {translator.translate("settingError")}
          </CAlert>
        )}
        <CButton
          styledType="LIGHT"
          type="submit"
          isDisabled={isBtnDisabled}
          styles={{
            "width": "20%", 
            "max-width": "200px",
            "margin-top": "30px",
            "background": "#c3c0c0",
          }}
        >
          {translator.translate("settingBtn")}
        </CButton>
      </CForm>
    </StyledSettingPage>
  );
};

import styled from "styled-components";

export const TableBackground = styled.div`
  padding-bottom: 66px;
  background-color: rgb(37, 40, 43);
  border-radius: 3px;
  margin-bottom: 33px;
`

export const Arrow = styled.div`
  width: 12px;
  height: 17px;
  margin-right: 8px;
  color: #000;
  
  & use{
    color: black;
  }
`

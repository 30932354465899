import React, {CSSProperties} from "react";
import styled from "styled-components";

interface IEmailInput {
  value: string,
  title: string,
  onChange: (value: string) => void,
  margin?: string,
  placeholder?: string,
  isValid?: boolean,
  style?: CSSProperties
}

const CustomInput: React.FC<IEmailInput> = (
  {
    margin = "0",
    ...props
  }) => {
  return(
    <InputContainer
      margin={margin}
      style={props.style}
    >
      <InputTitle>{props.title}</InputTitle>
      <Input
        value={props.value}
        onChange={ev => props.onChange(ev.target.value)}
        placeholder={props.placeholder}
      />
    </InputContainer>
  )
}

const InputContainer = styled.div<{ margin: string }>`
  background-color: transparent;
  border: 1px solid white;
  border-radius: 5px;
  height: 52px;
  width: 100%;
  padding: 7px 16px;
  margin: ${props => props.margin};
`

const InputTitle = styled.div`
  font-size: 12px;
  color: #6a7275;
  margin-bottom: 3px;
`

const Input = styled.input`
  background-color: transparent;
  border: none;
  height: 17px;
  font-size: 14px;
  
  &::placeholder{
    color: white;
  }
  
  &:focus{
    outline: none;
  }
`

export default CustomInput;

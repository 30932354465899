import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-apollo";
import { GLOBAL_SETTINGS, SET_GLOBAL_SETTINGS } from "./queries";
import { ISettingsData } from "./type";

export const useSupportBotRequest = () => {
  const [uniqueness, setUniqueness] = useState<string>();
  const [status, setStatus] = useState<boolean>();
  const { data: settingsData, loading: settingsLoading } = useQuery<ISettingsData>(GLOBAL_SETTINGS);
  const [setSettingsData, { loading: setSettingsLoading }] = useMutation(
    SET_GLOBAL_SETTINGS,
    {
      update(proxy) {
        const newCache: ISettingsData | null = proxy.readQuery({
          query: GLOBAL_SETTINGS,
        });

        if (newCache) {
          proxy.writeQuery({
            query: GLOBAL_SETTINGS,
            data: {
              getGlobalSupportBotParams: {
                ...newCache.getGlobalSupportBotParams,
                supportBotStatus: status,
                requiredMessageUniqeness: Number(uniqueness),
              },
            },
          });
        }
      },
    }
  );

  useEffect(() => {
    if(settingsData) {
      setStatus(settingsData?.getGlobalSupportBotParams.supportBotStatus);
      setUniqueness(settingsData?.getGlobalSupportBotParams.requiredMessageUniqeness.toString());
    }
  }, [settingsData])

  return {
    loading: settingsLoading || setSettingsLoading,
    setSettingsData,
    settingsData,
    setUniqueness,
    setStatus,
    status,
    uniqueness
  }
};

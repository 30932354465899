import React, { useState } from "react";
import Plot from "../../atoms/Plot";
import RadioList from "../../molecules/RadioList";
import TransactInfoList from "../../molecules/TransactInfoList";
import Select, { SelectItemType } from "../../atoms/Select";
import { useHistory, useParams } from "react-router-dom";
import { useTranslator } from "eo-locale";
import DateSelect from "../../atoms/DateSelect";
import { DatePeriod } from "./styled";
import { RadioItemType } from "../../atoms/RadioButton";
import ModalTransactionsReport from "../../molecules/ModalTransactionsReport";
import { Svg } from "../../atoms/Svg";
import { Indicators, Period } from "./type";
import { useCalendarControl } from "../../../hooks/useCalendarControl";
import { useTransactItemRequest } from "./useRequest";
import CButton from "../../atoms/CButton";
import { CSubTitle } from "../../atoms/CSubTitle";
import { Wrapper } from "../../atoms/Wrapper";

const TransactItem: React.FC = () => {
  const [period, setPeriod] = useState<Period | "Month">("Week");
  const [radio, setRadio] = useState<Indicators>("count");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const params = useParams<{ coin: string }>();
  const translator = useTranslator();
  const history = useHistory();
  const [
    onToDateChange,
    onFromDateChange,
    { fromDate, toDate, maxToDate, minToDate, maxFromDate },
  ] = useCalendarControl(period);
  const { transCommonInfo, chartData, chartDataLoading} = useTransactItemRequest({
    symbol: params.coin,
    fromDate,
    toDate,
    period,
  });

  const periodItems: Array<SelectItemType<Period | "Month">> = [
    {
      key: "Week",
      value: translator.translate("periodWeek"),
    },
    {
      key: "Month",
      value: translator.translate("periodMonth"),
    },
    {
      key: "Year",
      value: translator.translate("periodYear"),
    },
    {
      key: "change",
      value: translator.translate("periodChange"),
    },
  ];

  const transactRadioData: Array<RadioItemType> = [
    {
      title: translator.translate("transactItemRadio1"),
      key: "count",
    },
    {
      title: translator.translate("transactItemRadio2"),
      key: "transact",
    },
    {
      title: translator.translate("transactItemRadio3"),
      key: "commis",
    },
  ];

  const transactInfoData = [
    {
      allTitle: translator.translate("reportCountSum"),
      items: [
        {
          title: translator.translate("reportCountEntry"),
        },
        {
          title: translator.translate("reportCountOut"),
        },
        {
          title: translator.translate("reportCountInside"),
        },
      ],
    },
    {
      allTitle: translator.translate("reportTransactSum"),
      items: [
        {
          title: translator.translate("reportTransactEntry"),
        },
        {
          title: translator.translate("reportTransactOut"),
        },
        {
          title: translator.translate("reportTransactInside"),
        },
      ],
    },
    {
      allTitle: translator.translate("reportComissSum"),
      items: [
        {
          title: translator.translate("reportComissEntry"),
        },
        {
          title: translator.translate("reportComissOut"),
        },
        {
          title: translator.translate("reportComissInside"),
        },
      ],
    },
  ];

  const onPeriodSelect = (item: SelectItemType<Period>) => setPeriod(item.key);
  const onRadioChange = (item: RadioItemType) => setRadio(item.key as Indicators);
  return (
    <>
      <Wrapper
        styles={{
          "margin-bottom": "20px",
          "flex-flow": "row wrap",
        }}
      >
        <CButton
          type="button"
          styledType="BACK"
          onClick={() => history.push("/transact")}
        >
          <Svg iconId="icChev" color="white" width={"15px"} height={"15px"} />
        </CButton>
        <CSubTitle
          styles={{
            "font-size": "24px",
            "margin": "0",
            "font-family": "Roboto bold, sans-serif",
          }}
        >
          {translator.translate("mainTabTransact", { value: params.coin })}
        </CSubTitle>
      </Wrapper>

      <Select
        title={translator.translate("periodTitle")}
        items={periodItems}
        selectedItem={period}
        onSelect={onPeriodSelect}
        margin={period === "change" ? "0 0 15px 0" : "0 0 25px 0"}
        width="300px"
      />
      
      {period === "change" ? (
        <DatePeriod>
          <DateSelect
            title={translator.translate("periodTitleDate1")}
            onValueChange={onFromDateChange}
            value={fromDate.toDate()}
            maxDate={maxFromDate}
            theme="black"
            margin="0 15px 0 0"
          />

          <DateSelect
            title={translator.translate("periodTitleDate2")}
            onValueChange={onToDateChange}
            value={toDate.toDate()}
            theme="black"
            maxDate={maxToDate}
            minDate={minToDate}
          />
        </DatePeriod>
      ) : null}

      <RadioList
        items={transactRadioData}
        direction="row"
        margin="0 0 16px 0"
        selected={radio}
        itemsMargin="0 32px 0 0 "
        onSelect={onRadioChange}
      />

      <Plot
        fromDate={fromDate.valueOf()}
        toDate={toDate.valueOf()}
        chartData={{data: chartData, type: "TRANSACT"}}
        period={period}
        indicator={radio}
        loading={chartDataLoading}
      />

      {transCommonInfo && (
        <TransactInfoList
          data={transactInfoData}
          transactItemData={transCommonInfo}
          value={params.coin}
        />
      )}

      <CButton
        onClick={() => setIsModalOpen(true)}
        type="button"
        styledType="LIGHT"
        styles={{ "min-width": "344px" }}
      >
        {translator.translate("reportTransactButton")}
      </CButton>
      
      <ModalTransactionsReport
        enabled={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        defaultPeriod={period}
        radio={radio}
        defaultCoin={params.coin}
        defaultFromDate={fromDate}
        defaultToDate={toDate}
      />
 
    </>
  );
};

export default TransactItem;

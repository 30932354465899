import {gql} from "apollo-boost";

export const USERS_ALL_NORM = gql`
  query getUsersList($skip: Int!, $first: Int!, $email: String){
    getUsersList(filter:{first: $first, skip: $skip, email: $email}){
      count
      totalCount
      users{
        _id
        email
        eosName
        firstName
        lastName
        phone
        country{
          name
          fullName
        }
        registeredAt
        restrictions{
          internal
          output
        }
        withdrawAmount{
          level
        }
      }
    }
  }
`

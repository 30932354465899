import {useState} from "react";
import {useSubscription} from "@apollo/react-hooks";
import {SUBSCRIBTION_MESSAGES} from "./queries";
import {SupportMessageType} from "../../molecules/CSupportMessagesList/useLoadMessages";

export const useNewMessageSub = () => {
  const [newMessage, setNewMessage] = useState<SupportMessageType>();

  const { loading } = useSubscription(SUBSCRIBTION_MESSAGES, {
    onSubscriptionData: (options) => {
      let data = options.subscriptionData.data.newMessage;
      setNewMessage(data);
    }
  });

  return {newMessage, loading};
}

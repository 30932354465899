import React, { FC } from "react";
import { StyledForm } from "./style";

export interface IFormProps {
  styledType?: string;
  styles?: { [key: string]: string };
  onSubmit: () => void;
}

export const CForm: FC<IFormProps> = ({
  children,
  onSubmit,
  ...otherProps
}) => {
  return (
    <StyledForm {...otherProps} onSubmit={onSubmit}>
      {children}
    </StyledForm>
  );
};

import styled from "styled-components";

const StyledSettingTable = styled.table`
  background: #25282b;
  padding: 20px;
  border-radius: 3px;
  width: 100%;
  min-height: 585px;
  box-shadow: 4px 4px 8px 0px rgb(0 0 0 / 25%);
`;

const SettingHeadGroup = styled.thead`
  @media screen and (max-width: 990px) {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
`;

const SettingHead = styled.th`
  text-align: inherit;
  vertical-align: baseline;
  padding: 0 15px 15px 0;
`;

const SettingRow = styled.tr`
  @media screen and (max-width: 990px) {
    border-bottom: 2px solid #dddddd1c;
    display: block;
    margin-bottom: 30px;

    &:last-child {
      border-bottom: none;
    }
  }
`;

const SettingData = styled.td<{ styles?: { [key: string]: string } }>`
  ${({ styles }) => styles}
  @media screen and (max-width: 990px) {
    display: flex;
    justify-content: space-between;
    text-align: left;
    margin-bottom: 10px;
    align-items: center;
    &::before {
      content: attr(data-label);
      flex-basis: 50%;
      margin-right: 50px;
      font-size: 17px;
      overflow: auto;
    }
  }

  @media screen and (max-width: 694px) {
    flex-flow: row wrap;
    align-items: baseline;
    &:first-child {
      flex-flow: row nowrap;
      margin-bottom: 25px;
      &::before {
        margin: 0;
      }
    }
    &::before {
      margin: 0 0 10px 0;
      flex-basis: 100%;
    }
  }
`;

export {
  StyledSettingTable,
  SettingHeadGroup,
  SettingHead,
  SettingRow,
  SettingData,
};

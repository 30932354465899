import React, {useRef} from "react";
import CustomButton from "../../atoms/CustomButton";
import {useTranslator} from "eo-locale";
import {IChartData, ITransactData} from "../ModalTransactionsReport";
import dayjs from "dayjs";
import BigNumber from "bignumber.js";

interface ITransPngReportButton {
  chartData?: IChartData;
  transactionsData?: ITransactData;
  coin: string;
  parts: Array<string>;
  period: string
}

const TransPngReportButton: React.FC<ITransPngReportButton> = (
  {
    chartData,
    transactionsData,
    coin,
    parts,
    period,
  }
) => {
  const translator = useTranslator();
  const canvas = useRef<HTMLCanvasElement>(null)

  const onDownloadClick = () => {
    if(
      !chartData
      || !chartData.getChartData
      || !transactionsData
      || !transactionsData.transactionsCommonInfo
    ) return;

    // 300 и 70 это высота и ширина в dpi
    const elCavas = canvas.current,
      ctx = elCavas?.getContext('2d'),
      link = document.createElement('a'),
      itemCommon = transactionsData?.transactionsCommonInfo[0],
      itemChart = chartData.getChartData;

    let xBlock = parts.length === 1 ? 150 : parts.length === 2 ? 115 : 65,
      yBlock = 85 * 2,
      xText = parts.length === 1 ? 225 : parts.length === 2 ? 130 : 80,
      yText = parts.length === 1 ? 127 + 85 : 117 + 85,
      difY = parts.length === 1 ? 70 : 50,
      difX = parts.length === 1 ? 300 : 200,
      widthBlock = parts.length === 1 ? 300 : 200,
      heightBlock = parts.length === 1 ? 70 : 50;

    if(ctx && elCavas) {
      ctx.font = "30px Verdana";
      ctx.fillStyle = "black";
      ctx.fillText(`${coin} report`, 365, 47);
      ctx.font = `${parts.length === 1 ? 22 : 18}px Verdana`;
      const drawHeader = (text: string, copyXBlock: number, copyXText: number, widthBlock: number = parts.length === 1 ? 300 : 200) => {
        ctx.strokeRect(copyXBlock, yBlock - difY, widthBlock, heightBlock);
        ctx.fillText(text, copyXText, yText - difY);
      }
      for (let i = 0; i < itemChart.length; i++) {
        const date = dayjs(itemChart[i].fromDate),
        // было 'DD.MM.YYYY - hh:mm' убрал hh:mm т.к. время стоит заглушкой 7:00
        // добавим когда часы на беке будут корректно обрабаываться
          normalDate = date.format('DD.MM.YYYY'); 
        let copyXText = xText,
          copyXBlock = xBlock;

        ctx.beginPath();
        // Заголовок
        if (i === 0) drawHeader(translator.translate("reportTransactLabel"), copyXBlock, copyXText, widthBlock)
        ctx.fillText(normalDate, copyXText, yText);
        ctx.strokeRect(copyXBlock, yBlock, widthBlock, heightBlock);
        if (parts.includes('count')) {
          copyXText += difX
          copyXBlock += difX
          let sum: string = new BigNumber(
            itemChart[i].input.count + itemChart[i].output.count + itemChart[i].internal.count
          ).dp(8).toFormat();
          // Заголовок
          if (i === 0) drawHeader(translator.translate("transactItemRadio1"), copyXBlock, copyXText, widthBlock + 100)  
          ctx.strokeRect(copyXBlock, yBlock, widthBlock + 100, heightBlock);
          if (parts.length >= 2) {
            copyXText += 80
            ctx.fillText(sum, copyXText, yText)
            copyXText -= 40
          } else {
            ctx.fillText(sum, copyXText, yText)
          }
        }
        if (parts.includes('transact')) {
          let sum = new BigNumber(
            Number(itemChart[i].input.amount) +
            Number(itemChart[i].output.amount) +
            Number(itemChart[i].internal.amount)
          ).dp(8).toFormat();

          copyXText += difX - 30
          copyXBlock += difX
          // Заголовок
          if (i === 0) drawHeader(translator.translate("transactItemRadio2"), copyXBlock + 100, copyXText + 100, widthBlock + 100)  
          ctx.strokeRect(copyXBlock + 100, yBlock, widthBlock + 100, heightBlock);
          ctx.fillText(sum, copyXText + 100, yText);
        }
        if (parts.includes('commis')) {
          let sum: string = new BigNumber(
            Number(itemChart[i].input.commission) +
            Number(itemChart[i].output.commission) +
            Number(itemChart[i].internal.commission)
          ).dp(8).toFormat();

          copyXText += difX
          copyXBlock += difX
          // Заголовок
          if (i === 0) drawHeader(translator.translate("transactItemRadio3"), copyXBlock + 200, copyXText + 200, widthBlock + 100)  
          ctx.strokeRect(copyXBlock + 200, yBlock, widthBlock + 100, heightBlock);
          ctx.fillText(sum, copyXText + 200, yText);
        }
        yText += difY
        yBlock += difY
      }
      ctx.font = "18px Verdana";
      xText = 10 + 60
      difY = 35
      difX = 300
      if (parts.includes('count')) {
        let copyXText = xText
        ctx.fillText(translator.translate('reportCountSum'), copyXText, yText)
        yText += difY
        ctx.fillText(
          new BigNumber(itemCommon.input.count
          + itemCommon.output.count
          + itemCommon.internal.count).dp(8).toFormat(),
          copyXText, yText)
        yText += difY
        ctx.fillText(translator.translate('reportCountEntry'), copyXText, yText)
        yText += difY
        ctx.fillText(new BigNumber(itemCommon.input.count).dp(8).toFormat(), copyXText, yText)
        copyXText += difX
        yText -= difY
        ctx.fillText(translator.translate('reportCountOut'), copyXText, yText)
        yText += difY
        ctx.fillText(new BigNumber(itemCommon.output.count).dp(8).toFormat(), copyXText, yText)
        copyXText += difX
        yText -= difY
        ctx.fillText(translator.translate('reportCountInside'), copyXText, yText)
        yText += difY
        ctx.fillText(new BigNumber(itemCommon.internal.count).dp(8).toFormat(), copyXText, yText)
        yText += difY * 2
      }
      if (parts.includes('transact')) {
        let copyXText = xText
        ctx.fillText(translator.translate('reportTransactSum'), copyXText, yText)
        yText += difY
        ctx.fillText(new BigNumber(
          Number(itemCommon.input.amount)
          + Number(itemCommon.output.amount)
          + Number(itemCommon.internal.amount)
          ).dp(8).toFormat(),
          copyXText, yText)
        yText += difY
        ctx.fillText(translator.translate('reportTransactEntry'), copyXText, yText)
        yText += difY
        ctx.fillText(new BigNumber(itemCommon.input.amount).dp(8).toFormat(), copyXText, yText)
        copyXText += difX
        yText -= difY
        ctx.fillText(translator.translate('reportTransactOut'), copyXText, yText)
        yText += difY
        ctx.fillText(new BigNumber(itemCommon.output.amount).dp(8).toFormat(), copyXText, yText)
        copyXText += difX
        yText -= difY
        ctx.fillText(translator.translate('reportTransactInside'), copyXText, yText)
        yText += difY
        ctx.fillText(new BigNumber(itemCommon.internal.amount).dp(8).toFormat(), copyXText, yText)
        yText += difY * 2
      }
      if (parts.includes('commis')) {
        let copyXText = xText

        ctx.fillText(translator.translate('reportComissSum'), copyXText, yText)
        yText += difY
        ctx.fillText(new BigNumber(
          Number(itemCommon.input.commission)
          + Number(itemCommon.output.commission)
          + Number(itemCommon.internal.commission)
        ).dp(8).toFormat(), copyXText, yText);
        yText += difY
        ctx.fillText(translator.translate('reportComissEntry'), copyXText, yText)
        yText += difY
        ctx.fillText(new BigNumber(itemCommon.input.commission).dp(8).toFormat(), copyXText, yText)
        copyXText += difX
        yText -= difY
        ctx.fillText(translator.translate('reportComissOut'), copyXText, yText)
        yText += difY
        ctx.fillText(new BigNumber(itemCommon.output.commission).dp(8).toFormat(), copyXText, yText)
        copyXText += difX
        yText -= difY
        ctx.fillText(translator.translate('reportComissInside'), copyXText, yText)
        yText += difY
        ctx.fillText(new BigNumber(itemCommon.internal.commission).dp(8).toFormat(), copyXText, yText)
      }
      ctx.closePath();
      link.download = `${coin}_report.png`;
      elCavas.toDataURL("image/png")
      link.href = elCavas.toDataURL();
      link.click();
      ctx.clearRect(0, 0, elCavas.width, elCavas.height);
    }
  }
  
  return(
    <>
      <CustomButton
        height="37px"
        margin="0px 0px 15px"
        onClick={onDownloadClick}
      >
        {translator.translate('modalReportBtn3')}
      </CustomButton>

      <canvas
        style={{display: 'none'}}
        ref={canvas}
        id="canvas"
        width="1200"
        height={period === 'Week' ? '900' : period === 'Month' || 'change' ? (chartData?.getChartData.length! * 30 + 1500) : '1500'}
      >
        {translator.translate('modalReportBtn2')}
      </canvas>
    </>
  )
}

export default TransPngReportButton;

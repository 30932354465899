import styled from "styled-components";

export const Container = styled.div`
  padding: 0 33px;
  width: 100%;
`;

export const CurrentBalanceContainer = styled.div`
  margin-bottom: 41px;
`;

export const ReportButton = styled.button`
  margin-bottom: 56px;
  width: 297px;
  height: 52px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  background: white;
  border-radius: 5px;
  border: none;
  font-size: 24px;
  line-height: 28px;
`;

export const Arrow = styled.img`
  height: 17px;
  width: 12px;
  margin-right: 5px;
`;

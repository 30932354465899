import React from "react";
import { StyledTitle } from "./style";

export interface ITitleProps {
  styles?: { [key: string]: string };
  styledType?: string;
}

export const CTitle: React.FC<ITitleProps> = ({ children, ...otherProps }) => {
  return <StyledTitle {...otherProps}>{children}</StyledTitle>;
};

import React from "react";
import Checkbox from "../../atoms/Checkbox";
import {List, CheckboxContainer, ListItem} from './styled';

export interface IList {
  palette: string
}

interface IMenuWithSelection {
  onSelectionChange: (id: string) => void,
  selected: Array<string>;
  items: Array<{ id: string, text: string}>
}

const MenuWithSelection: React.FC<IMenuWithSelection> = (props) => {
  return(
    <List palette="primary">
      {
        props.items.map(item => (
          <ListItem>
            <CheckboxContainer>
              <Checkbox
                width={16}
                height={16}
                onClick={() => props.onSelectionChange(item.id)}
                selected={!!(props.selected.indexOf(item.id) + 1)}
              />
            </CheckboxContainer>

            {item.text}
          </ListItem>
        ))
      }
    </List>
  )
}

export default MenuWithSelection;

import React, {useEffect, useState} from 'react'
import {useTranslator} from 'eo-locale'
import {useQuery} from '@apollo/react-hooks'
import Pagination from '../../atoms/Pagination'
import Table from "../../atoms/Table";
import {TableHeadType} from "../../atoms/Table";
import {Filters, ResetFilters, FilterContainer, TableContainer, Title} from './styled'
import icons from "../../../assets/img/sprite.svg";
import Button from "../../atoms/Button";
import {useHistory} from "react-router-dom";
import CustomInput from "../../atoms/CustomInput";
import {USERS_ALL_VERIF} from "./queries";
import CustomLoader from "../../atoms/CustomLoader";
import {useRequestError} from "../../../hooks/useRequestError/useRequestError";

type TableItemType = {
  id: string,
  email: string,
  firstName: string,
  lastName: string,
  eosName: string,
  verification: string,
  regDate: string,
  restrictions: {
    internal: boolean
    output: boolean
  }
}

type GetAllType = {
  getUsersList: {
    count: number,
    totalCount: number,
    users: Array<UserType>
  }
}

type UserType = {
  _id: number,
  email: string,
  eosName: string
  firstName: string
  lastName: string
  phone: string
  country: {
    name: string
    fullName: string
  }
  registeredAt: number
  restrictions: {
    internal: boolean
    output: boolean
  },
  withdrawAmount: {
    level: number
  }
}

const UsersVerification: React.FC = () => {
  const translator = useTranslator();
  const [total, setTotal] = useState<number>();
  const [searchInput, setSearchInput] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [tableData, setTableData] = useState<Array<TableItemType>>([]);
  const [activePage, setActivePage] = useState(0);
  const {showError} = useRequestError();
  const history = useHistory();

  const tableHead: Array<TableHeadType<TableItemType>> = [
    {
      text: translator.translate('usersAllList0'),
      dataKey: "id",
      width: '75px'
    },
    {
      text: translator.translate('usersAllList1'),
      dataKey: "email",
      width: '185px'
    },
    {
      text: translator.translate('usersAllList2'),
      dataKey: "firstName",
      width: '120px'
    },
    {
      text: translator.translate('usersAllList3'),
      dataKey: "lastName",
      width: '120px'
    },
    {
      text: translator.translate('usersAllList4'),
      dataKey: "eosName",
      width: '150px'
    },
    {
      text: translator.translate('usersAllList5'),
      dataKey: "verification",
      width: '150px'
    },
    {
      text: translator.translate('usersAllList6'),
      dataKey: "regDate",
      width: '170px'
    },
  ]

  const {data: dataAll, loading} = useQuery<GetAllType>(USERS_ALL_VERIF, {
    variables: {
      skip: 12 * activePage,
      first: 12,
      email: searchValue
    },
    onError: (error) => {
      showError(error.message);
    },
  });

  useEffect(() => {
    let data: Array<UserType> = [];
    let total = 0;
    let newTableData: Array<TableItemType> = [];

    if(!dataAll) return;
    data =  dataAll.getUsersList.users;
    total = dataAll.getUsersList.totalCount

    if(data){
      data.forEach((item) => {
        newTableData.push({
          id: item._id.toString(),
          email: item.email,
          firstName: item.firstName,
          lastName: item.lastName,
          eosName: item.eosName,
          verification: item.withdrawAmount?.level.toString(),
          regDate: item.registeredAt ? new Date(item.registeredAt).toLocaleString(['ru-RU', 'en-US']) : '-',
          restrictions: {
            internal: item.restrictions?.internal,
            output: item.restrictions?.output
          }
        })
      })
      setTotal(total);
      setTableData(newTableData);
    }
  }, [dataAll]);

  useEffect(() => {
    const timeout = setTimeout(() => setSearchValue(searchInput), 500);
    return () => clearTimeout(timeout);
  }, [searchInput]);

  const changeSearchInput = (str: string) => {
    setSearchInput(str);
  };

  const onFiltersReset = () => {
    changeSearchInput('');
  }

  const onRowClick = (item: TableItemType) => {
    history.push(`/users/${item.email}`);
  }

  const changeActivePage = (index: number) => {
    setActivePage(index);
  }

  const onBackClick = () => {
    history.push('/users');
  }

  return (
    <>
      <Title>
        <Button margin="0 16px 0 0" reverse onClick={onBackClick} width="30px" height="30px">
          <div className="icChev">
            <svg className="inline-svg-icon">
              <use className="icas" xlinkHref={`${icons}#icChev`} name="debtors" />
            </svg>
          </div>
        </Button>

        {translator.translate('verifAllTitle', {value: ''})}
      </Title>


      <Filters>
        <FilterContainer>
          <CustomInput
            value={searchInput}
            title="Сортировка по e-mail"
            placeholder="Введите E-mail"
            onChange={changeSearchInput}
          />
        </FilterContainer>

        <ResetFilters onClick={onFiltersReset}>
          {translator.translate('usersSearchReset')}
        </ResetFilters>
      </Filters>

      <TableContainer>
        <Table
          data={tableData}
          headData={tableHead}
          clickable
          onRowClick={onRowClick}
        />

        <CustomLoader
          loading={loading}
          loaderWidth={50}
          isGlobal={false}
        />
      </TableContainer>

      <Pagination
        total={total ? total / 12 : 0}
        paginActive={activePage}
        changePaginActive={changeActivePage}
      />
    </>
  )
}

export default UsersVerification;


import { DeepMap, FieldError } from "react-hook-form";
import { OnSubmitData } from "./type";

// Есть ли ошибки
const checkError = (error: DeepMap<OnSubmitData, FieldError>) => {
  return Object.keys(error).length !== 0;
};

// Валидация инпутов
const validateInputs = (
  regExpList: { [key: string]: RegExp },
  value: string
) => {
  return Object.values(regExpList).some((r) => r.test(value));
};

// Заполнен ли хоть один input
const isSomeInputFilled = (fieldList: { [key: string]: string }) => {
  const fieldArr = Object.values(fieldList);
  return fieldArr.some((value) => value);
};

// Дает список инпутов с пустыми value
const getCleanInputList = (fieldList: { [key: string]: string }) => {
  const names = Object.keys(fieldList);
  return names.reduce(
    (inputs, i) => ({
      ...inputs,
      [i]: "",
    }),
    {}
  );
};

export { checkError, validateInputs, isSomeInputFilled, getCleanInputList };

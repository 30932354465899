import {useState} from "react";
import {useSubscription} from "@apollo/react-hooks";
import {SUBSCRIBTION_READ_MESSAGE} from "./queries";

export type ReadedMessage = {
  chatId: number
  maxMsgId: number
  userId: number
}

export const useReadMessageSub = () => {
  const [readedMessage, setReadedMessage] = useState<ReadedMessage>();

  const { loading } = useSubscription(SUBSCRIBTION_READ_MESSAGE, {
    onSubscriptionData: (options) => {
      let data = options.subscriptionData.data.readMessage;
      setReadedMessage(data);
    }
  });

  return {readedMessage, loading}
}

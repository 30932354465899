import { useLazyQuery } from "react-apollo";
import { LOGIN } from "../../../gql/useQuery/AUTH";
import { useState } from 'react';
import { useHistory } from "react-router-dom";
import AuthInfo from "../../../store/AuthInfo";
import { useEffect } from 'react';

export const useLoginRequest = () => {
  const history = useHistory();
  const [requestSuccess, setRequestSuccess] = useState(false);
  const [requestError, setRequestError] = useState(false);

  const [loginQuery, { data, loading }] = useLazyQuery(LOGIN, {
    onCompleted: () => {
      setRequestSuccess(true)
        const token = data.signIn.accessToken;
        if (token) {
          AuthInfo.setInfoByToken(token);
          localStorage.setItem("token", token);
          history.replace("/");
        }
    },
    onError: () => {
      setRequestError(true);
    },
  });

  // Сброс флагов к начальному состоянию после рендера ошибки
  useEffect(() => {
    if (requestError) {
      setRequestError(false);
    }

    if (requestSuccess) {
      setRequestSuccess(false);
    }
  }, [requestError, requestSuccess])

  return {
    requestSuccess,
    requestError,
    loading,
    loginQuery,
  }
}
import React from "react";
import { Styles } from "../../../commonTypes";
import { StyledText } from "./styled";

interface IText {
  children: string
  styles?: Styles
}

export const Text: React.FC<IText> = ({ children, styles }) => {
  return <StyledText styles={styles}>{children}</StyledText>;
};

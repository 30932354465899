import styled from "styled-components";
import {font, palette} from "styled-theme";

export const TransactInfoWrap = styled.div<{palette:string,last:boolean}>`  
  width: 100%;
  padding: 33px 0;
  border-bottom: ${props => props.last === true ? 'none' : '1px solid #fff'};
  border-color: ${palette({ primary: 2 }, 1)};  
  font-size: 18px;  
  
  & .sum{
    margin-bottom: 24px;
    
    & .title{
      font-family: ${font('medium')};
    }
  }
  
  & .info{
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    
    & .info__el{
      width: max-content;
      
      & .title{
        margin-bottom: 15px;
      }
    }
  } 
`

import React, {useState, useEffect} from 'react'
import {useTranslator} from 'eo-locale'
import {useQuery, useMutation} from '@apollo/react-hooks'
import Loader from 'react-loader-spinner'
import Button from '../../atoms/Button'
import Pagination from '../../atoms/Pagination'
import VerificationUser from '../../molecules/VerificationUser'
import {useHistory, useParams} from "react-router-dom";
import icons from "../../../assets/img/sprite.svg";
import {Title, InfoRow, ButtonWrap, Balance, TableBackground, TransactTitle, Status, RangeSelection} from "./styled";
import UserDocuments from "../../molecules/UserDocuments";
import Select, {SelectItemType} from "../../atoms/Select";
import Table, {TableHeadType} from "../../atoms/Table";
import UserInfo from "../../../store/UserInfo";
import {observer} from "mobx-react-lite";
import {USERS_BlOCK, USERS_ALL_SEARCH} from "./queries"
import DateSelect from "../../atoms/DateSelect";
import dayjs from 'dayjs'
import BigNumber from 'bignumber.js'
import {useRequestError} from "../../../hooks/useRequestError/useRequestError";

export interface IInfoRow {
    palette: string
}

export interface IStatus {
    enabled: boolean
}

type TransactTableItemType = {
    id: string,
    to: string,
    trx_id: string,
    quantity: string,
    memo: string,
    block_num: number,
    createdAt: number | string
}

export type UserDocumentType = {
    type: string,
    document: {
        file: {
            name: string,
            localId: number
        }
    },
    thumb: {
        file: {
            name: string,
            localId: number
        }
    }
    status: "EMPTY" | "VERIFIED" | "PENDING" | "REJECTED",
    createdAt: number | string,
    updatedAt: number,
    reasons: Array<
      "FILE_EMPTY" |
      "FILE_CORRUPTED" |
      "DOCUMENT_DUPLICATE" |
      "DOCUMENT_UNREADABLE" |
      "SELFIE_DOESNT_MATCH_PHOTO"
    >
}

export type UserQueryType = {
    getUserBy: {
        _id: number
        email: string
        eosName: string
        firstName: string
        lastName: string
        lastSeen: number
        phone: string
        balance: Array<{
            symbol: string
            amount: string
        }>
        country: {
            name: string
            fullName: string
        }
        registeredAt: number,
        restrictions: {
            internal: boolean,
            output: boolean
        }
        transactions: {
            totalCount: number,
            count: number,
            transactions: Array<{
                trx_id: string,
                from: string,
                to: string,
                quantity: string,
                memo: string,
                createdAt: number | string,
                block_num: number
            }>
        }
        kycDocuments: Array<UserDocumentType>,
        withdrawAmount: {
            level: number,
            canVerify: boolean
        }
    }
}

const UsersInfo: React.FC = observer(() => {
    const translator = useTranslator(),
        [transactTable, setTransactTable] = useState<Array<TransactTableItemType>>([]),
        [normalDate, setNormalDate] = useState(),
        [normalSeen, setNormalSeen] = useState(),
        [fromDate, setFromDate] = useState<Date>(new Date(dayjs().subtract(7, 'day').format())),
        [toDate, setToDate] = useState<Date>(new Date(dayjs().format())),
        history = useHistory(),
        {showError} = useRequestError(),
        params = useParams<{ email: string }>();

    const transactSelect: Array<SelectItemType<string>> = [
        {
            key: 'Week',
            value: translator.translate('periodWeek')
        },
        {
            key: 'Month',
            value: translator.translate('periodMonth')
        },
        {
            key: 'Year',
            value: translator.translate('periodYear')
        },
        {
            key: 'Change',
            value: translator.translate('periodChange')
        }
    ]

    const transactTableHead: Array<TableHeadType<TransactTableItemType>> = [
        {
            dataKey: 'to',
            text: translator.translate('usersInfoTransactTable0'),
            width: '148px'
        },
        {
            dataKey: 'trx_id',
            text: translator.translate('usersInfoTransactTable1'),
            width: '383px'
        },
        {
            dataKey: 'quantity',
            text: translator.translate('usersInfoTransactTable2'),
            width: '125px'
        },
        {
            dataKey: 'memo',
            text: translator.translate('usersInfoTransactTable3'),
            width: '383px'
        },
        {
            dataKey: 'block_num',
            text: translator.translate('usersInfoTransactTable4'),
            width: '113px'
        },
        {
            dataKey: 'createdAt',
            text: translator.translate('usersInfoTransactTable5'),
            width: '195px'
        },
    ]

    const {data: dataAll, loading} = useQuery<UserQueryType>(USERS_ALL_SEARCH, {
        variables: {
            email: params.email,
            period: UserInfo.transactPeriod,
            skip: 12 * UserInfo.transactActivePage,
            from: fromDate?.getTime(),
            to: toDate?.getTime()
        },
        onCompleted: (data) => {
            let date = new Date(data.getUserBy.registeredAt);
            setNormalDate(date.toLocaleString(['ru-RU', 'en-US']));
            date = new Date(data.getUserBy.lastSeen);
            setNormalSeen(date.toLocaleString(['ru-RU', 'en-US']));
            UserInfo.setId(data.getUserBy._id);
            UserInfo.setEmail(data.getUserBy.email);
            UserInfo.setVerificationLevel(data.getUserBy.withdrawAmount.level);
        },
        onError: (error) => {
            showError(error.message);
        }
    });

    useEffect(() => {
        if(dataAll){
            let newTransact: Array<TransactTableItemType> = [];

            dataAll.getUserBy.transactions.transactions.forEach((item,index) => {
                newTransact.push({
                    id: index.toString(),
                    to: item.to,
                    trx_id: item.trx_id,
                    quantity: item.quantity,
                    memo: item.memo,
                    block_num: item.block_num,
                    createdAt: dayjs(item.createdAt).format("DD/MM/YYYY HH:mm:ss")
                })
            })
            setTransactTable(newTransact);
        }
    }, [dataAll])

    useEffect(() => {
        UserInfo.fromDate = fromDate;
    }, [fromDate])

    useEffect(() => {
        UserInfo.toDate = toDate;
    }, [toDate])

    const [setUserRestrictions, {error: errorOld, loading: loadingBlock}] = useMutation(USERS_BlOCK, {
        update: (proxy ,mutationResult) => {
            const prevData: UserQueryType | null = proxy.readQuery({query: USERS_ALL_SEARCH, variables: {
                email: params.email,
                period: UserInfo.transactPeriod,
                skip: 12 * UserInfo.transactActivePage,
                from: fromDate?.getTime(),
                to: toDate?.getTime()
            }})

            proxy.writeQuery({
                query: USERS_ALL_SEARCH,
                data: {
                    getUserBy: {
                        ...prevData?.getUserBy,
                        restrictions: mutationResult.data.setUserRestrictions.restrictions
                    }
                }
            })
        },
    });

    const changeTransactPagination = (index: number) => {
        UserInfo.setTransactActivePage(index);
    }

    const TransactSelectChange = (item: SelectItemType<string>) => {
        UserInfo.setTransactPeriod(item.key);
    }

    const changeOut = () => {
        setUserRestrictions({
            variables: {
                email: dataAll?.getUserBy?.email,
                output: !dataAll?.getUserBy?.restrictions?.output,
                internal: dataAll?.getUserBy?.restrictions?.internal
            }
        })
    }

    const changeInternal = () => {
        setUserRestrictions({
            variables: {
                email: dataAll?.getUserBy?.email,
                output: dataAll?.getUserBy?.restrictions?.output,
                internal: !dataAll?.getUserBy?.restrictions?.internal
            }
        })
    }

    const onBackClick = () => {
        history.push('/users');
    }

    const onFromDateChange = (value: Date) => {
        setFromDate(value);
    }

    const onToDateChange = (value: Date) => {
        setToDate(value);
    }

    return (
        <>
            {(loading || loadingBlock) ?
                <div className="loader"><Loader type="Oval" color="#fff" height={100} width={100}/></div> :
                <>
                    <Title>
                        <Button margin="0 16px 0 0" reverse onClick={onBackClick} width="30px" height="30px">
                            <div className="icChev">
                                <svg className="inline-svg-icon">
                                    <use className="icas" xlinkHref={`${icons}#icChev`} name="debtors" />
                                </svg>
                            </div>
                        </Button>

                        {translator.translate('usersInfoTitle', {value: dataAll?.getUserBy.eosName})}
                    </Title>

                    <InfoRow palette="primary">
                        <span>{translator.translate('usersInfoLegend9')}</span>
                        {
                            dataAll?.getUserBy?.restrictions?.output ? (
                              <Status enabled={false}>{translator.translate('usersInfoStatusErrorOut')}</Status>
                            ) : (
                              <Status enabled={true}>{translator.translate('usersInfoStatusErrorNot')}</Status>
                            )
                        }
                    </InfoRow>
                    <InfoRow palette="primary">
                        <span>{translator.translate('usersInfoLegend10')}</span>
                        {
                            dataAll?.getUserBy?.restrictions?.internal ? (
                              <Status enabled={false}>{translator.translate('usersInfoStatusErrorIntern')}</Status>
                            ) : (
                              <Status enabled={true}>{translator.translate('usersInfoStatusErrorNot')}</Status>
                            )
                        }
                    </InfoRow>
                    <InfoRow palette="primary">
                        <span>{translator.translate('usersInfoLegend0')}</span> {dataAll?.getUserBy._id}
                    </InfoRow>
                    <InfoRow palette="primary">
                        <span>{translator.translate('usersInfoLegend1')}</span> {dataAll?.getUserBy.eosName}
                    </InfoRow>
                    <InfoRow palette="primary">
                        <span>{translator.translate('usersInfoLegend2')}</span> {dataAll?.getUserBy.email}
                    </InfoRow>
                    <InfoRow palette="primary">
                        <span>{translator.translate('usersInfoLegend3')}</span> {dataAll?.getUserBy.firstName}
                    </InfoRow>
                    <InfoRow palette="primary">
                        <span>{translator.translate('usersInfoLegend4')}</span> {dataAll?.getUserBy.lastName}
                    </InfoRow>
                    <InfoRow palette="primary">
                        <span>{translator.translate('usersInfoLegend5')}</span> {dataAll?.getUserBy.country.fullName}
                    </InfoRow>
                    <InfoRow palette="primary">
                        <span>{translator.translate('usersInfoLegend7')}</span> {normalDate}
                    </InfoRow>
                    <InfoRow palette="primary">
                        <span>{translator.translate('usersInfoLegend8')}</span> {normalSeen}
                    </InfoRow>

                    <ButtonWrap>
                        <Button width="413px" margin="0 33px 0 0" onClick={changeOut}>
                            {dataAll?.getUserBy?.restrictions?.output ? translator.translate('usersInfoBtnOutUn') : translator.translate('usersInfoBtnOut')}
                        </Button>
                        <Button width="413px" margin="0" onClick={changeInternal}>
                            {dataAll?.getUserBy?.restrictions?.internal ? translator.translate('usersInfoBtnTransUn') : translator.translate('usersInfoBtnTrans')}
                        </Button>
                    </ButtonWrap>

                    <VerificationUser
                        canVerify={!dataAll?.getUserBy?.withdrawAmount?.canVerify}
                        margin="0 0 32px 0"
                    />

                    <UserDocuments
                      documents={dataAll?.getUserBy.kycDocuments}
                    />

                    <Balance palette="primary">
                        <span className="title">{translator.translate('usersInfoBalance')}</span>
                        {dataAll?.getUserBy.balance.map((item: any) => {
                            return (
                                <span>{item.symbol} : {new BigNumber(item.amount).toFormat()}</span>
                            )
                        })}
                    </Balance>

                    <TransactTitle>
                        {translator.translate('usersInfoTransactTitle')}
                    </TransactTitle>

                    <Select
                      width='300px'
                      margin="0 0 15px 0"
                      title={translator.translate('periodTitle')}
                      items={transactSelect}
                      selectedItem={UserInfo.transactPeriod}
                      onSelect={TransactSelectChange}
                    />

                    {
                        UserInfo.transactPeriod === 'Change' ? (
                            <RangeSelection>
                                <DateSelect
                                    title={translator.translate('periodTitleDate1')}
                                    onValueChange={onFromDateChange}
                                    value={fromDate}
                                    theme="black"
                                    margin="0 10px 0 0"
                                />

                                <DateSelect
                                    title={translator.translate('periodTitleDate2')}
                                    onValueChange={onToDateChange}
                                    value={toDate}
                                    theme="black"
                                />
                            </RangeSelection>
                        ) : null
                    }

                    <TableBackground>
                        <Table
                          data={transactTable}
                          headData={transactTableHead}
                        />
                    </TableBackground>

                    <Pagination
                        total={(dataAll && dataAll.getUserBy.transactions.totalCount / 12) || 0}
                        paginActive={UserInfo.transactActivePage}
                        changePaginActive={changeTransactPagination}
                    />
                </>
            }
        </>
    )
})

export default UsersInfo

import React, {useEffect, useRef, useState} from "react";
import CustomButton from "../../atoms/CustomButton";
import ReactExport from "react-export-excel";
import {useTranslator} from "eo-locale";
import {IUsersDetail} from "../ModalUserReport";
import dayjs from "dayjs";
import BigNumber from "bignumber.js";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

interface IUsersExcelReportButton {
  data: IUsersDetail | undefined,
  device: string,
  totalCount: number | undefined
}

const UsersExcelReportButton: React.FC<IUsersExcelReportButton> = (
  {
    data,
    device,
    totalCount
  }
) => {
  const [excelData, setSaveData] = useState();
  const refExcel = useRef<HTMLButtonElement>(null);
  const translator = useTranslator();

  const getFormatDataExcel =  () => {
    let formatData = [];

    data?.getUsersDetail.chart.map((item, index: number) => {
      const date = dayjs(item.toDate);
      let normalDate = date.format('DD.MM.YYYY - hh:mm');

      formatData[index] = {
        col1: normalDate,
        col2: new BigNumber(item.count).toFormat(),
        col3: '',
      }
      return true
    })
    const item = data?.getUsersDetail.count
    formatData[formatData.length] = {
      col1: '',
      col2: '',
      col3: '',
    }
    formatData[formatData.length] = {
      col1: translator.translate('usersDetailItemAll'),
      col2: '',
      col3: '',
    }
    formatData[formatData.length] = {
      col1: totalCount ? new BigNumber(totalCount).toFormat() : '',
      col2: '',
      col3: '',
    }
    formatData[formatData.length] = {
      col1: translator.translate('usersDetailItemRegistr'),
      col2: translator.translate('usersDetailItemInc'),
      col3: '',
    }
    formatData[formatData.length] = {
      col1: item ? new BigNumber(item).toFormat() : '',
      col2: `${totalCount && item && new BigNumber(item / (totalCount - item) * 100).toPrecision(3)}%`,
      col3: '',
    }
    formatData[formatData.length] = {
      col1: '',
      col2: '',
      col3: '',
      col4: '',
    }
    setSaveData(formatData)
  }

  useEffect(() => {
    if(excelData){
      refExcel.current?.click()
    }
  }, [excelData])

  return(
    <>
      <CustomButton
        onClick={getFormatDataExcel}
        height='37px'
        margin="0 0 15px"
      >
        {translator.translate('modalReportBtn1')}
      </CustomButton>

      <ExcelFile
        filename={`${device === 'Desktop' ? 'Desktop/Web' : device}_report`}
        element={
          <button
            style={{display: 'none'}}
            ref={refExcel}
          >
            {translator.translate('modalReportBtn1')}
          </button>
        }
      >
        <ExcelSheet data={excelData} name="User report">
          <ExcelColumn label="Дата" value="col1" />
          <ExcelColumn label={'Количество регистраций'} value="col2" />
          <ExcelColumn label={''} value="col3" />
        </ExcelSheet>
      </ExcelFile>
    </>
  )
}

export default UsersExcelReportButton;

import React, {forwardRef} from "react";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export type ExcelColsType ={
  label: string,
  value: string
}

interface IExcelTableDownload {
  cols: Array<ExcelColsType>,
  data: any
}

const Index = forwardRef<HTMLButtonElement, IExcelTableDownload>( (props, ref) => {
  return(
    <ExcelFile
      filename={`exchange_bot_report`}
      element={
        <button style={{display: 'none'}} ref={ref}>
          Download Data
        </button>
      }
    >
      <ExcelSheet hideElement={true} data={props.data} name="Currency report">
        {
          props.cols.map((col, index) => (
            <ExcelColumn alignment={{vertical: "top", horizontal: 'center', wrapText: 'true'}} key={index} label={col.label} value={col.value} />
          ))
        }
      </ExcelSheet>
    </ExcelFile>
  )
})

export default Index;

import React, {CSSProperties} from "react";
import {Button} from './styled'

interface ICustomButton {
  onClick: () => void,
  type?: "submit" | "button",
  margin?: string,
  padding?: string,
  fSize?: string,
  width?: string;
  height?: string;
  disabled?: boolean,
  style?: CSSProperties
}

export type ButtonStyledType = {
  margin?: string,
  padding?: string,
  fSize?: string,
  width?: string;
  height?: string;
  style?: CSSProperties
}

const CustomButton: React.FC<ICustomButton> = (
  {
    margin = "0",
    padding = "5px",
    fSize = '16px',
    onClick,
    type,
    width= '100%',
    height = '100%',
    disabled,
    style,
    children
  }) => {
  return(
    <Button
      type={type || 'button'}
      onClick={() => onClick()}
      disabled={disabled}
      style={style}
      margin={margin}
      padding={padding}
      fSize={fSize}
      width={width}
      height={height}
    >
      {children}
    </Button>
  )
}



export default CustomButton;

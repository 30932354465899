/* eslint-disable react/prop-types */
import React from 'react'
import {TransactInfoWrap} from "./styled";
import BigNumber from "bignumber.js";

type dataType = {
  allTitle: string,
  items: Array<itemType>
}

type itemType = {
  title: string,
}

export interface ITransactInfo {
  data: dataType;
  value: string;
  last: boolean;
  dataItem: Array<number | string>;
}

const TransactInfo: React.FC<ITransactInfo> = (
  {
    data,
    value,
    last = true,
    dataItem
  }
) => {
  let sum = 0;

  dataItem.map((item) =>{
    if (typeof item === 'string') {
      sum += parseFloat(item)
    } else {
      sum += item;
    }
    
    return true
  });

  const all = data.items.map((item: itemType, index:number) => {
    return (
      <div className="info__el" key={`${value}_${index}`}>
        <div className="title">{`${item.title} ${value}`}</div>
        <div>
          {dataItem[index] && new BigNumber(dataItem[index]).toFormat()}
        </div>
      </div>
    )
  })

  const getCorrectNumber = (val: number) => {
    const formattedNumber = new BigNumber(val).toFormat(8);
    const correctNumber = new BigNumber(parseFloat(formattedNumber)).toFormat()
    return correctNumber;
  }

  return (
    <TransactInfoWrap palette="primary" last={last}>
      <div className="sum">
        <h3 className="title">{`${data.allTitle} ${value}`}</h3>
        {getCorrectNumber(sum)}
      </div>
      <div className="info">
        {all}
      </div>
    </TransactInfoWrap>
  )
}

export default TransactInfo;

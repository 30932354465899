import React, { useState } from "react";
import { useTranslator } from "eo-locale";
import Loader from "react-loader-spinner";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { CLoader } from "../CLoader/index";
import BigNumber from "bignumber.js";
import dayjs from "dayjs";
import { PlotData, TransactDataItem, TranscatChartData, UsersChartData, UsersDataItem } from "./type";
import { Indicators, Period } from "../../organism/TransactItem/type";
import { PlotWrap } from "./style";

// type guard
const isUsersDataItem = (item: UsersDataItem | TransactDataItem): item is UsersDataItem => {
  return 'count' in item;
}

const getIndicatorsSum = (item: TransactDataItem) => {
  return {
    commis: new BigNumber(Number(item.input.commission) + Number(item.output.commission) + Number(item.internal.commission)).dp(8).toNumber(),
    transact: new BigNumber(Number(item.input.amount) + Number(item.output.amount) + Number(item.internal.amount)).dp(8).toNumber(),
    count: new BigNumber(Number(item.input.count) + Number(item.output.count) + Number(item.internal.count)).dp(8).toNumber(),
  };
};

export interface IPlotProps {
  period: Period;
  indicator: Indicators | 'registration';
  chartData?: TranscatChartData | UsersChartData;
  fromDate: number;
  toDate: number;
  loading: boolean,
}

const Plot = ({
  period,
  indicator,
  chartData,
  fromDate,
  toDate,
  loading
}: IPlotProps) => {
  const [plotDataFull, setPlotDataFull] = useState<PlotData[] | []>([]);
  const translator = useTranslator();
  const getCorrectName = React.useCallback((index: number) => {
    switch(period) {
      case 'Week': return translator.translate(`plotDay${index}`)
      case 'Month': return index
      case 'Year': return translator.translate(`plotMounth${index}`)
      default: return index
    }
  },[period, translator]);

  const getFullPlotData = React.useCallback((chartData: TranscatChartData | UsersChartData, period: Period | 'Change') => {
    const {data, type} = chartData;
    const START_OF_YEAR_AND_WEEK = 0;
    const END_OF_YEAR = 11;
    const END_OF_WEEK = 6;

    const START_OF_MONTH = 1;
    const END_OF_MONTH = dayjs().endOf('month').date();

    const END_OF_CHANGE = dayjs(toDate).date();
    const START_OF_CHANGE = dayjs(fromDate).date();

    let plotData: (PlotData & {sortIndex: number} )[] = []
    let end: number;
    let start: number;
    // установка начального периода
    if(period === 'Month') start = START_OF_MONTH;
    else if (period === 'change' || period === 'Change') start = START_OF_CHANGE
    else start = START_OF_YEAR_AND_WEEK;

    // установка конечного периода
    if (period === 'Week') end = END_OF_WEEK;
    else if (period === 'Month') end = END_OF_MONTH;
    else if (period === 'Year') end = END_OF_YEAR;
    else end = END_OF_CHANGE;

    // заполняем данные графика
    for (let i = start; i <= end; i++) {
      const name = getCorrectName(i);
      // начало воскресенья - 0, но на графике воскресенье должно быть в конце
      // отсюда вместо 0 ставим для воскресенья 7 для дальнейшей сортировки
      const sortIndex = i === 0 && period === 'Week' ? 7 : i;

      const targetDataItem = (data as (TransactDataItem | UsersDataItem)[]).find(item => {
        if (period === 'Week') return dayjs(item.fromDate ?? item.toDate).day() === i
        else if (period === 'Month') return dayjs(item.fromDate ?? item.toDate).date() === i;
        else if (period === 'Year') return dayjs(item.fromDate ?? item.toDate).get('month') === i;
        else return dayjs(item.fromDate ?? item.toDate).date() === i;
      })

      if (targetDataItem) {
        if (isUsersDataItem(targetDataItem)) {
          plotData.push({
            name,
            sortIndex,
            registration: targetDataItem.count,
          })
        } else {
          plotData.push({
            name,
            sortIndex,
            ...getIndicatorsSum(targetDataItem)
          })
        }
      } else {
        plotData.push(type === 'TRANSACT'
        ? {name, count: 0, transact: 0, commis: 0, sortIndex}
        : {name, registration: 0, sortIndex})
      }
    }
    plotData.sort((a,b) => a.sortIndex - b.sortIndex);
    plotData.map((item: PlotData & {sortIndex?: number}) => {
      delete item.sortIndex
      return item
    })
    return plotData as PlotData[];
  },[fromDate, getCorrectName, toDate]);

  // Установка данных в стейт на основе приходящих данных
  React.useEffect(() => {
    if (chartData?.data) {
      setPlotDataFull(getFullPlotData(chartData, period));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData?.data, period, fromDate, toDate])

  return (
    <PlotWrap palette="primary">
      <CLoader styles={{ background: "transparent" }} trigger={loading}>
        <Loader
          visible={true}
          type="Oval"
          width={50}
          height={50}
          color="white"
        />
      </CLoader>
      {!loading && (
        <ResponsiveContainer
          width="100%"
          height="100%"
          className="chart-wrapper"
        >
          <LineChart
            data={plotDataFull}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis orientation="right" />
            <Tooltip />
            <Line
              type="monotone"
              dataKey={indicator}
              stroke="#ffffff"
              activeDot={{ r: 8 }}
            />
          </LineChart>
        </ResponsiveContainer>
      )}
    </PlotWrap>
  );
};

export default Plot;

import { useTranslator } from "eo-locale";
import { useEffect, useState } from "react";
import { Errors, ErrorMessage } from "./type";

export const useLoginError = <Err>(errors: Errors<Err>, requestError: boolean, callback: () => void) => {
  const [errorMessage, setErrorMessage] = useState<ErrorMessage>(null);
  const translator = useTranslator();

  useEffect(() => {
    if (!errors) return;
    const errorNames = Object.keys(errors);
    const isErrorFromForm = errorNames.length !== 0;
    if (isErrorFromForm) {
      setErrorMessage({
        name: errorNames,
        message: translator.translate('loginRequiredError')
      })
    }

    if (requestError) {
      setErrorMessage({
        name: 'requestError',
        message: translator.translate('loginRequestError')
      })
      callback();
    }
  }, [callback, errors, requestError, translator])

  const IsErrorName = (name: string) => {
    if (Array.isArray(errorMessage?.name)) {
      return errorMessage?.name.some((errorName) => errorName === name);
    } else {
      return errorMessage?.name === "requestError";
    }
  };

  return {IsErrorName, errorMessage, setErrorMessage};
}
import { gql } from "apollo-boost";

export const TRANSACT_ALL = gql`
    {
        transactionsCommonInfo {
            symbol
            input {
                amount
                count
                commission
            }
            output {
                amount
                count
                commission
            }
            internal {
                amount
                count
                commission
            }
        }
    }
`
import styled from "styled-components";
import {palette} from "styled-theme";
import { IWrap } from "../UsersPage";

export const Wrap = styled.div<IWrap>`
  padding: 123px 33px 33px;
  display: flex;
  width: 100%;
  background-color: ${palette({primary: 3}, 1)};
`

export const Container = styled.div`
  padding: 0 33px;
  width: 100%;
  overflow: auto;
`

import React from "react";
import {StyledCheckbox} from './styled';

interface ICheckbox {
  selected: boolean,
  width: number,
  height: number
  onClick?: (ev?: React.MouseEvent<HTMLDivElement>) => void
}

export interface IStyledCheckbox {
  palette: string,
  active: boolean,
  width: number,
  height: number
}

const Checkbox: React.FC<ICheckbox> = (props) => {
  return(
    <StyledCheckbox
      width={props.width}
      height={props.height}
      onClick={props.onClick}
      active={props.selected}
      palette="primary"
    />
  )
}

export default Checkbox;

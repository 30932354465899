import styled, { css } from "styled-components";
import { IButtonProps } from ".";

const buttonBack = () => css`
  width: 30px;
  height: 30px;
  margin: 0 16px 0 0;
  border: 1px solid #b5b5b5;
  border-radius: 5px;
  &:hover {
    background: #757474;
  }
`;

const buttonLight = () => css`
  color: black;
  justify-content: center;
  padding: 15px 0px;
  border-radius: 3px;
  background: #ffffff;
  min-width: 130px;
  &:hover {
    background: #e6e6e6;
  }
`;

const buttonTransparent = () => css`
  justify-content: space-between;
  position: absolute;
  left: 33px;
  top: 68px;
  min-width: 100px;
  padding: 0;
  font-size: 24px;
`;

const StyledButton = styled.button<IButtonProps>`
  display: flex;
  align-items: center;
  border: none;
  color: white;
  background: transparent;
  cursor: pointer;
  font: normal normal 16px/1.2 "Roboto Medium", sans-serif;
  ${({ styledType }) => {
    switch (styledType) {
      case "LIGHT":
        return buttonLight();
      case "TRANSPARENT":
        return buttonTransparent();
      case "BACK": 
        return buttonBack();
    }
  }}
  ${({ disabled }) =>
    disabled &&
    css`
      color: #636363;
      cursor: not-allowed;
    `}
  ${({ styles }) => styles}
`;

export { StyledButton };

import {gql} from "apollo-boost";

export const TRANSACTIONS_ALL = gql`
query getBotTraderReports($skip: Int, $limit: Int, $dateMin: Timestamp, $dateMax: Timestamp, $types: [OperationTypeEnum!]) {
  getBotTraderReports(input: { skip: $skip, limit: $limit, dateMin: $dateMin, dateMax: $dateMax, types: $types }) {
    total
    list {
      txId
      type
      date
      debSymbol
      debAmount
      credSymbol
      credAmount
      comissionAmount
      comissionSymbol
      balances {
        bot {
          symbol
          value
        }
        burse {
          symbol
          value
        }
        sum
      }
    }
  }
}
`

import styled from "styled-components";

export const StyledForgotPassPage = styled.main`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;

  & .forgot-chev {
    margin-right: 10px;
  }
`;

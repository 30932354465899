import gql from 'graphql-tag';

export const USERS_DETAIL = gql`
  query getUsersDetail($device: Device!, $period: Period!, $from: Float, $to: Float, $country: String){
    getUsersDetail(query:{device: $device, period: $period ,from: $from, to: $to, country: $country}) {
      count
      chart{
        toDate
        count
      }
      countries{
        fullName
        name
        count
      }
    }
  }
`

import React, {useCallback, useEffect, useRef} from "react";
import {
  UsersList,
  LoaderContainer
} from './styled';
import SupportUserItem from "../../atoms/SupportUserItem";
import {useLoadChats} from "./useLoadChats";
import {useHistory, useParams} from "react-router-dom";
import supportChatsStore from "../../../store/SupportChats";
import {observer} from "mobx-react-lite";
import Loader from "react-loader-spinner";

type ParamsType = {
  id: string | undefined
}

const CSupportUsersList: React.FC = observer(() => {
  const {data, loading, refetch} = useLoadChats(supportChatsStore.chatsPage, supportChatsStore.chatsLimit);
  const observer = useRef<IntersectionObserver>(null);
  const history = useHistory();
  const params: ParamsType = useParams();

  useEffect(() => {
    if(supportChatsStore.isRefetch){
      refetch();
    }
  }, [refetch, supportChatsStore.isRefetch])

  useEffect(() => {
    if(loading){
      supportChatsStore.isChatsLoading = true;
    }
  }, [loading]);

  useEffect(() => {
    if(data){
      supportChatsStore.addChats(data);
    }
  }, [data]);

  const lastItem = useCallback((node) => {
    if(loading || supportChatsStore.isChatsLoading) return;
    if(observer.current) observer.current.disconnect();

    // @ts-ignore
    observer.current = new IntersectionObserver(entries => {
      if(entries[0].isIntersecting && supportChatsStore.chatsHasMore){
        supportChatsStore.chatsPage = supportChatsStore.chatsPage + 1;
      }
    })

    if(node) observer.current.observe(node);
  }, [loading]);

  const onChatClick = (id: number) => () => {
    if(params.id){
      supportChatsStore.saveScrollPosition(params.id);
    }
    history.push(`/support/chats/${id}`);
  }

  return(
    <UsersList>
      {
        supportChatsStore.getChats().map((chat, index) => (
          <li
            style={{width: '100%'}}
            ref={index === supportChatsStore.getChats().length - 1 ? lastItem : null}
            key={`support_user_${chat.id}`}
            onClick={onChatClick(chat.id)}
          >
            <SupportUserItem
              username={chat.userName}
              topMessageDate={chat.topMessageDate}
              unreadCount={chat.unreadCount}
              selected={params.id === chat.id.toString()}
            />
          </li>
        ))
      }

      <LoaderContainer>
        {loading &&
          <Loader
              type="Oval"
              color="#fff"
          />
        }
      </LoaderContainer>
    </UsersList>
  )
})

export default CSupportUsersList;

import React, { useState } from "react";
import { CSubTitle } from "../../atoms/CSubTitle";
import Table, { TableHeadType } from "../../atoms/Table";
import { Wrapper } from "../../atoms/Wrapper";
import { useComissRequest } from "./useComissRequest";
import CButton from "../../atoms/CButton";
import { Svg } from "../../atoms/Svg";
import { ComissBtnStyles, StyledComissPage } from "./style";
import { CustomModalWithdrawal } from "../../molecules/CustomModalWithdrawal";
import { CLoader } from "../../atoms/CLoader";
import Loader from "react-loader-spinner";
import { useTranslator } from "eo-locale";

type TableType = { id: string; symbol: string; amount: string };
type InfoDataItem = { symbol: string; amount: string };
export const CustomComissPage: React.FC = ({children}) => {
  const translator = useTranslator();
  const [symbol, setSymbol] = useState("");
  const [amount, setAmount] = useState("")
  const [isModalShow, setModalShow] = useState(false);
  const [isBtnDisabled, setBtnDisabled] = useState(true);
  const { infoData, getHistoryData, loading } = useComissRequest(symbol);

  const headData: Array<TableHeadType<TableType>> = [
    { text: translator.translate('comisTableMainHead1'), align: "left", dataKey: "symbol" },
    { text: translator.translate('comisTableMainHead2'), align: "right", dataKey: "amount" },
  ];

  const bodyData: Array<TableType> = infoData?.map((item: InfoDataItem) => ({
    id: item?.symbol,
    symbol: item?.symbol,
    amount: item?.amount,
  }));

  const onRowClick = (item: TableType) => {
    setAmount(item.amount);
    setSymbol(item.id);
    setBtnDisabled(false);
  };

  const widthdrawalHandler = () => {
    if (!symbol || isBtnDisabled) return;
    setModalShow(true);
  };

  const historyHandler = () => {
    if (!symbol || isBtnDisabled) return;
    getHistoryData({ variables: { symbol } });
  };

  return (
    <StyledComissPage>
      {loading && (
        <CLoader trigger={loading} styles={{ background: "transparent" }}>
          <Loader type="Oval" color="#ffffff" height={80} width={80} />
        </CLoader>
      )}
      <CSubTitle styles={{
        "margin": "0", 
        "margin-bottom": "10px",
        "font-family": "Roboto Bold,sans-serif",
        "font-size": "24px"
      }}>
        {translator.translate('mainTabComis')}
      </CSubTitle>
      <CustomModalWithdrawal
        isShow={isModalShow}
        onHideModal={() => setModalShow(false)}
        symbol={symbol}
        amount={amount}
      />
      <Wrapper
        styles={{
          "background-color": "#25282b",
          "min-height": `${bodyData ? "auto" : "425px"}`
        }}
      >
        {bodyData && (
          <Table
            selectedId={symbol}
            clickable={true}
            onRowClick={onRowClick}
            headData={headData}
            data={bodyData}
          />
        )}
      </Wrapper>
      <Wrapper styles={{ "margin-top": "10px" }}>
        <CButton
          styles={{
            ...ComissBtnStyles,
            "border": `1px solid ${isBtnDisabled ? "#5d5d5d" : "white"}`,
            "&:hover": {
              "background": `${isBtnDisabled ? 'transparent' : 'rgba(255, 255, 255, 0.3)'}`
            }
          }}
          type="submit"
          onClick={historyHandler}
          isDisabled={isBtnDisabled}
        >
          {translator.translate('comisTableMainBtn1')}
        </CButton>
        <CButton
          type="submit"
          styles={{
            "min-width": "190px",
            "margin-left": "20px",
            ...ComissBtnStyles,
            "border": `1px solid ${isBtnDisabled ? "#5d5d5d" : "white"}`,
            "&:hover": {
              "background": `${isBtnDisabled ? 'transparent' : 'rgba(255, 255, 255, 0.3)'}`
            }
          }}
          isDisabled={isBtnDisabled}
          onClick={widthdrawalHandler}
        >
          <Svg
            iconId="icReport"
            className="comiss-report"
            color={isBtnDisabled ? "#636363" : "white"}
          />
          {translator.translate('comisTableMainBtn2')}
        </CButton>
      </Wrapper>
    </StyledComissPage>
  );
};

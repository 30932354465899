import {gql} from "apollo-boost";

export const USERS_ALL_VERIF = gql`
  query getUsersListVerified($first: Int, $skip: Int, $email: String){
    getUsersList(filter:{hasKycDocuments: PENDING, first: $first, skip: $skip, email: $email}) {
    count,
    totalCount,
    users{
      _id
      email
      eosName
      firstName
      lastName
      phone
      country{
        name
        fullName
      }
      registeredAt
      restrictions{
        internal
        output
      }
      withdrawAmount{
        level
      }
    }
  }
  }
`

import styled from "styled-components";

export const Title = styled.div`
  margin-bottom: 17px;
  font-size: 14px;
  line-height: 16px;
`

export const Table = styled.div`
  min-height: 105px;
`

import {useState} from "react";
import {useSubscription} from "@apollo/react-hooks";
import {SUBSCRIBTION_UPDATE_MESSAGE} from "./queries";

export const useUpdateMessageSub = () => {
  const [updatedMessage, setUpdatedMessage] = useState();

  const { loading } = useSubscription(SUBSCRIBTION_UPDATE_MESSAGE, {
    onSubscriptionData: (options) => {
      let data = options.subscriptionData.data.updateMessage;
      setUpdatedMessage(data);
    }
  });

  return {updatedMessage, loading};
}

import React from "react";
import { useTranslator } from "eo-locale";
import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  usePDF,
  View,
} from "@react-pdf/renderer";
import dayjs from "dayjs";
import BigNumber from "bignumber.js";
import source from "../../../assets/font/Roboto-Medium/Roboto-Medium.woff";
import { IChartData, ITransactData } from "../ModalTransactionsReport";
import {Link} from "../../atoms/Link/Link";
import { CLoader } from "../../atoms/CLoader";
import Loader from "react-loader-spinner";

Font.register({
  family: "Roboto",
  src: source,
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    fontFamily: "Roboto",
  },
  title: {
    padding: 10,
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 24,
    width: "100%",
  },
  table0: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    fontSize: 18,
  },
  table1: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    fontSize: 14,
  },
  tableCell0: {
    width: "50%",
    textAlign: "center",
    padding: 10,
    border: "1pt solid #000",
  },
  tableCell1: {
    width: "33%",
    textAlign: "center",
    padding: 10,
    border: "1pt solid #000",
  },
  tableCell2: {
    width: "25%",
    textAlign: "center",
    padding: 10,
    border: "1pt solid #000",
  },
  titleText: {
    fontWeight: "bold",
  },
  viewAll: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    width: "100%",
    marginTop: 5,
  },
  allBlock: {
    paddingLeft: 5,
    paddingRight: 5,
    marginTop: 15,
    fontSize: 12,
  },
});

type Parts = "commis" | "transact" | "count";

interface ITransPdfReportButton {
  chartData?: IChartData['getChartData'];
  transactionsData?: ITransactData['transactionsCommonInfo'][0];
  coin: string;
  parts?: Parts[];
}

const TransactPdfDoc = (
  coin: string, parts: Parts[], 
  chartData?: IChartData['getChartData'], 
  transCommonData?: ITransactData['transactionsCommonInfo'][0]
  ) => {
  const translator = useTranslator();
  const defaultParts = [
    {name: 'count', locale: "transactItemRadio1"}, 
    {name: 'transact', locale: "transactItemRadio2"}, 
    {name: 'commis', locale: "transactItemRadio3"}];

  const getPdfTableCellStyle = (parts?: string[]) => {
    if (parts?.length === 1) return styles.tableCell0;
    if (parts?.length === 2) return styles.tableCell1
    return styles?.tableCell2
  }

  const getPdfHeader = () => (
    <View style={parts?.length === 1 ? styles.table0 : styles.table1}>
      <Text style={getPdfTableCellStyle(parts)}> {translator.translate("reportTransactLabel")}</Text>
      {parts?.map((item, index) => {
        const targetPart = defaultParts.find(part => part.name === item);
        if (targetPart) return (
          <Text key={index} style={getPdfTableCellStyle(parts)}> {translator.translate(targetPart.locale)}</Text>
        )
      })}
    </View>
  );

  const getBodyPdf = () => {
    if (!transCommonData || !chartData) return "";

    return chartData.map((item, index) => {
      const normalDate = dayjs(item.fromDate).format("DD.MM.YYYY");
      if (index === chartData.length - 1 && transCommonData) {
        return (
        <View key={`pdfTable${index}`}>
          <View style={parts.length === 1 ? styles.table0 : styles.table1}>
            <Text style={getPdfTableCellStyle(parts)}>
              {normalDate}
            </Text>
            {parts.includes("count") && (
              <Text style={getPdfTableCellStyle(parts)}>
                {new BigNumber(
                  Number(item.input.count) + 
                  Number(item.output.count) + 
                  Number(item.internal.count)).toFormat()}
              </Text>
            )}
            {parts.includes("transact") && (
              <Text style={getPdfTableCellStyle(parts)}>
                {new BigNumber(
                  Number(item.input.amount) +
                  Number(item.output.amount) +
                  Number(item.internal.amount)).dp(8).toNumber()
                }
              </Text>
            )}
            {parts.includes("commis") && (
              <Text style={getPdfTableCellStyle(parts)}>
                {new BigNumber(
                  Number(item.input.commission) +
                  Number(item.output.commission) +
                  Number(item.internal.commission)
                ).dp(8).toNumber()}
              </Text>
            )}
          </View>
          {parts.includes("count") && (
            <View style={styles.allBlock}>
              <View>
                <Text style={styles.titleText}>{translator.translate("reportCountSum")}</Text>
                <Text>
                  {new BigNumber(
                    Number(transCommonData.input.count) +
                    Number(transCommonData.output.count) +
                    Number(transCommonData.internal.count)
                  ).dp(8).toNumber()}
                </Text>
              </View>
              <View style={styles.viewAll}>
                <View>
                  <Text>{translator.translate("reportCountEntry")}</Text>
                  <Text>{new BigNumber(transCommonData.input.count).toFormat()}</Text>
                </View>
                <View>
                  <Text>{translator.translate("reportCountOut")}</Text>
                  <Text>{new BigNumber(transCommonData.output.count).toFormat()}</Text>
                </View>
                <View>
                  <Text>{translator.translate("reportCountInside")}</Text>
                  <Text>{new BigNumber(transCommonData.internal.count).toFormat()}</Text>
                </View>
              </View>
            </View>
          )}
          {parts.includes("transact") && (
            <View style={styles.allBlock}>
              <View>
                <Text style={styles.titleText}>{translator.translate("reportTransactSum")}</Text>
                <Text>
                  {new BigNumber(
                    Number(transCommonData.input.amount) +
                    Number(transCommonData.output.amount) +
                    Number(transCommonData.internal.amount)
                  ).dp(8).toNumber()}
                </Text>
              </View>
              <View style={styles.viewAll}>
                <View>
                  <Text>{translator.translate("reportTransactEntry")}</Text>
                  <Text>{new BigNumber(transCommonData.input.amount).toFormat()}</Text>
                </View>
                <View>
                  <Text>{translator.translate("reportTransactOut")}</Text>
                  <Text>{new BigNumber(transCommonData.output.amount).toFormat()}</Text>
                </View>
                <View>
                  <Text>{translator.translate("reportTransactInside")}</Text>
                  <Text>{new BigNumber(transCommonData.internal.amount).toFormat()}</Text>
                </View>
              </View>
            </View>
          )}
          {parts.includes("commis") && (
            <View style={styles.allBlock}>
              <View>
                <Text style={styles.titleText}>
                  {translator.translate("reportComissSum")}
                </Text>
                <Text>
                  {new BigNumber(
                    Number(transCommonData.input.commission) +
                    Number(transCommonData.output.commission) +
                    Number(transCommonData.internal.commission)
                  ).dp(8).toNumber()}
                </Text>
              </View>
              <View style={styles.viewAll}>
                <View>
                  <Text>{translator.translate("reportComissEntry")}</Text>
                  <Text>{new BigNumber(transCommonData.input.commission).toFormat()}</Text>
                </View>
                <View>
                  <Text>{translator.translate("reportComissOut")}</Text>
                  <Text>{new BigNumber(transCommonData.output.commission).toFormat()}</Text>
                </View>
                <View>
                  <Text>{translator.translate("reportComissInside")}</Text>
                  <Text>{new BigNumber(transCommonData.internal.commission).toFormat()}</Text>
                </View>
              </View>
            </View>
          )}
        </View>    
        );
      } else {
        return (
          <View
            key={`pdfTable${index}`}
            style={parts.length === 1 ? styles.table0 : styles.table1}
          >
            <Text style={getPdfTableCellStyle(parts)}>{normalDate}</Text>
            {parts.includes("count") && (
              <Text style={getPdfTableCellStyle(parts)}>
                {new BigNumber(
                  Number(item.input.count) +
                  Number(item.output.count) +
                  Number(item.internal.count)
                ).dp(8).toNumber()}
              </Text>
            )}
            {parts.includes("transact") && (
              <Text style={getPdfTableCellStyle(parts)}>
                {new BigNumber(
                  Number(item.input.amount) +
                  Number(item.output.amount) +
                  Number(item.internal.amount)
                ).dp(8).toNumber()}
              </Text>
            )}
            {parts.includes("commis") && (
              <Text style={getPdfTableCellStyle(parts)}>
                {new BigNumber(
                  Number(item.input.commission) +
                  Number(item.output.commission) +
                  Number(item.internal.commission)
                ).dp(8).toNumber()}
              </Text>
            )}
          </View>
        );
      }
    })
  }

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.title}>
          <Text>
            {translator.translate("reportTransactHeader", { value: coin })}
          </Text>
        </View>
        {getPdfHeader()}
        {getBodyPdf()}
      </Page>
    </Document>
  );
};

const TransPdfReportButton: React.FC<ITransPdfReportButton> = ({
  chartData,
  transactionsData,
  coin,
  parts = [],
}) => {
  const [isDownload, setDownload] = React.useState(false);
  const linkRef = React.useRef<HTMLAnchorElement>(null);
  const translator = useTranslator();
  const [instance, updateInstance] = usePDF({ document: TransactPdfDoc(coin, parts, chartData, transactionsData) });

  // скачивание pdf при клике на кнопку
  React.useEffect(() => {
    if (isDownload && !instance.loading) {
      setDownload(false);
      linkRef.current?.click();
    }
  }, [instance.loading, isDownload])

  const onDownloadPdf = () => {
    updateInstance()
    setDownload(true)
  }

  return (
    <>
      <CLoader styles={{background: "transparent"}} trigger={instance.loading}>
        <Loader color="white" visible={true} type="Oval" width={50} height={50} />
      </CLoader>
      <a 
        style={{display: "none"}}
        ref={linkRef}
        href={instance.url!} 
        download={`${coin}_report.pdf`}>
      </a>
      <Link 
        styled={{"margin": "0px 0px 15px", "&:hover": {"background": "#F8EFE5"}}} 
        styledType="DOWNLOAD"
        onClick={onDownloadPdf}>
        {translator.translate("modalReportBtn2")}
      </Link>
    </>
  );
};

export default TransPdfReportButton;

import { gql } from "apollo-boost";

export const CREATE_PASS = gql`
  mutation recoverUserPassword(
    $code: Float!
    $username: String!
    $password: String!
  ) {
    recoverUserPassword(
      code: $code
      userCredentialsDto: { username: $username, password: $password }
    )
  }
`;

import React, { FC, useEffect, useState } from "react";
import { StyledLoader } from "./style";
interface ILoaderProps {
  trigger?: boolean;
  styles?: { [key: string]: string };
  timer?: number;
}

export const CLoader: FC<ILoaderProps> = ({
  children,
  trigger,
  timer = 500,
  styles,
}) => {
  const [isDisplay, setDisplay] = useState(Boolean(trigger));
  const [isVisible, setVisible] = useState(false);
  useEffect(() => {
    let timerId: number;
    if (trigger) {
      setDisplay(true);
      timerId = setTimeout(() => setVisible(true), 0);
    } else {
      setVisible(false);
      timerId = setTimeout(() => setDisplay(false), timer + 500);
    }
    return () => clearTimeout(timerId)
  }, [timer, trigger]);

  return (
    <StyledLoader
      isDisplay={isDisplay}
      isVisible={isVisible}
      timer={timer}
      styles={styles}
    >
      {children}
    </StyledLoader>
  );
};

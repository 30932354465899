import {gql} from "apollo-boost";

export const BALANCE = gql`
{
  getBotTraderBalances {
    bot {
      symbol
      value
    }
    burse {
      symbol
      value
    }
    botUsd
    burseUsd
  }
}
`

export const BALANCE_PROFIT = gql`
  {
    getBotTraderProfit {
      profit {
        symbol
        value
      }
      profitUsd
    }
  }  
`

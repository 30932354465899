import { useQuery } from "@apollo/react-hooks";
import { TRANSACT_ALL, TRANSACT_DATE_PERIOD } from "./queries";
import { ChartData, TransactRequestParams, TransCommonInfo } from "./type";
import  dayjs  from 'dayjs';

export const useTransactItemRequest = (params: TransactRequestParams) => {
  const {symbol, fromDate, toDate} = params;
  // приходит change, а в запросе нужен Change
  const period = params.period[0].toUpperCase() + params.period.slice(1);
  
  const { data: chartData, loading: chartDataLoading } = useQuery<ChartData>(
    TRANSACT_DATE_PERIOD,
    {
      variables: {
        symbol,
        fromDate: fromDate,
        toDate: period === 'Change' ? dayjs(toDate).add(1, "day").valueOf() : toDate, 
        period,    
      }
    }
  );
  
  const { data: dataAll, loading: transCommonInfoLoading } = useQuery<TransCommonInfo>(TRANSACT_ALL, {
    variables: {
      symbol,
      fromDate: fromDate, 
      toDate: toDate,
    }
  });


  return {
    chartData: chartData?.getChartData,
    transCommonInfo: dataAll?.transactionsCommonInfo,
    chartDataLoading,
    transCommonInfoLoading
  }
}


import React from "react";
import {
  UserItem,
  Title,
  LastDate,
  UnreadMessages
} from './styled';
import dayjs from "dayjs";

export interface IUserItem {
  selected?: boolean,
}

interface ISupportUserItem extends IUserItem{
  username?: string,
  topMessageDate?: number
  unreadCount?: number
}

const SupportUserItem: React.FC<ISupportUserItem> = (
  {
    selected = false,
    username = '',
    topMessageDate,
    unreadCount
  }
) => {
  return(
    <UserItem
      selected={selected}
    >
      <Title>
        {unreadCount ? (
          <UnreadMessages>
            {unreadCount}
          </UnreadMessages>
        ): null}

        {username}
      </Title>

      {topMessageDate ? (
        <LastDate>
          {dayjs.unix(topMessageDate).format("DD.MM.YYYY")}
        </LastDate>
      ): null}
    </UserItem>
  )
}

export default SupportUserItem;

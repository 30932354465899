import React from "react";
import file_img from "../../../assets/img/file.png";
import down_img from "../../../assets/img/down.png";
import {Arrow, Text, Icon, File} from './styled'

interface IFileElement {
  title: string,
  onClick: () => void;
}

const FileElement: React.FC<IFileElement> = (props) => {
  return(
    <File onClick={() => props.onClick()}>
      <Icon src={file_img} />

      <Text>{props.title}</Text>

      <Arrow src={down_img} />
    </File>
  )
}

export default FileElement;

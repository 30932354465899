import styled from "styled-components";
import { IModalUpdatePassProps } from ".";

const StyledModalUpdatePass = styled.div<IModalUpdatePassProps>`
  position: absolute;
  background: #25282b;
  border-radius: 3px;
  box-shadow: 2px 10px 17px 0px rgb(0 0 0 / 20%);
  ${({ styles }) => styles}
`;

export { StyledModalUpdatePass };

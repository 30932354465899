import styled from "styled-components";
import { IAlertProps } from ".";

export const StyledCAlert = styled.span<IAlertProps>`
  font: normal normal 14px/1.2 "Roboto Light", sans-serif;
  color: ${({ styledType }) =>
    styledType === "ERROR" ? "#ab3c3c" : "#1e941e"};
  display: block;
  ${({ styles }) => styles}
`;

import {gql} from "apollo-boost";

export const GET_LOTS = gql`
  query getLots($skip: Float, $first: Float, $name: String, $status: LotStatus){
    getLots(pagination:{first: $first, skip: $skip}, filter:{name: $name, status: $status}){
      pagination{
        total
        previous
      }
      lots{
        _id
        createdBy{
          _id
        }
        name
        amount
        status
        createdAt
      }
    }
  }
`

import gql from 'graphql-tag'

export const GET_COUNT = gql`
  {
    getUsersCount{
      total
      active
      devices{
        Desktop{
          total
          countries{
            name
            count
            fullName
          }
        }
        Android{
          total
          countries{
            name
            count
            fullName
          }
        }
        iOS{
          total
          countries{
            name
            count
            fullName
          }
        }
      }
    }
  }
`

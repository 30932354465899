import { useTranslator } from "eo-locale";
import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { useHistory, useLocation } from "react-router-dom";
import { useTimer } from "react-timer-hook";
import { useFormControl } from "../../../hooks/useFormControl";
import { CAlert } from "../../atoms/CAlert";
import CButton from "../../atoms/CButton";
import { CForm } from "../../atoms/CForm";
import { CInput } from "../../atoms/CInput";
import { CLoader } from "../../atoms/CLoader";
import { CSubTitle } from "../../atoms/CSubTitle";
import { CTitle } from "../../atoms/CTitle";
import { Svg } from "../../atoms/Svg";
import { Wrapper } from "../../atoms/Wrapper";
import { getCleanInputList } from "../CSettingPage/helpers";
import {
  getBtnName,
  getDefaultValues,
  getInputList,
  getTimeDelay,
  isCurrentStepCheckCode,
  isCurrentStepUpdatePass,
  isPasswordsMatch,
} from "./helpers";
import { StyledForgotPassPage } from "./style";
import { ForgotPassFormData, LocationState } from "./type";
import { useForgotError } from "./useForgotError";
import { useForgotRequest } from "./useForgotRequest";

export const ForgotPassPage: React.FC = (props) => {
  const [resendBtnDisabled, setResendBtnDisabled] = useState(true);
  const history = useHistory<LocationState>();
  const locationState = useLocation<LocationState>().state;
  const translator = useTranslator();
  const defaultValues = getDefaultValues(locationState.currentStep);
  const locales = {
    checkCodeInput: translator.translate("checkCodeInput"),
    recoveryPassInputEmail: translator.translate("recoveryPassInputEmail"),
    updatePassInputPass: translator.translate("updatePassInputPass"),
    updatePassInputPassConfirm: translator.translate(
      "updatePassInputPassConfirm"
    ),
    recoveryPassBtn: translator.translate("recoveryPassBtn"),
    checkCodeBtn: translator.translate("checkCodeBtn"),
    updatePassBtn: translator.translate("updatePassBtn"),
    updatePassError: translator.translate("updatePassError"),
    errorMessageRequiredField: translator.translate("errorMessageRequiredField"),
  };

  // Управление формой
  const {
    control,
    errors,
    onSubmit,
    reset,
    clearErrors,
    formData,
    getValues,
  } = useFormControl<ForgotPassFormData>(
    {
      defaultValues: defaultValues,
      reValidateMode: "onSubmit",
    },
    onSubmitHandler
  );

  // Обработка запросов
  const { requestHandler, loading, requestError } = useForgotRequest(formData);

  // Обработка ошибок
  const {
    errorMessage,
    setErrorMessage,
    IsErrorName,
  } = useForgotError<ForgotPassFormData>(errors, requestError, reset);
  const { seconds, minutes, restart, isRunning } = useTimer({
    expiryTimestamp: getTimeDelay(32),
    onExpire: () => setResendBtnDisabled(false),
  });

  function onSubmitHandler(formData: ForgotPassFormData) {
    const { submit } = requestHandler;
    if (isCurrentStepUpdatePass(formData)) {
      const { password, passwordConfirmation } = formData;
      const { username, code } = locationState;
      if (isPasswordsMatch(password, passwordConfirmation)) {
        submit({
          variables: {
            username,
            password,
            code,
          },
        });
      } else {
        setErrorMessage({
          message: locales.updatePassError,
          name: ["password", "passwordConfirmation"],
        });
        reset(getCleanInputList(getValues()));
      }

      reset(getCleanInputList(getValues()));
    } else if (isCurrentStepCheckCode(formData)) {
      submit({
        variables: {
          username: locationState.username,
          code: parseFloat(formData.code.trim()),
        },
      });
      reset(getCleanInputList(getValues()));
    } else {
      submit({ variables: { ...formData } });
    }
  }

  const resendHandler = () => {
    if (resendBtnDisabled) return;
    const { reCreateCode } = requestHandler;
    const { username } = locationState;
    reset(getCleanInputList(getValues()));
    clearErrors();
    setResendBtnDisabled(true);
    restart(getTimeDelay(30));
    reCreateCode?.({ variables: { username } });
  };

  const onChangeHandler = () => {
    clearErrors();
    setErrorMessage(null);
  };

  useEffect(() => {
    if (locationState.currentStep === 'CHECK_CODE') {
      restart(getTimeDelay(30));
      setResendBtnDisabled(true);
    }
  }, [locationState.currentStep])

  return (
    <StyledForgotPassPage>
      <Wrapper
        styles={{
          "justify-content": "center",
        }}
      >
        <CLoader trigger={loading}>
          <Loader color={"white"} type="Oval" width={100} height={100} />
        </CLoader>
        <CButton
          type="button"
          styledType="TRANSPARENT"
          onClick={() => {
            history.goBack()
            
          }}
          styles={{ width: "20px", height: "20px" }}
        >
          <Svg iconId="icChev" color="white" className="forgot-chev" />
          {translator.translate("back")}
        </CButton>

        <Wrapper
          styles={{
            "flex-flow": "column nowrap",
            "max-width": "290px",
          }}
        >
          <CTitle styles={{ margin: "0 0 15px 0" }}>
            {translator.translate("title")}
          </CTitle>
          <CForm onSubmit={onSubmit}>
            <CSubTitle styles={{ margin: "0 0 10px 0" }}>
              {translator.translate("forgotPassSubtitle")}
            </CSubTitle>
            {getInputList(locales, locationState.currentStep).map(
              (inputData, index) => (
                <CInput
                  key={index}
                  control={control}
                  isError={IsErrorName(inputData.name)}
                  sendInputData={onChangeHandler}
                  defaultValue=""
                  {...inputData}
                />
              )
            )}
            {locationState.currentStep === "CHECK_CODE" && (
              <Wrapper
                styles={{
                  "display": "flex",
                  "margin-bottom": "10px",
                  "padding": "0 3px",
                }}
              >
                <div>
                  <span>{minutes}</span>:<span>{seconds}</span>
                </div>
                <CButton
                  type="button"
                  onClick={resendHandler}
                  isDisabled={resendBtnDisabled}
                  styles={{
                    "margin-left": "auto",
                    "margin-bottom": "5px",
                    "font-family": "Roboto Light",
                    "font-size": "14px",
                    "&:hover": {
                      "color": `${resendBtnDisabled ? '#636363': "#bbbbbb"}`,
                    },
                  }}
                >
                  {translator.translate("checkCodeResendBtn")}
                </CButton>
              </Wrapper>
            )}
            {errorMessage && (
              <CAlert
                styledType="ERROR"
                styles={{ "margin-bottom": "10px", "padding-left": "5px" }}
              >
                {errorMessage.message}
              </CAlert>
            )}
            <CButton type="submit" styledType="LIGHT">
              {getBtnName(locationState.currentStep, locales)}
            </CButton>
          </CForm>
        </Wrapper>
      </Wrapper>
    </StyledForgotPassPage>
  );
};

import {makeAutoObservable, toJS} from "mobx";
import {AllChatsType, SupportChatType} from "../../components/molecules/CSupportUsersList/useLoadChats";
import {MessagesByIdType, SupportMessageType} from "../../components/molecules/CSupportMessagesList/useLoadMessages";

export type MediaType = {
  fileId: number,
  filename: string,
  thumbId: number,
  mimeType: string
}

export type MessageType = {
  id: number,
  date: number,
  chatId: number,
  fromId: number
  message: string
  readed: boolean,
  deleted: boolean,
  media: MediaType,
  toId: number
}

export type ChatType = {
  id: number,
  userId: number,
  userName: string,
  topMessage: number,
  topMessageDate: number,
  newMessages: number,
  botStatus: boolean,
  messages: Array<MessageType>,
  messagesPage: number
  messagesHasMore: boolean,
  unreadCount: number,
  isMessagesLoading: boolean
}

class supportChatsStore {
  readChats: Array<ChatType> = [];
  unreadChats: Array<ChatType> = [];
  chatsHasMore: boolean = true;
  chatsPage: number = 0;
  chatsLimit: number = 20;
  isChatsLoading: boolean = false;
  isRefetch: boolean = false;
  currentScrollPosition: number = 0;
  scrollPositions: {[key: string]: number} = {}

  constructor() {
    makeAutoObservable(this)
  }

  addChats(data: AllChatsType) {
    if(!data) return;
    if(this.getChatById(data.getSupportChats.chats[0]._id) && !this.isRefetch) return;

    let newReadChats: Array<ChatType> = this.isRefetch ? [] : [...this.readChats];
    let newUnreadChats: Array<ChatType> = this.isRefetch ? []: [...this.unreadChats];

    data.getSupportChats.chats.forEach(chat => {
      const message: ChatType = {
        id: chat._id,
        topMessage: chat.topMessage,
        topMessageDate: chat.topMessageDate,
        userId: chat.usersData[1]?.userId,
        userName: chat.usersData[1]?.username,
        newMessages: 0,
        botStatus: false,
        messages: [],
        messagesPage: 0,
        messagesHasMore: true,
        unreadCount: chat.unreadedCount,
        isMessagesLoading: false
      }

      if(chat.unreadedCount){
        newUnreadChats.push(message);
      } else {
        newReadChats.push(message)
      }
    })

    this.chatsHasMore = data.getSupportChats.pagination.next;
    this.readChats = newReadChats;
    this.unreadChats = newUnreadChats;
    this.isChatsLoading = false;
    this.isRefetch && (this.isRefetch = false);
  }

  getChats() {
    return [
      ...this.unreadChats,
      ...this.readChats
    ]
  }

  getChatById(id: number) {
    return this.getChats().find(el => el.id === id)
  }

  addMessagesToChat(id: number, data: MessagesByIdType){
    const chat = this.getChatById(id);

    if(chat && data && data.getSupportMessage.messages.length) {
      let newMessages = [...chat.messages];
      if(chat.id !== data.getSupportMessage.messages[0].chatId) return;

      data.getSupportMessage?.messages.forEach(message => {
        if(!chat.messages.find(el => el.id === message._id)){
          newMessages.push({
            id: message._id,
            chatId: message.chatId,
            date: message.date,
            deleted: message.deleted,
            message: message.message,
            fromId: message.fromId,
            readed: message.readed,
            toId: message.toId,
            media: message.media
          })
        }
      });

      chat.messagesHasMore = data.getSupportMessage.pagination.next;
      chat.messages = newMessages;
      chat.isMessagesLoading = false;
    }
  }

  addNewMessage(id: number, messages: Array<SupportMessageType>){
    if(!id) return;

    let chat = this.getChats().find(el => el.id === id);
    if(!chat){
      this.isRefetch = true;
      this.chatsPage = 0;
      return;
    }
    if(chat.messages.length === 0){
      return;
    }

    let newMessages = [...chat.messages];
    for(let i = 0; i < messages.length; i++){
      newMessages.splice(i, 0, {
        id: messages[i]._id,
        chatId: messages[i].chatId,
        message: messages[i].message,
        date: messages[i].date,
        readed: messages[i].readed,
        deleted: messages[i].deleted,
        toId: messages[i].toId,
        fromId: messages[i].fromId,
        media: messages[i].media
      })
    }
    chat.messages = newMessages;
  }

  readMessages(chatId: number, messageId: number) {
    const chat = this.getChatById(chatId);

    if(chat){
      let newMessages = [...chat.messages];

      newMessages.forEach(mes => {
        if(mes.id <= messageId){
          mes.readed = true;
        }
      });

      chat.messages = newMessages;
    }
  }

  updateChat(chatId: number, data: SupportChatType) {
    if(!chatId || !data) return;
    let chat = this.getChatById(chatId);

    if(!chat) {
      this.isRefetch = true;
      return;
    }

    let index = this.unreadChats.indexOf(chat);
    if(chat.unreadCount && data.unreadedCount){
      this.unreadChats[index] = {
        ...this.unreadChats[index],
        unreadCount: data.unreadedCount,
        topMessageDate: data.topMessageDate,
        topMessage: data.topMessage,
        botStatus: data.botAnswers
      };
      return;
    }

    let newReadChats = [...this.readChats];
    let newUnreadChats = [...this.unreadChats];
    let newChat = {
      ...chat,
      unreadCount: data.unreadedCount,
      topMessageDate: data.topMessageDate,
      topMessage: data.topMessage,
      botStatus: data.botAnswers
    }

    index = this.readChats.indexOf(chat);

    if(data.unreadedCount === chat.unreadCount){
      this.readChats[index] = newChat;
      return;
    }

    if(data.unreadedCount) {
      newReadChats.splice(index, 1);
      newUnreadChats.splice(0, 0, newChat);
    } else {
      newUnreadChats.splice(index, 1);
      if(index >= 0){
        newReadChats.splice(index, 1, newChat);
      } else {
        newReadChats.splice(0, 0, newChat);
      }
    }

    this.readChats = newReadChats;
    this.unreadChats = newUnreadChats;
  }

  updateMessage(message: SupportMessageType) {
    let chat = this.getChatById(message.chatId);
    if(!chat) return;

    let newMessages = [...chat.messages];
    let targetMessage = newMessages.find(el => el.id === message._id);

    if(targetMessage) {
      targetMessage = {
        date: message.date,
        deleted: message.deleted,
        readed: message.readed,
        ...targetMessage
      }
    }

    chat.messages = newMessages;
  }

  setBotStatus(id: number, status: boolean) {
    const chat = this.getChatById(id);
    if(!chat) return;

    chat.botStatus = status;
  }

  setScrollPosition(position: number) {
    this.currentScrollPosition = position;
  }

  saveScrollPosition(id: string) {
    this.scrollPositions[id] = this.currentScrollPosition;
  }
}

export default new supportChatsStore()

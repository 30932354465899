import React from "react";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { LoginPage } from "../LoginPage/index";
import { ForgotPassPage } from "../ForgotPassPage/index";

export const AuthPage: React.FC = () => {
  let { path } = useRouteMatch();
  const currentStep = useLocation<{ currentStep?: string }>().state
    ?.currentStep;
  return (
    <>
      <Switch>
        <Route exact path={`${path}`} component={LoginPage} />
        {currentStep && (
          <Route exact path="/forgot-pass" component={ForgotPassPage} />
        )}
        <Route path="/">
          <Redirect to={`${path}`} />
        </Route>
      </Switch>
    </>
  );
};

import styled from "styled-components";

export const Header = styled.h2`
  font-size: 24px;
  margin: 0 0 24px;
  font-weight: normal;
  font-family: "Roboto Bold", sans-serif;
  display: flex;
  align-items: center;
`

export const UsersInfoStyled = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: 24px;
  margin-top: 0;
`

export const UsersInfoItem = styled.li`
  font-size: 18px;
  margin: 0 0 12px;
  font-weight: normal;
`

export const TableBackground = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 66px;
  min-height: 225px;
  background-color: rgb(37, 40, 43);
  font-size: 14px;
  border-spacing: 0;
  border-radius: 3px;
  margin-bottom: 33px;
  overflow: hidden;
`

export const Controls = styled.div`
  display: flex;
`

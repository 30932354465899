const locales = [
	{
		language: 'ru',
		messages: {
			title: 'Tezro Admin',
			back: 'Назад',
			ErorrMessageAuth: 'Неверный логин или пароль',
			authSubtitle: 'Авторизация',
			authSubtitleRecovery: 'Восстановление пароля',
			authInputLogin: 'Введите логин',
			authInputPass: 'Введите пароль',
			authInputEmail: 'Введите E-mail',
			authInputCode: 'Введите полученный код',
			authForgot: 'Забыли пароль?',
			authForgotReturn: 'Отправить код повторно',
			authBtn: 'Вход',

      loginSubtitle: "Авторизация",
      loginRequestError: "Неверный логин или пароль",
      loginInputLogin: "Введите логин",
      loginInputPass: "Введите пароль",
      loginButtonText: "Вход",
      loginForgotText: "Забыли пароль?",
      loginRequiredError: 'Одно из полей не заполнено',

      recoveryPassBtn: "Отправить код",
      recoveryPassInputEmail: "Введите E-mail",
      recoveryPassReuestError: 'Логин не найден',
      forgotPassSubtitle: "Восстановление пароля",

      checkCodeResendBtn: "Отправить код повторно",
      checkCodeBtn: "Проверить код",
      checkCodeInput: "Введите полученный код",
      checkCodeRequestError: "Код введен неверно",

      updatePassInputPass: "Введите новый пароль",
      updatePassInputPassConfirm: "Повторите новый пароль",
      updatePassBtn: "Сменить пароль",
      updatePassRequestError: 'Ошибка запроса',
      updatePassError: 'Пароли не совпадают',

			authBtnRecovery: 'Отправить код',
			authBtnContinue: 'Продолжить',
			authBtnEnd: 'Войти',

      errorMessageNotCorrectOldPass: "Неверно введен пароль",
      errorMessagePassNotMatch: "Пароли не совпадают",
      errorMessageRequiredField: "Поле не заполнено",
      errorMessageMin6Symbols: "Длина меньше 6 символов",
			modalPassTitle: 'Смена пароля',
			modalPassInputOld: 'Введите старый пароль',
			modalPassInputNew: 'Введите новый пароль',
			modalPassInputReturn: 'Повторите новый пароль',
			modalPassBtn: 'Сменить пароль',
			notificRecavery: 'Смена пароля успешна',

			plugMenuTitle1: 'Администратор',
			plugMenuTitle2: 'Модератор',
			plugMenuPass: 'Сменить пароль',
			plugMenuExit: 'Выйти',

			mainTabTransact: 'Транзакции {value}',
			mainTabComis: 'Комиссия',
			mainTabProfile: 'Пользователи',
			mainTabAuctions: 'Аукционы',
			mainTabSetting: 'Настройки',
			TransactTableHead1: 'Валюта',
			TransactTableHead2: 'Ввод / Кол-во',
			TransactTableHead3: 'Вывод / Кол-во',
			TransactTableHead4: 'Внутренние переводы / Кол-во',
			TransactTableHead5: 'Комиссия за ввод',
			TransactTableHead6: 'Комиссия за вывод',
			TransactTableHead7: 'Комиссия за трансфер',
			btnReport: 'Создать отчет',
			btnReportActive: 'Выгрузить отчёт',
			periodTitle: 'Период отчетности',
			periodTitleDate1: 'Начало периода',
			periodTitleDate2: 'Конец периода',
			periodWeek: 'За текущую неделю',
			periodMonth: 'За последний месяц',
			periodYear: 'За последний год',
			periodChange: 'Задать период(в рамках месяца)',
			modalChangeQuestionTitle1: 'Название',
			modalChangeQuestionTitle2: 'Категория',
			modalChangeQuestionTitle3: 'Ответ',
			modalChangeQuestionSelectPlaceholder: 'Выберите категорию',
			modalChangeQuestionSave: 'Сохранить',
			modalChangeQuestionCancel: 'Отмена',
			modalChangeQuestionChange: 'Изменить',
			modalChangeQuestionDelete: 'Удалить',
			reportCountSum: 'Суммарное кол-во транзакций',
			reportCountEntry: 'Кол-во ввода средств',
			reportCountOut: 'Кол-во вывода средств',
			reportCountInside: 'Кол-во внутренних транзакций',
			reportTransactButton: 'Выгрузить отчет',
			reportTransactSum: 'Суммарный объем средств',
			reportTransactSumPng: "Средства",
			reportTransactEntry: 'Объем введенных средств',
			reportTransactOut: 'Объем выведенных средств',
			reportTransactInside: 'Объем внутренних транзакций',
			reportComissSum: 'Суммарный объем комиссии',
			reportComissSumPng: "Комиссия",
			reportComissEntry: 'Объем комиссии за ввод',
			reportComissOut: 'Объем комиссии за вывод',
			reportComissInside: 'Объем комиссии за внутренние операции',
			plotMounth0: 'Янв',
			plotMounth1: 'Фев',
			plotMounth2: 'Мар',
			plotMounth3: 'Апр',
			plotMounth4: 'Май',
			plotMounth5: 'Июн',
			plotMounth6: 'Июл',
			plotMounth7: 'Авг',
			plotMounth8: 'Сен',
			plotMounth9: 'Окт',
			plotMounth10: 'Ноя',
			plotMounth11: 'Дек',
			plotDay0: 'Вс',
			plotDay1: 'Пн',
			plotDay2: 'Вт',
			plotDay3: 'Ср',
			plotDay4: 'Чт',
			plotDay5: 'Пт',
			plotDay6: 'Сб',
			transactItemRadio1: 'Количество транзакций',
			transactItemRadio2: 'Объем средств',
			transactItemRadio3: 'Комиссия',
			transactInfoAll: 'Суммарное кол-во транзакций',
			transactInfoEntry: 'Кол-во ввода средств',
			transactInfoOut: 'Кол-во вывода средств',
			transactInfoInside: 'Кол-во внутренних транзакций',
			modalReportTitle: 'Создать отчет',
			modalReportPeriod: 'Период',
			modalReportValueTitle: 'Валюты',
			modalReportBtn1: 'Отчет в Excel',
			modalReportBtn2: 'Отчет в PDF',
			modalReportBtn3: 'Отчет в PNG ',
			reportDownloadFiller1: 'Скачать отчет excel',
			reportDownloadFiller2: 'Скачать отчет pdf',
			reportTransactHeader: '{value} report',
			reportTransactLabel: 'Дата',
			comisTableMainHead1: 'Валюта',
			comisTableMainHead2: 'Доступный объем комиссии',
			comisTableMaiAcc: '№ счета: {number}',
			comisTableMainWall: 'Адрес кошелька: {number}',
			comisTableMainBtn1: 'История',
			comisTableMainBtn2: 'Вывести средства',
			comisHistoryTitle: 'История операций {value}',
			comisHistoryTableHead1: 'Операция',
			comisHistoryTableHead2: 'Кому',
			comisHistoryTableHead3: 'От кого',
			comisHistoryTableHead4: 'Сумма',
			comisHistoryTableHead5: 'Дата и время',
			comisHistoryTableHead6: 'Доп. информация',
			comisHistoryTableBody1: 'Вывод средств',
			comisHistoryTableBody2: 'Начисление средств',
			settingTableHead1: 'Валюта',
			settingTableHead2: 'Комиссия на внутренние операции',
			settingTableHead3: 'Комиссия вывода',
			settingTableHead4: 'Минимальная сумма перевода',
			settingTableHead5: 'Минимальная сумма вывода',
			settingBtn: 'Сохранить',
			settingError: "Одно из полей заполнено неправильно",
			settingErrorRequest: "Ошибка запроса",
			modalWithdrawalTitle: 'Вывод средств',
			modalWithdrawalSubTitle: 'Данные для вывода',
			modalWithdrawalPlace1: 'Адрес кошелька',
			modalWithdrawalPlace2: 'Сумма вывода',
			modalWithdrawalOut: 'Вывод средств',
			modalWithdrawalOutCard: 'Вывод на карту',
			modalWithdrawalOutAcc: 'Вывод на счет',
			modalWithdrawalErrorRequired: 'Поле не заполнено',
			modalWithdrawalErrorRequest: 'Ошибка запроса',
			modalWithdrawalErrorIncorrectInputData: "Неправильно заполнено одно из полей",
			modalWithdrawalErrorInsufficientFunds: "Недостаточно средств",

			usersTitle: 'Пользователи {value}',
			usersAll: 'Всего: {value}',
			usersActive: 'Активные пользователи: {value}',
			usersTableHead1: 'Платформа',
			usersTableHead2: 'Зарегистрировано пользователей',
			usersTableBodyWeb: 'Desktop/Web',
			usersTableBodyAndroid: 'Android',
			usersTableBodyIOS: 'iOS',
			usersReportDescr: 'Платформа',
			usersDetailItemAll: 'Всего пользователей',
			usersDetailItemRegistr: 'Зарегистрировано пользователей',
			usersDetailItemInc: 'Процент прироста пользователей',
			usersDetailCountryTableHead1: 'Страна',
			usersDetailCountryTableHead2: 'Всего пользователей',
			usersDetailCountryTableHead3: 'Зарегистрировано',
			usersAllBtn: 'Полный список пользователей',
			usersAllTitle: 'Список пользователей',
			usersAllLabel: 'Поиск по e-mail',
			usersAllPlace: 'Введите E-mail',
			usersAllList0: 'ID',
			usersAllList1: 'E-mail',
			usersAllList2: 'Имя',
			usersAllList3: 'Фамилия',
			usersAllList4: 'EOS name',
			usersAllList5: 'Верификация',
			usersAllList6: 'Дата регистрации',
			usersInfoTitle: 'Пользователь {value}',
			usersInfoStatus: 'Статус:',
			usersInfoStatusErrorNot: 'Не заблокирован',
			usersInfoStatusErrorIntern: 'Заблокированы транзакции',
			usersInfoStatusErrorOut: 'Заблокирован вывод',
			usersSearchReset: 'Сбросить поиск',
			usersInfoLegend0: 'ID:',
			usersInfoLegend1: 'EOS Name:',
			usersInfoLegend2: 'Email:',
			usersInfoLegend3: 'Имя:',
			usersInfoLegend4: 'Фамилия:',
			usersInfoLegend5: 'Страна:',
			usersInfoLegend6: 'Псевдоним:',
			usersInfoLegend7: 'Дата регистрации:',
			usersInfoLegend8: 'Последний раз в сети:',
			usersInfoLegend9: 'Вывод средств пользователя: ',
			usersInfoLegend10: 'Транзакции пользователя: ',
			usersInfoBtnOut: 'Заблокировать вывод средств пользователя',
			usersInfoBtnTrans: 'Заблокировать транзакции пользователя',
			usersInfoBtnOutUn: 'Разблокировать вывод средств пользователя',
			usersInfoBtnTransUn: 'Разблокировать транзакции пользователя',
			usersInfoBalance: 'Баланс:',
			usersInfoTransactTitle: 'Транзакции',
			usersInfoTransactTable0: 'Кому',
			usersInfoTransactTable1: 'TRX ID',
			usersInfoTransactTable2: 'Количество',
			usersInfoTransactTable3: 'Memo',
			usersInfoTransactTable4: 'Номер блока',
			usersInfoTransactTable5: 'Дата создания',
			countryAll: 'Все страны',
			countryDescr: 'Страны',
			usersVerificationBtn: 'Верификация личности',
			usersVerificationTitle: 'Подтверждение личности',
			usersVerificationReason: 'Причина: {value}',
			usersVerificationLevel: 'Уровень верификации:',
			usersVerificationChangeLevel: 'Изменить уровень верификации',
			usersVerificationDoc: 'Документы',
			userVerificationPassportDocs: 'Фото паспорта',
			userVerificationPassportSelfieDocs: 'Селфи с паспортом',
			userVerificationIncomesDocs: 'Справки о доходах',
			userVerificationAdditionalDocs: 'Прочие фото',
			usersVerificationProhib: 'Запретить повторную верификацию',
			usersVerificationChangeStatus: 'Изменить статус',
			usersVerificationStatus: 'Статус: {value}',
			usersVerificationStatus_empty: 'Не верифицирован',
			usersVerificationStatus_pending: 'Ожидает подтверждения',
			usersVerificationStatus_verified: 'Подтвержден',
			usersVerificationStatus_rejected: 'Отклонен',
			usersVerificationReason_file_empty: 'Пустой файл',
			usersVerificationReason_file_corrupted: 'Файл поврежден',
			usersVerificationReason_document_duplicate: 'Дубликат документа',
			usersVerificationReason_document_unreadable: 'Документ нечитаем',
			usersVerificationReason_selfie_doesnt_match_photo:
				'На фото не видно лица',
			usersAllTableRequestError: 'Перезагрузите страницу',
			ModalChangeStatusVerifTitle: 'Подтверждение личности {username}',
			ModalChangeStatusVerifSelectTitle: "Уровень верификации",
			ModalChangeStatusUserDocument: 'Смена статуса {value}',
			ModalSelectStatusTitle: 'Статус',
			ModalSelectReasonTitle: 'Причина отклонения',
			ModalChangeStatusVerifyStatus: 'Cтатус',
			ModalChangeStatusVerifySave: 'Сохранить',
			ModalChangeStatusVerifyCancel: 'Отмена',
			verifAllTitle: 'Верификация личности {value}',
			statusVerify0: 'Ожидает проверки',
			statusVerify1: 'Подтверждён',
			statusVerify2: 'Отклонён',
			statusVerifyDefault: 'Выберите статус',
			reasonRefusal0: 'Неверные документы',
			reasonRefusal1: 'Часть информации скрыта',
			reasonRefusal2: 'Попытка подделки документа',
			reasonRefusal3: 'Документы недействительны',
			reasonRefusal4: 'Документы не соответствуют требованию',
			reasonRefusalDefault: 'Выберите причину',
			reasonRefusalProhib: 'Запретить повторную верификацию',
			auctionsTitle: 'Аукционы {value}',
			auctionsInfoTitle: 'Аукцион {value}',
			auctionsTableHead0: 'Модерация',
			auctionsTableHead1: 'ID лота',
			auctionsTableHead2: 'ID автора',
			auctionsTableHead3: 'Название лота',
			auctionsTableHead4: 'Цена лота',
			auctionsTableHead5: 'Дата создания',
			AuctionsInfoLabel0: 'ID лота:',
			AuctionsInfoLabel1: 'ID автора:',
			AuctionsInfoLabel2: 'Название лота:',
			AuctionsInfoLabel3: 'Описание:',
			AuctionsInfoLabel4: 'Цена:',
			AuctionsInfoLabel5: 'Номер страховки: ',
			AuctionsInfoLabel6: 'Приватность: ',
			AuctionsInfoLabel7: 'Имя автора: ',
			AuctionsInfoLabel8: 'Email автора: ',
			AuctionsModerationTitle: 'Модерация',
			AuctionsModerationStatus: 'Статус: ',
			AuctionsModerationReason: 'Причина: ',
			AuctionsModerationPhoto: 'Фотографии',
			AuctionsModerationPercentToSell: 'Доля лота, подлежащая дистрибуции: ',
			AuctionsModerationСertificate: 'Сертификаты',
			AuctionsModerationDocument: 'Документы',
			AuctionsModerationInfurence: 'Страховка',
			AuctionsModerationChange: 'Изменить статус',
			AuctionsRefusal0: 'Неверные документы',
			AuctionsRefusal1: 'Часть информации скрыта',
			AuctionsRefusal2: 'Попытка подделки документа',
			AuctionsRefusal3: 'Документы недействительны',
			AuctionsRefusal4: 'Документы не соответствуют требованию',
			AuctionsRefusal5: 'Другая причина (ввести)',
			AuctionsRefusal6: 'Номер страховки не найден',
			AuctionsRefusal7: 'Страховка не действительна',
			AuctionModalTitle: 'Подтверждение лота {value}',
			AuctionSearch: 'Введите id лота или название',
			AuctionSearchLabel: 'Поиск',
			AuctionSearchReset: 'Сбросить поиск',
			AuctionInputReason: 'Введите причину',
			mainTabExchange: 'Exchange bot',
			mainTabExchangeReport: 'Сформировать отчёт',
			currentBalanceTitle: 'Текущий баланс',
			currentBalanceRow1: 'На боте',
			currentBalanceRow2: 'На бирже',
			currentBalanceRow3: 'Доходы',
			transactionTableHead1: 'Тип операции',
			transactionTableHead2: 'ID транзакции',
			transactionTableHead3: 'Дата',
			transactionTableHead4: 'TxId',
			transactionTableHead5: 'Название операции',
			transactionTableHead6: 'Отдали',
			transactionTableHead7: 'Получили',
			transactionTableHead8: 'Суммарная комиссия',
			modalExchangeTitle: 'Сформировать отчёт',
			reportButton1: 'Отчет в Excel',
			reportButton2: 'Отчет в PDF',
			reportButton3: 'Отчет в PNG',
			mainTabSupport: 'Поддержка',
			supportBotSettingsTitle: 'Настройки бота',
			supportBotSettingsStatus: 'Статус бота: ',
			supportBotSettingsUniqunes: 'Точность уникальности',
			supportBotSettingsSave: 'Сохранить',
			supportBotSettingsStatusText1: 'Активен',
			supportBotSettingsStatusText2: 'Не активен',
			supportBotSettingsRequestError: "Поле не заполнено",
			supportBotSettingsInputPlaceholder: "Введите значение",
			supportBotLoading: "Загрузка...",
			supportBotWrongFileType: "Не поддерживаемый тип файла",
			supportBotDivider: 'Непрочитанные сообщения',

			faqLink: 'FAQ',
			supportChatBotStatus: 'Статус бота:',
			supportSettingsLink: 'Настройки бота',
			messagesListTitle: 'Сообщения в тех поддержку',
			messagesDeletedMessage: 'Удалено',
			messagesListHead1: 'Имя',
			messagesListHead2: 'Последнее сообщение',
			messagesListHead3: 'Дата',
			questionsTitle: 'FAQ',
			questionsSearchPlaceholder: 'Введите вопрос',
			questionsBackButton: 'Назад',
			questionsCreateButton: 'Создать вопрос',
			questionsTableTitle: 'Категории',
			questionsTableResetButton: 'Сбросить поиск',
			categoriesTitle: 'Категории',
			categoriesCreate: 'Создать категорию',
			categoriesSearchPlaceholder: 'Введите название категории',
			categoriesTableTitle: 'Список категорий',
			categoriesDeleteButton: 'Удалить',
			categoriesModalText: 'Вы действительно хотите удалить категорию?',
			categoriesModalCheckboxText: 'Удалить связаные задачи',
			categoriesInputPlaceholder: 'Введите название категории',
			categoriesColumn1: 'ID',
			categoriesColumn2: 'Название категории',
			categoriesColumn3: 'Дата создания',
			createCategoryName: 'Название',
			exchangeTransactionsListItem1: "Обмен бота с пользователем",
			exchangeTransactionsListItem2: "Зачисление средств на бота",
			exchangeTransactionsListItem3: "Вывод средств с бота",
			exchangeTransactionsListItem4: "Обмен на бирже",
			exchangeTransactionsListItem5: "Зачисление средств на биржу",
			exchangeTransactionsListItem6: "Вывод средств с биржи",
			exchangeSumCell: "Сумма в $",
			createCategoryInputPlaceholder: 'Введите название',
			createCategoryNamePlaceholder: 'Введите название',
			confirmationYes: 'Да',
			confirmationNo: 'Нет',
			questionsCreateNameTitle: 'Название',
			questionsCreateCategoryTitle: 'Категория',
			questionsCreateCategoryPlaceholder: 'Выберите категорию',
			questionsCreateAnswerTitle: 'Ответ',
			questionsCreateButton1: 'Сохранить',
			questionsCreateButton2: 'Отмена',
			changeQuestionCategoryTitle: 'Изменить категорию',
			changeQuestionCategorySelectTitle: 'Категория',
			changeQuestionCategoryButton1: 'Сохранить',
			changeQuestionCategoryButton2: 'Отмена',
			auctionsInfoStatus: 'Статус:'
		},
	},
];
export default locales;

import {useMutation} from "react-apollo";
import {GET_BOT_STATUS, SET_BOT_STATUS} from "./queries";

export const useBotStatusMutation = () => {
  const [changeBotStatus] = useMutation(SET_BOT_STATUS);

  const setBotStatus = (chatId?: number, status?: boolean) => {
    return changeBotStatus({
      variables: {
        chatId: chatId,
        status: status
      },
      update(proxy){
        proxy.writeQuery({
          query: GET_BOT_STATUS,
          variables:  {
            chatId: chatId
          },
          data: {
            getSupportBotStatusByChatId: status
          }
        })
      }
    })
  }

  return setBotStatus;
}

import styled from "styled-components";
const DropDownContainer = styled.div<{ isRotate: boolean }>`
  position: relative;
  & .dropdown-chev {
    width: 10px;
    height: 10px;
    transform: ${({ isRotate }) =>
      isRotate ? "rotate(90deg)" : "rotate(-90deg)"};
  }
`;
const DropDownHeader = styled.div`
  align-items: center;
  display: flex;
  min-width: 150px;
  font-weight: 500;
  font-size: 1.3rem;
  background-color: transparent;
  cursor: pointer;
  user-select: none;
`;

const DropDownList = styled.ul<{isOpen: boolean}>`
  display: ${({isOpen}) => isOpen ? 'block' : 'none'};
  z-index: 999;
  background-color: #464749;
  position: absolute;
  padding: 22px 19px 8px 22px;
  margin: 20px 0 0 0;
  right: -12px;
  left: 0;
  box-shadow: 4px 4px 8px 0px rgb(0 0 0 / 40%);
`;

const ListItem = styled.li`
  list-style: none;
  margin-bottom: 0.8em;
  user-select: none;
`;

export { ListItem, DropDownList, DropDownHeader, DropDownContainer };

import {gql} from "apollo-boost";

export const GLOBAL_SETTINGS = gql`
{
  getGlobalSupportBotParams{
    supportBotStatus,
    requiredMessageUniqeness
  }
}
`

export const SET_GLOBAL_SETTINGS = gql`
  mutation setGlobalSupportBotParams($status: Boolean, $uniquenes: Float){
    setGlobalSupportBotParams(input: { supportBotStatus: $status, requiredMessageUniqeness: $uniquenes })
  }
`
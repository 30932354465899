import React, { useEffect, useState } from "react";
import Title from "../../atoms/Title";
import { useTranslator } from "eo-locale";
import { InputWrapper } from "./styled";
import Select, { SelectItemType } from "../../atoms/Select";
import { useFormControl } from "./../../../hooks/useFormControl";
import { CInput } from "../../atoms/CInput";
import { CForm } from "../../atoms/CForm";
import { Text } from "../../atoms/Text";
import CButton from "../../atoms/CButton";
import { isSomeInputFilled } from "../../../utils/isSomeInputFilled";
import { validateInputs } from "../../../utils/validateInputs";
import { useSupportBotRequest } from "./useSupportBotRequest";
import { CLoader } from "../../atoms/CLoader";
import Loader from "react-loader-spinner";

const SupportBotSettings: React.FC = () => {
  const [isBtnDisabled, setBtnDisabled] = useState(true);
  const translator = useTranslator();
  const statusItems: Array<SelectItemType<boolean>> = [
    {
      key: true,
      value: translator.translate("supportBotSettingsStatusText1"),
    },
    {
      key: false,
      value: translator.translate("supportBotSettingsStatusText2"),
    },
  ];
  const {
    loading,
    setSettingsData,
    settingsData,
    setStatus,
    setUniqueness,
    status,
    uniqueness
  } = useSupportBotRequest();

  const { control, errors, onSubmit, reset, clearErrors, setValue, getValues } = useFormControl<{
    uniqueness: string;
  }>(
    {
      defaultValues: { uniqueness: uniqueness || '' },
      reValidateMode: "onSubmit",
    },
    onSubmitHandler
  );

  // Отправка данных из формы
  function onSubmitHandler(formData: { uniqueness: string }) {
    const {uniqueness} = formData
    setUniqueness(uniqueness)
    setSettingsData({
      variables: {
        status,
        uniquenes: Number(uniqueness),
      },
    });
  }

  // Валидация инпута
  const onInputHandler = (name: string, value: string) => {
    const regExpList = {
      startFromZero: /^0{1}$/i,
      startFromZeroWithPoint: /^0{1}\.{1}(0*|0*[1-9]*)$/i,
      startFromInteger: /^[1-9]+[0-9]*\.?(0*|0*[1-9]*)$/i,
    };

    const isValid = validateInputs(regExpList, value);
    if (isValid) {
      clearErrors(name as "uniqueness");
      setBtnDisabled(false);
    } else {
      setValue((name as "uniqueness"), value?.slice(0, value.length - 1));
      const isFilled = isSomeInputFilled(getValues());
      setBtnDisabled(!isFilled);
    }
  };

  // Устанавливает дефолтное значение инпута после того как пришли данные с сервера
  useEffect(() => {
    if (uniqueness) {
      reset({uniqueness: uniqueness})
    }
  }, [reset, settingsData, uniqueness]);

  // Изменился ли статус в select
  useEffect(() => {
    if (!status === settingsData?.getGlobalSupportBotParams.supportBotStatus) setBtnDisabled(false);
  }, [settingsData, status])

  return (
    <>
    <CLoader trigger={loading}>
      <Loader visible={true} type="Oval" color="white" width={50} height={50}/>
    </CLoader>

      <Title margin="0 0 30px 0" fSize="24px">
        {translator.translate("supportBotSettingsTitle")}
      </Title>

      <Select
        width="200px"
        title={translator.translate("supportBotSettingsStatus")}
        items={statusItems}
        selectedItem={status}
        onSelect={(item) => setStatus(item.key)}
        margin="0 0 20px 0"
      />
      <CForm onSubmit={onSubmit}>
        <InputWrapper>
          <Text styles={{
            "color": `${errors?.uniqueness ? "#ab3c3c" : "#6a7275"}`
          }}>{errors?.uniqueness ? translator.translate("supportBotSettingsRequestError") : translator.translate("supportBotSettingsUniqunes")}</Text>
          <CInput
            name="uniqueness"
            placeholder={translator.translate("supportBotSettingsInputPlaceholder")}
            type="string"
            control={control}
            sendInputData={onInputHandler}
            rules={{ 
              required: translator.translate("supportBotSettingsRequestError"),
            }}
            styles={{
              "height": "auto",
              "padding": "0",
              "max-width": "170px",
              "margin": "5px 0 0 10px",
              "border": "none"
            }}
          />
        </InputWrapper>

        <CButton
          styledType="LIGHT"
          styles={{"max-width": "204px"}}
          type="submit"
          isDisabled={isBtnDisabled}
        >
          {translator.translate("supportBotSettingsSave")}
        </CButton>
      </CForm>
    </>
  );
};

export default SupportBotSettings;

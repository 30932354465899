import styled from "styled-components";

export const MessagesList = styled.ul`
  display: flex;
  flex-direction: column-reverse;
  list-style: none;
  padding: 0;
  margin: 0;
`

//Фиксит баг хрома
export const ScrollBox = styled.div`
  overflow-y: scroll;
  display: flex;
  flex-direction: column-reverse;
  height: calc(100% - 122px);

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`

export const LoaderContainer = styled.div`
  margin: auto;
  width: 30px; 
  height: 30px;
`

export const StyledDividerContainer = styled.li`
  margin: 0 auto 0 auto;
`

export const StyledScrollDown = styled.button`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 0;
  background-color: #25282b;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  bottom: 92px;
  right: 20px;
  
  & svg{
    transform: rotate(-90deg);
  }
`

import {StyleSheet} from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    fontFamily: "Roboto",
    fontSize: 6,
    padding: 9
  },
  head: {
    display: 'flex',
    flexDirection: 'row',
  },
  headTextCell: {
    width: 41,
    padding: 1,
    border: '1 solid #000',
    borderLeft: 'none',
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  headBalanceCell: {
    width: 27,
    padding: 1,
    border: '1 solid #000',
    borderLeft: 'none',
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  bodyCell: {
    width: 41,
    padding: 1,
    borderRight: '1 solid #000',
    borderBottom: '1 solid #000',
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start"
  },
  bodyBalanceCell: {
    width: 27,
    padding: 1,
    borderRight: '1 solid #000',
    borderBottom: '1 solid #000',
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start"
  }
});

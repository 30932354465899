import React, {useState} from "react";
import MenuWithSelection from "../MenuWithSelection";
import DateSelect from "../../atoms/DateSelect";
import {Container, ResetButton, Filter, TypeButton, TypeSelectionContainer} from './styled';

export type FilterType = {
  id: string
  text: string
}

interface ITransactionsListFilters {
  activeOperations: Array<string>;
  onActiveOperationsChange: (newActive: Array<string>) => void;
  filters: Array<FilterType>;
  dateBegin: Date | undefined;
  dateEnd: Date | undefined;
  onDateBeginChange: (value: Date) => void;
  onDateEndChange: (value: Date) => void;
  onReset: () => void;
}

const Index: React.FC<ITransactionsListFilters> = (props) => {
  const [isTypeSelectionOpen, setIsTypeSelectionOpen] = useState(false);

  const onOperationsChange = (id: string) => {
    const index = props.activeOperations.indexOf(id);
    const newActive = [...props.activeOperations];

    if(index >= 0){
      newActive.splice(index, 1);
    } else {
      newActive.push(id);
    }
    props.onActiveOperationsChange(newActive);
  }

  return(
    <Container>
      <Filter>
        <DateSelect
          onValueChange={props.onDateBeginChange}
          value={props.dateBegin}
          theme="black"
          title="Начало периода"
          placeholder="ДД-ММ-ГГГ"
        />
      </Filter>

      <Filter>
        <DateSelect
          onValueChange={props.onDateEndChange}
          value={props.dateEnd}
          theme="black"
          title="Конец периода"
          placeholder="ДД-ММ-ГГГ"
        />
      </Filter>

      <ResetButton
        onClick={props.onReset}
        disabled={!props.dateEnd && !props.dateBegin}
      >
        Сбросить поиск
      </ResetButton>

      <TypeSelectionContainer>
        <TypeButton onClick={() => setIsTypeSelectionOpen(!isTypeSelectionOpen)}>
          Тип операции
        </TypeButton>
        {isTypeSelectionOpen ? (
          <MenuWithSelection
            selected={props.activeOperations}
            items={props.filters}
            onSelectionChange={onOperationsChange}
          />
        ) : null}
      </TypeSelectionContainer>
    </Container>
  )
}

export default Index;

import {StyleSheet, Font} from "@react-pdf/renderer";

Font.register({
  family: "Roboto",
  src:
    "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf"
});

export const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    fontFamily: "Roboto"
  },
  title: {
    padding: 10,
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 24,
    width: '100%',
  },
  table0: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    fontSize: 18,
  },
  table1: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    fontSize: 14,
  },
  tableCell0: {
    width: '50%',
    textAlign: 'center',
    padding: 10,
    border: '1pt solid #000',
  },
  tableCell1: {
    width: '33%',
    textAlign: 'center',
    padding: 10,
    border: '1pt solid #000',
  },
  tableCell2: {
    width: '25%',
    textAlign: 'center',
    padding: 10,
    border: '1pt solid #000',
  },
  titleText:{
    fontWeight: 'bold'
  },
  viewAll: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
    marginTop: 5,
  },
  allBlock:{
    paddingLeft: 5,
    paddingRight: 5,
    marginTop: 15,
    fontSize: 12,
  },
});

import RobotoBoldEot from './Roboto-Bold/Roboto-Bold.eot'
import RobotoBoldTtf from './Roboto-Bold/Roboto-Bold.ttf'
import RobotoBoldWoff from './Roboto-Bold/Roboto-Bold.woff'

import RobotoLightEot from './Roboto-Light/Roboto-Light.eot'
import RobotoLightTtf from './Roboto-Light/Roboto-Light.ttf'
import RobotoLightWoff from './Roboto-Light/Roboto-Light.woff'

import RobotoMediumEot from './Roboto-Medium/Roboto-Medium.eot'
import RobotoMediumTtf from './Roboto-Medium/Roboto-Medium.ttf'
import RobotoMediumWoff from './Roboto-Medium/Roboto-Medium.woff'

import RobotoRegularEot from './Roboto-Regular/Roboto-Regular.eot'
import RobotoRegularTtf from './Roboto-Regular/Roboto-Regular.ttf'
import RobotoRegularWoff from './Roboto-Regular/Roboto-Regular.woff'

export default {
  RobotoBoldEot, RobotoBoldTtf, RobotoBoldWoff, RobotoLightEot, RobotoLightTtf, RobotoLightWoff, RobotoMediumEot, RobotoMediumTtf, RobotoMediumWoff, RobotoRegularEot, RobotoRegularTtf, RobotoRegularWoff,
}

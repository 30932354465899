import styled from "styled-components";
import { palette } from "styled-theme";

export const PlotWrap = styled.div<{ palette: string }>`
  width: 100%;
  height: 360px;
  transform: translateX(-19px);
  position: relative;
  & .chart-wrapper {
    min-width: 0;
    overflow: "hidden";
  }
  & .loader {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  & .recharts-cartesian-axis-tick-value * {
    fill: ${palette({ primary: 0 }, 1)};
    font-size: 18px;
  }
  & * {
    color: ${palette({ primary: 1 }, 1)};
  }
`;
import styled from "styled-components";
import { IStatus } from "../UsersInfo";


export const StatusField = styled.div`
  font-size: 18px;
  color: #fff;
`

export const Status = styled.span<IStatus>`
  color: ${({color}) => color}
`

export const InputWrapper = styled.div`
  width: 203px;
  margin-bottom: 20px;
  background-color: transparent;
  border: 1px solid white;
  border-radius: 5px;
  height: 52px;
`
import {gql} from "apollo-boost";

export const TABLE_DATA = gql`
query getBotTraderReports($dateMin: Timestamp, $dateMax: Timestamp) {
  getBotTraderReports(input: { dateMin: $dateMin, dateMax: $dateMax }) {
    total,
    list {
      txId
      tradeRate
      type
      date
      debSymbol
      debAmount
      credSymbol
      credAmount
      comissionAmount
      comissionSymbol
      rates {
        symbol
        value
      }
      balances {
        bot {
          symbol
          value
        }
        burse {
          symbol
          value
        }
        sum
      }
    }
  }
}
`

import React, {useEffect, useState} from 'react'
import { FilesContainer, ModerationStatus, FilesItem, Moderation, ModerationStatusItem } from './styled'
import {useTranslator} from 'eo-locale'
import {useLazyQuery} from '@apollo/react-hooks'
import Loader from 'react-loader-spinner'
import Button from "../../atoms/Button";
import {useHistory, useParams} from 'react-router-dom'
import icons from "../../../assets/img/sprite.svg";
import Title from "../../atoms/Title";
import InfoList from "../../atoms/InfoList";
import {ListItemPrototypeType} from "../../atoms/InfoList";
import FileElement from "../../atoms/FileElement";
import ModalChangeLotStatus from "../../molecules/ModalChangeLotStatus";
import {GET_LOTS_BY} from "./queries";
import ModalOpenDocument from "../../molecules/ModalOpenDocument";

type ListDataType = {
  lot_id: string
  createdBy_id: string
  firstName: string
  email: string
  name: string
  description: string
  amount: string
  insuranceNo: string
  percentToSell: string
  privacy: string
}

type ModerFileType = {
  name: string,
  link: string
}

type ModerFilesType = {
  photo: Array<ModerFileType> | null,
  doc: Array<ModerFileType> | null,
  insurance: Array<ModerFileType> | null,
  certificate: Array<ModerFileType> | null
}

type RequestDataType = {
  getLotById: {
    additionalDocuments: Array<number>
    amount: string
    certificate: number
    createdBy: {
      _id: number,
      firstName: string,
      lastName: string,
      email: string,
    }
    currency: string
    description: string
    insurance: number
    insuranceNo: string
    name: string
    percentToSell: number
    photos: Array<number>
    privacy: string
    reasons: Array<string>
    status: "IN_MODERATION" | "PUBLISHED" | "NOT_PUBLISHED"
    _id: number
  }
}

const AuctionsInfo: React.FC = () => {
  const translator = useTranslator();
  const [moderFiles, setModerFiles] = useState<ModerFilesType>();
  const [listData, setListData] = useState<ListDataType>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openDocument, setOpenDocument] = useState<ModerFileType>();
  const { id } = useParams();
  const history = useHistory();

  const listInfoPrototype: Array<ListItemPrototypeType> = [
    {
      title: translator.translate('AuctionsInfoLabel0'),
      dataKey: 'lot_id'
    },
    {
      title: translator.translate('AuctionsInfoLabel1'),
      dataKey: 'createdBy_id'
    },
    {
      title: translator.translate('AuctionsInfoLabel7'),
      dataKey: 'firstName'
    },
    {
      title: translator.translate('AuctionsInfoLabel8'),
      dataKey: 'email'
    },
    {
      title: translator.translate('AuctionsInfoLabel2'),
      dataKey: 'name'
    },
    {
      title: translator.translate('AuctionsInfoLabel3'),
      dataKey: 'description'
    },
    {
      title: translator.translate('AuctionsInfoLabel4'),
      dataKey: 'amount'
    },
    {
      title: translator.translate('AuctionsModerationPercentToSell'),
      dataKey: 'percentToSell'
    },
    {
      title: translator.translate('AuctionsInfoLabel6'),
      dataKey: 'privacy'
    },
  ]

  const moderationStatuses = {
    IN_MODERATION: translator.translate('statusVerify0'),
    PUBLISHED: translator.translate('statusVerify1'),
    NOT_PUBLISHED: translator.translate('statusVerify2')
  }

  const [getLotsById, {data: dataAll, loading}] = useLazyQuery<RequestDataType>(GET_LOTS_BY, {
    onCompleted: (data) => {
      const uploadUrl = process.env.REACT_APP_API_APLOAD;
      if(uploadUrl){
        const photo = data.getLotById.photos ? data.getLotById.photos.map((item) => {
          return (
            {
              name: 'photoAuct-' + data.getLotById._id + '-' + item + '.jpeg',
              link: item.toString()
            }
          )
        }) : null;
        const insurance = data.getLotById.insurance ?
          [{
            name: 'docInsur-' + data.getLotById._id + '-' + data.getLotById.insurance + '.jpeg',
            link: data.getLotById.insurance.toString()
          }] : null;

        const certificate = data.getLotById.certificate ?
          [{
            name: 'certAuct-' + data.getLotById._id + '-' + data.getLotById.certificate + '.jpeg',
            link: data.getLotById.certificate.toString()
          }] : null;
        const doc = data.getLotById.additionalDocuments ? data.getLotById.additionalDocuments.map((item) => {
          return (
            {
              name: 'docAuct-' + data.getLotById._id + '-' + item + '.jpeg',
              link: item.toString()
            }
          )
        }) : null;
        const files: ModerFilesType = {
          photo: photo,
          doc: doc,
          insurance: insurance,
          certificate: certificate
        }
        setModerFiles(files);
      }
    }
  });

  useEffect(() => {
    let floatId = parseFloat(id);
    if(floatId){
      getLotsById({ variables: { id: floatId } });
    } else {
      history.push(`/auctions`)
    }
  }, [history, getLotsById, id]);

  useEffect(() => {
    if(dataAll){
      setListData({
        lot_id: dataAll.getLotById._id.toString() || '',
        createdBy_id: dataAll.getLotById.createdBy._id.toString() || '',
        firstName: dataAll.getLotById.createdBy.lastName || '',
        email: dataAll.getLotById.createdBy.email || '',
        name: dataAll.getLotById.name || '',
        description: dataAll.getLotById.description || '',
        amount: `${dataAll.getLotById.amount} ${dataAll.getLotById.currency}`,
        insuranceNo: dataAll.getLotById.insuranceNo,
        percentToSell: dataAll.getLotById.percentToSell.toString(),
        privacy: dataAll.getLotById.privacy
      })
    }
  }, [dataAll])

  const onBackClick = () => {
    history.push(`/auctions`)
  }

  const onModalClose = () => {
    setIsModalOpen(false);
  }

  const onStatusUpdate = () => {
    getLotsById({ variables: { id: parseFloat(id) } });
  }

  const onDocumentOpen = (item: ModerFileType) => {
    setOpenDocument(item);
  }

  const onDocumentClose = () => {
    setOpenDocument(undefined);
  }

  return (
    <>
      {(loading) ? <div className="loader"><Loader type="Oval" color="#fff" height={100} width={100}/></div> :
        <>
          <Title fSize="24px" flex margin="0 0 24px 0">
            <Button margin="0 16px 0 0" reverse onClick={onBackClick} width="30px" height="30px">
              <div className="icChev">
                <svg className="inline-svg-icon">
                  <use className="icas" xlinkHref={`${icons}#icChev`} name="debtors"/>
                </svg>
              </div>
            </Button>

            {translator.translate('auctionsInfoTitle', { value: dataAll?.getLotById.name })}
          </Title>

          <InfoList
            data={listData}
            prototype={listInfoPrototype}
          />

          <Moderation>
            <h2>Модерация</h2>

            <ModerationStatus>
              <ModerationStatusItem>
                {translator.translate('auctionsInfoStatus')} {
                  dataAll && dataAll.getLotById && moderationStatuses[dataAll.getLotById.status]
                }
              </ModerationStatusItem>

              {dataAll?.getLotById?.reasons ? (
                <ModerationStatusItem>
                  Причина: {dataAll?.getLotById?.reasons[0]}
                </ModerationStatusItem>
              ) : null}
            </ModerationStatus>
          </Moderation>

          <h3>Фотографии</h3>

          <FilesContainer>
            {moderFiles && moderFiles.photo ? moderFiles.photo.map((item, index) => (
              <FilesItem key={index}>
                <FileElement
                  onClick={() => onDocumentOpen(item)}
                  title={item.name}
                />
              </FilesItem>)) : (<div style={{margin: 10}}>-</div>)
            }
          </FilesContainer>

          <h3>Документы</h3>

          <FilesContainer>
            {moderFiles && moderFiles.doc ? moderFiles.doc.map((item, index) => (
              <FilesItem key={index}>
                <FileElement
                  onClick={() => onDocumentOpen(item)}
                  title={item.name}
                />
              </FilesItem>)) : (<div style={{margin: 10}}>-</div>)
            }
          </FilesContainer>

          {((dataAll?.getLotById?.status !== 'PUBLISHED')
            && (moderFiles?.doc || moderFiles?.certificate || moderFiles?.photo || moderFiles?.insurance)) &&
            <Button height='30px' type="button" onClick={() => setIsModalOpen(true)} margin="20px 0">
              {translator.translate('AuctionsModerationChange')}
            </Button>
          }

          <ModalChangeLotStatus
            item={dataAll?.getLotById}
            enabled={isModalOpen}
            onClose={onModalClose}
            onStatusUpdate={onStatusUpdate}
          />

          <ModalOpenDocument
            name={openDocument?.name}
            id={openDocument?.link}
            onClose={onDocumentClose}
            enabled={!!openDocument}
          />
        </>
      }
    </>
  )
};

export default AuctionsInfo

import React from 'react';
import { Styles } from '../../../commonTypes';
import { StyledLink } from './styled';

export interface ILink {
  href?: string,
  download?: string
  styledType?: "DOWNLOAD", 
  styled?: Styles
  onClick?: () => void;
}
export const Link: React.FC<ILink> = ({children, href, download, onClick, ...otherProps}) => {
  return (
    <StyledLink onClick={onClick} download={download} href={href} {...otherProps}>
      {children}
    </StyledLink>
  );
};
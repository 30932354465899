import dayjs from "dayjs";
import React, {useRef, useState} from "react";
import Calendar from "react-calendar";
import {
  Wrap,
  Select,
  Title,
  Selected,
  Menu
} from "./styled";
export interface ISelect {
  border?: string,
  width?: string,
  margin?: string
}

export interface ISelected {
  color: string
}

interface IDatePeriod{
  onValueChange: (value: Date) => void
  value: Date | undefined
  theme: "black" | "white"
  placeholder?: string
  title?: string,
  maxDate?: Date,
  minDate?: Date,
}

const DateSelect: React.FC<IDatePeriod & ISelect> = (
  {
    maxDate,
    minDate,
    width= '251px',
    margin = "0",
    onValueChange,
    border,
    value,
    theme,
    placeholder,
    title
  }
) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  }

  const theming = {
    white: [
      "#000",
      "#6A7275"
    ],
    black: [
      "#fff",
      "#b8bfc1"
    ]
  }

  const onChange = (item: Date | Date[]) => {
    if(!Array.isArray(item)) {
      onValueChange(item);
      setIsMenuOpen(false);
    }
  }


  return(
    <Wrap>
      <Select
        width={width}
        margin={margin}
        border={border || theming[theme][0]}
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        <div>
          <Title
            color={theming[theme][1]}
          >
            {title}
          </Title>
          <Selected
            color={theming[theme][0]}
          >
            {(value && value.toLocaleString("ru", options)) || placeholder}
          </Selected>
        </div>
      </Select>

      {
        isMenuOpen ? (
          <Menu
            ref={ref}
          >
            <Calendar
              selectRange={false}
              value={value}
              onChange={onChange}
              maxDate={maxDate}
              minDate={minDate}
            />
          </Menu>
        ) : null
      }
    </Wrap>
  )
}

export default DateSelect;

import { useTranslator } from "eo-locale";
import React, { useState } from "react";
import CustomButton from "../../atoms/CustomButton";
import FileElement from "../../atoms/FileElement";
import { UserDocumentType } from "../../organism/UsersInfo";
import ModalChangeDocumentStatus from "../ModalChangeDocumentStatus";
import ModalOpenDocument from "../ModalOpenDocument";
import { Card, FileContainer, FileInfoItem } from "../UserDocuments/styled";

interface IKycDocument {
    document: UserDocumentType
}
type StatusLocalizeType = {
    "VERIFIED": string,
    PENDING :string,
    REJECTED: string,
    EMPTY: string
}
type ReasonLocalizeType = {
    FILE_EMPTY: string,
    FILE_CORRUPTED: string,
    DOCUMENT_DUPLICATE: string,
    DOCUMENT_UNREADABLE: string,
    SELFIE_DOESNT_MATCH_PHOTO: string
}

const KycDocument: React.FC<IKycDocument> = ({
    document
}) => {
    const translator = useTranslator();
    const [ isChangeStatusModal, setIsChangeStatusModal ] = useState<boolean>(false);
    const [ isOpenDocument, setIsOpenDocument ] = useState<boolean>(false);
    const [ newDocument, setNewDocument ] = useState<UserDocumentType>(document);

    const statusLocalize: StatusLocalizeType = {
        VERIFIED: translator.translate('usersVerificationStatus_verified'),
        PENDING: translator.translate('usersVerificationStatus_pending'),
        REJECTED: translator.translate('usersVerificationStatus_rejected'),
        EMPTY: translator.translate('usersVerificationStatus_empty'),
    }

    const reasonLocalize: ReasonLocalizeType = {
        FILE_EMPTY: translator.translate('usersVerificationReason_file_empty'),
        FILE_CORRUPTED: translator.translate('usersVerificationReason_file_corrupted'),
        DOCUMENT_DUPLICATE: translator.translate('usersVerificationReason_document_duplicate'),
        DOCUMENT_UNREADABLE: translator.translate('usersVerificationReason_document_unreadable'),
        SELFIE_DOESNT_MATCH_PHOTO: translator.translate('usersVerificationReason_selfie_doesnt_match_photo')
    }

    const clickPhotoHandler = () => {
        setIsOpenDocument(true);
    }

    const clickChangeStatusHandler = () => {
        setIsChangeStatusModal(true);
    }
    
    return (
        <>
            <Card key={newDocument.document.file.localId}>
                <FileContainer>
                <FileElement
                    title={newDocument.document.file.name}
                    onClick={clickPhotoHandler}
                />

                <CustomButton
                    width='230pc'
                    height='30px'
                    margin="15px 0 0 0"
                    onClick={clickChangeStatusHandler}
                >
                    {translator.translate('usersVerificationChangeStatus')}
                </CustomButton>
                </FileContainer>

                <div>
                <FileInfoItem>
                    {translator.translate('usersVerificationStatus', { value: statusLocalize[newDocument.status] })}
                </FileInfoItem>

                {
                    newDocument.reasons && newDocument.reasons.map((reason, index) => (
                    <FileInfoItem key={`FileInfoItem_${index}`}>
                        {translator.translate('usersVerificationReason', { value: reasonLocalize[reason] })}
                    </FileInfoItem>
                    ))
                }

                <FileInfoItem>
                    {new Date(newDocument.createdAt).toLocaleDateString('ru')}
                </FileInfoItem>
                </div>
            </Card>

            {isChangeStatusModal && <ModalChangeDocumentStatus
                enabled
                item={newDocument}
                onChange={setNewDocument}
                onClose={setIsChangeStatusModal}
            />}

            {isOpenDocument && <ModalOpenDocument
                id={newDocument.document.file.localId.toString()}
                name={newDocument.document.file.name}
                enabled
                onClose={setIsOpenDocument}
            />}
        </>
    );

}

export default KycDocument;
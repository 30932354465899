import React, {useEffect, useState} from "react";
import {useTranslator} from "eo-locale";
import BigNumber from "bignumber.js";
import dayjs from "dayjs";
import {TransactionsListType} from "../../organism/TransactionsList";
import Table, {TableHeadType} from "../../atoms/Table";

type TableDataType = {
  id: string
  type: string,
  date: string,
  name: string,
  cred: string,
  deb: string,
  comis: string,
  txId: string
}

interface ITransactionsTable {
    data?: Array<TransactionsListType>,
}

const Index: React.FC<ITransactionsTable> = (
  {
    data
  }
) => {
  const [tableData, setTableData] = useState<Array<TableDataType>>([]);
  const translator = useTranslator();

  const headData: Array<TableHeadType<TableDataType>> = [
    {
      dataKey: "type",
      text: translator.translate('transactionTableHead1')
    },
    {
      dataKey: "date",
      text: translator.translate('transactionTableHead3')
    },
    {
      dataKey: "txId",
      text: translator.translate('transactionTableHead4')
    },
    {
      dataKey: "name",
      text: translator.translate('transactionTableHead5')
    },
    {
      dataKey: "cred",
      text: translator.translate('transactionTableHead6')
    },
    {
      dataKey: "deb",
      text: translator.translate('transactionTableHead7')
    },
    {
      dataKey: "comis",
      text: translator.translate('transactionTableHead8')
    },
  ]

  useEffect(() => {
    const OperationTypes = {
      BOT_TRADE: translator.translate('exchangeTransactionsListItem1'),
      BOT_DEPOSIT: translator.translate('exchangeTransactionsListItem2'),
      BOT_WITHDRAW: translator.translate('exchangeTransactionsListItem3'),
      BURSE_TRADE: translator.translate('exchangeTransactionsListItem4'),
      BURSE_DEPOSIT: translator.translate('exchangeTransactionsListItem5'),
      BURSE_WITHDRAW: translator.translate('exchangeTransactionsListItem6')
    }

    if(data) {
      let newTableData: Array<TableDataType> = [];

      data.forEach((item, index) => {
        newTableData.push({
          id: index.toString(),
          type: item.type,
          txId: item.txId,
          date: dayjs(item.date).format('DD.MM.YYYY'),
          name: OperationTypes[item.type],
          cred: item.credAmount && item.credSymbol ?
            (new BigNumber(item.credAmount).toFormat() + " " + item.credSymbol) : "",
          deb:item.debAmount && item.debSymbol ?
            (new BigNumber(item.debAmount).toFormat() + " " + item.debSymbol) : "",
          comis: item.comissionAmount && item.comissionSymbol ? (
            new BigNumber(item.comissionAmount).toFormat() + " " + item.comissionSymbol) : ""
        })
      })

      setTableData(newTableData);
    }
  }, [translator, data])

  return(
    <Table
      data={tableData}
      headData={headData}
    />
  )
}

export default Index;

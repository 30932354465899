import React, {useCallback, useEffect, useRef, useState} from "react";
import {
  MessagesList,
  LoaderContainer,
  ScrollBox,
  StyledDividerContainer, StyledScrollDown,
} from './styled';
import {useParams} from "react-router-dom";
import supportChatsStore, {ChatType} from "../../../store/SupportChats";
import {useLoadMessages} from "./useLoadMessages";
import {observer} from "mobx-react-lite";
import CSupportChatMessage from "../CSupportChatMessage";
import Loader from "react-loader-spinner";
import ModalOpenDocument from "../ModalOpenDocument";
import {useReadMessages} from "./useReadMessages";
import {useTranslator} from "eo-locale";
import {Svg} from "../../atoms/Svg";

export type MediaType = {
  filename: string,
  fileId: number,
  thumbId: number,
  mimeType: string
}

const CSupportMessagesList = observer(() => {
  const [media, setMedia] = useState<MediaType>();
  const {id} = useParams();
  const observer = useRef<IntersectionObserver>();
  let chat: ChatType | undefined = supportChatsStore.getChatById(Number(id));
  const {data, loading} = useLoadMessages(Number(id), chat?.messagesPage, 20);
  const { markReaded: readMessages, loading: readLoading } = useReadMessages();
  const listRef = useRef<HTMLDivElement>(null);
  const translator = useTranslator();

  useEffect(() => {
    if(chat && loading){
      chat.isMessagesLoading = true;
    }
  }, [chat, loading]);

  useEffect(() => {
    if(listRef && listRef.current){
      const scrollPosition = supportChatsStore.scrollPositions[id] || 0;
      listRef.current.scrollTop = scrollPosition
      supportChatsStore.setScrollPosition(scrollPosition);
    }
  }, [id]);

  useEffect(() => {
    if(chat && data && !loading){
      supportChatsStore.addMessagesToChat(Number(id), data);
    }
  }, [id, chat, data, loading]);

  const lastElementRef = useCallback(node => {
    if (loading || chat?.isMessagesLoading) return;
    if (observer.current) observer.current.disconnect();

    // @ts-ignore
    observer.current = new IntersectionObserver(entries => {
      if (chat && entries[0].isIntersecting && chat.messagesHasMore) {
        chat.messagesPage = chat.messagesPage + 1
      }
    });

    if (node) observer.current.observe(node);
  }, [chat, loading]);

  const onMediaDisplay = (data: MediaType) => {
    setMedia(data);
  }

  const onMediaClose = () => {
    setMedia(undefined);
  }

  const onMessagesRead = () => () => {
    if(readLoading || !chat || !chat.messages) return;
    for(let i = 0; i < chat.messages.length; i++){
      let currChat = chat.messages[i];
      if(currChat.fromId !==3 && !currChat.readed){
        readMessages(chat?.id, currChat.id);
        return;
      }
    }
  }

  let dividerIndex = -1;
  if(chat && chat.messages) {
    let index = -1;
    let messages = chat.messages;
    for(let i = 0; i < messages.length; i++){
      if(messages[i].fromId === chat.userId && !messages[i].readed){
        index = i;
      }
    }
    dividerIndex = index;
  }

  const onScroll = (event: React.UIEvent<HTMLDivElement>) => {
    supportChatsStore.setScrollPosition(event.currentTarget.scrollTop)
  }

  const onScrollDown = () => () => {
    if(listRef && listRef.current){
      listRef.current.scrollTo({top: 0});
      supportChatsStore.setScrollPosition(0);
    }
  }

  return(
    <>
      <ScrollBox
        onMouseMove={onMessagesRead()}
        ref={listRef}
        onScroll={onScroll}
      >
        <MessagesList >
          {
            chat?.messages.map((message, index) => (
              <>
                <CSupportChatMessage
                  ref={index + 1 === chat?.messages.length ? lastElementRef : null}
                  key={`support_mess_${message.id}`}
                  margin={message.fromId === chat?.userId ? '15px auto 15px 10px' : '15px 10px 15px auto'}
                  item={message}
                  onMediaDisplay={onMediaDisplay}
                />
                {
                  dividerIndex !== -1 && dividerIndex - 1 !== chat?.messages.length && dividerIndex === index ? (
                    <StyledDividerContainer key={`support_mess_divider`}>
                      {translator.translate('supportBotDivider')}
                    </StyledDividerContainer>
                  ) : null
                }
              </>
            ))
          }

          <LoaderContainer>
            {
              loading ? (
                <Loader type="Oval" color="#fff" />
              ): null
            }
          </LoaderContainer>
        </MessagesList>

        {(supportChatsStore.currentScrollPosition < -200) && (
          <StyledScrollDown onClick={onScrollDown()}>
            <Svg iconId="icChev" width="20px" height="20px" />
          </StyledScrollDown>
        )}
      </ScrollBox>

      <ModalOpenDocument
        name={media?.filename}
        id={media?.fileId.toString()}
        onClose={onMediaClose}
        enabled={!!media}
        mimeType={media?.mimeType}
      />
    </>
  )
})

export default CSupportMessagesList;

import React from "react";
import {useToasts} from "react-toast-notifications";
import {useTranslator} from "eo-locale";
import {StyledMessageContainer, StyledText} from "./styled";

export const useRequestError = () => {
  const { addToast } = useToasts();
  const translator = useTranslator();

  const showError = (
    message: string,
    dismissTimeout?: number,
    onDismiss?: (id: string) => void,
    id?: string,
  ) => {
    addToast(
      <StyledMessageContainer>
        <StyledText>{message}</StyledText>
        <StyledText>{translator.translate('usersAllTableRequestError')}</StyledText>
      </StyledMessageContainer>
      ,{
        id: id,
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: dismissTimeout || 5000,
        onDismiss: onDismiss
      });
  }

  return {showError}
}

import React from 'react'
import {Radio, Input, Checkbox, Label} from './styled';

export type RadioItemType = {
  key: string,
  title: string
}

interface IRadioItem {
  item: RadioItemType,
  selected: boolean,
  onClick?: (item: RadioItemType) => void,
  margin?: string
}

const RadioItem: React.FC<IRadioItem> = (
  {
    item,
    selected,
    onClick = () => {},
    margin = '0'
  }
) => {
  return(
    <Radio
      margin={margin}
    >
      <Label
        onClick={() => onClick(item)}
      >
        <Input type="radio"/>
        <Checkbox selected={selected} />

        {item.title}
      </Label>
    </Radio>
  )
}

export default RadioItem;

import React, { useState } from "react";
import Title from "../../atoms/Title";
import CurrentBalance from "../../organism/CurrentBalance";
import down from "../../../assets/img/arrow.svg";
import TransactionsList from "../../organism/TransactionsList";
import ModalExchangeReport from "../../molecules/ModalExchangeReport";
import { useTranslator } from "eo-locale";
import {Container, Arrow, CurrentBalanceContainer, ReportButton} from './styled'

const ExchangeBot: React.FC = () => {
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const translator = useTranslator();

  return (
    <>
      <Container>
        <Title fSize="24px">
          {translator.translate('mainTabExchange')}
        </Title>

        <CurrentBalanceContainer>
          <CurrentBalance />
        </CurrentBalanceContainer>

        <ReportButton onClick={() => setIsReportModalOpen(true)}>
          <Arrow src={down} />
          {translator.translate('mainTabExchangeReport')}
        </ReportButton>

        <TransactionsList />

        <ModalExchangeReport
          isOpen={isReportModalOpen}
          onModalClose={() => setIsReportModalOpen(false)}
        />
      </Container>
    </>
  );
};



export default ExchangeBot;

import React, {useEffect, useState} from "react";
import {useSendMessage} from "./useSendMessage";
import {useParams} from "react-router-dom";
import supportChatsStore, {ChatType} from "../../../store/SupportChats";
import {
  InputContainer,
  TextArea,
  ButtonContainer
} from './styled';
import Button from "../../atoms/Button";
import {observer} from "mobx-react-lite";

const CSupportChatInput: React.FC = observer(() => {
  const [messageText, setMessageText] = useState<string>('');
  let params: {id: string} = useParams();
  let chat: ChatType | undefined = supportChatsStore.getChatById(Number(params.id));
  const sendMessage = useSendMessage();

  const onSendMessage = () => {
    if(messageText.trim() === '') return;

    sendMessage(chat?.id, messageText);
    setMessageText('');
  }

  useEffect(() => {
    setMessageText('');
  }, [params.id]);

  return(
    <InputContainer>
      <TextArea
        onKeyDown={(ev => {
          if(ev.key === "Enter") {
            onSendMessage();
            ev.preventDefault();
          }
        })}
        onChange={(event => {
          setMessageText(event.target.value);
        })}
        value={messageText}
      />

      <ButtonContainer>
        <Button onClick={onSendMessage} height="35px" width="35px"> > </Button>
      </ButtonContainer>
    </InputContainer>
  )
})

export default CSupportChatInput;

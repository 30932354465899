import React, {useEffect, useState} from "react";
import {
  ModalBackground,
  CloseButton,
  Title,
  BlockTitle,
  DatePeriod
} from "./styled";
import Modal from "../../atoms/Modal";
import icons from "../../../assets/img/sprite.svg";
import {useTranslator} from "eo-locale";
import Select, {SelectItemType} from "../../atoms/Select";
import CheckBoxList from "../CheckBoxList";
import {CheckboxItemType} from "../../atoms/CheckBoxItem";
import DateSelect from "../../atoms/DateSelect";
import TransExcelReportButton from "../TransExcelReportButton";
import {useQuery} from "react-apollo";
import CustomLoader from "../../atoms/CustomLoader";
import TransPdfReportButton from "../TransPdfReportButton";
import TransPngReportButton from "../TransPngReportButton";
import {TRANSACT_ALL, TRANSACT_DATE_PERIOD} from "./queries";
import { Indicators, Period } from "../../organism/TransactItem/type";
import { useCalendarControl } from '../../../hooks/useCalendarControl';
import { Dayjs } from "dayjs";

export interface IChartData {
  getChartData: Array<{
    fromDate: number
    toDate: number
    input: {
      amount: string
      count: number
      commission: string
    }
    output: {
      amount: string
      count: number
      commission: string
    }
    internal: {
      amount: string
      count: number
      commission: string
    }
  }>
}

export interface ITransactData {
  transactionsCommonInfo: Array<{
    symbol: string
    input: {
      amount: string
      count: number
      commission:string
    }
    output: {
      amount: string
      count: number
      commission:string
    }
    internal: {
      amount: string
      count: number
      commission:string
    }
  }>
}

interface IModalTransactionsReport {
  enabled: boolean,
  onClose?: () => void;
  defaultPeriod?: Period;
  defaultCoin?: string;
  radio: Indicators;
  defaultFromDate?: Dayjs;
  defaultToDate?: Dayjs;
}

const ModalTransactionsReport: React.FC<IModalTransactionsReport> = (
  {
    enabled,
    defaultCoin= "",
    onClose = () => {},
    defaultPeriod = 'Week',
    defaultFromDate,
    defaultToDate,
    radio
  }
) => {

  const [period, setPeriod] = useState<string>(defaultPeriod);
  const [parts, setParts] = useState<Array<string>>([radio]);
  const [
    onToDateChange,
    onFromDateChange,
    { fromDate, toDate, minToDate, maxToDate, maxFromDate }
  ] = useCalendarControl(period as Period, defaultFromDate, defaultToDate)
  const translator = useTranslator();

  const periodItems: Array<SelectItemType<string>> = [
    {
      key: 'Week',
      value: translator.translate('periodWeek')
    },
    {
      key: 'Month',
      value: translator.translate('periodMonth')
    },
    {
      key: 'Year',
      value: translator.translate('periodYear')
    },
    {
      key: 'change',
      value: translator.translate('periodChange')
    }
  ];

  const partsItems: Array<CheckboxItemType> = [
    {
      key: "count",
      title: translator.translate('transactItemRadio1')
    },
    {
      key: "transact",
      title: translator.translate('transactItemRadio2')
    },
    {
      key: "commis",
      title: translator.translate('transactItemRadio3')
    }
  ];

  const {data: chartData, loading: chartLoad} = useQuery<IChartData>(TRANSACT_DATE_PERIOD, {
    variables: {
      symbol: defaultCoin,
      period: period === 'change' ? period[0].toUpperCase() + period.slice(1) : period,
      fromDate: period === 'change' ? fromDate.add(1, "day").valueOf() : fromDate.valueOf(), 
      toDate: period === 'change' ? toDate.add(1, "day").valueOf() : toDate.valueOf(),
    }
  });

  const {data: transactionsData, loading: transactLoad} = useQuery<ITransactData>(TRANSACT_ALL, {
    variables: {
      symbol: defaultCoin,
      fromDate: fromDate.isSame(toDate, "day") ? fromDate.subtract(1, "day").valueOf() : fromDate.valueOf(), 
      toDate: toDate.valueOf(),
    }
  });
  const coinItems: Array<CheckboxItemType> = [
    {
      key: defaultCoin,
      title: defaultCoin
    }
  ];
  const onModalClose = () => {
    onClose();
  }

  const onPeriodSelect = (item: SelectItemType<string>) => {
    setPeriod(item.key);
  }

  const onPartClick = (item: CheckboxItemType) => {
    let newParts = [...parts];

    if(!newParts.includes(item.key)){
      newParts.push(item.key);
    } else {
      newParts.splice(newParts.indexOf(item.key), 1);
    }
    setParts(newParts);
  }

  // Синхронизация данных transactPage и модалки transactReport
  useEffect(() => {
    if (radio) setParts(prev => {
      const isRadioInParts = prev.some(item => item === radio);
      if (!isRadioInParts) {
        return [radio]
      } else {
        return prev;
      }
    })

    if (defaultPeriod) setPeriod(prev => prev !== defaultPeriod ? defaultPeriod : prev);
  }, [defaultFromDate, defaultPeriod, defaultToDate, radio])
  return(
    <Modal
      enabled={enabled}
      onBackgroundClick={onModalClose}
    >
      <CustomLoader
        loading={transactLoad || chartLoad}
      />

      <ModalBackground>
        <CloseButton
          type="button"
          onClick={onModalClose}
        >
          <svg className="inline-svg-icon">
            <use className="icas" xlinkHref={`${icons}#icClose`}/>
          </svg>
        </CloseButton>

        <Title>
          {translator.translate('modalReportTitle')}
        </Title>

        <BlockTitle>
          {translator.translate('modalReportPeriod')}
        </BlockTitle>

        <Select
          title={translator.translate('periodTitle')}
          items={periodItems}
          selectedItem={period}
          onSelect={onPeriodSelect}
          margin='0px 0px 16px 0'
        />

        {
          period === 'change' && (
            <DatePeriod>
              <DateSelect
                title={translator.translate('periodTitleDate1')}
                width="160px"
                margin="0 20px 0 0"
                onValueChange={onFromDateChange}
                maxDate={maxFromDate}
                value={fromDate.toDate()}
                theme="black"
              />

              <DateSelect
                title={translator.translate('periodTitleDate2')}
                width="160px"
                onValueChange={onToDateChange}
                value={toDate.toDate()}
                theme="black"
                maxDate={maxToDate}
                minDate={minToDate}
              />
            </DatePeriod>
          )
        }

        <CheckBoxList
          items={partsItems}
          direction="column"
          onClick={onPartClick}
          selected={parts}
          margin="0px 0px 16px"
          marginItem="0px 0px 16px"
        />

        <BlockTitle>
          {translator.translate('modalReportValueTitle')}
        </BlockTitle>

        <CheckBoxList
          items={coinItems}
          margin="0px 0px 32px"
          selected={[defaultCoin]}
        />

        <TransExcelReportButton
          chartData={chartData}
          transactionsData={transactionsData}
          coin={defaultCoin}
          parts={parts}
        />

        <TransPdfReportButton
          chartData={chartData?.getChartData}
          transactionsData={transactionsData?.transactionsCommonInfo[0]}
          coin={defaultCoin}
          parts={parts as ("commis" | "transact" | "count")[]}
        />

        <TransPngReportButton
          chartData={chartData}
          transactionsData={transactionsData}
          coin={defaultCoin}
          parts={parts}
          period={period}
        />
      </ModalBackground>
    </Modal>

  )
}

export default ModalTransactionsReport;

import styled from "styled-components";
export const StyledHeader = styled.header<{ isLogin?: boolean }>`
  display: flex;
  flex-flow: row wrap;
  height: ${({ isLogin }) => (isLogin ? "90px" : "36px")};
  width: 100%;
  background: #464749;
  padding: 0 33px;
  justify-content: space-between;
  align-items: center;
  box-shadow: 2px 10px 17px 0px rgb(0 0 0 / 30%);

  @media (max-width: 440px) {
    flex-flow: column nowrap;
    justify-content: center;
  }
`;
import React from "react";
import {StyledModal} from './styled';

interface IModal {
  enabled: boolean;
  onBackgroundClick: () => void;
}

const Modal: React.FC<IModal> = ({ children, enabled, onBackgroundClick }) => {
  if (!enabled) {
    return null;
  }

  const onClickHandler = (
    ev: React.MouseEvent<HTMLDivElement, MouseEvent> & { target: HTMLElement }
  ) => {
    if (ev.target.dataset.name === "modal-background") {
      onBackgroundClick();
    }
  };

  return (
    <StyledModal onMouseDown={onClickHandler} data-name="modal-background">
      {children}
    </StyledModal>
  );
};

export default Modal;

import React, {useState} from "react";
import TransactionsTable from "../../molecules/TransactionsTable";
import TransactionsListFilters from "../../molecules/TransactionsListFilters";
import Pagination from '../../atoms/Pagination'
import {useQuery} from "@apollo/react-hooks";
import Loader from "react-loader-spinner";
import {TRANSACTIONS_ALL} from "./queries";
import {FiltersContainer} from './styled';
import {useTranslator} from "eo-locale";

type Operations = "BOT_TRADE"
  | "BOT_DEPOSIT"
  | "BOT_WITHDRAW"
  | "BURSE_TRADE"
  | "BURSE_DEPOSIT"
  | "BURSE_WITHDRAW"

export type TransactionsListType = {
  type: Operations
  date: number
  debSymbol: string
  debAmount: string
  credSymbol: string
  credAmount: string
  txId: string
  comissionAmount: string
  comissionSymbol: string
  balances: {
    bot: {
      symbol: string
      value: string
    }
    burse: {
      symbol: string
      value: string
    }
  }
}

export type TransactionsDataType = {
  getBotTraderReports: {
    total: number,
    list: Array<TransactionsListType>
  }
}

const TransactionsList: React.FC = () => {
  const [activeOperations, setActiveOperations] = useState([
    "BOT_TRADE", "BOT_DEPOSIT", "BOT_WITHDRAW",
    "BURSE_TRADE", "BURSE_DEPOSIT", "BURSE_WITHDRAW"
  ]);
  const [currentPage, setCurrentPage] = useState(0);
  const [dateBegin, setDateBegin] = useState<Date | undefined>();
  const [dateEnd, setDateEnd] = useState<Date | undefined>();
  const translator = useTranslator();
  const pageLimit = 8;

  const { data: transactions, loading } = useQuery<TransactionsDataType>(TRANSACTIONS_ALL, {
    errorPolicy: "ignore",
    variables: {
      skip: currentPage * pageLimit,
      limit: pageLimit,
      dateMin: dateBegin?.getTime(),
      dateMax: dateEnd?.getTime(),
      types: activeOperations
    }
  });

  const operationsMenuItems = [
    {
      id: "BOT_TRADE",
      text: translator.translate('exchangeTransactionsListItem1')
    },
    {
      id: "BOT_DEPOSIT",
      text: translator.translate('exchangeTransactionsListItem2')
    },
    {
      id: "BOT_WITHDRAW",
      text: translator.translate('exchangeTransactionsListItem3')
    },
    {
      id: "BURSE_TRADE",
      text: translator.translate('exchangeTransactionsListItem4')
    },
    {
      id: "BURSE_DEPOSIT",
      text: translator.translate('exchangeTransactionsListItem5')
    },
    {
      id: "BURSE_WITHDRAW",
      text: translator.translate('exchangeTransactionsListItem6')
    }
  ]

  const changePage = (num: number) => {
    setCurrentPage(num);
  }

  const dateEndChange = (value: Date) => {
    setDateEnd(value);
  }

  const dateBeginChange = (value: Date) => {
    setDateBegin(value);
  }

  const onFilterReset = () => {
    setDateBegin(undefined);
    setDateEnd(undefined);
  }

  return(
    <>
      {loading ? <div className="loader">< Loader type="Oval" color="#fff" height={100} width={100} /></div > :(
        <>
          <FiltersContainer>
            <TransactionsListFilters
              dateBegin={dateBegin}
              dateEnd={dateEnd}
              onDateBeginChange={dateBeginChange}
              onDateEndChange={dateEndChange}
              filters={operationsMenuItems}
              activeOperations={activeOperations}
              onActiveOperationsChange={(newActive) => setActiveOperations(newActive)}
              onReset={onFilterReset}
            />
          </FiltersContainer>

          <TransactionsTable
            data={transactions?.getBotTraderReports.list}
          />

          <Pagination
            total={transactions && transactions.getBotTraderReports.total ? transactions.getBotTraderReports.total / pageLimit : 0}
            paginActive={currentPage}
            changePaginActive={changePage}
          />
        </>
      )}
    </>
  )
}

export default TransactionsList;

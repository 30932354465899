/* eslint-disable no-useless-escape */
import React from 'react'
import {PaginationWrap, StyledPagination} from './styled'

interface IPagination {
  total: number;
  paginActive: number;
  changePaginActive: (e:number)=>void;
  name?: string;
}

export default (
  {
    total,
    paginActive,
    changePaginActive,
    name = ''
  }: IPagination
) => {
  let normalTotal = total;
  normalTotal = Math.trunc(normalTotal);
  let content:any = [];
  let start;
  let end;
  if(paginActive < 4){
    start = 2;
    end = 4;
  } else if(paginActive >= normalTotal - 4){
    start = normalTotal - 5;
    end = normalTotal - 3;
  } else {
    start = paginActive - 1
    end = paginActive + 1
  }

  if (normalTotal > 6){
    content[0] =
      <StyledPagination
        palette="primary"
        active={paginActive === 0}
        onClick={() => changePaginActive(0)}
        key={`pagin${name}_1`}
      >
        1
      </StyledPagination>
    content[1] =
      <StyledPagination
        palette="primary"
        active={paginActive >= 4 ? false : paginActive === 1}
        cursor={paginActive >= 4 ? "default" : "pointer"}
        onClick={paginActive >= 4 ? () => {} : () => changePaginActive(1)}
        key={`pagin${name}_2`}
      >
        {paginActive >= 4 ? '...' : 2}
      </StyledPagination>

    for(let i = start; i <= end; i++) {
      content[content.length] =
        <StyledPagination
          palette="primary"
          active={paginActive === i}
          onClick={() => changePaginActive(i)}
          key={`pagin${name}_${i + 1}`}
        >
          {i + 1}
        </StyledPagination>
    }

    content[5] =
      <StyledPagination
        palette="primary"
        active={paginActive < normalTotal - 4 ? false : paginActive === normalTotal - 2}
        onClick={paginActive < normalTotal - 4 ? () => {} : () => changePaginActive(normalTotal - 2)}
        cursor={paginActive < normalTotal - 4 ? "default" : "pointer"}
        key={`pagin${name}_${normalTotal-1}`}
      >
        {paginActive < normalTotal - 4 ? '...' : normalTotal - 1}
      </StyledPagination>
    content[6] =
      <StyledPagination
        palette="primary"
        active={paginActive === normalTotal - 1}
        onClick={() => changePaginActive(normalTotal - 1)}
        key={`pagin${name}_${normalTotal}`}
      >
        {normalTotal}
      </StyledPagination>
  }else{
    for (let i = 0; i <= normalTotal; i++) {
      content[i] =
        <StyledPagination
          key={`pagin${name}_${i}`}
          palette="primary"
          active={i === paginActive}
          onClick={() => changePaginActive(i)}
        >
          {i + 1}
        </StyledPagination>
    }
  }
  return (
    <PaginationWrap>
      {content}
    </PaginationWrap>
  )
}


import styled from "styled-components";

export const UsersStatistic = styled.div`
  padding: 33px 0;
  margin-bottom: 32px;
  border-bottom: 1px solid rgb(59, 61, 63);
`

export const StatisticsBlock = styled.div<{ margin: string }>`
  margin: ${({margin}) => margin};
`

export const StatisticsTitle = styled.h3<{ fontWeight: "normal" | "bold" }>`
  font-size: 18px;
  margin: 0 0 15px 0;
  font-weight: ${({fontWeight}) => fontWeight};
`

export const StatisticsRow = styled.div`
  display: flex;
`

import styled from "styled-components";

export const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

export const ListItem = styled.li`
  color: rgb(255, 255, 255);
  font-size: 18px;
  margin-bottom: 12px;
  min-height: 20px;
`

export const Title = styled.span`
  font-weight: 600;
  margin-right: 6px;
`

import styled from "styled-components";

export const InputContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 72px;
    padding: 0 20px;
    background-color: rgb(37, 40, 43);
    border-top: 1px solid #fff;
    justify-content: flex-end;
    position: relative;

    & .textbox {
      width: 599px;
      max-height: 51px;
      min-height: 36px;
      background: aliceblue;
      border-radius: 5px;
      margin-left: 20px;
      margin-right: 10px;
      color: black;
      outline: none;
      overflow-wrap: break-word;
      overflow-x: hidden;
      overflow-y: auto;
      position: absolute;
      bottom: 17px;
      left: 0;
    }
`

export const TextArea = styled.textarea`
  color: black;
  width: 100%;
  margin-right: 20px;
  resize: none;
  border-radius: 3px;
  padding: 5px;
`

export const ButtonContainer = styled.div`
  height: 35px;
  width: 45px;
  margin: 13px 13px 13px 0;
`

import React, {useEffect} from "react";
import {
  TitleContainer,
  ChatContainer,
  UsersListContainer,
  MessagesContainer
} from './styled';
import Title from "../../atoms/Title";
import {useTranslator} from "eo-locale";
import RoleRenderer from "../../atoms/RoleRenderer";
import {Link, useParams} from "react-router-dom";
import CSupportUsersList from "../../molecules/CSupportUsersList";
import SupportChatHeader from "../../molecules/SupportChatHeader";
import CSupportMessagesList from "../../molecules/CSupportMessagesList";
import CSupportChatInput from "../../molecules/CSupportChatInput";
import supportChatsStore from "../../../store/SupportChats";
import {useNewMessageSub} from "./useNewMessageSub";
import {useUpdateChatsSub} from "./useUpdateChatsSub";
import {useReadMessageSub} from "./useReadMessageSub";
import {useUpdateMessageSub} from "./useUpdateMessageSub";

const CSupportChat: React.FC = () => {
  const params: {id: string} = useParams();
  const translator = useTranslator();
  const {newMessage} = useNewMessageSub();
  const {updatedChat} = useUpdateChatsSub();
  const {readedMessage} = useReadMessageSub();
  const {updatedMessage} = useUpdateMessageSub();

  useEffect(() => {
    if(readedMessage){
      supportChatsStore.readMessages(readedMessage.chatId, readedMessage.maxMsgId);
    }
  }, [readedMessage]);

  useEffect(() => {
    if(updatedChat){
      supportChatsStore.updateChat(updatedChat._id, updatedChat);
    }
  }, [updatedChat])

  useEffect(() => {
    if(newMessage){
      supportChatsStore.addNewMessage(newMessage.chatId, [newMessage]);
    }
  }, [newMessage]);

  useEffect(() => {
    if(updatedMessage){
      supportChatsStore.updateMessage(updatedMessage);
    }
  }, [updatedMessage])

  return(
    <>
      <TitleContainer>
        <Title margin="0 0 30px 0" fSize="24px">
          {translator.translate('messagesListTitle')}
        </Title>

        <RoleRenderer roles={['ADMIN']}>
          <Link
            to={'/support/settings'}
            style={{textDecoration: 'none'}}
          >
            {translator.translate('supportSettingsLink')}
          </Link>
        </RoleRenderer>
      </TitleContainer>

      <ChatContainer>
        <UsersListContainer>
          <CSupportUsersList />
        </UsersListContainer>

        <MessagesContainer>
          {
            params.id && (
              <>
                <SupportChatHeader />

                <CSupportMessagesList />

                <CSupportChatInput />
              </>
            )
          }
        </MessagesContainer>
      </ChatContainer>
    </>
  )
}

export default CSupportChat;

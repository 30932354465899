import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { SidebarItem, SidebarList, StyledSidebar } from "./style";
import { NavListItem, Styles } from "./type";

interface ISidebarProps {
  navList: NavListItem[];
  styles?: Styles;
}
export const Sidebar: React.FC<ISidebarProps> = ({
  navList,
  ...otherProps
}) => {
  const { pathname } = useLocation();
  return (
    <StyledSidebar {...otherProps}>
      <SidebarList>
        {navList.map((linkData, index) => (
          <SidebarItem key={index}>
            <NavLink
              style={{
                display: "block",
                textDecoration: "none",
              }}
              to={linkData.to}
              activeClassName="activeLink"
            >
              {linkData.text}
            </NavLink>
          </SidebarItem>
        ))}
      </SidebarList>
    </StyledSidebar>
  );
};

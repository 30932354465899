import {gql} from "apollo-boost";

export const GET_LOTS_BY = gql`
    query getLotById($id: Float!){
        getLotById(id:$id){
            _id
            createdBy{
                _id
                firstName
                lastName
                email
            }
            name
            description
            amount
            currency
            insuranceNo
            reasons
            insurance
            privacy
            status
            photos
            certificate
            additionalDocuments
            percentToSell
        }
    }
`
export const USERS_BlOCK = gql`
    mutation setUserRestrictions($email: String!, $internal: Boolean, $output: Boolean){
        setUserRestrictions(query:{email: $email},restrictions:{internal: $internal, output: $output},locale: "RUS"){
            email
            eosName
            restrictions{
                internal
                output
            }
        }
    }
`
export const VERIFIC_PROHIB = gql`
    mutation canUserReverify($email: String!, $access: Boolean!){
        canUserReverify(access:$access, query:{email: $email})
    }
`


import {createGlobalStyle} from "styled-components";
import fonts from "../assets/font";

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Roboto Light';
    src: url('${fonts.RobotoLightEot}');
    src: url('${fonts.RobotoLightEot}?#iefix') format('embedded-opentype'),
    url('${fonts.RobotoLightWoff}') format('woff'),
    url('${fonts.RobotoLightTtf}') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Roboto Regular';
    src: url('${fonts.RobotoRegularEot}');
    src: url('${fonts.RobotoRegularEot}?#iefix') format('embedded-opentype'),
    url('${fonts.RobotoRegularWoff}') format('woff'),
    url('${fonts.RobotoRegularTtf}') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Roboto Medium';
    src: url('${fonts.RobotoMediumEot}');
    src: url('${fonts.RobotoMediumEot}?#iefix') format('embedded-opentype'),
    url('${fonts.RobotoMediumWoff}') format('woff'),
    url('${fonts.RobotoMediumTtf}') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Roboto Bold';
    src: url('${fonts.RobotoBoldEot}');
    src: url('${fonts.RobotoBoldEot}?#iefix') format('embedded-opentype'),
    url('${fonts.RobotoBoldWoff}') format('woff'),
    url('${fonts.RobotoBoldTtf}') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  body {
    margin: 0;    
  }
  input{
    width: 100%;
    height: 100%;    
  }
  img {
    width: 100%;
  }
  svg {
    width: 100%;
    height: 100%;
  }
  button:focus{
    outline: none;
  }
  h1,h2,h3{
    font-weight: normal;
  }
  svg, a, button{
    transition: all 0.3s ease-out;
  }
  .icReport{
    width: 12px;
    height: 17px;
    margin-right: 8px;
  }
  .icCalend{    
    height: 17px;
    width: 18px; 
  }
  .loader{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0, 0.4);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    & svg{
      width: 100px;
      height: 100px;
    }
  }
  * {
    box-sizing: border-box;
    font-family: 'Roboto Regular', sans-serif;
    color: #fff;
    fill: transparent;    
    font-weight: normal;
  }
`

import styled from "styled-components";

export const UsersList = styled.ul`
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
  height: 100%;
  width: 100%;
  background-color: #25282b;
  border-right: 1px solid #fff;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`

export const LoaderContainer = styled.div`
  margin: auto;
  width: 30px;
  height: 30px;
`

import styled from "styled-components";

export const ModalBody = styled.div`
  width: 330px;
  border-radius: 5px;
  background: #353535;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  box-shadow: 9px 6px 23px 0px rgb(0 0 0 / 34%);
  & .report-arrow {
    width: 25px;
    height: 20px;
  }
`;

export const ReportBtnStyles = {
  "transition": "all 500ms ease-out",
  "box-shadow": "5px 5px 7px #232323, -5px -5px 7px #333333",
  "background": "linear-gradient(145deg, #d5d5d5, #3a3a3a)",
  "width": "175px",
  "height": "50px",
  "justify-content": "center",
  "color": "#f5f5f5",
  "margin": "0 0 10px 0",
  "border-radius": "30px",
  "&:hover": {
    "background": "dimgrey",
  }
};

import styled from "styled-components";

export const Background = styled.div`
  width: 580px;
  background-color: rgb(37, 40, 43);
  border-radius: 3px;
  padding: 48px 64px 58px 48px;
`

export const Title = styled.h2`
  font-size: 24px;
  margin: 0 0 32px;
  font-weight: normal;
`

export const Selects = styled.div`
  margin-bottom: 32px;
`

export const Controls = styled.div`
  display: flex;
`

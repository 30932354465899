import styled from "styled-components";
import {ButtonStyledType} from "./index";

export const Button = styled.button<ButtonStyledType>`
  width: 100%;
  max-width: ${({width}) => width};
  height: ${({height}) => height};
  padding: ${({padding}) => padding};
  margin: ${({margin}) => margin };
  font-size: ${({fSize}) => fSize};
  font-family: "Roboto Medium", sans-serif;
  color: black;
  background-color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  
  &:disabled{
    cursor: default;
    background-color: gray;

    &:hover{
      background: gray;
    }
  }

  &:hover{
    background: #F8EFE5;
  }
`

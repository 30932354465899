import React, { useRef, useState } from "react";
import { useOutClick } from "../../../hooks/useOutClick/useOutClick";
import CButton from "../../atoms/CButton";
import { IconId, Svg } from "../../atoms/Svg";
import {
  DropDownContainer,
  DropDownHeader,
  DropDownList,
  ListItem,
} from "./style";
interface IVDropdown {
  options: { text: string; handler: () => void }[];
  IconId?: IconId;
  role?: string;
}

export const CDropdown: React.FC<IVDropdown> = ({ options, role, IconId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<null | HTMLDivElement>(null);
  useOutClick(() => setIsOpen(false), {ref: dropdownRef.current, parent: true})
  const onToggleHandler = () => {
    setIsOpen(!isOpen);
  };
  
  return (
    <DropDownContainer  isRotate={isOpen} ref={dropdownRef}>
      <DropDownHeader onClick={onToggleHandler}>
        {IconId && <Svg iconId={IconId} className="dropdown-chev" />}
        <CButton type="button" styles={{ "font-size": "21px", color: "white" }}>
          {role}
        </CButton>
      </DropDownHeader>
 
      <DropDownList isOpen={isOpen}>
        {options.map((option, index) => {
          const { text, handler } = option;
          return (
            <ListItem key={index}>
              <CButton
                type="button"
                styles={{
                  "color": "white",
                  "font-size": "14px",
                  "font-weight": "normal",
                  "text-align": "left",
                }}
                onClick={() => {
                  handler();
                  setIsOpen(false)
                }}
              >
                {text}
              </CButton>
            </ListItem>
          );
        })}
      </DropDownList>
    
    </DropDownContainer>
  );
};

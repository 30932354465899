import {gql} from "apollo-boost";

export const GET_BOT_STATUS = gql`
  query getSupportBotStatusByChatId($chatId: Float!){
    getSupportBotStatusByChatId(chatId: $chatId)
  } 
`

export const SET_BOT_STATUS = gql`
  mutation setSupportBotStatusByChatId($chatId: Float!, $status: Boolean!){
    setSupportBotStatusByChatId(input: { chatId: $chatId, status: $status })
  }
`

import styled, {css} from 'styled-components';
import {ILoaderContainer} from "./index";

export const LoaderContainer = styled.div<ILoaderContainer>`
    ${({ isGlobal }) => isGlobal ? css`
        position: fixed;
        width: 100vw;
        height: 100vh;
    ` : css`
        position: absolute;
        width: 100%;
        height: 100%;
    ` }
    
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.4);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
`

import {
  CheckCodeDefaultValues,
  CurrentStep,
  ForgotPassFormData,
  Locales,
  UpdatePassDefaultValues,
} from "./type";
const recoveryPassDefaultValues = {
  username: "",
};
const checkCodeDefaultValues = {
  code: "",
};
const updatePassDefaultValues = {
  password: "",
  passwordConfirmation: "",
};

const getDefaultValues = (currentStep?: CurrentStep) => {
  switch (currentStep) {
    case "RECOVERY_PASS":
      return recoveryPassDefaultValues;
    case "CHECK_CODE":
      return checkCodeDefaultValues;
    case "UPDATE_PASS":
      return updatePassDefaultValues;
  }
};

const getInputList = (locales: Locales, currentStep: CurrentStep) => {
  const inputs = [
    {
      name: "username",
      type: "email",
      placeholder: locales.recoveryPassInputEmail,
      styledType: "DARK" as const,
      styles: { "margin-bottom": "15px" },
      rules: {
        required: locales.errorMessageRequiredField,
      },
    },
    {
      name: "code",
      type: "string",
      placeholder: locales.checkCodeInput,
      styledType: "DARK" as const,
      styles: { "margin-bottom": "15px" },
      rules: {
        required: locales.errorMessageRequiredField,
      },
    },
    {
      name: "password",
      type: "password",
      placeholder: locales.updatePassInputPass,
      styledType: "DARK" as const,
      styles: { "margin-bottom": "15px" },
      rules: {
        required: locales.errorMessageRequiredField,
      },
    },
    {
      name: "passwordConfirmation",
      type: "password",
      placeholder: locales.updatePassInputPassConfirm,
      styledType: "DARK" as const,
      styles: { "margin-bottom": "15px" },
      rules: {
        required: locales.errorMessageRequiredField,
      },
    },
  ];
  const getTargetInputs = (inputsObject: { [key: string]: string }) => {
    return inputs.filter(({ name }) =>
      Object.keys(inputsObject).includes(name)
    );
  };
  switch (currentStep) {
    case "RECOVERY_PASS":
      return getTargetInputs(recoveryPassDefaultValues);
    case "CHECK_CODE":
      return getTargetInputs(checkCodeDefaultValues);
    case "UPDATE_PASS":
      return getTargetInputs(updatePassDefaultValues);
  }
};

// Определяет имя кнопки
const getBtnName = (currentStep: CurrentStep, locales: Locales) => {
  switch (currentStep) {
    case "RECOVERY_PASS":
      return locales.recoveryPassBtn;
    case "CHECK_CODE":
      return locales.checkCodeBtn;
    case "UPDATE_PASS":
      return locales.updatePassBtn;
  }
};

// Совпадают ли пароли
const isPasswordsMatch = (newPass: string, newPassConfirm: string) => {
  let collator = new Intl.Collator();
  let result = collator.compare(newPass, newPassConfirm);
  return result === 0 ? true : false;
};

// Время таймера
const getTimeDelay = (delay = 30) => {
  const time = new Date();
  time.setSeconds(time.getSeconds() + delay);
  return (time as unknown) as number;
};

// guard function
const isCurrentStepUpdatePass = (
  formData: ForgotPassFormData
): formData is UpdatePassDefaultValues => {
  return "password" in formData;
};

// guard function
const isCurrentStepCheckCode = (
  formData: ForgotPassFormData
): formData is CheckCodeDefaultValues => {
  return "code" in formData;
};

export {
  getDefaultValues,
  getInputList,
  getBtnName,
  isPasswordsMatch,
  getTimeDelay,
  isCurrentStepUpdatePass,
  isCurrentStepCheckCode,
};

import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import {Container} from './styled';
import SupportBotSettings from "../../organism/SupportBotSettings";
import CSupportChat from "../../organism/CSupportChat";

const Support: React.FC = () => {
  let { path } = useRouteMatch();

  return (
    <>
      <Container>
        <Switch>
          <Route path={`${path}/chats`} exact>
            <CSupportChat />
          </Route>
          <Route path={`${path}/chats/:id`} exact>
            <CSupportChat />
          </Route>
          <Route path={`${path}/settings`} exact>
            <SupportBotSettings />
          </Route>
          <Route path="*">
            <Redirect to={`${path}/chats`} />
          </Route>
        </Switch>
      </Container>
    </>
  );
};

export default Support;

import React from 'react'
import {Container} from "./styled";
import {Route, Switch, useRouteMatch} from "react-router-dom";
import TransactAll from '../../organism/TransactAll'
import TransactItem from "../../organism/TransactItem";
import { observer } from "mobx-react-lite";

const TransactPage: React.FC = observer(() => {
  const {path} = useRouteMatch();

  return (
    <>
      <Container>
        <Switch>
          <Route path={path} exact>
            <TransactAll />
          </Route>

          <Route path={`${path}/:coin`}>
            <TransactItem />
          </Route>
        </Switch>
      </Container>
    </>
  )
})

export default TransactPage;

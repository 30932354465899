import styled, {css} from "styled-components";
import {font} from "styled-theme";

export const StyledSubTitle = styled.h2<{fSize:string,margin:string,bold:boolean}>`
  font-size: ${props => props.fSize};
  margin: 0;
  margin: ${props => props.margin};
  font-weight: normal;
  word-break: break-word;
  ${props => props.bold && css`font-family: ${font('bold')};`};
`

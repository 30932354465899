import React, { useEffect } from "react";
import {Switch, Route, Redirect, useHistory} from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { EOLocale } from "eo-locale";
import TransactPage from "./pages/TransactPage";
import UsersPage from "./pages/UsersPage";
import AuctionsPage from "./pages/AuctionsPage";
import locales from "../locales/data";
import theme from "./themes/default";
import ExchangeBot from "./pages/ExchangeBot";
import Support from "./pages/SupportPage";
import { GlobalStyle } from "./GlobalStyles";
import { observer } from "mobx-react-lite";
import BigNumber from "bignumber.js";
import AuthInfo from "../store/AuthInfo";
import { Layout } from "./Layout";
import { CSettingPage } from "./pages/CSettingPage";
import { HistoryPage } from "./pages/HistoryPage";
import { CustomComissPage } from "./pages/CustomComissPage";
import { AuthPage } from "./pages/AuthPage";
import { PrivateRoute } from "./atoms/PrivateRoute";
import {ToastProvider} from "react-toast-notifications";

const App = observer(() => {
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      AuthInfo.setInfoByToken(token);
    }
  }, []);

  const history = useHistory();

  useEffect(() => {
    if (AuthInfo.exp)
    {
      setTimeout(() => {
        AuthInfo.clearStore();
        localStorage.removeItem('token');
        history.replace("/", null);
      }, AuthInfo.exp * 1000 - Date.now())
    }

  }, [AuthInfo.exp])

  BigNumber.config({
    FORMAT: {
      prefix: "",
      decimalSeparator: ".",
      groupSeparator: " ",
      groupSize: 3,
      secondaryGroupSize: 0,
      fractionGroupSeparator: " ",
      fractionGroupSize: 0,
      suffix: "",
    },
  });

  return (
    <EOLocale.Provider language="ru" locales={locales}>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <ToastProvider>
          {AuthInfo.token ? (
            <Layout isLogin={Boolean(AuthInfo.token)}>
              <Switch>
                <PrivateRoute path="/transact">
                  <TransactPage />
                </PrivateRoute>
                <PrivateRoute path="/comis" exact={true}>
                  <CustomComissPage />
                </PrivateRoute>
                <PrivateRoute path="/comis/history" exact={true}>
                  <HistoryPage />
                </PrivateRoute>
                <PrivateRoute path="/setting">
                  <CSettingPage />
                </PrivateRoute>
                <PrivateRoute path="/exchange">
                  <ExchangeBot />
                </PrivateRoute>
                <PrivateRoute path="/users">
                  <UsersPage />
                </PrivateRoute>
                <PrivateRoute path="/auctions">
                  <AuctionsPage />
                </PrivateRoute>
                <PrivateRoute path="/support">
                  <Support />
                </PrivateRoute>

                <Route path="/">
                  <Redirect
                    to={`${AuthInfo.role === "ADMIN" ? "/transact" : "/users"}`}
                  />
                </Route>
              </Switch>
            </Layout>
          ) : (
            <Layout isLogin={Boolean(AuthInfo.token)}>
              <Route path="/">
                <AuthPage />
              </Route>
            </Layout>
          )}
        </ToastProvider>
      </ThemeProvider>
    </EOLocale.Provider>
  );
});

export default App;

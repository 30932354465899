import React, { FC } from "react";
import { StyledWrapper } from "./style";

export interface IWrapperProps {
  styles?: { [key: string]: string };
}

export const Wrapper: FC<IWrapperProps> = ({ children, ...otherProps }) => {
  return <StyledWrapper {...otherProps}>{children}</StyledWrapper>;
};

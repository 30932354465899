/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import AuctionsAll from "../../organism/AuctionsAll";
import AuctionsInfo from "../../organism/AuctionsInfo";
import {Container} from './styled';

const AuctionsPage = () => {
  let { path } = useRouteMatch();

  return (
    <Container>
      <Switch>
        <Route path={`${path}/:id`} exact>
          <AuctionsInfo />
        </Route>

        <Route path={`${path}`} exact>
          <AuctionsAll />
        </Route>

        <Route path="*">
          <Redirect to={path} />
        </Route>
      </Switch>
    </Container>
  );
};

export default AuctionsPage;

import React, { useEffect, useState } from "react";
import Table, { TableHeadType } from "../../atoms/Table";
import { useHistory, useLocation } from "react-router-dom";
import { Wrapper } from "../../atoms/Wrapper";
import { HistoryBtnStyle, StyledHistoryPage } from "./style";
import CButton from "../../atoms/CButton";
import { Svg } from "../../atoms/Svg";
import { CSubTitle } from "../../atoms/CSubTitle";
import Pagination from "../../atoms/Pagination";
import ComisReportModal, { ComisTableType } from "../../molecules/ComisReportModal/ComisReportModal";
import { useTranslator } from "eo-locale";
import { LocationHistoryState, TableType, InfoDataItem } from "./type";
import BigNumber from "bignumber.js";
import dayjs from "dayjs";

export const HistoryPage = () => {
  const [paginActive, setPaginActive] = useState(0);
  const [isReportOpen, setReportOpen] = useState(false);
  const [reportData, setReportData] = useState<Array<ComisTableType>>();
  const histroy = useHistory();
  const translator = useTranslator();
  const {historyData, symbol} = useLocation<LocationHistoryState>().state;
  const AMOUNT_ITEMS_ON_PAGE = 15;
  const START_PAGINATION = paginActive * AMOUNT_ITEMS_ON_PAGE;
  const headData: Array<TableHeadType<TableType>> = [
    {
      text: translator.translate("comisHistoryTableHead1"),
      align: "center",
      dataKey: "operation",
    },
    {
      text: translator.translate("comisHistoryTableHead2"),
      align: "center",
      dataKey: "to",
    },
    {
      text: translator.translate("comisHistoryTableHead3"),
      align: "center",
      dataKey: "from",
    },
    {
      text: translator.translate("comisHistoryTableHead4"),
      align: "center",
      dataKey: "quantity",
    },
    {
      text: translator.translate("comisHistoryTableHead5"),
      align: "center",
      dataKey: "createdAt",
    },
    {
      text: translator.translate("comisHistoryTableHead6"),
      align: "left",
      dataKey: "memo",
    },
  ];

  // Формирование ряда таблицы
  const renderRow = (item: InfoDataItem) => {
    return {
      operation:
        item.type === "Incoming"
          ? translator.translate("comisHistoryTableBody2")
          : translator.translate("comisHistoryTableBody1"),
      to: item.transaction.to,
      from: item.transaction.from,
      quantity: new BigNumber(parseFloat(item.transaction.quantity)).toFormat(),
      createdAt: dayjs(item.transaction.createdAt).format("DD/MM/YYYY HH:mm:ss"),
      memo: item.type !== "Incoming" ? item.transaction.memo : null,
    };
  };

  // Формирование тела таблицы
  const bodyData: Array<TableType> = [...Array(AMOUNT_ITEMS_ON_PAGE)]
    .reduce((rows: (InfoDataItem & {id: string})[], _, index) => {
      if (historyData[START_PAGINATION + index]) {
        return [...rows, historyData?.[START_PAGINATION + index]];
      } else {
        return rows;
      }
    }, [])
    .map((item: InfoDataItem) => renderRow(item));
  
  // Данные для формирования отчета
  useEffect(() => {
    if (isReportOpen) {
      const newTableData: Array<ComisTableType> = [];
      historyData.forEach((item: InfoDataItem) => {
        newTableData.push({
          type: translator.translate(
            item.type === "Incoming"
              ? "comisHistoryTableBody2"
              : "comisHistoryTableBody1"
          ),
          to: item.transaction.to,
          quantity: item.transaction.quantity,
          from: item.transaction.from,
          createdAt: dayjs(item.transaction.createdAt).format("DD/MM/YYYY HH:mm:ss"),
          memo: item.type !== "Incoming" ? item.transaction.memo : null,
        });
      });
      setReportData(newTableData);
    }
  }, [historyData, isReportOpen, translator]);

  const getCorrectTotal = () => {
    const totalCountPages = Math.ceil(historyData.length / AMOUNT_ITEMS_ON_PAGE) + 1;
    const endStart = AMOUNT_ITEMS_ON_PAGE * totalCountPages - AMOUNT_ITEMS_ON_PAGE;
    const isHaveItemsInEnd = [...Array(AMOUNT_ITEMS_ON_PAGE)].some((_,index) => historyData[endStart + (index)])

    const isIncorrectTotalCountPages = !isHaveItemsInEnd && (totalCountPages === 3 || totalCountPages === 2);
    return isHaveItemsInEnd
    ? totalCountPages
    : isIncorrectTotalCountPages ? totalCountPages - 2 : totalCountPages - 1
  }

  return (
    <StyledHistoryPage>
      {(reportData && isReportOpen) && (
        <ComisReportModal
          enabled={isReportOpen}
          onClose={() => setReportOpen(false)}
          data={reportData}
        />
      )}

      <Wrapper
        styles={{
          "justify-content": "space-between",
          "margin-bottom": "20px",
          "flex-flow": "row wrap",
        }}
      >
        <Wrapper styles={{ "width": "auto" }}>
          <CButton
            type="button"
            styledType="BACK"
            onClick={() => histroy.goBack()}
          >
            <Svg iconId="icChev" color="white" width={"15px"} height={"15px"} />
          </CButton>
          <CSubTitle styles={{ "font-size": "24px", "margin": "0", "font-family": "Roboto bold, sans-serif"}}>
            {translator.translate('comisHistoryTitle', {value: symbol})}
          </CSubTitle>
        </Wrapper>

        <CButton styles={HistoryBtnStyle} type="button" onClick={() => setReportOpen(true)}>
          {translator.translate("modalExchangeTitle")}
        </CButton>
      </Wrapper>
      <Wrapper
        styles={{
          "display": "flex",
          "flex-flow": "column nowrap",
          "background-color": "#25282b",
          "padding": "0 0 20px 0",
        }}
      >
        {bodyData && (
          <Table
            selectedId={""}
            clickable={false}
            headData={headData}
            data={bodyData}
          />
        )}
        <Pagination
          total={getCorrectTotal()}
          paginActive={paginActive}
          changePaginActive={(value: number) => setPaginActive(value)}
        />
      </Wrapper>
    </StyledHistoryPage>
  );
};

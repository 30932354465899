import styled from "styled-components";

export const Table = styled.table`
  min-width: 738px;
  width: 100%;
  table-layout: fixed;
  border-top: 1px solid white;
  border-collapse: collapse;
  font-size: 24px;
  font-weight: 500;
`

export const TableHead = styled.thead`
  border-bottom: 1px solid white;
`

export const TableBody = styled.tbody`
  text-align: right;
`

export const Row = styled.tr`
  height: 35px;
  border-bottom: 1px solid white;
  &:last-child{
    border-bottom: none;
  }
`

export const Cell = styled.td`
  border-right: 1px solid white;
  padding-right: 6px;
  padding-left: 6px;
  &:first-child{
    text-align: left;
  }
  &:last-child{
    border-right: none;
  }
`

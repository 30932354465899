import React from 'react'
import styled, { css } from 'styled-components'
import { font, palette } from 'styled-theme'

export const Button = styled.button<{ fSize: string, margin: string, width: string, height: string, reverses: boolean, palette: string, disabled: boolean, type: string;}>`
  cursor: pointer;
  font-size: ${props => props.fSize};  
  margin: ${props => props.margin};
  padding: 0;
  width: 100%;
  max-width: ${props => props.width};
  height: ${props => props.height};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: normal;
  font-family: ${font('medium')};
  border: none;
  font-size: 16px;
  border-radius: 3px;
  & .icChev{
    height: 14px;
    width: 8px;
  }
  &:hover{
    background: #F8EFE5;
  }
  ${props => props.reverses === true
    ? css`
      background-color: ${props.disabled ? 'rgba(255, 255, 255, 0.3)!important':'transparent'};
      color: ${palette({ primary: 0 }, 1)};
      border: 1px solid ${palette({ primary: 0 }, 1)};
      & *{
        color: ${palette({ primary: 0 }, 1)};
      }
      &:hover{
        background-color: rgba(255, 255, 255, 0.3)!important;
      }
    `
    : css`
      background-color: ${palette({ primary: 0 }, 1)};
      color: ${palette({ primary: 1 }, 1)};
      & *{
        color: ${palette({ primary: 1 }, 1)};
      }
  `}
  ${props => props.disabled === true 
    && css`
      cursor: default;
      background-color: ${palette({ primary: 14 }, 1)};
      &:hover{
        background-color: ${palette({ primary: 14 }, 1)};
      }
  `}

`
export interface Props {
  fSize?: string;
  margin?: string;
  width?: string;
  height?: string;
  name?: string;
  children: any;
  reverse?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  type?: any;
  style?: any;
}
export default ({
  children,
  fSize = '36px',
  margin = '0 0 18px 0',
  onClick,
  width="344px",
  height="53px",
  reverse=false,
  disabled=false,
  name='button',
  type='button',
  style
}:Props) => {
  return (
    <Button
      type={type}
      disabled={disabled}
      palette="primary"
      height={height}
      reverses={reverse}
      width={width}
      fSize={fSize}
      margin={margin}
      onClick={onClick}
      name={name}
      style={style}
    >
      {children}
    </Button>
  )
}

import { useEffect } from "react";
import { CallBack, CompareDomElements, Config } from "./type";
export const useOutClick = (callBack: CallBack, config: Config) => {
  useEffect(() => {
    const compareDomElements: CompareDomElements = (element, targetElement) => {
      const {parentElement} = element;
      if (parentElement === null) return true;

      return parentElement?.isEqualNode(targetElement)
      ? false
      : compareDomElements(parentElement, targetElement)
    }

    const outClick = (ev: MouseEvent) => {
      const target = ev.target as HTMLElement;
      const {ref, parent} = config;

      if (ref) {
        if (parent) {
          const isOutClick = compareDomElements(target, ref);
          isOutClick && callBack();
        } else {
          !target.isEqualNode(ref) && callBack();   
        }
      }
    };

    window.addEventListener("click", outClick);

    return () => window.removeEventListener("click", outClick);
  }, [callBack, config]);
}
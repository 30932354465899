import React, {useCallback, useEffect, useState} from "react";
import ReactPDF, {Document, Page, PDFDownloadLink, Text, View} from "@react-pdf/renderer";
import {useTranslator} from "eo-locale";
import CustomButton from "../../atoms/CustomButton";
import {styles} from "./styled";
import {IUsersDetail} from "../ModalUserReport";
import dayjs from "dayjs";
import BigNumber from "bignumber.js";
import { CLoader } from "../../atoms/CLoader";
import Loader from "react-loader-spinner";

interface IUsersPdfReportButton {
  data: IUsersDetail | undefined,
  totalCount: number | undefined,
  device: string
}

const UsersPdfReportButton: React.FC<IUsersPdfReportButton> = (
  {
    data,
    totalCount,
    device
  }
) => {
  const [pdfData, setPdfData] = useState<React.ReactElement<ReactPDF.DocumentProps> | null>(null);
  const translator = useTranslator();

  const getFormatDataPdf = useCallback((data, totalCount, device) => {
    if(!data || !totalCount) return;

    const items = data?.getUsersDetail.count
    let formatData = data?.getUsersDetail.chart.map((item: any, index: number) => {
      const date = dayjs(item.toDate);
      let normalDate = date.format('DD:MM:YYYY - hh:mm')

      if (index === data?.getUsersDetail.chart.length - 1){
        return(
          <View key={`pdfUserTable${index}`}>
            <View style={styles.table1} >
              <Text style={styles.tableCell1}>{normalDate}</Text>
              <Text style={styles.tableCell1}>{new BigNumber(item.count).toFormat()}</Text>
            </View >
            <View style={styles.allBlock}>
              <View>
                <Text style={styles.titleText}>{translator.translate('usersDetailItemAll')}</Text>
                <Text>{totalCount ? new BigNumber(totalCount).toFormat() : '-'}</Text>
              </View>
              <View style={styles.viewAll}>
                <View>
                  <Text>{translator.translate('usersDetailItemRegistr')}</Text>
                  <Text>{new BigNumber(items).toFormat()}</Text>
                </View>
                <View>
                  <Text>{translator.translate('usersDetailItemInc')}</Text>
                  <Text>{totalCount && `${new BigNumber(items / (totalCount - items) * 100).toPrecision(3)}%`}</Text>
                </View>
              </View>
            </View>
          </View>
        )
      }else{
        return (
          <View key={`pdfTable${index}`} style={styles.table1} >
            <Text style={styles.tableCell1}>{normalDate}</Text>
            <Text style={styles.tableCell1}>{new BigNumber(item.count).toFormat()}</Text>
          </View >
        )
      }
    })

    if(formatData){
      let newPdfData = (
        <Document>
          <Page size="A4" style={styles.page}>
            <View key={`pdfTableTitle`} style={styles.title} >
              <Text >{`${device === 'Desktop' ? 'Desktop/Web' : device} report`}</Text>
            </View>

            {formatData}
          </Page>
        </Document>
      )

      setPdfData(newPdfData);
    }
  }, [translator])

  useEffect(() => {
    getFormatDataPdf(data, totalCount, device);
  }, [data, totalCount, device, getFormatDataPdf])

  return(
    <>
      <PDFDownloadLink
        className="pdfUserDownload"
        document={pdfData as React.ReactElement<ReactPDF.DocumentProps>}
        fileName={`${device === 'Desktop' ? 'Desktop/Web' : device}_report.pdf`}
      >
        {({loading}) => 
          (
          <>
          {loading &&  
          <CLoader styles={{background: "transparent"}} trigger={loading}>
            <Loader color="white" visible={true} type="Oval" width={50} height={50} />
          </CLoader>}
          <CustomButton
            onClick={() => {}}
            height='37px'
            margin="0 0 15px"
          >
            {translator.translate('modalReportBtn2')}
          </CustomButton>
          </>
        )}
      </PDFDownloadLink>
    </>
  )
}

export default UsersPdfReportButton;

import React from "react";
import Loader from "react-loader-spinner";
import { BodyItem, Row, StyledTable, TableContainer, HeadRow, HeadItem, LoaderContainer } from "./styled";

export type TableContainerType = {
  minHeight?: string,
  padding?: string
}

export type RowType = {
  clickable: boolean,
  selected: boolean
}

export type HeadItemType = {
  width?: string,
  textAlign?: string
}

export type BodyItemType = {
  textAlign?: string
}

export type TableHeadType<T> = {
  text: string,
  dataKey: keyof T,
  width?: string,
  align?: string
}

interface IAuctionsTable<T> {
  data: Array<T>,
  headData: Array<TableHeadType<T>>,
  isLoading?: boolean,
  minHeight?: string,
  clickable?: boolean,
  onRowClick?: (item: T, index: number) => void,
  selectedId?: string,
  align?: "left" | "right" | "center"
}

const Table = <T extends { id: string },>(
  props: IAuctionsTable<T>
) => {
  const onRowClick = (item: T, index: number) => {
    if(props.clickable && props.onRowClick){
      props.onRowClick(item, index)
    }
  }

  return(
    <TableContainer
      minHeight={props.minHeight}
    >
      {
        props.isLoading ? (
          <LoaderContainer>
            <Loader type="Oval" color="#fff" height={100} width={100} />
          </LoaderContainer>
        ): null
      }
      <StyledTable>
        <thead>
          <HeadRow>
            {
              props.headData.map((item, index) => (
                <HeadItem
                  width={item.width}
                  key={`head_${item.dataKey}`}
                  textAlign={item.align}
                >
                  {item.text}
                </HeadItem>
              ))
            }
          </HeadRow>
        </thead>

        <tbody>
          {
            props.data.map((item, index) => {
              let isSelected = false

              if(props.selectedId){
                isSelected = props.selectedId === item.id
              }
              return (
                <Row
                  onClick={() => onRowClick(item, index)}
                  key={`row_${index}`}
                  clickable={!!props.clickable}
                  selected={isSelected}
                >
                  {
                    props.headData.map(row => (
                      <BodyItem
                        key={`row_${index}_${row.dataKey}`}
                        textAlign={row.align}
                      >
                        {item[row.dataKey] ? item[row.dataKey] : ''}
                      </BodyItem>
                    ))
                  }
                </Row>
              )
            })
          }
        </tbody>
      </StyledTable>
    </TableContainer>
  )
}

export default Table;

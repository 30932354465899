import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-apollo";
import { SET_SETTINGS } from "../../../gql/useMutation/SET_SETTINGS";
import { SETTING_INFO } from "../../../gql/useQuery/SETTING_INFO";
import { TransactionsDetails } from "./type";

export const useSettingRequest = (timer: number = 500) => {
  const [isSuccess, setSuccess] = useState(false);
  const [isError, setError] = useState(false);
  const [cryptoSymbols, setCryptoSymbols] = useState<null | string[]>(null);

  // ПОЛУЧЕНИЕ ДАННЫХ
  const { loading: getSettingLoading, data } = useQuery(SETTING_INFO);
  const transactionDetails: TransactionsDetails =
    data?.getTransactionCommonDetails;

  // УСТАНОВКА НОВЫХ ДАННЫХ
  const [setSettings, { loading: setSettingLoading }] = useMutation(
    SET_SETTINGS,
    {
      awaitRefetchQueries: true,
      refetchQueries: [{ query: SETTING_INFO }],
      onCompleted: () => setSuccess(true),
      onError: () => setError(true),
    }
  );

  // СОХРАНЯЕТ ИМЕНА ВАЛЮТ
  useEffect(() => {
    if (transactionDetails) {
      const cryptoSymbols = transactionDetails.map(
        (item) => item?.name as string
      );

      setCryptoSymbols(cryptoSymbols);
    }
  }, [transactionDetails]);

  // СБРОС СОСТОЯНИЯ ФЛАГОВ
  useEffect(() => {
    if (!setSettingLoading && isSuccess) {
      setTimeout(() => setSuccess(false), timer);
    }

    if (!setSettingLoading && isError) {
      setTimeout(() => setError(false), timer);
    }
  }, [isError, isSuccess, setSettingLoading, timer]);

  return {
    isSuccess,
    isError,
    cryptoSymbols,
    transactionDetails,
    loading: getSettingLoading || setSettingLoading,
    setSettings,
  };
};

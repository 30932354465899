import styled from "styled-components";

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-content: center;
`

export const ChatContainer = styled.div`
  display: flex;
  height: calc(100vh - 250px);
  width: 100%;
  border-radius: 3px;
  overflow: hidden;
  background-color: rgb(70, 71, 73);
`

export const UsersListContainer = styled.div`
  width: 30%;
  min-width: 250px;
  max-width: 500px;
`

export const MessagesContainer = styled.div`
  width: 70%;
  position: relative;
`

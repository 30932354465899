import styled from "styled-components";

const StyledLayout = styled.div`
  background: #1d1f20;
  min-height: 100vh;
  overflow: auto;
`;

const isLoginWrapperStyles = {
  "padding": "33px",
  "display": "flex",
  "align-items": "flex-start",
  "width": "100%",
};

export { StyledLayout, isLoginWrapperStyles };

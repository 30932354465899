import styled from 'styled-components'

export const Moderation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 66%;
`

export const ModerationStatus = styled.div`
  display: flex;
  flex-direction: column;
`

export const ModerationStatusItem = styled.div`
  padding-top: 10px;
  font-size: 18px;

  &:first-of-type{
    padding-top: 26px;
  }
`

export const FilesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

export const FilesItem = styled.div`
  margin: 10px;
`

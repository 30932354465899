import { reversePalette } from 'styled-theme/composer'

const theme = {palette:{},reversePalette:{},fonts:{},sizes:{}}

theme.palette = {
  primary: ['#ffffff', '#000000', '#3b3d3f', '#1d1f20', '#25282b',
            '#6a7275', '#171819', '#6a7275', '#212426', '#464749',
            '#1d1f20', '#25282b', '#ff3b30', '#ff938d', '#bbbcbb',
            '#d10000', '#1d1f21', '#151617', '#c4c4c4'], //18
  back: ['#1d1f20', '#464749', '#ffffff'],
  input: ['#ffffff', '#212426', '#6a7275', '#000000'],
}

theme.reversePalette = reversePalette(theme.palette)

theme.fonts = {
  light: 'Roboto Light, sans-serif',
  regular: 'Roboto Regular, sans-serif',
  medium: 'Roboto Medium, sans-serif',
  bold: 'Roboto Bold, sans-serif',
}

theme.sizes = {
  maxWidth: '1100px',
}

export default theme

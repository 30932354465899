import React, { Ref, useRef } from "react";
import Modal from "../../atoms/Modal";
import ExcelTableDownload from "../../atoms/ExcelTableDownload";
import PDFTableDownload from "../../atoms/PDFTableDownload";
import PNGTableDownload from "../../atoms/PNGTableDownload";
import { useTranslator } from "eo-locale";
import { PDFHeadType } from "../../atoms/PDFTableDownload";
import { PNGHeadItemType } from "../../atoms/PNGTableDownload";
import CButton from "../../atoms/CButton";
import { CSubTitle } from "../../atoms/CSubTitle";
import { Svg } from "../../atoms/Svg";
import { ModalBody, ReportBtnStyles } from "./style";
import { Wrapper } from "../../atoms/Wrapper";

export type ComisTableType = {
  type: string;
  to: string | null;
  quantity: string;
  from: string | null;
  createdAt: string;
  memo: string | null;
};

interface IComisReportModal {
  enabled: boolean;
  onClose: () => void;
  data: Array<ComisTableType>;
}

const ComisReportModal: React.FC<IComisReportModal> = (props) => {
  const ExcelDownload: Ref<HTMLButtonElement> = useRef(null);
  const PDFDownload: Ref<HTMLButtonElement> = useRef(null);
  const PNGDownload: Ref<HTMLButtonElement> = useRef(null);
  const translator = useTranslator();
  const LabelList = [
    {
      label: translator.translate("comisHistoryTableHead1"),
      value: "type",
    },
    {
      label: translator.translate("comisHistoryTableHead2"),
      value: "to",
    },
    {
      label: translator.translate("comisHistoryTableHead3"),
      value: "from",
    },
    {
      label: translator.translate("comisHistoryTableHead4"),
      value: "quantity",
    },
    {
      label: translator.translate("comisHistoryTableHead5"),
      value: "createdAt",
    },
    {
      label: translator.translate("comisHistoryTableHead6"),
      value: "memo",
    },
  ];
  const PNGheaderWidthList = [300, 300, 300, 300, 300, 900];
  const PDFheaderWidthList = [60, 80, 80, 80, 60, 220];
  
  const onClose = () => {
    props.onClose();
  };

  const createFormatData = (widthList?: number[]) => {
    return LabelList.map((label, index) => {
      if (widthList) {
        return {
          width: widthList[index],
          ...label,
        };
      }

      return label;
    });
  };

  const onExcel = () => {
    ExcelDownload && ExcelDownload.current && ExcelDownload.current.click();
  };

  const onPDF = () => {
    PDFDownload && PDFDownload.current && PDFDownload.current.click();
  };

  const onPNG = () => {
    PNGDownload && PNGDownload.current && PNGDownload.current.click();
  };

  const btnList = [
    { onClickHandler: onExcel, text: translator.translate("reportButton1") },
    { onClickHandler: onPDF, text: translator.translate("reportButton2") },
    { onClickHandler: onPNG, text: translator.translate("reportButton3") },
  ];
 
  return (
    <Modal enabled={props.enabled} onBackgroundClick={onClose}>
      <ModalBody>
        <Wrapper
          styles={{
            "display": "flex",
            "justify-content": "flex-end",
            "padding": "12px 8px 0 0"
          }}
        >
          <CButton
            type="button"
            styles={{
              "width": "35px",
              "height": "22px",
            }}
            onClick={onClose}
          >
            <Svg iconId="icClose" className="close" color="#844040" />
          </CButton>
        </Wrapper>
        <Wrapper styles={{
              "flex-flow": "column",
              "align-items": "center",
              "padding": "15px 15px 28px 15px"
        }}>
          <CSubTitle
            styles={{
              "font-size": "24px",
              "line-height": "28px",
              "color": "white",
              "margin-bottom": "20px",
              "margin": "0 0 20px 0",
            }}
          >
            {translator.translate("modalExchangeTitle")}
          </CSubTitle>
          {btnList.map((btn, index) => (
            <CButton
              key={index}
              type="button"
              onClick={btn.onClickHandler}
              styles={ReportBtnStyles}
            >
              <Svg iconId="icReport" color="#f5f5f5" className="report-arrow" />
              {btn.text}
            </CButton>
          ))}

          <ExcelTableDownload
            cols={createFormatData()}
            data={props.data}
            ref={ExcelDownload}
          />

          <PDFTableDownload
            header={createFormatData(PDFheaderWidthList) as PDFHeadType[]}
            data={props.data}
            ref={PDFDownload}
          />

          <PNGTableDownload
            header={createFormatData(PNGheaderWidthList) as PNGHeadItemType[]}
            data={props.data}
            ref={PNGDownload}
          />
        </Wrapper>
      </ModalBody>
    </Modal>
  );
};

export default ComisReportModal;

import {gql} from "apollo-boost";

export const SEND_MUTATION = gql`
  mutation sendSupportMessage($chatId: Int!, $message: String!) {
    sendSupportMessage(query: {
        chatId: $chatId,
        message: {
            message: $message
        }
    })
  }
`;

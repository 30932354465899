// Совпадают ли пароли
const isPasswordsMatch = (newPass: string, newPassConfirm: string) => {
  let collator = new Intl.Collator();
  let result = collator.compare(newPass, newPassConfirm);
  return result === 0 ? true : false;
};

// Заполнен ли какой-либо инпут
const isSomeInputFilled = (field: string[]) => field.some((val) => val);

export { isPasswordsMatch, isSomeInputFilled };

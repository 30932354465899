import { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "react-apollo";
import { useHistory, useLocation } from "react-router-dom";
import { CREATE_CODE } from "../../../gql/useMutation/CREATE_CODE";
import { CREATE_PASS } from "../../../gql/useMutation/CREATE_PASS";
import { CHECK_CODE } from "../../../gql/useQuery/CHECK_CODE";
import { CurrentStep, LocationState } from "./type";

export const useForgotRequest = (
  formData: { [key: string]: string } | null
) => {
  const [requestError, setRequestError] = useState(false);
  const [requestSuccess, setRequestSuccess] = useState(false);
  const history = useHistory<LocationState>();
  const locationState = useLocation<LocationState>().state;

  // Отправка кода на почту (RECOVERY_PASS)
  const [createRecoveryCode, { loading: recoveryLoading }] = useMutation(
    CREATE_CODE,
    {
      onCompleted: () => {
        setRequestSuccess(true);
        history.push("/forgot-pass", {
          username: formData?.username,
          currentStep: "CHECK_CODE",
        });
      },
      onError: () => {
        setRequestError(true);
      },
    }
  );

  // Создание кода (CHECK_CODE)
  const [reCreateCode] = useMutation(CREATE_CODE);

  // Проверка кода (CHECK_CODE)
  const [checkCode, { loading: checkCodeLoading }] = useLazyQuery(CHECK_CODE, {
    onCompleted: () => {
      setRequestSuccess(true);
      history.push("/forgot-pass", {
        currentStep: "UPDATE_PASS",
        username: locationState.username,
        code: parseFloat(formData?.code.trim() as string),
      });
    },
    onError: () => {
      setRequestError(true);
    },
  });

  // Обновление пароля (UPDATE_PASS)
  const [updatePass, { loading: updatePassLoading }] = useMutation(
    CREATE_PASS,
    {
      onCompleted: () => {
        setRequestSuccess(true);
        history.replace("/");
      },
      onError: () => {
        setRequestError(true);
      },
    }
  );

  // Сброс флагов после выполнения запроса
  useEffect(() => {
    if (requestError) {
      setRequestError(false);
    }

    if (requestSuccess) {
      setRequestSuccess(false);
    }
  }, [requestError, requestSuccess]);

  const isLoading = () => {
    const loadingList = [recoveryLoading, checkCodeLoading, updatePassLoading];
    return loadingList.some((l) => l);
  };

  const getRequestHandler = (currentStep: CurrentStep) => {
    switch (currentStep) {
      case "RECOVERY_PASS":
        return { submit: createRecoveryCode };
      case "CHECK_CODE":
        return { submit: checkCode, reCreateCode: reCreateCode };
      case "UPDATE_PASS":
        return { submit: updatePass };
    }
  };

  return {
    loading: isLoading(),
    requestError,
    requestSuccess,
    requestHandler: getRequestHandler(locationState.currentStep),
  };
};

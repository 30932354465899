import React, {useEffect, useState} from "react";
import {Title, FilesBlock, FilesBlockTitle} from './styled';
import {useTranslator} from "eo-locale";
import {observer} from "mobx-react-lite";
import {UserDocumentType} from "../../organism/UsersInfo";
import KycDocument from "../KycDocument";

interface IUserDocuments {
  documents?: Array<UserDocumentType>
}

const UserDocuments: React.FC<IUserDocuments> = observer(({
  documents = []
}) => {
  const [passportDocs, setPassportDocs] = useState<Array<UserDocumentType>>([]);
  const [passportSelfieDocs, setPassportSelfieDocs] = useState<Array<UserDocumentType>>([]);
  const [incomesDocs, setIncomesDocs] = useState<Array<UserDocumentType>>([]);
  const [additionalDocs, setAdditionalDocs] = useState<Array<UserDocumentType>>([]);
  const translator = useTranslator();


  useEffect(() => {
    let newPassDocs: Array<UserDocumentType> = [];
    let newPassSelfieDocs: Array<UserDocumentType> = [];
    let newIncomeDocs: Array<UserDocumentType> = [];
    let newAddDocs: Array<UserDocumentType> = [];

    documents?.forEach(item => {
      switch (item.type) {
        case 'PASSPORT':{
          newPassDocs.push(item);
          break;
        }
        case 'PASSPORT_SELFIE':{
          newPassSelfieDocs.push(item);
          break;
        }
        case 'INCOMES':{
          newIncomeDocs.push(item);
          break;
        }
        case 'ADDITIONAL':{
          newAddDocs.push(item)
          break;
        }
      }
    })

    setPassportDocs(newPassDocs);
    setPassportSelfieDocs(newPassSelfieDocs);
    setIncomesDocs(newIncomeDocs);
    setAdditionalDocs(newAddDocs);
  }, [documents]);

  const getFileCards = (items: Array<UserDocumentType>) => {
    return items.map((item: UserDocumentType) => <KycDocument document={item} />);
  }

  return(
    <>
      <Title>
        {translator.translate('usersVerificationDoc')}
      </Title>

      {
        passportDocs.length ? (
          <FilesBlock>
            <FilesBlockTitle>
              {translator.translate('userVerificationPassportDocs')}
            </FilesBlockTitle>

            {getFileCards(passportDocs)}
          </FilesBlock>
        ) : null
      }

      {
        passportSelfieDocs.length ? (
          <FilesBlock>
            <FilesBlockTitle>
              {translator.translate('userVerificationPassportSelfieDocs')}
            </FilesBlockTitle>

            {getFileCards(passportSelfieDocs)}
          </FilesBlock>
        ) : null
      }

      {
        incomesDocs.length ? (
          <FilesBlock>
            <FilesBlockTitle>
              {translator.translate('userVerificationIncomesDocs')}
            </FilesBlockTitle>

            {getFileCards(incomesDocs)}
          </FilesBlock>
        ) : null
      }

      {
        additionalDocs.length ? (
          <FilesBlock>
            <FilesBlockTitle>
              {translator.translate('userVerificationAdditionalDocs')}
            </FilesBlockTitle>

            {getFileCards(additionalDocs)}
          </FilesBlock>
        ) : null
      }
    </>
  )
})

export default UserDocuments;

import React from "react";
import {Table, TableHead, Cell, Row, TableBody} from './styled';
import {useTranslator} from "eo-locale";

export type ReportBalances = {
  bot: Array<SymbolData>,
  burse: Array<SymbolData>,
  burseUsd: string,
  botUsd: string
}

export type ProfitBalances = {
  profit: Array<SymbolData>,
  profitUsd: string
}

export type SymbolData = {
  symbol: string,
  value: string
}

interface IBalanceTable {
  data: ReportBalances | undefined,
  profits: ProfitBalances | undefined,
  cells: Array<{ text: string, symbol: string }>
}

const BalanceTable: React.FC<IBalanceTable> = (
  {
    data,
    profits,
    cells
  }
) => {
  const translator = useTranslator();

  const getCells = (data: Array<SymbolData> | undefined, row: number) => {
    return cells.map((item, index) => {
      const record = data?.find(el => item.symbol === el.symbol)

      return(
        <Cell key={`balance_table_row1_${index}`}>
          {record && record.value}
        </Cell>
      );
    })
  }

  return(
    <Table>
      <TableHead>
        <Row>
          <Cell />
          {
            cells.map((item, index) => (
              <Cell key={index}>
                {item.text}
              </Cell>
            ))
          }
          <Cell>{translator.translate('exchangeSumCell')}</Cell>
        </Row>
      </TableHead>

      <TableBody>
        <Row>
          <Cell>
            {translator.translate('currentBalanceRow1')}
          </Cell>

          { getCells(data?.burse, 1) }

          <Cell>{data?.burseUsd}</Cell>
        </Row>

        <Row>
          <Cell>
            {translator.translate('currentBalanceRow2')}
          </Cell>

          { getCells(data?.bot, 2) }

          <Cell>{data?.botUsd}</Cell>
        </Row>

        <Row>
          <Cell>
            {translator.translate('currentBalanceRow3')}
          </Cell>

          { getCells(profits?.profit, 3) }

          <Cell>{profits?.profitUsd}</Cell>
        </Row>
      </TableBody>
    </Table>
  )
}

export default BalanceTable;

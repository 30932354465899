import {gql} from "apollo-boost";

export const SUBSCRIBTION_MESSAGES = gql`
  subscription {
    newMessage {
      _id
      deleted
      message
      fromId
      toId
      chatId
      readed
      date
      entities {
        type
        offset
        length
        url
      }
      media {
        fileId
        thumbId
        mimeType
        filename
      }
    }
  }
`;

export const SUBSCRIBTION_CHATS_UPDATE = gql`
  subscription {
      updateChat {
          _id
          userIds
          topMessage
          topMessageDate
          unreadedCount,
          botAnswers
      }
  }
`;

export const SUBSCRIBTION_READ_MESSAGE = gql`
  subscription {
    readMessage {
      chatId,
      maxMsgId,
      userId,
    }
  }
`;

export const SUBSCRIBTION_UPDATE_MESSAGE = gql`
  subscription {
    updateMessage {
      _id
      deleted
      message
      fromId
      toId
      chatId
      readed
      date
      entities {
        type
        offset
        length
        url
      }
      media {
        fileId
        thumbId
        mimeType
        filename
      }
    }
  }
`;

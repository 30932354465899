import React from "react";
import { StyledButton } from "./style";

type ButtonTypes = "button" | "submit" | "reset" | undefined;
type Styles = { [key: string]: string };
export interface IButtonProps {
  type: ButtonTypes;
  isDisabled?: boolean;
  styledType?: "LIGHT" | "TRANSPARENT" | "BACK";
  styles?: { [key: string]: string | Styles };
  onClick?: () => void;
}
const CButton: React.FC<IButtonProps> = ({
  children,
  type,
  isDisabled,
  ...otherProps
}) => {
  return (
    <StyledButton
      aria-disabled={isDisabled}
      type={type}
      disabled={isDisabled}
      {...otherProps}
    >
      {children}
    </StyledButton>
  );
};

export default CButton;

import styled from "styled-components";

export const StyledComissPage = styled.main`
  padding: 0 20px;
  width: 100%;
  position: relative;
  & .comiss-report {
    width: 20px;
    height: 20px;
    transform: rotate(-90deg);
  }
`;

export const ComissBtnStyles = {
  "border": "1px solid #ffffff",
  "display": "flex",
  "justify-content": "space-between",
  "padding": "10px",
  "border-radius": "4px",
  "&:hover": {
    "background": "rgba(255, 255, 255, 0.3)",
  },
};

import styled, { css } from "styled-components";
import { Styles } from "./type";
const StyledSidebar = styled.nav<{ styles?: Styles }>`
  background: #464749;
  border-radius: 3px;
  max-width: 200px;
  flex-basis: 200px;
  padding: 22px;
  font-size: 16px;
  max-width: 200px;
  min-width: 200px;
  box-shadow: -5px -1px 8px 0px rgb(0 0 0 / 30%);
  ${({ styles }) => styles}
`;

const SidebarList = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-flow: column wrap;
`;

const SidebarItem = styled.li`
  position: relative;
  margin-bottom: 15px;
  transition: padding 0.3s ease, transform 0.3s ease;
  width: 100%;
  word-wrap: break-word;
  white-space: break-spaces;
  
  & a{
    &::before {
      transition: left 0.3s ease;
      content: "";
      border-radius: 10px;
      background: transparent;
      width: 5px;
      height: 5px;
      top: 6px;
      left: -10px;
      position: absolute;
    }
  }
  
  & .activeLink{
    transform: scale(1.2);
    padding-left: 30px;
    &::before {
      background: white;
      top: 0.35em;
      left: 10%;
    }
  }
`;

export { StyledSidebar, SidebarList, SidebarItem };

import styled from "styled-components";

export const Title = styled.div`
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: normal;
  font-family: Roboto Bold,sans-serif;
`

export const Card = styled.div`
  display: flex;
  margin-bottom: 20px;
  
  &:last-child{
    margin-bottom: 0;
  }
`

export const FileContainer = styled.div`
  margin-right: 20px;
`

export const FileInfoItem = styled.div`
  margin-bottom: 10px;
`

export const FilesBlock = styled.div`
  margin-bottom: 25px;
  
  &:last-child{
    margin-bottom: 0;
  }
`

export const FilesBlockTitle = styled.div`
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 15px;
  font-family: Roboto Bold,sans-serif;
`

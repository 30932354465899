import styled, { css } from "styled-components";
import { StyledInputProps } from "./type";

const inputDark = () => css`
  border-radius: 3px;
  border-bottom: 1px solid #6a7275;
  padding: 14px 5px 14px 11px;
  background: #212426;
  transition: all 0.3s ease-out;
  &::placeholder {
    transition: color 0.2s ease-out;
    color: gray;
  }

  &:focus {
    border-bottom: 1px solid white;
    &::placeholder {
      color: #404040b0;
    }
  }
`;

const inputGray = () => css`
  background: #212426;
  border-radius: 3px;
  padding-left: 11px;
  padding-right: 27px;
  transition: all 0.3s ease-out;
`;

const StyledInput = styled.input<StyledInputProps>`
  outline: none;
  border: none;
  font-size: 16px;
  background: transparent;
  ${({ styledType }) => {
    switch (styledType) {
      case "DARK":
        return inputDark();
      case "GRAY":
        return inputGray();
    }
  }}
  ${({ styles }) => styles}
  ${({ isError }) =>
    isError &&
    css`
      border-bottom-color: #ab3c3c;
      color: #ab3c3c;
      &::placeholder {
        color: #ab3c3c;
      }
    `};
`;

export { StyledInput };

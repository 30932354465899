import styled from "styled-components";

export const Background = styled.div`
  position: relative;
  background: rgb(37, 40, 43);
  width: 1000px;
  height: 850px;
  border-radius: 3px;
  padding: 48px 64px 58px 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const CloseButton = styled.button`
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding: 0;
  width: 20px;
  height: 20px;
`

export const ImgContainer = styled.div`
  width: 900px;
  height: 700px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

export const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: contain;

  &:focus{
    outline: none;
  }
`

export const DownloadContainer = styled.div`
  width: 100%;
  text-align: right;
`



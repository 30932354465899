import styled from "styled-components";

export const Title = styled.div`
  display: flex;
  align-items: center;
  font-size: 24px;
  margin: 0 0 24px;
  font-weight: normal;
  font-family: "Roboto Bold", sans-serif;
`

export const Filters = styled.div`
    display: flex;
    margin-bottom: 24px;
`

export const ResetFilters = styled.button`
    border: none;
    border-radius: 5px;
    height: 52px;
    background-color: white;
    color: black;
    cursor: pointer;
`

export const FilterContainer = styled.div`
    width: 500px;
    margin-right: 15px;
`

export const TableContainer = styled.div`
    position: relative;
    overflow: hidden;
    border-radius: 3px;
    min-height: 556px;
    background-color: #25282b;
    padding-bottom: 56px;
`

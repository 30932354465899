import styled, {css} from "styled-components";
import {palette} from "styled-theme";
import {IStyledCheckbox} from "./index";

export const StyledCheckbox = styled.div<IStyledCheckbox>`
  width: ${({width}) => width}px;
  height: ${({height}) => height}px;
  border: 1px solid ${palette({ primary: 18 }, 1)};
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${props => props.active ? css`
    &:after{
      content: '';
      background-color: ${palette({ primary: 18 }, 1)};
      width: ${props.width - 6}px;
      height: ${props.height - 6}px;
      border-radius: 1px;
    }
  ` : null}
`

import styled from "styled-components";

export const ChatHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  height: 50px;
  background-color: #25282b;
  border-bottom: 1px solid white;
`

export const BotStatusContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const BotStatusText = styled.div`
  margin-right: 6px;
  font-size: 14px;
`

export const BotStatus = styled.div<{statusColor: string}>`
  width: 10px;
    height: 10px;
    background-color: ${({statusColor}) => statusColor};
    border-radius: 50%;
`

export const ChatUserName = styled.span`
  font-size: 18px;
  font-weight: 700;
`

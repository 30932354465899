import styled from "styled-components";

export const SearchInput = styled.div`
  width: 100%;
  margin-right: 10px;
`

export const Flex = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 24px 0;
`

export const TableBackground = styled.div`
  background-color: #25282b;
  border-radius: 5px;
  overflow: hidden;
  min-height: 501px;
  padding:0 0 66px 0;
`

import React, { PropsWithChildren } from "react";
import { useController, UseControllerOptions } from "react-hook-form";
import { StyledInput } from "./style";
import { InputListType, OnChangeHandler, SendInputData } from "./type";

export type IInputProps<FormData extends InputListType> = {
  name: string;
  type: string;
  placeholder?: string;
  isError?: boolean;
  styles?: { [key: string]: string };
  styledType?: "DARK" | "GRAY";
  sendInputData?: SendInputData<FormData>;
} & UseControllerOptions;

export const CInput = 
  <FormData extends InputListType>({
    isError,
    control,
    defaultValue,
    name,
    sendInputData,
    ...otherProps
  }: PropsWithChildren<IInputProps<FormData>>) => {
    const {
      field: { onChange, value },
      meta: { invalid },
    } = useController({ control, defaultValue, name, ...otherProps });

    const onChangeHandler = (ev: OnChangeHandler) => {
      onChange(ev); // нужно для правильной работы react-hook-form

      sendInputData?.(name, ev.target.value);
    };

    return (
      <StyledInput
        onChange={onChangeHandler}
        value={value}
        name={name}
        isError={isError || invalid}
        aria-describedby={name}
        aria-invalid={isError || invalid}
        {...otherProps}
      />
    );
  }

import { useTranslator } from "eo-locale";
import { useCallback, useEffect, useState } from "react";
import { ErrorMessage, Errors } from "../LoginPage/type";
import { useLocation } from 'react-router-dom';
import { CurrentStep, LocationState } from "./type";

export const useForgotError = <Err>(errors: Errors<Err>, requestError: boolean, callback: () => void) => {
  const [errorMessage, setErrorMessage] = useState<ErrorMessage>(null);
  const translator = useTranslator();
  const currentStep = useLocation<LocationState>().state.currentStep;
  
  const getRequestErrorMessage = useCallback((currentStep: CurrentStep) => {
    switch(currentStep) {
      case 'RECOVERY_PASS': return translator.translate('recoveryPassReuestError')
      case 'CHECK_CODE': return translator.translate('checkCodeRequestError');
      case 'UPDATE_PASS': return translator.translate('updatePassRequestError')
    }
  },[translator]);

  useEffect(() => {
    const errorNames = Object.keys(errors);
    const isErrorFromForm = errorNames.length !== 0;
    if (isErrorFromForm) {
      setErrorMessage({
        name: errorNames,
        message: currentStep === 'UPDATE_PASS'
        ? translator.translate('updatePassError')
        : translator.translate('loginRequiredError')
      })
    }

    if (requestError) {
      setErrorMessage({
        name: 'requestError',
        message: getRequestErrorMessage(currentStep)
      })
      callback();
    }
  }, [callback, currentStep, errors, getRequestErrorMessage, requestError, translator])

  const IsErrorName = (name: string) => {
    if (Array.isArray(errorMessage?.name)) {
      return errorMessage?.name.some((errorName) => errorName === name);
    } else {
      return errorMessage?.name === "requestError";
    }
  };

  return {IsErrorName, errorMessage, setErrorMessage};
}
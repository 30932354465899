import React from "react";
import {List} from "./styled";
import CheckBoxItem, {CheckboxItemType} from "../../atoms/CheckBoxItem";

interface ICheckBoxList {
  items: Array<CheckboxItemType>,
  margin?: string,
  marginItem?: string,
  selected?: Array<string>,
  onClick?: (item: CheckboxItemType) => void,
  direction?: "row" | "column"
}

const CheckBoxList: React.FC<ICheckBoxList> = (
  {
    selected= [],
    items,
    margin= "0",
    marginItem= "0",
    onClick= () => {},
    direction = "row"
  }
) => {
  return(
    <List
      margin={margin}
      direction={direction}
    >
      {
        items.map(item => (
          <li key={item.key}>
            <CheckBoxItem
              item={item}
              isChecked={selected?.includes(item.key)}
              margin={marginItem}
              onClick={() => onClick(item)}
            />
          </li>
        ))
      }
    </List>
  )
}

export default CheckBoxList;

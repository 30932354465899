import React, { FC, useState } from "react";
import { CHeader } from "../organism/CHeader";
import { CTitle } from "../atoms/CTitle";
import { CDropdown } from "../molecules/CDropdown";
import { Wrapper } from "../atoms/Wrapper";
import { Sidebar } from "../organism/Sidebar";
import Modal from "../atoms/Modal";
import { ModalUpdatePass } from "../molecules/CModalUpdatePass";
import { useHistory } from "react-router-dom";
import AuthStore from "./../../store/AuthInfo/index";
import { useTranslator } from "eo-locale";
import { isLoginWrapperStyles, StyledLayout } from "./style";
import AuthInfo from "./../../store/AuthInfo/index";
import { getFilteredNavList } from "./helpers";

interface ILayoutProps {
  isLogin?: boolean;
}

export const Layout: FC<ILayoutProps> = ({ children, isLogin = false }) => {
  const [isShowUpdatePassModal, setShowUpdatePassModal] = useState(false);
  const history = useHistory();
  const translator = useTranslator();
  const navList = [
    {
      to: "/transact",
      text: translator.translate("mainTabTransact", { value: "" }),
    },
    { to: "/comis", text: translator.translate("mainTabComis") },
    { to: "/users", text: translator.translate("mainTabProfile") },
    { to: "/auctions", text: translator.translate("mainTabAuctions") },
    { to: "/setting", text: translator.translate("mainTabSetting") },
    { to: "/exchange", text: translator.translate("mainTabExchange") },
    { to: "/support", text: translator.translate("mainTabSupport") },
  ];

  const onExit = () => {
    onHideModalHandler();
    localStorage.removeItem("token");
    AuthStore.clearStore();
    history.replace("/", null);
  };

  const onHideModalHandler = () => {
    setShowUpdatePassModal(false);
  };

  return (
    <>
      <Modal
        enabled={isShowUpdatePassModal}
        onBackgroundClick={onHideModalHandler}
      >
        <ModalUpdatePass
          styles={{
            "top": "25%",
            "left": "40%",
            "min-width": "400px",
          }}
          onHideModalHandler={onHideModalHandler}
          onSuccessHandler={onExit}
        />
      </Modal>

      <StyledLayout>
        <CHeader isLogin={isLogin}>
          <CTitle>{translator.translate("title")}</CTitle>
          <CDropdown
            options={[
              {
                text: translator.translate("plugMenuPass"),
                handler: () => setShowUpdatePassModal(true),
              },
              {
                text: translator.translate("plugMenuExit"),
                handler: onExit,
              },
            ]}
            role={
              AuthInfo.role === "MODERATOR"
                ? translator.translate("plugMenuTitle2")
                : translator.translate("plugMenuTitle1")
            }
            IconId="icChev"
          />
        </CHeader>

        <Wrapper styles={isLogin ? isLoginWrapperStyles : { margin: "0 auto" }}>
          {isLogin && <Sidebar navList={getFilteredNavList(navList)} />}
          {children}
        </Wrapper>
      </StyledLayout>
    </>
  );
};

import {makeAutoObservable} from "mobx";

class UserInfo {
    transactPeriod: string = 'Week';
    transactActivePage: number = 0;
    id: number | undefined;
    email: string | undefined;
    fromDate: Date | undefined;
    toDate: Date | undefined;
    verificatonLevel: number = 0;

    constructor() {
        makeAutoObservable(this)
    }

    setEmail(email: string){
        this.email = email
    }

    setId(id: number){
        this.id = id;
    }

    setTransactPeriod(period: string) {
        this.transactPeriod = period
    }

    setTransactActivePage(page: number) {
        this.transactActivePage = page;
    }

    setVerificationLevel(level: number) {
        this.verificatonLevel = level;
    }
}

export default new UserInfo()
